import { useCallback, useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";
import { clsx } from "clsx";
import styles from "./Popup.module.scss";

type BaseTooltipProps = {
  /** When to show the popup. Default: `{ click: true }` */
  showOn?: {
    click?: boolean;
    mouseenter?: boolean;
  };
  /** When to hide the popup. Default: `{ overlayClick: true }` */
  hideOn?: {
    overlayClick?: boolean;
    contentClick?: boolean;
    mouseleave?: boolean;
    /** When user submits a form inside the popup */
    onSubmit?: boolean;
  };
  /** Display a triangle */
  triangle?: boolean;
  /** Where to place the popover */
  placement?: "bottom" | "top" | "right" | "bottom-left";
  offsetVertical?: number;
  offsetHorizontal?: number;
  /** place triangle in right quarter */
  rightTriangle?: boolean;
};

interface PopupReturnValue {
  visible: boolean;
  triggerProps: React.HTMLAttributes<HTMLElement>;
  contentProps: React.HTMLAttributes<HTMLElement>;
}

// Hook
function useEventListener<K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: (this: HTMLDivElement, ev: HTMLElementEventMap[K]) => any,
  element: any = window,
  listen = true,
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<typeof handler>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Make sure element supports addEventListener
    const isSupported = listen && element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    // @ts-ignore
    const eventListener = (event: Parameters<typeof handler>) => savedHandler.current(event);

    // Add event listener
    element.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, listen]); // Re-run if eventName or element changes
}

export function usePopup(props: BaseTooltipProps): PopupReturnValue {
  const [visible, setVisible] = useState(false);
  const contentRef = useRef<HTMLElement>(null);
  const triggerRef = useRef<HTMLElement>(null);
  const {
    offsetHorizontal,
    showOn = { click: true },
    hideOn = { overlayClick: true },
    triangle = true,
    placement = "bottom",
    offsetVertical,
    rightTriangle,
  } = props;

  const [dimensions, setDimensions] = useState({
    popupWidth: 0,
    parentHeight: 0,
    parentWidth: 0,
  });

  useEffect(
    function onVisibilityChange() {
      if (triggerRef.current && contentRef.current) {
        setDimensions({
          parentHeight: triggerRef.current.clientHeight,
          parentWidth: triggerRef.current.clientWidth,
          popupWidth: contentRef.current.clientWidth + (rightTriangle ? contentRef.current.clientWidth / 2 : 0),
        });
      }
    },
    [rightTriangle, visible],
  );

  useEffect(() => {
    if (hideOn.onSubmit) {
      setVisible(false);
    }
  }, [hideOn]);

  const horizontalPlacements = {
    top: () => -(dimensions.popupWidth / 2 - dimensions.parentWidth),
    bottom: () => -(dimensions.popupWidth / 2 - dimensions.parentWidth / 2),
    right: () => dimensions.parentWidth,
    "bottom-left": () => -(dimensions.popupWidth - dimensions.parentWidth),
  };

  const toggleVisibility = useCallback(() => setVisible((state) => !state), []);
  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  const clickHandler = useCallback((event: MouseEvent) => {
    const inContent = contentRef.current?.contains(event.target as Node);
    const inTrigger = triggerRef.current?.contains(event.target as Node);
    if (!inContent && !inTrigger) {
      setVisible(false);
    }
  }, []);

  const shouldListenForOverlayClick = visible && hideOn.overlayClick;

  useEventListener("mousedown", clickHandler, document, shouldListenForOverlayClick);

  return {
    visible,
    triggerProps: {
      // @ts-ignore
      ref: triggerRef,
      onClick: showOn.click ? toggleVisibility : undefined,
      onKeyDown: (e) => e.key === "Escape" && hide(),
      onMouseEnter: showOn.mouseenter ? show : undefined,
      onMouseLeave: hideOn.mouseleave ? hide : undefined,
    },
    contentProps: {
      // @ts-ignore
      ref: contentRef,
      className: clsx(
        styles.popout,
        triangle && !rightTriangle && styles.triangle,
        triangle && rightTriangle && styles.triangleRightQuarter,
        triangle && placement === "right" && styles.triangleLeft,
      ),
      style: {
        top: (() => {
          if (placement === "bottom" || placement === "bottom-left") {
            return dimensions.parentHeight + (offsetVertical || 0);
          }
          if (placement === "right") {
            return -(dimensions.parentHeight / 1.5);
          }
          return undefined;
        })(),
        bottom: (() => {
          if (placement === "top") {
            return dimensions.parentHeight + (offsetVertical || 0);
          }
          return undefined;
        })(),
        // [placement === "bottom" ? "top" : "bottom"]: dimensions.parentHeight + (offsetVertical || 0),
        left: horizontalPlacements[placement]() + (offsetHorizontal || 0),
        visibility: dimensions.parentHeight && visible ? "visible" : "hidden",
      },
      onClick: hideOn.contentClick ? hide : undefined,
    },
  };
}

type PopupProps = BaseTooltipProps & {
  title?: string;
  use?: string | React.ComponentType<any>;
  className?: string;
  trigger: JSX.Element;
  content: JSX.Element;
  contentClassName?: string;
  triggerClassName?: string;
  overlayClassName?: string;
  id?: string;
};

export default function Popup(props: PopupProps) {
  const {
    className,
    use: T = "div",
    content,
    trigger,
    contentClassName,
    triggerClassName,
    overlayClassName,
    id,
    title,
    ...rest
  } = props;
  const { visible, triggerProps, contentProps } = usePopup(rest);

  return (
    <T id={id} className={clsx(styles.container, className)}>
      <button title={title} {...triggerProps} className={triggerClassName}>
        {trigger}
      </button>
      {visible && (
        <>
          <div
            {...contentProps}
            className={clsx(contentProps.className, "rounded-lg bg-white shadow-xl dark:bg-surface2", contentClassName)}
          >
            {content}
          </div>
          {(!rest.hideOn || rest.hideOn.overlayClick) &&
            ReactDOM.createPortal(
              <div className={clsx(styles.overlay, overlayClassName)} />,
              document.getElementById("root")!,
            )}
        </>
      )}
    </T>
  );
}
