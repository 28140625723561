import { type MutableRefObject, useMemo } from "react";

/**
 * Allows for multiple refs to be composed into a single ref, for use as a ref prop.
 *
 * The most common case is when you want to compose a prop ref with a ref that you create in your component.
 */
export function useComposedRefs<T>(value1?: MutableRefObject<T>, value2?: MutableRefObject<T>): MutableRefObject<T> {
  return useMemo(() => {
    return {
      get current() {
        return value1?.current || value2?.current;
      },
      set current(value) {
        if (typeof value === "undefined") {
          return;
        }
        if (value1) {
          value1.current = value;
        }
        if (value2) {
          value2.current = value;
        }
      },
    } as MutableRefObject<T>;
  }, [value1, value2]);
}
