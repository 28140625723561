import { clsx } from "clsx";
import { forwardRef } from "react";

export type VideoProps = {
  src: string;
  muted: boolean | undefined;
  poster: string | undefined;
  preload: boolean | undefined;
  overlay?: JSX.Element;
  onClick?: () => void;
  overlayAlways?: boolean;
};

const Video = forwardRef<HTMLVideoElement, VideoProps>((props, ref) => {
  return (
    <div className="relative flex w-full justify-center">
      <video
        ref={ref}
        src={`${props.src}#t=0.1`}
        preload={props.preload ? "metadata" : "none"}
        muted={props.muted}
        poster={props.poster}
        onClick={props.overlay ? undefined : props.onClick}
        playsInline
        className="w-full"
      />
      {props.overlay ? (
        <div
          className={
            "group absolute inset-0 flex cursor-pointer items-center justify-center transition-colors hover:bg-[rgba(0,0,0,0.4)]"
          }
          onClick={props.onClick}
        >
          <div
            className={clsx(
              "flex h-full w-full items-center justify-center transition-all",
              props.overlayAlways ? "opacity-100" : "opacity-0 group-hover:opacity-100",
            )}
          >
            {props.overlay}
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default Video;
