import type { ReactNode } from "react";
import { clsx } from "clsx";
import usePageTitle from "../hooks/usePageTitle";
import { useSidebar } from "~/components/ui/Sidebar.store";
import { twMerge } from "tailwind-merge";

type Props = {
  /** Document title */
  title: string;
  children: ReactNode;
  className?: string;
};

/**
 * Configures the document title & sidebar.
 *
 * Wrap every page with this component
 */
function FaceliftPage(props: Props) {
  usePageTitle(props.title);
  return (
    <div
      // Used to navigate to the top of the page
      id="top"
      className="w-full max-w-screen-xl scroll-mt-20 sm:px-4"
    >
      <div className={clsx("h-full min-h-[70vh]", props.className)}>{props.children}</div>
    </div>
  );
}

FaceliftPage.WithoutTitle = function FaceliftPageWithoutTitle(props: Omit<Props, "title">) {
  return (
    <div className="w-full max-w-screen-xl px-2 sm:px-4">
      <div className={clsx("min-h-[70vh]", props.className)}>{props.children}</div>
    </div>
  );
};

FaceliftPage.TwoColumns = function FaceliftPageTwoColumns(props: {
  className?: string;
  children: [ReactNode, ReactNode];
}) {
  const sidebarState = useSidebar((s) => s.state);

  return (
    <div className={clsx("flex h-full items-stretch gap-x-6", props.className)}>
      <div
        className={twMerge(
          clsx(
            "hidden w-80 min-w-80 max-w-80 md:block print:md:hidden",
            sidebarState === "open" && "block w-52 min-w-52 max-w-52",
            sidebarState === "closed-md" && "hidden",
          ),
        )}
      >
        {props.children[0]}
      </div>
      <div className="flex w-full scroll-mt-20 flex-col sm:py-2" id="content">
        {props.children[1]}
      </div>
    </div>
  );
};

export default FaceliftPage;
