import { clsx } from "clsx";

const ratingLabels = ["Todo", "Hard", "Good", "Easy"];

function getRatingLevel(percentage: number) {
  if (percentage >= 90) {
    return 3;
  }
  if (percentage >= 50) {
    return 2;
  }
  if (percentage > 0) {
    return 1;
  }
  return 0;
}

export default function RatingCircle({ percentage, useRatingText }: { percentage: number; useRatingText?: boolean }) {
  const ratingLevel = getRatingLevel(percentage);
  const shouldUseRatingText = useRatingText && !!ratingLabels[ratingLevel];

  let ratingText = "";
  if (shouldUseRatingText) {
    ratingText = ratingLabels[ratingLevel];
  } else if (percentage) {
    ratingText = Math.round(percentage).toString();
  } else {
    ratingText = "0";
  }

  return (
    <div
      className={clsx(
        "flex h-12 min-h-12 w-12 min-w-12 items-center justify-center rounded-full text-sm font-semibold",
        {
          "bg-missilesurfacedark text-missilegray2 dark:text-white": !ratingLevel,
          "bg-missileaccent text-white": ratingLevel === 1,
          "bg-orange-500 text-white": ratingLevel === 2,
          "bg-missilegreen text-white": ratingLevel === 3,
        },
      )}
    >
      {ratingText}
    </div>
  );
}
