import { RateableTestTypeIds } from "@rocketlanguages/shared/utils/constants";
import { DrawItFacelift } from "@rocketlanguages/shared/components/RateableTests/DrawIt/DrawItFacelift";
import { FlashcardsFacelift } from "@rocketlanguages/shared/components/RateableTests/Flashcards/FlashcardsFacelift";
import { KnowItFacelift } from "@rocketlanguages/shared/components/RateableTests/KnowIt/KnowItFacelift";
import { QuizFacelift } from "@rocketlanguages/shared/components/RateableTests/Quiz/QuizFacelift";
import { SortItFacelift } from "@rocketlanguages/shared/components/RateableTests/SortIt/SortItFacelift";
import { TranslateItFacelift } from "@rocketlanguages/shared/components/RateableTests/TranslateIt/TranslateItFacelift";
import { WriteItFacelift } from "@rocketlanguages/shared/components/RateableTests/WriteIt/WriteItFacelift";
import { HearItFacelift } from "@rocketlanguages/shared/components/RateableTests/HearIt/HearItFacelift";

export const TestComponentMap = {
  [RateableTestTypeIds.FLASHCARDS]: FlashcardsFacelift,
  [RateableTestTypeIds.HEAR_IT]: HearItFacelift,
  [RateableTestTypeIds.QUIZ]: QuizFacelift,
  [RateableTestTypeIds.KNOW_IT]: KnowItFacelift,
  [RateableTestTypeIds.WRITE_IT]: WriteItFacelift,
  [RateableTestTypeIds.TRANSLATE_IT]: TranslateItFacelift,
  [RateableTestTypeIds.SORT_IT]: SortItFacelift,
  [RateableTestTypeIds.DRAW_IT]: DrawItFacelift,
};

export const ExtraTestComponentMap = {
  [RateableTestTypeIds.WRITE_IT]: WriteItFacelift,
  [RateableTestTypeIds.WRITE_IT_NATIVE]: WriteItFacelift,
  [RateableTestTypeIds.SORT_IT]: SortItFacelift,
  [RateableTestTypeIds.SORT_IT_NATIVE]: SortItFacelift,
};

export const AllTestsComponentMap = {
  ...TestComponentMap,
  ...ExtraTestComponentMap,
};
