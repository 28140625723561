import { useState, useEffect } from "react";

export default function useObserver(
  target: React.MutableRefObject<Element | null>,
  options?: IntersectionObserverInit,
) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Older browsers don't have this
    if (!window.IntersectionObserver) {
      setIsVisible(true);
      return;
    }
    const observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    }, options);
    const element = target.current;
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  return { contentVisibility: isVisible };
}
