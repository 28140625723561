// Old versions of Chrome, Safari don't have Promise.finally
// eslint-disable-next-line no-extend-native
Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    finally(fn) {
      const onFinally = (callback) => Promise.resolve(fn()).then(callback);
      return this.then(
        (result) => onFinally(() => result),
        (reason) => onFinally(() => Promise.reject(reason)),
      );
    },
  }.finally;

if (!Array.prototype.flat) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "flat", {
    configurable: true,
    value: function flat() {
      var depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);

      return depth
        ? Array.prototype.reduce.call(
            this,
            (acc, cur) => {
              if (Array.isArray(cur)) {
                acc.push.apply(acc, flat.call(cur, depth - 1));
              } else {
                acc.push(cur);
              }

              return acc;
            },
            [],
          )
        : Array.prototype.slice.call(this);
    },
    writable: true,
  });
}

if (!Array.prototype.at) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "at", {
    configurable: true,
    value: function at(index) {
      const length = this.length;
      return this[index >= 0 ? index : length + index];
    },
    writable: true,
  });
}
