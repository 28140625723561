import Notation from "../Notation";
import type { PhraseStringNotation } from "@rocketlanguages/types";
import { TooltipContent, TooltipFacelift, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui";
import useNotations from "../../../hooks/useNotations";
import { WritingSystemIds } from "../../../utils/constants";
import { TooltipPortal } from "@rocketlanguages/ui/Tooltip/Tooltip";
import { useRef, useState } from "react";

export default function PhraseStringNotationButton(props: {
  phraseStringNotation: PhraseStringNotation;
  phraseText: string;
  ws: number;
  variant?: "faded";
}) {
  const [open, setOpen] = useState(false);
  const isPointerDownRef = useRef(false);
  const notations = useNotations();
  const activeNotation = notations[props.phraseStringNotation.notation_id] || props.phraseStringNotation.meta;
  const title = activeNotation?.tooltip_label || "";

  const getNotationTextNodes = () => {
    if (!props.phraseText) {
      return [];
    }

    const [lower, upper] = props.phraseStringNotation.mode_value.split("-");
    const notationSelectedText = props.phraseText.substring(Number(lower), Number(upper));
    const tooltipDescription = activeNotation?.tooltip_description || "";
    const isRTLText = props.ws === WritingSystemIds.arabic;

    if (isRTLText) {
      const activeNotationWithReplacersRemoved = activeNotation?.tooltip_description.replace(/(“|")%s(”|")/g, "") || "";
      return [
        <div key="notationSelectedText">
          <span className={`ws-${props.ws} inline-block text-center`}>{notationSelectedText}</span>
        </div>,
        <span key="tooltipLabel">{`${activeNotation?.tooltip_label}: `}</span>,
        <span key="activeNotationWithReplacersRemoved">{activeNotationWithReplacersRemoved}</span>,
      ];
    }

    const parts = tooltipDescription.split("%s");
    const notationTextNodes = [<span key="tooltip_label">{`${activeNotation?.tooltip_label}: `}</span>];

    for (let i = 0; i < parts.length; i++) {
      notationTextNodes.push(<span key={`part-${i}`}>{parts[i]}</span>);
      if (i < parts.length - 1) {
        notationTextNodes.push(
          <span key={`notation-${i}`} className={`ws-${props.ws} inline-block`}>
            {notationSelectedText}
          </span>,
        );
      }
    }

    return notationTextNodes;
  };

  // No notation found
  if (!title) {
    return null;
  }

  return (
    <TooltipProvider delayDuration={200}>
      <TooltipFacelift
        open={open}
        onOpenChange={(v) => {
          if (!isPointerDownRef.current) {
            setOpen(v);
          }
        }}
      >
        <TooltipPortal>
          <TooltipContent>
            <div className="max-w-96">{getNotationTextNodes()}</div>
          </TooltipContent>
        </TooltipPortal>
        <TooltipTrigger
          onPointerDown={() => {
            isPointerDownRef.current = true;
            setOpen(true);
          }}
          onPointerLeave={() => {
            // The library will call `onOpenChange(false)` when the pointer is
            // We'd still like to keep it around on mobile, so we'll defer the ref change
            // this avoids the tooltip from closing when the user taps
            setTimeout(() => {
              isPointerDownRef.current = false;
            }, 16);
          }}
        >
          <Notation title={title} variant={props.variant} />
        </TooltipTrigger>
      </TooltipFacelift>
    </TooltipProvider>
  );
}
