import classes from "./Switch.module.scss";

interface SwitchProps {
  id: string;
  value: boolean;
  label?: string;
  action: () => void;
}

export default function Switch({ id = "switch", value, action, label }: SwitchProps) {
  return (
    <label htmlFor={id} className={classes["gui-switch"]}>
      {label}
      <input id={id} type="checkbox" role="switch" checked={value} onChange={action} />
    </label>
  );
}
