import type { RateableTestMode, ScriptWriterComponent } from "@rocketlanguages/types";
import { asyncResetTest, rateTest, updateComponentRatings } from "../store/lesson/actions";
import { getRatingValueFromLabel, getUpdatedUserComponentRatings } from "../hooks/useRateableTest/utils";

import API from "../res/Api";
import type { SharedRootState } from "../store/types";
import type { Store } from "redux";
import { create } from "zustand";
import { shallowEqual } from "react-redux";
import { useMemo } from "react";
import { useSharedStore } from "../store";
import { shuffleArray } from "../utils";

type DrawItState = {
  /** Current index. Use for accessing testComponents[index] */
  index: number;
  /** Current session status */
  status: "idle" | "active" | "complete";
  /** Whether the user has completed the test in the current session */
  hasCompletedWithinSession: boolean;
  /** All components involved in testing */
  allComponents: ScriptWriterComponent[];
  /** Shuffled list of incomplete components to be tested on */
  testComponents: ScriptWriterComponent[];
  actions: {
    /** Starts the test */
    start(): void;
    /** Redo */
    redoNonEasyCharacters(): void;
    /** Resets the test */
    reset(): void;
    rate(label: "easy" | "good" | "hard"): void;
  };
};

interface UseDrawItProps {
  rateableTestId: number;
  lessonId: number;
  mode: RateableTestMode;
}

/** Creates a unique instance of a draw it store */
export default function useDrawIt({ rateableTestId, lessonId, mode }: UseDrawItProps) {
  const store = useSharedStore();

  const drawItStore = useMemo(
    () => createDrawItStore({ lessonId, store, rateableTestId, mode }),
    [lessonId, store, rateableTestId, mode],
  );

  return drawItStore;
}

export function useDrawItPosition(useDrawItStore: ReturnType<typeof createDrawItStore>) {
  const { status, index, allComponents, testComponents } = useDrawItStore(
    ({ status, testComponents, index, allComponents }) => ({ status, index, allComponents, testComponents }),
    shallowEqual,
  );

  const position = (() => {
    switch (status) {
      case "complete":
        return allComponents.length;
      case "active":
        return Math.min(index + (allComponents.length - testComponents.length) + 1, allComponents.length);
      case "idle":
        return Math.min(index + (allComponents.length - testComponents.length) + 1, allComponents.length);
    }
  })();

  return position;
}

interface CreateDrawItStoreProps {
  store: Store<SharedRootState, any>;
  rateableTestId: number;
  lessonId: number;
  mode: RateableTestMode;
}

function createDrawItStore({ store, rateableTestId, lessonId, mode }: CreateDrawItStoreProps) {
  const getInitialState = (): Omit<DrawItState, "actions"> => {
    const currentStoreState = store.getState();
    const isComplete = isCompleteSelector(currentStoreState, rateableTestId);
    const allComponents = allDrawItComponentsSelector(currentStoreState, lessonId) || [];
    const testComponents =
      mode === "unrated_components"
        ? testComponentsSelector(currentStoreState, allComponents, rateableTestId)
        : nonEasyComponentsSelector(currentStoreState, allComponents, rateableTestId);

    return {
      index: 0,
      status: isComplete ? "complete" : "idle",
      hasCompletedWithinSession: false,
      allComponents,
      testComponents,
    };
  };

  return create<DrawItState>((set, get) => ({
    ...getInitialState(),
    actions: {
      start() {
        set({
          status: "active",
          index: 0,
        });
      },
      reset() {
        // Redux store: reset test
        store.dispatch(
          asyncResetTest({
            rateableTestId,
            lessonId,
          }),
        );
        // Reset state
        set({
          ...getInitialState(),
          status: "active",
        });
      },
      redoNonEasyCharacters() {
        set(() => {
          const currentStoreState = store.getState();
          const allComponents = allDrawItComponentsSelector(currentStoreState, lessonId) || [];
          const nonEasyComponents = nonEasyComponentsSelector(currentStoreState, allComponents, rateableTestId);
          return {
            index: 0,
            status: "active",
            testComponents: nonEasyComponents,
          };
        });
      },
      rate(label: "easy" | "good" | "hard") {
        const currentState = get();
        const currentComponent = currentState.testComponents[currentState.index];
        const nextIndex = currentState.index + 1;
        const isComplete = nextIndex === currentState.testComponents.length;

        if (!currentComponent) {
          return;
        }

        // Go to the next item
        set({
          index: nextIndex,
          status: isComplete ? "complete" : "active",
          hasCompletedWithinSession: isComplete,
        });

        const ratingValue = getRatingValueFromLabel(label);

        // Get updated component ratings
        const componentRatings = getUpdatedUserComponentRatings({
          store: store.getState(),
          componentId: currentComponent.id,
          componentType: "script_writer",
          rateableTestId,
          rating: ratingValue,
        });

        // Update user_rateable_test_component_ratings to rate the phrase (state.ratings.hard, etc)
        store.dispatch(
          updateComponentRatings({
            rateableTestId,
            componentRatings,
          }),
        );

        // Call API with component & test rating
        const testRating = (() => {
          if (!isComplete) {
            return;
          }
          return componentRatings.reduce((prev, curr) => prev + curr.value, 0) / componentRatings.length;
        })();

        const productId = store.getState().preferences.activeProduct?.id || 0;

        const testRatingData: { productId?: number; testRating?: number } = testRating
          ? {
              productId,
              testRating,
            }
          : {
              // Required for clearing dashboard test progress cache
              productId,
            };

        API.get(
          [
            "v2/rate/component/{rateableComponentType:name}/{componentId}/{rateableTest}/{value}",
            {
              "rateableComponentType:name": "script_writer",
              componentId: currentComponent.id,
              rateableTest: rateableTestId,
              value: ratingValue,
            },
          ],
          testRatingData,
        ).catch((err) => {
          // TODO: undo "updateComponentRatings" / "rateTest" actions
          console.warn(err);
        });

        // Client side update of rating level
        if (testRating) {
          store.dispatch(
            rateTest({
              rateableTestId,
              rating: testRating,
            }),
          );
        }
      },
    },
  }));
}

const isCompleteSelector = (state: SharedRootState, rateableTestId: number) => {
  const rateableTestRating = state.lesson.entities.user_rateable_test_ratings[rateableTestId];
  return Boolean(rateableTestRating?.value && !rateableTestRating.marked_complete);
};

export const allDrawItComponentsSelector = (state: SharedRootState, lessonId: number) => {
  return state.lesson.entities.lesson_components[lessonId]
    ?.filter((component) => component.type === "script_writer" && component.status === "live")
    .map((item) => item.component) as ScriptWriterComponent[];
};

const testComponentsSelector = (
  state: SharedRootState,
  allComponents: ScriptWriterComponent[],
  rateableTestId: number,
) => {
  const componentRatings = state.lesson.entities.user_rateable_test_component_ratings[rateableTestId] || [];
  const unratedComponents = allComponents.filter(
    (component) => !componentRatings.find((item) => item.component_id === component.id),
  );

  return shuffleArray(unratedComponents);
};

const nonEasyComponentsSelector = (
  state: SharedRootState,
  allComponents: ScriptWriterComponent[],
  rateableTestId: number,
) => {
  const componentRatings = state.lesson.entities.user_rateable_test_component_ratings[rateableTestId] || [];

  const nonEasyRatings = componentRatings.filter((item) => item.value < 100);

  const nonEasyComponents = allComponents.filter((component) =>
    nonEasyRatings.some((item) => item.component_id === component.id),
  );

  return shuffleArray(nonEasyComponents);
};
