import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { HTMLAttributes } from "react";

import { clsx } from "clsx";

export type CheckboxProps<T = unknown> = HTMLAttributes<HTMLButtonElement> & {
  checked: boolean;
  onChange?: (bool?: boolean, value?: T) => void;
  disabled?: boolean;
  value?: T;
  label?: string | JSX.Element;
  subLabel?: string | JSX.Element;
  name?: string;
};

export default function Checkbox<T = unknown>(props: CheckboxProps<T>) {
  const { checked, onChange, value, label, subLabel, ...rest } = props;

  return (
    <button
      type="button"
      className={clsx("flex items-center text-brand", props.disabled && "text-slate-300")}
      role="checkbox"
      aria-checked={props.checked}
      tabIndex={0}
      onClick={() => {
        if (props.disabled) return;
        if (value) {
          onChange?.(!props.checked, value);
        } else {
          onChange?.();
        }
      }}
      {...rest}
    >
      {checked ? (
        <FaRegCheckSquare className="dark:text-text1 h-[28px] w-[28px] min-w-[28px] text-rocketgreen-alt" />
      ) : (
        <FaRegSquare className="dark:text-text1 h-[28px] w-[28px] min-w-[28px] text-rocketgreen-alt" />
      )}
      <div className="mx-2 flex flex-col text-left">
        {!!label && <span className={clsx("text-text1", props.disabled && "text-slate-300")}>{label}</span>}
        {!!subLabel && (
          <span className={clsx("text-text1 mt-2 text-sm", props.disabled && "text-slate-300")}>{subLabel}</span>
        )}
      </div>
    </button>
  );
}
