import { Button, Centered, CircularProgress, ErrorMessage, RoundedButton } from "@rocketlanguages/ui";
import { useContext, useEffect, useState } from "react";
import useQuizTest, { actions as quizActions } from "../../../hooks/useQuizTest";
import AudioContext from "../../../ui/Audio/AudioContext";
import LessonContext from "../../../context/LessonContext";
import type { QuizAnswer } from "@rocketlanguages/types";
import { RateableTestTypeIds, RateableTestTypes } from "../../../utils/constants";
import RateableTestUI from "../RateableTestUI";
import { ReinforcementContext } from "../../../context/ReinforcementContext";
import { requestRateTest } from "../../../store/lesson/actions";
import { useDispatch } from "react-redux";
import { useSharedSelector, useSharedStore } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";
import API from "../../../res/Api";
import { getNextRateableTest } from "../../../utils";
import { clsx } from "clsx";
import CongratulationsMessage from "../RateableTestUI/includes/CongratulationsMessage";
import ResetTestButton from "../RateableTestUI/buttons/ResetTestButton";
import { TrophyConfettiSvg, TrophySvg } from "../RateableTestUI/CompleteFacelift";
import { Link } from "react-router-dom";
import { useRateableTestRating } from "../../../hooks/useLessonRateableTests";

export function QuizFacelift(props: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  const { setActivePhraseTest, activePhraseTest } = useContext(ReinforcementContext);
  const activeProduct = useSharedSelector((store) => store.preferences.activeProduct);
  const dispatch = useDispatch();
  const test = useQuizTest({
    lessonId: lesson.id,
    rateableTestId: props.rateableTestId,
    onComplete: () => {
      API.post("v2/events/capture", {
        event: "test end",
        properties: {
          rateableTestId: props.rateableTestId,
        },
      });
    },
  });

  const [quizStarted, setQuizStarted] = useState(activePhraseTest === props.rateableTestId);
  const audioContext = useContext(AudioContext);

  // disable audio player when a test is started
  useEffect(() => {
    if (quizStarted) {
      audioContext.activePlayer?.pause();
    }
  }, [quizStarted, audioContext.activePlayer]);

  useEffect(() => {
    if (activePhraseTest !== props.rateableTestId) {
      setQuizStarted(false);
    }
  }, [activePhraseTest, props.rateableTestId]);

  // update state on quiz test reset to return to 'get started'
  useEffect(() => {
    setQuizStarted(activePhraseTest === props.rateableTestId);
  }, [activePhraseTest, props.rateableTestId]);

  const numQuestions = test.computed.quizQuestions.length;

  const position = getPosition({
    test,
    numQuestions,
    quizStarted,
  });

  return (
    <RateableTestUI.Container rateableTestTypeId={RateableTestTypeIds.QUIZ}>
      <RateableTestUI.Header
        testName={t("quiz")}
        testSubheading={t("quiz-subheading")}
        rateableTestId={props.rateableTestId}
        rateableTestTypeId={RateableTestTypeIds.QUIZ}
        ratingLevel={test.computed.ratingLevel}
        position={position}
        total={numQuestions}
      />
      {test.state.isComplete ? <QuizTestComplete rateableTestId={props.rateableTestId} test={test} /> : null}

      {!quizStarted && !test.state.isComplete ? (
        <div className="flex w-full flex-1 items-center justify-center">
          <RoundedButton
            className="hover:bg-missileaccent/90 w-full max-w-60 bg-missileaccent font-semibold text-white"
            onClick={() => {
              if (!activeProduct) {
                return;
              }

              dispatch(
                requestRateTest({
                  productId: activeProduct.id,
                  rateableTestId: props.rateableTestId,
                  rating: 0,
                  markComplete: false,
                }),
              );

              setActivePhraseTest(props.rateableTestId);

              setQuizStarted(true);
              API.post("v2/events/capture", {
                event: "test start",
                properties: {
                  rateableTestId: props.rateableTestId,
                },
              });
            }}
          >
            {t("get-started")}
          </RoundedButton>
        </div>
      ) : null}
      {!test.state.isComplete && quizStarted ? <QuizTest test={test} /> : null}
    </RateableTestUI.Container>
  );
}

function QuizTestComplete({ rateableTestId, test }: { test: ReturnType<typeof useQuizTest>; rateableTestId: number }) {
  const store = useSharedStore();
  const lesson = useContext(LessonContext);
  const t = useTranslation();
  const nextRateableTest = getNextRateableTest({
    rateableTestId: rateableTestId,
    lessonId: lesson.id,
    store,
  });
  const localeKey = nextRateableTest ? RateableTestTypes[nextRateableTest.rateable_test_type_id]?.code : null;
  const nextTestName = localeKey ? t(localeKey) : null;

  return (
    <QuizComplete
      rateableTestId={rateableTestId}
      ratingLevel={test.computed.ratingLevel}
      numAnswered={test.state.answered.length}
      numCorrect={test.state.answered.reduce((prev, curr) => prev + (curr.isCorrect ? 1 : 0), 0)}
      numQuestions={test.computed.quizQuestions.length}
      onReset={test.methods.reset}
      nextActivityTitle={nextTestName ? `Next Activity: ${nextTestName}` : undefined}
      nextActivityHref={localeKey ? `#${localeKey}` : undefined}
    />
  );
}

export function QuizTest(props: { test: ReturnType<typeof useQuizTest> }) {
  const t = useTranslation();
  const { status, state, dispatch, methods, computed } = props.test;

  // While the request is still ongoing
  if (status === "loading") {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }

  if (status === "error") {
    return (
      <ErrorMessage
        title="Network Error"
        message="Looks like there was a problem loading the quiz set."
        actions={
          <Button color="primary" onClick={methods.reset}>
            {t("try-again")}
          </Button>
        }
      />
    );
  }

  if (!computed.currentSection) {
    return null;
  }

  const question = computed.quizQuestions[state.index];
  const hasAnswered = state.answered.length - 1 === state.index;
  const answerId = state.answered[state.index]?.answerId;
  const selectedId = state.selected[state.index];

  if (!question) {
    console.warn("Quiz question not found on index", state.index, computed.quizQuestions);
    return null;
  }

  return (
    <div className="flex w-full flex-col justify-between gap-4">
      <div>
        <div
          className="font-bold leading-5"
          dangerouslySetInnerHTML={{
            // Note: this contains entities, so we need to use dangerouslySetInnerHTML
            __html: question.text,
          }}
        />
        <div className="p-6" />
        <div className="grid grid-cols-2 gap-5">
          {question.answers.map((answer: QuizAnswer) => {
            return (
              <QuizOption
                key={answer.id}
                answered={hasAnswered}
                answer={answer}
                selected={answer.id === (hasAnswered ? answerId : selectedId)}
                onClick={() => dispatch(quizActions.selectAnswer(answer.id))}
              />
            );
          })}
        </div>
      </div>
      <div className="mt-4">
        <CheckContinueButtons
          state={state.answered.length - 1 === state.index ? "continue" : "reveal"}
          onContinue={() => dispatch(quizActions.next())}
          onReveal={methods.confirmAnswer}
          revealDisabled={state.selected.length - 1 !== state.index}
        />
      </div>
    </div>
  );
}

const getPosition = (options: { test: ReturnType<typeof useQuizTest>; numQuestions: number; quizStarted: boolean }) => {
  if (options.test.state.isComplete) {
    return options.numQuestions;
  }
  if (!options.quizStarted) {
    return 0;
  }
  return Math.min(options.numQuestions, options.test.state.index + 1);
};

function QuizOption({
  answer,
  onClick,
  answered,
  selected,
}: {
  answer: QuizAnswer;
  onClick: () => void;
  answered?: boolean;
  selected?: boolean;
}) {
  return (
    <button
      type="button"
      className={clsx(
        "flex flex-row space-x-4 rounded-2xl border px-4 py-5",
        answered
          ? {
              "border-missilegreen bg-green-50 dark:bg-green-950": answer.is_correct,
              "border-missileaccent bg-red-50 dark:bg-red-950": selected && !answer.is_correct,
              "border-missilesurfacedark bg-missilesurfacelight": !selected && !answer.is_correct,
            }
          : {
              "border-missilebrand": selected,
              "border-missilestroke": !selected,
            },
      )}
      onClick={onClick}
      disabled={answered}
    >
      <div
        className={clsx(
          "flex size-6 min-h-6 min-w-6 items-center justify-center rounded-full border bg-white dark:bg-missilesurfacelight",
          answered
            ? {
                "border-missilegreen": answer.is_correct,
                "border-missilesurfacedark": !answer.is_correct && !selected,
                "border-missileaccent": !answer.is_correct && selected,
              }
            : {
                "border-missilebrand": selected,
                "border-missilesurfacedark": !selected,
              },
        )}
      >
        <div
          className={clsx(
            "size-3 rounded-full",
            answered
              ? {
                  "bg-missileaccent": selected && !answer.is_correct,
                  "bg-missilegreen": answer.is_correct,
                }
              : {
                  "bg-missilebrand": selected,
                },
          )}
        />
      </div>
      <div className="flex flex-col items-start space-y-1">
        <span className="font-semibold leading-5" dangerouslySetInnerHTML={{ __html: answer.text }} />
        {answer.explanation ? (
          <span
            className={clsx(
              "text-start",
              !answered && "invisible",
              answered && answer.is_correct ? "text-missiledark" : "text-missilegray2",
            )}
            dangerouslySetInnerHTML={{
              // Note: this is set as HTML entities because it includes html entities
              __html: answer.explanation,
            }}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </button>
  );
}

function CheckContinueButtons({
  state,
  onContinue,
  onReveal,
  revealDisabled,
}: {
  state: "reveal" | "continue";
  onContinue(): void;
  onReveal(): void;
  revealDisabled?: boolean;
}) {
  const t = useTranslation();

  return (
    <RoundedButton
      onClick={state === "continue" ? onContinue : onReveal}
      disabled={revealDisabled}
      className="hover:bg-missileaccent/90 mx-auto w-full max-w-60 bg-missileaccent font-semibold text-white"
    >
      {state === "continue" ? t("continue") : "Check"}
    </RoundedButton>
  );
}

function QuizComplete(props: {
  rateableTestId: number;
  ratingLevel: number;
  numQuestions: number;
  numAnswered: number;
  numCorrect: number;
  onReset: VoidFunction;
  nextActivityTitle?: string;
  nextActivityHref?: string;
}) {
  const t = useTranslation();

  const completedWithinSession = props.numAnswered === props.numQuestions;
  const rating = useRateableTestRating(props.rateableTestId);

  const numCorrect = completedWithinSession
    ? props.numCorrect
    : // Compute the number of correct answers based on the test rating (0-100)
      Math.min(props.numQuestions, Math.ceil(props.numQuestions * (rating / 100)));

  return (
    <div className="mx-auto flex w-96 max-w-96 flex-1 flex-col justify-evenly gap-y-8">
      <div className="relative h-20">
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <TrophySvg />
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <TrophyConfettiSvg />
        </div>
      </div>
      {completedWithinSession ? <CongratulationsMessage /> : null}
      <div className="rounded-2xl border border-missilestroke p-3">
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Easy</div>
          <div className="h-5 w-9 rounded-lg bg-missilegreen px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">{numCorrect}</div>
          </div>
        </div>
        <div className="my-4 border border-dashed border-missilestroke" />
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Hard</div>
          <div className="h-5 w-9 rounded-lg bg-missileaccent px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">
              {props.numQuestions - numCorrect}
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {props.nextActivityHref && props.nextActivityTitle ? (
          <Link to={props.nextActivityHref}>
            <RoundedButton className="hover:bg-missileaccent/90 w-full bg-missileaccent font-semibold text-white">
              {props.nextActivityTitle}
            </RoundedButton>
          </Link>
        ) : null}
        <ResetTestButton className="w-full" testName={t("quiz")} onConfirmReset={props.onReset} />
      </div>
    </div>
  );
}
