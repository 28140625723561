/**
 * Purpose of selectors:
 * Import these selectors to your mapStateToProps/mapDispatchToProps inside your Redux-connected components
 * These selectors are designed to filter out only what is needed for the specific component
 * So that there won't be any re-rendering on small state changes
 */
// Types
import type { LessonComponentUnion, Phrase, VideoComponent } from "@rocketlanguages/types";

import type { SharedRootState } from "./types";
import type { Store } from "redux";

export const userSelector = (state: SharedRootState) => state.user;
export const userProductsSelector = (state: SharedRootState) => state.user.products;
export const userProfileSelector = (state: SharedRootState) => state.user.profile;
export const userPreferencesSelector = (state: SharedRootState) => state.user.preferences;
export const userPaidProductsSelector = (state: SharedRootState) => state.user.products.paid;
export const authSelector = (state: SharedRootState) => state.auth;
export const lessonSelector = (state: SharedRootState) => state.lesson;
export const dashboardSelector = (state: SharedRootState) => state.dashboard;
export const preferencesSelector = (state: SharedRootState) => state.preferences;
export const activeCourseSelector = (state: SharedRootState) => state.preferences.activeCourse;
export const activeProductSelector = (state: SharedRootState) => state.preferences.activeProduct;
export const dashboardProductsSelector = (state: SharedRootState) => state.dashboard.products;
export const hasSyncedThisSessionSelector = (state: SharedRootState) => state.user.hasSyncedThisSession;

export function lessonPhrasesSelector(store: Store<SharedRootState>, lessonId: number): Phrase[] {
  const state = store.getState();
  const phraseIds = state.lesson.entities.lesson_phrase_ids[lessonId];
  const phraseMap = state.lesson.entities.phrases;
  return (phraseIds || []).map((id) => phraseMap[id]!);
}

export function translateItVideosSelector(store: Store<SharedRootState>, lessonId: number): VideoComponent[] {
  const state = store.getState();
  const lessonComponents = state.lesson.entities.lesson_components[lessonId];

  return getTranslateItVideos(lessonComponents);
}

export function getTranslateItVideos(lessonComponents: LessonComponentUnion[] | undefined) {
  const videoComponents: VideoComponent[] = [];
  if (!lessonComponents) {
    return videoComponents;
  }

  lessonComponents.forEach((component) => {
    if (component.type === "video") {
      if (component.status !== "live") {
        return;
      }
      // Matching on the string answer...
      const { caption } = component.component;
      if (caption.length > 0) {
        videoComponents.push(component.component);
      }
    }
  });

  return videoComponents;
}
