import type {
  LessonComponent,
  LessonComponentMap as LessonComponentMapType,
  LessonComponentUnion,
} from "@rocketlanguages/types";
import { memo, useContext } from "react";
import { AddToVocab } from "@rocketlanguages/shared/components/Lesson/Vocab/AddToVocab";
import ComponentStatusWrapper from "@rocketlanguages/shared/components/Lesson/includes/ComponentStatusWrapper";
import FileComponent from "@rocketlanguages/shared/components/Lesson/LessonComponents/FileComponent";
import ImageComponent from "@rocketlanguages/shared/components/Lesson/ImageComponent";
import InteractiveAudioComponent from "@rocketlanguages/shared/components/Lesson/InteractiveAudioComponent";
import LessonContext from "@rocketlanguages/shared/context/LessonContext";
import { MarkdownComponent } from "@rocketlanguages/shared/components/Lesson/MarkdownComponent/MarkdownComponent";
import PhraseboxComponent from "@rocketlanguages/shared/components/Lesson/PhraseboxComponent/PhraseboxComponent";
import ScriptWriterComponent from "@rocketlanguages/shared/components/Lesson/ScriptWriterComponent/ScriptWriterComponent";
import TextComponent from "@rocketlanguages/shared/components/Lesson/LessonComponents/TextComponent";
import TranscriptComponent from "@rocketlanguages/shared/components/Lesson/TranscriptComponent/TranscriptComponent";
import VerbBoxComponent from "@rocketlanguages/shared/components/Lesson/LessonComponents/VerbBoxComponent";
import VideoComponent from "@rocketlanguages/shared/components/Lesson/VideoComponent";
import { useStoreSelector } from "~/store";
import LessonScrollProgressTracker from "../LessonScrollProgressTracker";
import { LessonTypeId } from "@rocketlanguages/shared/utils/constants";
import type { SelectedLanguageId } from "@rocketlanguages/shared/store/preferences/types";

const LessonComponentMap: {
  [key in keyof LessonComponentMapType]: React.ComponentType<{
    lessonComponent: LessonComponent<key, LessonComponentMapType[key]>;
  }>;
} = {
  markdown: MarkdownComponent,
  text: TextComponent,
  file: FileComponent,
  image: ImageComponent,
  video: VideoComponent,
  // audio: AudioComponent,
  audio: InteractiveAudioComponent,
  transcript: TranscriptComponent,
  phrasebox: PhraseboxComponent,
  verb_box: VerbBoxComponent,
  script_writer: ScriptWriterComponent,
  onboarding_step: () => null,
  section_start: () => null,
  section_end: () => null,
};

export default function LessonComponents({ components }: { components: LessonComponentUnion[] | undefined }) {
  const lessonContext = useContext(LessonContext);

  const filterLanguageId = useStoreSelector((state) => {
    const { activeProduct, productOptions } = state.preferences;
    if (!activeProduct || !productOptions) {
      return "all";
    }
    const options = productOptions[activeProduct.id];
    if (!options || !options.filterLanguageId) {
      return "all";
    }
    return options.filterLanguageId;
  });

  return (
    <AddToVocab>
      {/* Non-IAC lessons have their scroll progress tracked */}
      {lessonContext.lesson_type_id !== LessonTypeId.IAC && <LessonScrollProgressTracker lessonId={lessonContext.id} />}
      <LessonComponentElements components={components} filterLanguageId={filterLanguageId} />
    </AddToVocab>
  );
}

const LessonComponentElements = memo(
  ({
    components,
    filterLanguageId,
  }: {
    components: LessonComponentUnion[] | undefined;
    filterLanguageId: SelectedLanguageId;
  }) => {
    if (!components) {
      return null;
    }

    if (!Array.isArray(components)) {
      return <h1>You do not have access to this lesson.</h1>;
    }

    return (
      <>
        {components.map((lessonComponent) => (
          <LessonComponentElement
            key={lessonComponent.id}
            lessonComponent={lessonComponent}
            filterLanguageId={filterLanguageId}
          />
        ))}
      </>
    );
  },
);

function LessonComponentElement({
  lessonComponent,
  filterLanguageId,
}: {
  lessonComponent: LessonComponentUnion;
  filterLanguageId: SelectedLanguageId;
}) {
  const key = `${lessonComponent.component.id}${lessonComponent.type}`;
  const isDraft = lessonComponent.status === "draft";
  const lessonContext = useContext(LessonContext);

  if (lessonContext?.isTravelogue) {
    const languageId = lessonComponent.language_id || 15; // Default to 15 (English) if not set
    const isLanguageEnglish = languageId === 15;
    // Render component if language matches filter
    const isTextOrMarkdown = Boolean(lessonComponent.type === "text" || lessonComponent.type === "markdown");
    const isNotFiltered = Boolean(filterLanguageId === "all" || languageId === filterLanguageId);
    const shouldRender = Boolean(!isTextOrMarkdown || isNotFiltered);

    if (!shouldRender) {
      return null;
    }

    if (!isLanguageEnglish) {
      if (lessonComponent.type === "markdown") {
        return (
          <ComponentStatusWrapper key={key} draft={isDraft}>
            <MarkdownComponent lessonComponent={lessonComponent} translation />
          </ComponentStatusWrapper>
        );
      }
      if (lessonComponent.type === "text") {
        return (
          <ComponentStatusWrapper key={key} draft={isDraft}>
            <TextComponent lessonComponent={lessonComponent} italicize />
          </ComponentStatusWrapper>
        );
      }
    }
  }

  const LessonComponentFn = LessonComponentMap[lessonComponent.type];

  if (!LessonComponentFn) {
    return null;
  }

  return (
    <ComponentStatusWrapper key={key} draft={isDraft}>
      <div className="my-4">
        <LessonComponentFn
          key={key}
          // @ts-ignore
          lessonComponent={lessonComponent}
        />
      </div>
    </ComponentStatusWrapper>
  );
}
