import type { LessonComponent, VideoComponent as VideoComponentType } from "@rocketlanguages/types";
import VideoPlayer from "../../../ui/VideoPlayer";
import { isScriptLanguage } from "../../../utils/constants";
import { useSharedSelector } from "../../../store";

export type Props = {
  lessonComponent: LessonComponent<"video", VideoComponentType>;
};

export default function VideoComponent(props: Props) {
  const { component } = props.lessonComponent;
  const activeCourse = useSharedSelector((store) => store.preferences.activeCourse);
  const isSignLanguage = activeCourse?.slug === "sign-language";

  return (
    <div className="my-10">
      <VideoPlayer
        videoId={component.id}
        title={component.caption}
        url={component.file}
        preload
        thumbnail={component.thumbnail}
        isScript={isScriptLanguage[activeCourse?.slug || ""]}
        muted={isSignLanguage}
      />
    </div>
  );
}
