// Saga initiators
export const ASYNC_REQUEST_LESSON = "Lesson/ASYNC_REQUEST";
export const ASYNC_REQUEST_TESTS = "Lesson/ASYNC_REQUEST_TESTS";
export const RATE_LESSON = "Lesson/RATE_LESSON";
// Rocket Record, etc
export const REQUEST_ADD_POINTS = "Lesson/REQUEST_ADD_POINTS";
// Regular actions
export const REQUEST_START = "Lesson/REQUEST_START";
export const REQUEST_FAIL = "Lesson/REQUEST_FAIL";
export const REQUEST_SUCCESS = "Lesson/REQUEST_SUCCESS";
export const VISIT = "Lesson/VISIT";
// Rateable tests
export const TEST_REQUEST_START = "Lesson/TEST_REQUEST_START";
export const TEST_REQUEST_FAIL = "Lesson/TEST_REQUEST_FAIL";
export const TEST_REQUEST_SUCCESS = "Lesson/TEST_REQUEST_SUCCESS";
// Entities
export const UPDATE_ENTITY = "Lesson/UPDATE_ENTITY";
// Rating
export const RESET_TEST_RATING = "Lesson/RESET_TEST_RATING";
export const RATE_PHRASE = "Lesson/RATE_PHRASE";
export const RATE_PLAY_IT = "Lesson/RATE_PLAY_IT";
export const REQUEST_RATE_TEST = "Lesson/REQUEST_RATE_TEST";
export const RATE_TEST = "Lesson/RATE_TEST";
export const RATE_COMPONENT = "Lesson/RATE_COMPONENT";
export const ASYNC_RESET_TEST = "Lesson/ASYNC_RESET_TEST";
export const RESET_TEST_FAIL = "Lesson/RESET_TEST_FAIL";
export const RESET_TEST_SUCCESS = "Lesson/RESET_TEST_SUCCESS";
export const UPDATE_COMPONENT_RATINGS = "Lesson/UPDATE_COMPONENT_RATINGS";
// Lesson Progress
export const SAVE_AUDIO_PROGRESS = "Lesson/SAVE_AUDIO_PROGRESS";
// Updates the 'isDone' prop on the lesson item
export const UPDATE_LESSON_COMPLETION = "Lesson/UPDATE_LESSON_COMPLETION";
export const SET_NOTATIONS = "Lesson/SET_NOTATIONS";
export const ASYNC_UPDATE_NOTE = "Lesson/ASYNC_UPDATE_NOTE";
export const UPDATE_NOTE = "Lesson/UPDATE_NOTE";
export const CLEAR_NOTE = "Lesson/CLEAR_NOTE";
export const SET_PHRASE_RATING_DISPLAY = "Lesson/SET_PHRASE_RATING_DISPLAY";
export const SET_LESSON_COMPONENTS = "Lesson/SET_LESSON_COMPONENTS";

// Admin
export const ASYNC_ADMIN_REQUEST_LESSON = "Lesson/ASYNC_ADMIN_REQUEST_LESSON";
export const ADMIN_REQUEST_SUCCESS = "Lesson/ADMIN_REQUEST_SUCCESS";
export const ADMIN_ADD_PHRASE_STRING_NOTATION = "Lesson/ADMIN_ADD_PHRASE_STRING_NOTATION";
export const ADMIN_DELETE_PHRASE_STRING_NOTATION = "Lesson/ADMIN_DELETE_PHRASE_STRING_NOTATION";
export const ADMIN_UPDATE_PHRASE_STRING_NOTATION = "Lesson/ADMIN_UPDATE_PHRASE_STRING_NOTATION";
