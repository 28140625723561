import { clsx } from "clsx";
import type { ColorProps, SizeProps } from "./types";

const buttonColors = {
  primary: {
    default: "bg-brand hover:bg-brand-dark active:bg-brand-dark focus:ring",
    faded: "bg-brand-light hover:bg-brand active:bg-brand focus:ring",
    active: "bg-brand-dark hover:bg-brand-dark active:bg-brand-dark focus:ring",
  },
  secondary: {
    default: "bg-rocketblue hover:bg-rocketblue-dark active:bg-rocketblue-dark focus:ring",
    active: "bg-rocketblue-dark hover:bg-rocketblue-dark active:bg-rocketblue-dark focus:ring",
  },
  success: {
    default: "bg-rocketgreen hover:bg-rocketgreen-dark active:bg-rocketgreen-dark focus:ring",
    faded: "bg-rocketgreen opacity-50 hover:opacity-100 active:opacity-100 focus:opacity-100 focus:ring",
    active: "bg-rocketblue-dark hover:bg-rocketblue-dark active:bg-rocketblue-dark focus:ring",
  },
  warning: {
    default: "bg-rocketorange hover:bg-rocketorange-dark active:bg-rocketorange-dark focus:ring",
    faded: "bg-rocketorange opacity-50 hover:opacity-100 active:opacity-100 focus:opacity-100 focus:ring",
    active: "bg-rocketorange-dark hover:bg-rocketorange-dark active:bg-rocketorange-dark focus:ring",
  },
  error: {
    default: "bg-rocketred hover:bg-rocketred-dark active:bg-rocketred-dark focus:ring",
    faded: "bg-rocketred opacity-50 hover:opacity-100 active:opacity-100 focus:opacity-100 focus:ring",
    active: "bg-rocketred-dark hover:bg-rocketred-dark active:bg-rocketred-dark focus:ring",
  },
  transparent: {
    default: "bg-transparent hover:bg-transparent active:bg-transparent",
  },
  grey: {
    default:
      "bg-surface1 dark:bg-neutral-800 dark:hover:bg-neutral-600 dark:text-white hover:bg-neutral-200 active:bg-neutral-200 focus:ring",
    active: "bg-neutral-200 dark:bg-neutral-700 dark:text-white hover:bg-neutral-300 active:bg-neutral-300 focus:ring",
  },
  "light-green": {
    default: "text-rocketgreen-alt bg-brand2 hover:bg-brand2-dark active:bg-brand2-dark focus:ring",
    active: "text-rocketgreen-alt bg-brand2-dark hover:bg-brand2-dark active:bg-brand2-dark focus:ring",
  },
} satisfies { [key in ColorProps]: { default: string; faded?: string; active?: string } };

export const getColorClasses = ({
  color,
  disabled,
  faded,
  active,
}: {
  disabled?: boolean;
  color?: ColorProps;
  faded?: boolean;
  active?: boolean;
}) => {
  if (disabled) {
    return "bg-gray-300";
  }

  const classSet = buttonColors[color || "primary"];

  if (faded) {
    return "faded" in classSet ? classSet.faded : "bg-brand-light hover:bg-brand active:bg-brand focus:ring";
  }

  if (active) {
    return "active" in classSet ? classSet.active : "bg-brand-dark hover:bg-brand-dark active:bg-brand-dark focus:ring";
  }
  return classSet.default;
};

export const getButtonClasses = (props: {
  disabled?: boolean;
  color?: ColorProps;
  faded?: boolean;
  active?: boolean;
  fill?: boolean;
  size?: SizeProps;
  className?: string;
}) => {
  const colorClasses = getColorClasses({
    color: props.color,
    faded: props.faded,
    active: props.active,
    disabled: props.disabled,
  });

  return clsx(
    colorClasses,
    props.fill && "w-full",
    props.size === "small" ? "h-8 py-2 px-2" : "h-10 py-2 px-12",
    props.className,
  );
};
