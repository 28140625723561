import { RateableTestTypeIds } from "../../utils/constants";
import { useMemo } from "react";
import createDrawItSelectors from "./_selectors/drawItSelectors";
import createTranslateItSelectors from "./_selectors/translateItSelectors";
import createPhraseTestSelectors from "./_selectors/phraseTestSelectors";

const selectorMap = {
  default: createPhraseTestSelectors,
  [RateableTestTypeIds.DRAW_IT]: createDrawItSelectors,
  [RateableTestTypeIds.TRANSLATE_IT]: createTranslateItSelectors,
};

type TypeofSelectorMap = typeof selectorMap;

export default function useRateableTestSelectors<TKey extends number>(
  rateableTestTypeId: TKey,
): ReturnType<TKey extends keyof TypeofSelectorMap ? TypeofSelectorMap[TKey] : TypeofSelectorMap["default"]> {
  // @ts-ignore
  return useMemo(
    () => (selectorMap[rateableTestTypeId as keyof typeof selectorMap] || selectorMap.default)(),
    [rateableTestTypeId],
  );
}
