import courses from "@rocketlanguages/shared/res/courses";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import FaceliftPage from "~/components/FaceliftPage";
import { CourseFlagCircle } from "@rocketlanguages/ui";
import { Link } from "react-router-dom";
import { NavArrowLeft as NavArrowLeftIcon } from "iconoir-react";

export default function Signup() {
  const t = useTranslation();

  return (
    <FaceliftPage title="Sign Up" className="p-2">
      <FaceliftPage.TwoColumns>
        <div className="pt-2">
          <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
            <Link
              to="/login"
              className="flex h-12 w-full items-center justify-start gap-2 rounded-3xl px-5 font-bold leading-5 hover:bg-missilestroke focus:outline-none focus:ring focus-visible:ring dark:hover:bg-neutral-700"
            >
              <NavArrowLeftIcon aria-hidden className="h-6 w-6 md:h-4 md:w-4" />
              <span className="inline-block">Back to Log In</span>
            </Link>
          </div>
        </div>
        <div className="overflow-hidden rounded-3xl border border-missilestroke bg-surface2 p-8">
          <div className="pb-8">
            <Link
              to="/login"
              className="flex w-full items-center justify-start gap-x-2 font-bold leading-5 hover:underline md:hidden"
            >
              <NavArrowLeftIcon aria-hidden className="h-6 w-6 md:h-4 md:w-4" />
              <span className="inline-block">Back to Log In</span>
            </Link>
          </div>

          <div className="space-y-8">
            <h1 className="text-3xl font-bold">{t("sign-up-for-a-free-trial-here")}</h1>
            <p>{t("sign-up-for-a-free-trial-here-description")}</p>
            <h3 className="text-xl font-bold">{t("try-it-for-free")}</h3>

            <div className="grid grid-cols-2 justify-items-center gap-4 lg:grid-cols-4 xl:grid-cols-5">
              {courses.map((course) => {
                if (["ebook", "eigo", "arabic-msa"].includes(course.slug)) {
                  return null;
                }
                return (
                  <a
                    key={course.id}
                    className="flex size-36 flex-col items-center justify-center gap-2 rounded-[46px] border border-missilestroke bg-missilesurfacelight p-5 hover:bg-missilesurfacedark"
                    href={`https://www.rocketlanguages.com/${course.slug}/premium`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CourseFlagCircle courseSlug={course.slug} courseName={course.name} />
                    <div className="text-center">{course.name}</div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </FaceliftPage.TwoColumns>
    </FaceliftPage>
  );
}
