import RocketMarkdown from "./RocketMarkdown";
import { defaultOptions } from "./options";
import useParseVocab from "../../../../hooks/useParseVocab";
import { useSharedSelector } from "../../../../store";

interface TravelogueMarkdownComponentProps {
  baseText: string;
  isTranslation: boolean | undefined;
}

export default function TravelogueMarkdownComponent({ baseText, isTranslation }: TravelogueMarkdownComponentProps) {
  const hideEnglish = useSharedSelector((store) => !!Number(store.user.preferences?.hide_english));
  const hideTranslations = useSharedSelector((store) => !!Number(store.user?.preferences?.hide_translations));

  const { text, markdownOptions } = useParseVocab(baseText);

  if (hideEnglish && !isTranslation) {
    return null;
  }

  if (!hideTranslations && isTranslation) {
    return null;
  }

  return (
    <RocketMarkdown
      options={{
        forceBlock: true,
        overrides: { ...defaultOptions.overrides, ...(markdownOptions?.overrides || {}) },
        namedCodesToUnicode: defaultOptions.namedCodesToUnicode,
      }}
    >
      {text}
    </RocketMarkdown>
  );
}
