import { FocusOnMount, RoundedButton } from "@rocketlanguages/ui";
import { clsx } from "clsx";
import DynamicLink from "~/components/DynamicLink";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { type ReactNode, useMemo } from "react";
import { ArrowRight } from "iconoir-react";

type ProductCardLinkProps = {
  title: string;
  to: string;
  /** Optional module label. If empty, defaults to "Modules" */
  moduleLabel?: string;
  description: string;
  imageUrl: string | null;
  status?: "resume" | "getStarted" | "locked";
};

function CallToAction({ text, children }: { text: string; children?: ReactNode }) {
  return (
    <RoundedButton
      type="button"
      className="w-32 gap-1 text-nowrap bg-missileaccent font-semibold text-white group-hover:bg-missileaccent/90"
      tabIndex={-1}
    >
      {text}
      {children}
    </RoundedButton>
  );
}

export function ProductCardLink(props: ProductCardLinkProps) {
  const t = useTranslation();

  // The description can be formatted with bold text in the form of **bold text**
  const formattedDescription = useMemo(
    () =>
      props.description.split(/\*\*(.*?)\*\*/g).reduce(
        (acc, curr, i) => {
          if (i % 2 === 0) {
            acc.push(curr);
          } else {
            acc.push(<strong key={i}>{curr}</strong>);
          }
          return acc;
        },
        [] as (string | JSX.Element)[],
      ),
    [props.description],
  );

  return (
    <FocusOnMount value={props.status === "resume" || props.status === "getStarted"}>
      <DynamicLink
        path={props.to}
        newTab={true}
        className={clsx(
          "group flex h-56 gap-2 overflow-hidden rounded-3xl bg-surface2 outline hover:bg-missilesurfacedark focus:outline-none focus:ring focus-visible:ring dark:hover:bg-neutral-700 sm:h-64",
          props.status === "resume" || props.status === "getStarted"
            ? "shadow-lg outline-brand"
            : "outline-missilestroke",
        )}
      >
        <div className="flex h-full flex-1 flex-col justify-start gap-4 p-6 sm:gap-7">
          <h3 className="text-3xl font-bold">{props.title}</h3>
          <p
            className={"overflow-hidden"}
            style={{ display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical" }}
          >
            {formattedDescription}
          </p>
          <div className="self-start">
            {props.status === "getStarted" && (
              <CallToAction text="Start">
                <ArrowRight className="size-4 min-w-4" />
              </CallToAction>
            )}
            {props.status === "resume" && (
              <CallToAction text={t("continue")}>
                <ArrowRight className="size-4 min-w-4" />
              </CallToAction>
            )}
            {props.status === "locked" && <CallToAction text="Buy Now" />}
          </div>
        </div>
        <div className="hidden justify-end sm:flex">
          {props.imageUrl && (
            <img
              className="h-full w-36 bg-neutral-200 object-cover dark:bg-missilebrand lg:w-64"
              src={props.imageUrl}
              alt={props.title}
              loading="lazy"
            />
          )}
        </div>
      </DynamicLink>
    </FocusOnMount>
  );
}
