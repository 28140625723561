import type { Phrase, SetStateFunction } from "@rocketlanguages/types";
import { createContext, useContext, useState } from "react";
import { type PlayItState, usePlayItAnalytics, usePlayItEvents, usePlayItRewarder } from "./usePlayIt";
import LessonContext from "../../../../../context/LessonContext";
import { useLocation, useNavigate } from "react-router-dom";
import usePlayItComponents from "../../../../../hooks/usePlayItComponents";

export type PlayItContextValue = {
  hideWords: boolean;
  setHideWords: SetStateFunction<boolean>;
  audioOnly: boolean;
  setAudioOnly: SetStateFunction<boolean>;
  filteredWritingSystems: Set<number>;
  setFilteredWritingSystems: SetStateFunction<Set<number>>;
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: (
    key: string | number,
    phrase: Phrase,
    component: JSX.Element,
    phraseIndex: number,
  ) => JSX.Element | null;
};

export const PlayItContext = createContext<PlayItContextValue | null>(null);

type Props = {
  onStart?: (state: PlayItState) => void;
  children: React.ReactNode;
  onEnd?: (state: PlayItState) => void;
  transcriptId: number;
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: (
    key: string | number,
    phrase: Phrase,
    component: JSX.Element,
    phraseIndex: number,
  ) => JSX.Element | null;
};

/**
 * This is a UI component that contains the PlayIt context.
 *
 * Use the PlayItProviderUI component as a child component to lay out the UI (i.e. countdown & stop button)
 *
 * Note: has no idea of the list of phrases.
 * It just works by containing state of the current play it game
 *
 * Used in: TranscriptComponent
 *
 * Usage:
 *
 * ```js
 * <LessonContext.Provider value={{ id: 1, ... }}>
 *   <PlayItProviderUI onStart={() => {}} onEnd={() => {}}>
 *      <TranscriptComponent />
 *   </PlayItProviderUI>
 * </LessonContext.Provider>
 * ```
 */
export default function PlayItProvider(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const lessonId = useContext(LessonContext)?.id;
  const [hideWords, setHideWords] = useState(false);
  const [audioOnly, setAudioOnly] = useState(false);
  /** List of writing system IDs that are filtered */
  const [filteredWritingSystems, setFilteredWritingSystems] = useState<Set<number>>(new Set());

  const playItComponents = usePlayItComponents(lessonId);

  usePlayItRewarder({ lessonId, transcriptId: props.transcriptId });
  usePlayItEvents({
    onStart: (state) => {
      if (state.transcriptId !== props.transcriptId) {
        return;
      }
      props.onStart?.(state);
    },
    onEnd: (state) => {
      if (state.transcriptId !== props.transcriptId) {
        return;
      }
      props.onEnd?.(state);
    },
  });
  usePlayItAnalytics({ transcriptId: props.transcriptId });

  usePlayItEvents({
    onStart: (state) => {
      if (state.transcriptId !== props.transcriptId) {
        return;
      }
      const playItId = getPlayItId(state.transcriptId, playItComponents);
      // play-it-${lessonComponent.component.id}
      // this is to be able to block the popup while doing this activity
      navigate(playItId, { replace: true, relative: "path", preventScrollReset: true });
    },
    onEnd: (state) => {
      if (state.transcriptId !== props.transcriptId) {
        return;
      }
      navigate(location.pathname, { replace: true, preventScrollReset: true });
    },
  });

  return (
    <PlayItContext.Provider
      value={{
        audioOnly,
        setAudioOnly,
        phraseboxWrapper: props.phraseboxWrapper,
        hideWords,
        setHideWords,
        filteredWritingSystems,
        setFilteredWritingSystems,
      }}
    >
      {props.children}
    </PlayItContext.Provider>
  );
}

const getPlayItId = (transcriptId: number, playItComponents: ReturnType<typeof usePlayItComponents>) => {
  if (playItComponents.length <= 1) {
    return "#role-playing";
  }
  // Travelogue components may have multiple play it components
  const playItNumber = playItComponents.findIndex((lc) => lc.component.id === transcriptId) + 1;
  return `#role-playing-#${playItNumber}`;
};
