import { useMemo } from "react";

const markdownFirstTitleRegex = /^[#]+?.*/;

export function parseMarkdownTitleAndBody(markdown: string | null | undefined) {
  if (!markdown) {
    return {
      title: "",
      body: "",
    };
  }
  const title = (markdown.match(markdownFirstTitleRegex)?.[0] || "").trim();
  const body = (markdown.replace(markdownFirstTitleRegex, "") || "").trim();

  return { title, body };
}

export default function useCustomMarkdownTitleBodyParser(markdown: string) {
  return useMemo(() => parseMarkdownTitleAndBody(markdown), [markdown]);
}
