import { clsx } from "clsx";
import type { ReactNode } from "react";

type PositionProps = {
  ratingLevel?: number;
  children: ReactNode;
  className?: string;
};

export default function Position({ children, ratingLevel, className }: PositionProps) {
  const colorClass: Record<number, string> = {
    0: "bg-brand2 text-rocketgreen-alt dark:text-text1 dark:bg-neutral-700",
    1: "bg-rocketred text-white",
    2: "bg-rocketorange text-white",
    3: "bg-rocketgreen text-white",
  };
  return (
    <div
      className={clsx(
        colorClass[ratingLevel || 0],
        "flex h-10 w-12 select-none items-center justify-center whitespace-nowrap rounded-md px-4 font-semibold sm:w-16",
        className,
      )}
    >
      {children}
    </div>
  );
}
