import type { LessonComponent, ScriptWriterComponent as ScriptWriterComponentType } from "@rocketlanguages/types";
import { getRatingButtonColorProp, getRatingLevel, getRatingPercentageDisplay } from "../../../utils";

import { Button } from "@rocketlanguages/ui";
import ScriptWriter from "../../RateableTests/DrawIt/includes/HanziWriter/ScriptWriter";
import { clsx } from "clsx";

export type Props = {
  lessonComponent: LessonComponent<"script_writer", ScriptWriterComponentType>;
  showEmptyComponents?: boolean;
};

export default function ScriptWriterComponent(props: Props) {
  const { component } = props.lessonComponent;

  // In the case of admins publishing components without a character
  if (!component.character && !props.showEmptyComponents) {
    return null;
  }

  return (
    <ScriptWriter
      component={component}
      showHintAfterMisses={2}
      showCharacter
      showOutline
      // hideAnimationButton
      controls={({ quiz, rating, startQuiz, stopQuiz }) => {
        return (
          <>
            <div />
            <Button color="secondary" onClick={!quiz?.active ? startQuiz : stopQuiz}>
              {!quiz?.active ? "DRAW" : "STOP"}
            </Button>

            <Button
              size="small"
              color={getRatingButtonColorProp(getRatingLevel(rating || 0))}
              className={clsx("pointer-events-none w-[48px]", typeof rating !== "number" && "invisible")}
            >
              {typeof rating === "number" ? getRatingPercentageDisplay(rating) : null}
            </Button>
          </>
        );
      }}
    />
  );
}
