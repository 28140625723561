import { clearNote, updateNote } from "@rocketlanguages/shared/store/lesson/actions";
import { useEffect, useRef, useState } from "react";

import type { NoteTypeSimple } from "@rocketlanguages/types";
import useNoteEditor from "@rocketlanguages/shared/hooks/useNoteEditor";
import { useStoreDispatch } from "~/store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";

interface LessonNoteEditorProps {
  note: NoteTypeSimple | null;
  lessonId: number;
  onSaving(value: boolean): void;
  onClose(): void;
}

export default function LessonNoteEditor({ note, lessonId, onSaving, onClose }: LessonNoteEditorProps): JSX.Element {
  const t = useTranslation();
  const dispatch = useStoreDispatch();
  const [localNote, setLocalNote] = useState<string | null>(note?.text || "");
  const textRef = useRef<HTMLTextAreaElement | null>(null);

  const [hasEdited, setHasEdited] = useState(false);
  const { saving } = useNoteEditor({
    id: lessonId,
    idType: "lesson",
    text: localNote || "",
    onUpdate: (note) => {
      if (note) {
        dispatch(updateNote(note));
      } else {
        dispatch(clearNote({ lessonId }));
      }
    },
    allowSave: hasEdited,
  });

  useEffect(() => {
    onSaving(saving);
  }, [saving, onSaving]);

  return (
    <div className="flex flex-col">
      <label className="mb-2 text-sm font-bold">{t("add-a-note-to-this-lesson")}</label>
      <textarea
        ref={textRef}
        autoFocus
        value={localNote || ""}
        placeholder={t("notes-placeholder-text")}
        className={
          "h-36 w-96 min-w-[280px] resize rounded-md border-2 border-brand bg-surface1 p-4 focus:ring dark:bg-neutral-800"
        }
        onKeyDown={(e) => {
          // Close the editor on escape
          if (e.key === "Escape") {
            onClose();
          }
        }}
        onChange={(e) => {
          setHasEdited(true);
          setLocalNote(e.currentTarget.value);
        }}
      />
    </div>
  );
}
