import * as constants from "./constants";

import type {
  Course,
  CoursePreferences,
  ExtraProfilePayload,
  FlashcardPreferences,
  Product,
  Rank,
  SyncBasicUserPayload,
  SyncUserAndProductPayload,
  UnseenNotifications,
  UserPreferences,
  UserProfile,
  UserStats,
  VoiceRecognitionDifficulty,
} from "@rocketlanguages/types";

import type { UserState } from "./types";
import { action } from "typesafe-actions";

/**
 * Called from CourseSelectionScreen
 * Handled in sagas.ts
 * @param course Course object
 * @param shouldForceUpdateProduct Used for "GetStarted" to reinitialize the product
 * @param shouldNavigate whether the user should navigate to the dashboard of the updated course
 */
export function asyncSelectCourse(course: Course, shouldNavigate: boolean, shouldForceUpdateProduct = false) {
  return action(constants.ASYNC_SELECT_COURSE, {
    course,
    shouldForceUpdateProduct,
    shouldNavigate,
  });
}

/*
 * Sets the active product in preferences before then consuming the asyncSelectCourse call
 */
export function asyncSelectProduct(product: Product, course: Course, shouldNavigate: boolean) {
  return action(constants.ASYNC_SELECT_PRODUCT, {
    product,
    course,
    shouldNavigate,
  });
}

export function sagaSyncUser() {
  return action(constants.SAGA_SYNC_USER);
}

/**
 * May occur on rehydration (resuming) of app
 * Use when the active product isn't set. Otherwise see syncUserAndProduct.
 */
export function syncBasicUser(user: SyncBasicUserPayload) {
  return action(constants.SYNC_BASIC_USER, {
    user,
  });
}

/**
 * May occur on rehydration (resuming) of app
 * Occurs on rehydration
 * Side effects: Sets suggested lesson ID on Dashboard store
 */
export function syncUserAndProduct(productId: number, payload: SyncUserAndProductPayload) {
  return action(constants.SYNC_USER_AND_PRODUCT, {
    payload,
    productId,
  });
}

export function updateLeaderboardPosition(position: number, totalPositions: number) {
  return action(constants.UPDATE_LEADERBOARD_POSITION, {
    position,
    totalPositions,
  });
}

export function updateStreak(currentStreak: number, longestStreak: number) {
  return action(constants.UPDATE_STREAK, {
    currentStreak,
    longestStreak,
  });
}

export function updateDailyPoints(dailyPoints: number) {
  return action(constants.UPDATE_DAILY_POINTS, {
    dailyPoints,
  });
}

export function updateDailyPointsGoal(dailyPointsGoal: number) {
  return action(constants.UPDATE_DAILY_POINTS_GOAL, {
    dailyPointsGoal,
  });
}

export function updateRRDifficulty(difficulty: VoiceRecognitionDifficulty) {
  return action(constants.UPDATE_RR_DIFFICULTY, {
    difficulty,
  });
}

/**
 * Updates daily points, longest streak, position
 */
export function updateStats(stats: UserStats) {
  return action(constants.UPDATE_STATS, { stats });
}

/**
 * An error occurs after selecting a course from the course selection screen
 * Typically a product that we don't have.
 */
export function courseSelectionError() {
  return action(constants.COURSE_SELECTION_ERROR);
}

/**
 * Update a user preference
 */
export function asyncUpdatePreference(preferenceKey: keyof UserPreferences, preference: any) {
  return action(constants.ASYNC_UPDATE_PREFERENCE, { preferenceKey, preference });
}

/**
 * Update a user preferences
 */
export function updatePreferences(preferences: Partial<UserPreferences>) {
  return action(constants.UPDATE_PREFERENCES, preferences);
}

/**
 * Update multiple preferences in a single API call
 * @param preferences
 */
export function asyncUpdatePreferences(preferences: Partial<UserPreferences>) {
  return action(constants.ASYNC_UPDATE_PREFERENCES, preferences);
}

/**
 * Update user preferences for a specific course
 */
export function asyncUpdateCoursePreferences(payload: {
  courseId: number;
  preferences: {
    [key in keyof CoursePreferences]: CoursePreferences[key];
  };
}) {
  return action(constants.ASYNC_UPDATE_COURSE_PREFERENCES, payload);
}

/**
 * Update user preferences for front and back flashcard strings
 */
export function updateFlashcardPreferences(courseId: number, flashcardPreferences: FlashcardPreferences) {
  return action(constants.UPDATE_FLASHCARD_PREFERENCE, {
    courseId,
    flashcardPreferences,
  });
}

/**
 * Display rank upgrade modal with new rank
 */
export function showRankUpgradeNotification(newRank: Rank) {
  return action(constants.SHOW_RANK_NOTIFICATION, newRank);
}

/**
 * Remove the rank upgrade item from the store after user has confirmed the notification
 */
export function removeRankUpgradeNotification() {
  return action(constants.REMOVE_RANK_NOTIFICATION);
}

/**
 * Display the signup modal for guest users
 */
export function showSignupModal() {
  return action(constants.SHOW_SIGNUP_MODAL);
}

export function hideSignupModal() {
  return action(constants.HIDE_SIGNUP_MODAL);
}

/** Partial update */
export function put(user: Partial<UserState>) {
  return action(constants.PUT, user);
}

export function updateProfile(profile: Partial<UserProfile>) {
  return action(constants.UPDATE_PROFILE, profile);
}

export function asyncGetNotifications(activeCourseId: number) {
  return action(constants.ASYNC_GET_NOTIFICATIONS, activeCourseId);
}

export function updateNotifications(notifications: UnseenNotifications) {
  return action(constants.UPDATE_NOTIFICATIONS, notifications);
}

/**
 * User Profile Updates
 */

export function asyncUpdateAccount(payload: { userId: number; data: FormData }) {
  return action(constants.ASYNC_UPDATE_ACCOUNT, payload);
}

export function updateExtraProfile(payload: ExtraProfilePayload) {
  return action(constants.UPDATE_EXTRA_PROFILE, payload);
}

export function asyncUpdateForumNotificationsPreferences(payload: {
  preferences: {
    send_forum_notifications: "0" | "1";
  };
}) {
  return action(constants.ASYNC_UPDATE_FORUM_NOTIFICATIONS_PREFERENCES, payload);
}

export function updateForumNotificationsPreferences(payload: "0" | "1") {
  return action(constants.UPDATE_FORUM_NOTIFICATIONS_PREFERENCES, payload);
}

export function asyncUpdateAvatar(payload: FormData) {
  return action(constants.ASYNC_UPDATE_AVATAR, payload);
}

export function updateAvatar(payload: string) {
  return action(constants.UPDATE_AVATAR, payload);
}

// User Update Loading States

export function startLoading(payload: { key: keyof UserState["requestStatus"] }) {
  return action(constants.START_LOADING, payload);
}

export function stopLoading(payload: { key: keyof UserState["requestStatus"] }) {
  return action(constants.STOP_LOADING, payload);
}

export function errorLoading(payload: { key: keyof UserState["requestStatus"] }) {
  return action(constants.ERROR_LOADING, payload);
}
