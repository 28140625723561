import * as constants from "./constants";

import type { APISchema, AuthSuccessPayload, LibrarySignupPayload } from "@rocketlanguages/types";

import { action } from "typesafe-actions";

/**
 * [Watcher/Action] Login request has been initiated (from LoginScreen)
 *
 * Credentials are taken by the saga `sagas.ts@asyncLoginRequest` to start the login process.
 */
export function asyncLoginRequest(credentials: { email: string; password: string }) {
  return action(constants.ASYNC_LOGIN, credentials);
}

/**
 * Clears any authentication error messages
 */
export function clearErrorMessage() {
  return action(constants.CLEAR_ERROR_MESSAGE);
}

/**
 * [Watcher/Action] Login request has been initiated (from SignUpScreen)
 *
 * Credentials are taken by the saga `sagas.ts@asyncSignupRequest` to start the sign up process.
 */
export function asyncSignupRequest(
  formData: APISchema["POST"]["v2/user/signup-course/{course}/{source}"]["params"],
  source: "browser" | "app",
) {
  return action(constants.ASYNC_SIGNUP, {
    formData,
    source,
  });
}

/** Executes a normal signup request but redirects to the autologin url returned from the signup request */
export function asyncGuestSignup(
  formData: APISchema["POST"]["v2/user/signup-course/{course}/{source}"]["params"],
  source: "browser" | "app" | "browser-app",
) {
  return action(constants.ASYNC_GUEST_SIGNUP, {
    formData,
    source,
  });
}

/** Guest auth fail is different from normal auth as the guest shouldn't be logged out of the guest account if it fails
 * Status remains unchanged and only an error message and loading state is updated in the store
 */
export function guestAuthFail(errorMessage: string) {
  return action(constants.GUEST_AUTH_FAIL, errorMessage);
}

/** Separate signup path for library multiusers as requires different payload - course agnostic */
export function asyncMultiuserSignupRequest(payload: LibrarySignupPayload, libraryHash: string) {
  return action(constants.ASYNC_MULTIUSER_SIGNUP, { payload, libraryHash });
}

/**
 * Tells the app we want to log out a user. Navigates back to the log in screen
 */
export function logout(navigateOnLogout = true) {
  return action(constants.LOGOUT, navigateOnLogout);
}

/**
 * [Action] Login or signup request was successful
 *
 * Contains a payload from the server which is used to populate the user state
 */
export function success(params: { source: "login" | "signup"; data: AuthSuccessPayload }) {
  return action(constants.SUCCESS, {
    source: params.source,
    data: params.data,
  });
}

/**
 * [Action] Login or signup request was unsuccessful
 */
export function fail(errorMessage: string) {
  return action(constants.FAIL, errorMessage);
}

export function setToken(token: string) {
  return action(constants.SET_TOKEN, token);
}
