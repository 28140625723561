import { useStoreSelector } from "~/store";
import { Script } from "./Script";

export function ZendeskWidget() {
  const isTrialAndLoggedIn = useStoreSelector(
    (store) => !store.user.products.paid.length && store.auth.status === "loggedin",
  );

  if (!isTrialAndLoggedIn) {
    return null;
  }

  return (
    <Script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=8cea3eed-76f0-431e-8498-1a264e05a438" />
  );
}
