import React, { forwardRef } from "react";

import { ButtonProps } from "../Button";
import OutlineButton from "./OutlineButton";
import { clsx } from "clsx";

const defaultProps: ButtonProps = {
  color: "primary",
  size: "medium",
};

const LinkOutlineButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    download?: string;
    target?: string;
    href?: string;
    to?: string;
    rel?: string;
    type?: string;
  }
>((props, ref) => {
  return (
    <OutlineButton use="a" ref={ref} {...props} className={clsx(!props.fill && "w-min", props.className)}>
      {props.children}
    </OutlineButton>
  );
});

LinkOutlineButton.defaultProps = defaultProps;

export default LinkOutlineButton;
