import * as constants from "./constants";

import type { DashboardPayload } from "@rocketlanguages/types";
import { action } from "typesafe-actions";

/**
 * Creates an action to initate a lesson request
 *
 * Handled by a Saga to perform a request using Axios
 *
 * Triggered by: Dashboard.js@componentDidMount
 *
 * Active watchers: sagas.ts@asyncDashboardRequest
 *
 * @param {number} productId
 */
export function asyncDashboardRequest(payload: { productId: number; timezone: string }) {
  return action(constants.ASYNC_DASHBOARD_REQUEST, payload);
}

/**
 * Same as dashboard request, but with extra data for admin area
 *
 * @param payload
 */
export function asyncAdminDashboardRequest(payload: { productId: number }) {
  return action(constants.ASYNC_ADMIN_DASHBOARD_REQUEST, payload);
}

/**
 * Dashboard request has started.
 *
 * Triggered from: Dashboard.tsx@componentDidMount => asyncDashboardRequest() => sagas.ts@asyncDashboardRequest
 *
 * Product ID is used for tracking loading state.
 */
export function dashboardRequestStart(productId: number) {
  return action(constants.DASHBOARD_REQUEST_START, { productId });
}

/**
 * Identical to above but doesn't have an exception for when dashboard is already loaded
 */
export function adminDashboardRequestStart(productId: number) {
  return action(constants.ADMIN_DASHBOARD_REQUEST_START, { productId });
}

/**
 * Dashboard request was successful.
 *
 * Triggered from sagas.ts@asyncDashboardRequest
 *
 * Product ID is used for the payload & clearing loading state
 */
export function dashboardRequestSuccess(payload: DashboardPayload, productId: number) {
  return action(constants.DASHBOARD_REQUEST_SUCCESS, { payload, productId });
}

export function dashboardRequestFail(payload: {
  errorText: string;
  errorCode: string;
  statusCode: number;
  productId: number;
}) {
  return action(constants.DASHBOARD_REQUEST_FAIL, payload);
}

export function dashboardReset(productId: number) {
  return action(constants.DASHBOARD_RESET, { productId });
}
