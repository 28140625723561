/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from "react";

const useMediaQuery = (query: string) => {
  if (typeof window === "undefined" || typeof window.matchMedia === "undefined") {
    return false;
  }

  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [match, setMatch] = useState(!!mediaQuery.matches);

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery?.addEventListener("change", handler);

    return () => {
      mediaQuery?.removeEventListener("change", handler);
    };
  }, [mediaQuery]);

  return match;
};

export default useMediaQuery;
