import { useState } from "react";
import type { Vocab } from "@rocketlanguages/types";
import { Highlight, TooltipFacelift, TooltipContent, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui";
import AddToVocabModal from "./VocabModals/AddToVocabModal";
import useTranslation from "../../../hooks/useTranslation";

export default function VocabTooltip(props: { children: string; vocab: Vocab }) {
  const t = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <TooltipProvider delayDuration={200}>
        <TooltipFacelift>
          <TooltipContent>
            <em>{props.vocab?.translation || t("no-translation")}</em>
          </TooltipContent>
          <TooltipTrigger>
            <Highlight onClick={() => setModalOpen(true)}>{props.children}</Highlight>
          </TooltipTrigger>
        </TooltipFacelift>
      </TooltipProvider>
      {modalOpen && (
        <AddToVocabModal
          mode={{
            val: "edit",
            vocab: props.vocab,
          }}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
