import { createContext } from "react";
import usePhraseTest from "../../../hooks/usePhraseTest/usePhraseTest";

const RateableTestContext = createContext<{
  phraseTest: ReturnType<typeof usePhraseTest>;
  testTypeId: number;
  /** Container DOM Ref, used for focus key events */
  testContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}>({
  // @ts-ignore
  phraseTest: {},
  testTypeId: 0,
  testContainerRef: { current: null },
});

export default RateableTestContext;
