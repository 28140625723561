import Video, { type VideoProps } from "../../../../ui/VideoPlayer/includes/Video";
import { FaPlay } from "react-icons/fa";
import { useRef } from "react";
import useVideoPlayer from "../../../../hooks/useVideoPlayer";
import useVideoPlayerEvents from "../../../../ui/VideoPlayer/utils/useVideoPlayerEvents";

export default function TranslateItVideoPlayer(props: VideoProps & { autoPlay?: boolean; videoId?: number }) {
  const { autoPlay, videoId, ...rest } = props;
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const Player = useVideoPlayer(videoElementRef);
  useVideoPlayerEvents(Player, { src: props.src, autoPlay, videoId });

  const OverlayElement =
    Player.status !== "playing" ? (
      <div className="flex h-full w-full items-center justify-center bg-black/[0.25]">
        <FaPlay color="white" size={78} />
      </div>
    ) : undefined;

  return (
    <Video
      ref={videoElementRef}
      {...rest}
      onClick={() => {
        if (Player.status === "playing") {
          Player.pause();
          Player.seek(0);
        } else {
          Player.play();
        }
      }}
      overlay={OverlayElement}
      overlayAlways
    />
  );
}
