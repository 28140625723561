import {
  Button,
  Card,
  Centered,
  CircularProgress,
  ErrorMessage,
  PromiseResolver,
  TooltipContent,
  TooltipFacelift,
  TooltipProvider,
  TooltipTrigger,
} from "@rocketlanguages/ui";
import { Fragment, type ReactNode, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  StickySidebarContainer,
  SidebarBackButton,
  SidebarAnchor,
  SidebarToolsLink,
  SidebarSection,
  SidebarDivider,
} from "~/components/ui/SidebarFacelift";
import { dashboardSelector, type DashboardSelectedData, type ModuleData } from "./includes/utils/selector";
import { getModuleLabel, scrollIntoView, slugify } from "@rocketlanguages/shared/utils";
import { useStoreDispatch, useStoreSelector } from "../../../store";
import { ModuleItem } from "~/pages/members/dashboard/includes/components/ModuleItem";
import FaceliftPage from "~/components/FaceliftPage";
import { Navigate } from "react-router-dom";
import { asyncDashboardRequest } from "~/store/dashboard/actions";
import { create } from "zustand";
import { logout } from "@rocketlanguages/shared/store/auth/actions";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import useElementObserver from "@rocketlanguages/shared/hooks/useElementObserver";
import useProductFromCourseParams from "~/hooks/useProductFromCourseParams";
import useSidebarProducts from "./includes/useSidebarProducts";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import RocketMarkdown from "@rocketlanguages/shared/components/Lesson/MarkdownComponent/includes/RocketMarkdown";
import { clsx } from "clsx";
import { NavArrowDown as NavArrowDownIcon, NavArrowRight as NavArrowRightIcon } from "iconoir-react";
import { twMerge } from "tailwind-merge";
import type { LessonEntity } from "@rocketlanguages/types";

/** Route: members/course/:courseId/level/:levelId/dashboard */
export function RedirectToDashboardPage() {
  const product = useProductFromCourseParams();
  if (product) {
    return <Navigate to={`/members/products/${product.id}/dashboard`} replace />;
  }
  return <Navigate to="/members/courses" replace />;
}

export default function DashboardPage() {
  const t = useTranslation();
  const dispatch = useStoreDispatch();
  const productId = Number(useParams().productId);
  const dashboard = useStoreSelector(dashboardSelector);

  useEffect(() => {
    if (!dashboard) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      dispatch(asyncDashboardRequest({ productId, timezone }));
    }
  }, [dashboard, dispatch, productId]);

  return (
    <FaceliftPage title={t("dashboard<title>")}>
      <FaceliftPage.TwoColumns>
        <DashboardSidebar modules={dashboard?.status === "loaded" ? dashboard.data.modules : null} />
        <PromiseResolver
          state={dashboard}
          loading={
            <Centered className="py-20">
              <CircularProgress />
            </Centered>
          }
          error={
            <Card variant="rounded" className="flex justify-center">
              <div className="py-10">
                <ErrorMessage
                  title={PromiseResolver.getErrorText(dashboard, "Network Error")}
                  message="If this error persists, try logging in and out again"
                  actions={
                    <>
                      <Button color="primary" onClick={() => window.location.reload()}>
                        Reload
                      </Button>
                      <Button color="primary" onClick={() => dispatch(logout())}>
                        Log out
                      </Button>
                    </>
                  }
                />
              </div>
            </Card>
          }
          Component={LoadedDashboardPage}
        />
      </FaceliftPage.TwoColumns>
    </FaceliftPage>
  );
}

function LoadedDashboardPage({ modules, suggestedLessonId }: { modules: ModuleData[]; suggestedLessonId: number }) {
  const levelId = useActiveProduct()?.level_id || 0;
  const activeCourse = useActiveCourse();
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const isEbook = activeCourse?.slug === "ebook";
  const isTravelogue = activeProduct?.level_id === 4;
  const isPlayThePart = activeProduct?.level_id === 10;
  const showSectionTitles = !isEbook && !isTravelogue && !isPlayThePart;
  const firstName = useStoreSelector((store) => store.user.profile?.first_name || "");
  const isInIntroModule = useMemo(
    () => modules?.[0]?.data.flatMap((s) => s.lessons.map((l) => l.id)).includes(suggestedLessonId),
    [modules, suggestedLessonId],
  );

  useLayoutEffect(() => {
    // https://app.clickup.com/t/86cwbfwhm?comment=90160059071183
    if (isInIntroModule) {
      return;
    }
    const selector = `lesson.${suggestedLessonId}`;
    const element = document.getElementById(selector);
    element?.focus();
    if (element) {
      scrollIntoView(element, { instant: true, offset: 100 });
    }
    // only want this to run on initial render
  }, [suggestedLessonId, isInIntroModule]);

  return (
    <div className="space-y-4">
      <DashboardModules>
        {modules
          .map((m, index) => {
            return {
              id: m.id,
              index: index,
              title: m.title,
              description: m.description?.replace(/{{first_name}}/g, firstName),
              suggestedLessonId,
              showSectionTitles,
              label: getModuleLabel({ title: m.title, levelId }),
              subsections: m.data,
            };
          })
          .filter((m) => m.subsections.length > 0)
          .map((m) => {
            return (
              <DashboardModuleContainer
                key={`module.${m.id}`}
                id={slugify(m.label)}
                moduleIndex={m.index}
                className={clsx(
                  "space-y-8 border p-4 sm:rounded-3xl sm:p-8",
                  m.index === 0 && !!m.description
                    ? "border-[#cedff3] bg-[#E7F2FF] dark:border-missilestroke dark:bg-surface2"
                    : "border-missilestroke bg-surface2",
                )}
              >
                {m.index === 0 && !!m.description ? (
                  <RocketMarkdown
                    className="space-y-4"
                    options={{
                      overrides: {
                        h4: {
                          component: ({ children }: React.PropsWithChildren<unknown>) => (
                            <h1 className="text-3xl font-bold leading-6">👋 {children}</h1>
                          ),
                        },
                        a: ({ children, href }) => (
                          <a
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer font-semibold underline"
                          >
                            {children}
                          </a>
                        ),
                      },
                    }}
                  >
                    {m.description}
                  </RocketMarkdown>
                ) : null}
                <div className="flex flex-col-reverse items-center justify-between gap-4 lg:flex-row">
                  <h3 className="w-full">{m.label}</h3>
                </div>

                <ModuleItem
                  subsections={m.subsections}
                  suggestedLessonId={m.suggestedLessonId}
                  showSectionTitles={m.showSectionTitles}
                />
              </DashboardModuleContainer>
            );
          })}
      </DashboardModules>
    </div>
  );
}

function DashboardModules(props: { children: ReactNode }) {
  useEffect(() => {
    // Clear all visible module elements when unmounting (e.g. adding type filters)
    return () => {
      useVisibleModuleElements.setState({ visible: [] });
    };
  });
  return <div className="flex flex-col gap-y-8">{props.children}</div>;
}

const useVisibleModuleElements = create<{ visible: boolean[]; setVisible(index: number, visible: boolean): void }>(
  (set) => {
    return {
      visible: [],
      setVisible: (index, visible) => {
        set((state) => {
          const visibleCopy = [...state.visible];
          visibleCopy[index] = visible;
          return { visible: visibleCopy };
        });
      },
    };
  },
);

type DashboardModuleContainerProps = {
  id: string;
  children: ReactNode;
  moduleIndex: number;
  className?: string;
};

const headingObserverOptions = { threshold: 0.3 };

function DashboardModuleContainer({ id, children, moduleIndex, className }: DashboardModuleContainerProps) {
  const ref = useElementObserver<HTMLHeadingElement>((visible) => {
    setTimeout(() => {
      useVisibleModuleElements.getState().setVisible(moduleIndex, visible);
    }, 32);
  }, headingObserverOptions);

  return (
    <div
      ref={ref}
      id={id}
      className={
        // Add extra top margin on the first module to display the "Show" dropdown
        twMerge(clsx(moduleIndex === 0 ? "scroll-mt-36" : "scroll-mt-28", className))
      }
    >
      {children}
    </div>
  );
}

export function DashboardSidebar(props: { modules: DashboardSelectedData["modules"] | null }) {
  const t = useTranslation();
  const sidebarProducts = useSidebarProducts();
  const [isOpen, setIsOpen] = useState(true);
  const course = useActiveCourse();

  const userRateableTestRatings = useStoreSelector((store) => store.lesson.entities.user_rateable_test_ratings);
  const lessonRateableTestIds = useStoreSelector((store) => store.lesson.entities.lesson_rateable_test_ids);
  const rateableTests = useStoreSelector((store) => store.lesson.entities.rateable_tests);
  const lessonStatus = useStoreSelector((store) => store.lesson.entities.user_lesson_status);

  return (
    <StickySidebarContainer className="space-y-4 pb-8">
      <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
        <SidebarBackButton label={t("courses-and-levels")} to="/members/courses" />
        <SidebarDivider />
        <SidebarSection className="flex flex-1 flex-col">
          {sidebarProducts.map((sidebarProduct, index) => {
            if (!sidebarProduct.isActive) {
              return (
                <Fragment key={`product.${sidebarProduct.product.id}`}>
                  <SidebarAnchor
                    use={Link}
                    to={`/members/products/${sidebarProduct.product.id}/dashboard`}
                    className="justify-between font-bold"
                    label={sidebarProduct.label}
                  >
                    <NavArrowRightIcon />
                  </SidebarAnchor>
                  {index !== sidebarProducts.length - 1 ? <SidebarDivider /> : null}
                </Fragment>
              );
            }

            if (!isOpen) {
              return (
                <Fragment key={`product.${sidebarProduct.product.id}`}>
                  <SidebarAnchor
                    use={(p) => <div {...p} />}
                    label={sidebarProduct.label}
                    onClick={() => setIsOpen(true)}
                    className="justify-between font-bold"
                  >
                    <NavArrowRightIcon />
                  </SidebarAnchor>
                  {index !== sidebarProducts.length - 1 ? <SidebarDivider /> : null}
                </Fragment>
              );
            }

            const getCompletedLessons = (lessons: LessonEntity[]) => {
              return lessons.filter((l) => {
                const lessonTests = lessonRateableTestIds[l.id]?.map((testId) => rateableTests[testId]);
                if (!lessonTests) {
                  return lessonStatus[l.id]?.is_done;
                }

                for (const test of lessonTests) {
                  if (!test) {
                    return false;
                  }
                  if (test.is_extra) {
                    continue;
                  }
                  const testRating = userRateableTestRatings[test.id];
                  const isTestComplete = (testRating?.value || 0) > 0 || testRating?.marked_complete;
                  if (!isTestComplete) {
                    return false;
                  }
                }
                return true;
              });
            };

            return (
              <Fragment key={`product.${sidebarProduct.product.id}`}>
                <SidebarAnchor
                  use={(p) => <div {...p} />}
                  label={sidebarProduct.label}
                  onClick={() => setIsOpen(false)}
                  className="justify-between font-bold"
                >
                  <NavArrowDownIcon />
                </SidebarAnchor>
                <div>
                  {!props.modules ? (
                    <ModuleSelectionSkeleton />
                  ) : (
                    props.modules.map((m, index) => (
                      <ModuleAnchor
                        key={`anchor.${m.id}`}
                        index={index}
                        moduleTitle={m.title}
                        productId={sidebarProduct.product.id}
                        numberOfLessons={m.data.reduce((acc, d) => acc + d.lessons.length, 0)}
                        numberOfFinishedLessons={m.data.reduce(
                          (acc, mod) => acc + getCompletedLessons(mod.lessons).length,
                          0,
                        )}
                      />
                    ))
                  )}
                </div>
                {index !== sidebarProducts.length - 1 ? <SidebarDivider /> : null}
              </Fragment>
            );
          })}
        </SidebarSection>
      </div>
      {course?.slug !== "ebook" && (
        <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
          <SidebarToolsLink />
        </div>
      )}
    </StickySidebarContainer>
  );
}

function ModuleAnchor({
  moduleTitle,
  index,
  productId,
  numberOfLessons,
  numberOfFinishedLessons,
}: {
  moduleTitle: string;
  index: number;
  productId: number;
  numberOfLessons: number;
  numberOfFinishedLessons: number;
}) {
  const activeProduct = useActiveProduct();
  const levelId = activeProduct?.level_id || 1;
  const visibleModules = useVisibleModuleElements((s) => s.visible);
  const label = getModuleLabel({ title: moduleTitle, levelId });
  const domId = slugify(label);
  const isActive = visibleModules[index] && !visibleModules[index + 1];

  return (
    <SidebarAnchor
      use={Link}
      to={`/members/products/${productId}/dashboard?module=${domId}`}
      onClick={() => {
        setTimeout(() => {
          scrollIntoView(domId, {
            instant: true,
            // First module should also show the "Show" dropdown
            offset: index === 0 ? 100 : undefined,
          });
        });
      }}
      label={label}
      active={isActive}
    >
      <div className="flex-grow text-end">
        <TooltipProvider delayDuration={200}>
          <TooltipFacelift>
            <TooltipContent>
              {numberOfFinishedLessons}/{numberOfLessons} Lessons Completed
            </TooltipContent>
            <TooltipTrigger>
              <span className="text-missilegray2">
                {numberOfFinishedLessons}/{numberOfLessons}
              </span>
            </TooltipTrigger>
          </TooltipFacelift>
        </TooltipProvider>
      </div>
    </SidebarAnchor>
  );
}

function ModuleSelectionSkeleton() {
  return (
    <div className="flex flex-col pl-5">
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300" />
      </div>
    </div>
  );
}
