import { omit } from "../../utils";
import { SharedRootAction } from "../types";
import * as constants from "./constants";
import { CustomFlashcardsState } from "./types";

const initialState: CustomFlashcardsState = {
  collections: {},
  collectionSettings: {},
  testResetting: {},
};

export default function customFlashcardsReducer(
  state: CustomFlashcardsState = initialState,
  action: SharedRootAction,
): CustomFlashcardsState {
  switch (action.type) {
    case constants.UPDATE_COLLECTION: {
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.payload.collectionId]: {
            ...state.collections[action.payload.collectionId],
            ...action.payload.collection,
          },
        },
      };
    }
    case constants.UPDATE_CURRENT_TEST: {
      const { collectionId, flashcardId, rating } = action.payload;
      const collection = state.collections[collectionId];

      if (!collection) {
        return state;
      }

      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collection,
            ratings: {
              ...collection.ratings,
              [flashcardId]: rating,
            },
          },
        },
      };
    }
    case constants.UPDATE_CURRENT_TEST_POSITION: {
      const { collectionId, position } = action.payload;
      const collection = state.collections[collectionId];

      if (!collection) {
        return state;
      }

      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collection,
            position,
          },
        },
      };
    }
    case constants.RATE_CURRENT_TEST: {
      const { collectionId, totalRating } = action.payload;
      const collection = state.collections[collectionId];

      if (!collection) {
        return state;
      }

      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collection,
            totalRating,
          },
        },
      };
    }
    case constants.RESET_TEST: {
      const { collectionId } = action.payload;
      const collection = state.collections[collectionId];

      if (!collection) {
        return state;
      }

      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collection,
            ratings: {},
          },
        },
      };
    }
    case constants.RESET_TEST_SUCCESS: {
      const { collectionId } = action.payload;

      return {
        ...state,
        testResetting: omit(state.testResetting, collectionId),
      };
    }
    case constants.RESET_TEST_ERROR: {
      const { collectionId } = action.payload;
      return {
        ...state,
        testResetting: omit(state.testResetting, collectionId),
      };
    }
    case constants.UPDATE_SETTINGS: {
      const { collectionId, ...rest } = action.payload;
      return {
        ...state,
        collectionSettings: {
          [collectionId]: {
            ...rest,
          },
        },
      };
    }
    default:
      return state;
  }
}
