import type { ReactNode } from "react";

import { LiteralStringNotationButton } from "../../../Notation";
import type { PhraseString } from "@rocketlanguages/types";
import PhraseStringNotationButtons from "../../../Phrasebox/includes/PhraseStringNotationButtons";
import { clsx } from "clsx";
import styles from "./Flashcard.module.scss";

type FlashcardProps = {
  flipped: boolean;
  children: [ReactNode, ReactNode];
  onClick?: () => void;
  onEnter?: () => void;
  notationPhraseString?: PhraseString;
  literalString?: string | null;
  literalStringPlacement?: "front" | "back";
};

export function Flashcard(props: FlashcardProps) {
  const { children, onClick, flipped } = props;

  return (
    <div
      onClick={onClick}
      role="button"
      aria-label="Flashcard"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          // e.preventDefault();
          onClick?.();
        }
      }}
      className={clsx(
        "relative flex h-[350px] w-full items-center justify-center",
        styles.container,
        flipped && styles.flipped,
      )}
    >
      <div
        className={clsx(
          "absolute h-full w-full rounded-2xl bg-[#D9E2FF] text-center dark:bg-slate-700",
          (!!props.notationPhraseString || (props.literalStringPlacement === "front" && props.literalString)) &&
            "rounded-tr-none",
          styles.card,
          styles.front,
        )}
      >
        <span className="flex h-full flex-col items-center justify-center p-2">
          {children[0]}
          <div className="absolute -top-[1.55rem] right-0 flex flex-row items-center justify-center space-x-2">
            {props.notationPhraseString && <PhraseStringNotationButtons phraseString={props.notationPhraseString} />}
            {props.literalStringPlacement === "front" && props.literalString && (
              <LiteralStringNotationButton literalString={props.literalString} />
            )}
          </div>
        </span>
      </div>
      <div
        className={clsx(
          "absolute h-full w-full rounded-2xl bg-[#D9E2FF] text-center dark:bg-gray-900",
          props.literalStringPlacement === "back" && props.literalString && "rounded-tr-none",
          styles.card,
          styles.back,
        )}
      >
        <span className="flex h-full flex-col items-center justify-center p-2">
          {children[1]}
          <div className={"absolute -top-[1.55rem] right-0 flex flex-row items-center justify-center space-x-2"}>
            {props.literalStringPlacement === "back" && props.literalString && (
              <LiteralStringNotationButton literalString={props.literalString} />
            )}
          </div>
        </span>
      </div>
    </div>
  );
}
