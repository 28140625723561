import { isAndroidMobile, isChromeiOS, isSafari } from "../../../../utils/browser";
import { sleep } from "../../../../utils";
import { RecordErrorFlags } from "../../../../hooks/useRocketRecord/utils";
import type { Encoding } from "../../../../hooks/useRocketRecord/types";

// @ts-ignore
const isUsingPolyfill = isSafari || (typeof window !== "undefined" && Boolean(!window.MediaRecorder));

// Polyfill for Safari / Edge
if (isUsingPolyfill) {
  import("audio-recorder-polyfill").then((module) => {
    window.MediaRecorder = module.default;
  });
}

export const ContextSingleton: { ref: AudioContext | undefined; get(): AudioContext | undefined } = {
  ref: undefined,
  get() {
    if (ContextSingleton.ref && ContextSingleton.ref.state !== "closed") {
      return ContextSingleton.ref;
    }
    // @ts-ignore
    const Context = window.AudioContext || window.webkitAudioContext;

    if (Context) {
      ContextSingleton.ref = new Context();
    }

    return ContextSingleton.ref;
  },
};

class AudioRecorder {
  static ref: MediaRecorder | undefined = undefined;
  static stream: MediaStream | undefined = undefined;
  static chunks: Blob[] = [];
  static recording = false;

  static canRecord() {
    // Don't allow mobile devices to record
    // Note: isGetUserMediaSupported will be checked when recording
    return !isAndroidMobile();
  }

  static async start(params?: {
    /** Record mono channel for uploading to speech recognition API */
    mono: boolean;
  }): Promise<void> {
    if (AudioRecorder.recording) {
      throw new Error("ERROR_BUSY");
    }

    if (!navigator.mediaDevices?.getUserMedia || isChromeiOS) {
      // Chrome iOS doesn't support Voice Recognition or Audio recording
      console.warn("No web audio support in this browser!");
      throw new Error("ERROR_UNAVAILABLE");
    }

    AudioRecorder.recording = true;
    AudioRecorder.ref = undefined;
    AudioRecorder.chunks = [];

    // Get audio and process
    try {
      const stream = await Promise.race([
        sleep(25000),
        navigator.mediaDevices.getUserMedia({
          audio: params?.mono ? { channelCount: 1 } : true,
        }),
      ]);

      if (!stream) {
        throw { name: "NotReadableError", message: "Unable to start stream" };
      }
      // Get root scope audio context, if there isn't one, create one
      ContextSingleton.get();

      return new Promise((resolve, reject) => {
        AudioRecorder.ref = new window.MediaRecorder(stream, {
          mimeType: params?.mono ? AudioRecorder.getMimeType() : undefined,
        });

        AudioRecorder.ref.addEventListener("dataavailable", (e) => AudioRecorder.chunks.push(e.data));

        // Something went wrong when starting the recorder
        const startTimeout = setTimeout(() => {
          if (AudioRecorder.ref?.state !== "recording") {
            AudioRecorder.recording = false;
            reject("ERROR_DEVICE_INPUT");
          }
        }, 4000);

        // Resolve when the recorder has started
        AudioRecorder.ref.addEventListener("start", () => {
          clearTimeout(startTimeout);

          if (!isUsingPolyfill) {
            resolve();
            return;
          }

          // On polyfills, we need to wait till audio is detected

          const mediaStreamSource = AudioRecorder.getMediaStreamSource();
          const audioCtx = ContextSingleton.get();

          if (!mediaStreamSource || !audioCtx) {
            AudioRecorder.recording = false;
            reject("ERROR_AUDIO");
            return;
          }

          const analyser = audioCtx.createAnalyser();
          analyser.fftSize = 32;
          // bufferLength should be 16 (half of fftSize)
          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          mediaStreamSource.connect(analyser);

          let request: number | null = null;

          const audioDetectionTimeout = setTimeout(() => {
            if (request) {
              cancelAnimationFrame(request);
            }
            AudioRecorder.recording = false;
            reject("ERROR_NO_SOUND");
          }, 5000);

          const waitTillAudioDetected = () => {
            analyser.getByteFrequencyData(dataArray);
            if ((dataArray[0] || 0) > 1) {
              clearTimeout(audioDetectionTimeout);
              resolve();
              return;
            }
            request = requestAnimationFrame(waitTillAudioDetected);
          };

          request = requestAnimationFrame(waitTillAudioDetected);
        });

        // Start recording
        AudioRecorder.ref.start();
      });
    } catch (err: any) {
      console.warn("err.name", err.name, err.message);
      AudioRecorder.recording = false;
      throw new Error(RecordErrorFlags[err.name as keyof typeof RecordErrorFlags] || "ERROR_UNKNOWN");
    }
  }

  static getMediaStreamSource() {
    if (!AudioRecorder.ref?.stream) {
      return null;
    }
    const ctx = ContextSingleton.get();
    if (!ctx) {
      return null;
    }
    return ctx.createMediaStreamSource(AudioRecorder.ref.stream);
  }

  /**
   * Finish the recording. Process results.
   */
  static async finishRecording(saveRecording = true) {
    if (!AudioRecorder.ref || !AudioRecorder.recording) {
      return {};
    }

    const blob = saveRecording ? await AudioRecorder.getBlob() : undefined;

    AudioRecorder.ref?.stream?.getTracks().forEach((track) => track.stop());

    AudioRecorder.recording = false;

    if (!blob) {
      return {};
    }

    try {
      const blobUrl = typeof window !== "undefined" && blob.size > 100 ? window.URL.createObjectURL(blob) : undefined;

      return {
        blob,
        uri: blobUrl,
      };
    } catch (err) {
      return { blob };
    }
  }

  static getMimeType(): string | undefined {
    for (const type of ["audio/webm", "audio/wav", "audio/mp4"]) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    // "audio/webm;codecs=opus" -> audio/webm
    return AudioRecorder.ref?.mimeType?.split(";")[0];
  }

  /** Get supported encoding for speech recognition API */
  static getEncoding(): Encoding {
    const mimeType = AudioRecorder.ref?.mimeType;
    switch (mimeType) {
      case "audio/webm;codecs=opus":
        return "WEBM_OPUS";
      case "audio/ogg;codecs=opus":
        return "OGG_OPUS";
      case "audio/flac":
        return "FLAC";
      case "audio/wav":
        return "LINEAR16";
      default:
        return "ENCODING_UNSPECIFIED";
    }
  }

  /** Stops audio recording and returns a blob url */
  static async getBlob(): Promise<Blob | undefined> {
    return new Promise((resolve) => {
      if (!AudioRecorder.ref) {
        resolve(undefined);
        return;
      }

      // Timeout stop event listener in case it never fires
      const timeout = setTimeout(() => resolve(undefined), 2500);

      // Add "stop" event listener before calling recorder.stop()
      AudioRecorder.ref.addEventListener("stop", () => {
        clearTimeout(timeout);
        // Note: Metadata required for playback on Safari
        const blob = new Blob(
          AudioRecorder.chunks,
          isUsingPolyfill ? { type: AudioRecorder.getMimeType() } : undefined,
        );

        AudioRecorder.chunks = [];

        resolve(blob);
      });

      // Stop recorder
      AudioRecorder.ref.stop();
    });
  }
}

export default AudioRecorder;
