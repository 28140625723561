import type { Course, Product } from "@rocketlanguages/types";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useStoreDispatch, useStoreSelector } from "../../store";

import Courses from "@rocketlanguages/shared/res/courses";
import { setActiveCourseAndProduct } from "@rocketlanguages/shared/store/preferences/actions";
import { useParams } from "react-router-dom";
import { MembersFaceliftProductIdLayout } from "~/layouts/MembersLayout/MembersFaceliftLayout";

/** Applies for layouts with /:courseSlug/level/:levelId */
export function MembersCourseSlugLevelIdLayout() {
  const courseSlug = useParams()?.courseSlug;
  const productLevelId = Number(useParams()?.levelId);
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const [course, product] = useUserProductFromCourseSlugAndLevel(String(courseSlug), Number(productLevelId));
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);

  useEffect(() => {
    // No user-owned product/course found
    if (!course || !product) {
      navigate("/members/courses", { replace: true });
      return;
    }
    const activeProductHasChanged = product.id !== activeProduct?.id;
    // User has product, but it doesnt match url, switch active course and product
    if (activeProductHasChanged) {
      dispatch(setActiveCourseAndProduct(course, product));
    }
  }, [activeProduct, course, dispatch, navigate, product]);

  return <MembersFaceliftProductIdLayout />;
}

/** Derives user-owned products & courses from URL params */
const useUserProductFromCourseSlugAndLevel = (courseSlug: string, productLevelId: number) => {
  const course = useMemo(() => Courses.find((c) => c.slug === courseSlug), [courseSlug]);
  const products = useStoreSelector((store) => store.user.products);
  return useMemo((): [null, null] | [Course, Product | null] => {
    if (!course) {
      return [null, null];
    }
    const userProducts = [...products.paid, ...products.trial];

    return [
      course,
      course.products.find(
        (p) =>
          p.level_id === productLevelId && userProducts.some((up) => up.id === p.id && up.course_id === p.course_id),
      ) || null,
    ];
  }, [course, productLevelId, products.paid, products.trial]);
};
