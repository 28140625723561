import { RateableTestTypeIds, RomanizedWritingSystemIds, WritingSystemIds } from "../../../utils/constants";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import usePhraseTest, { type usePhraseTestProps } from "../../../hooks/usePhraseTest/usePhraseTest";
import { useSharedDispatch, useSharedSelector } from "../../../store";

import { Flashcard } from "./includes/Flashcard";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import Settings from "./includes/Settings";
import { clsx } from "clsx";
import { requestAddPoints } from "../../../store/lesson/actions";
import styles from "./includes/Flashcard.module.scss";
import useTranslation from "../../../hooks/useTranslation";
import useWritingSystemIds from "../../../hooks/useWritingSystemIds";
import RocketMarkdown from "../../Lesson/MarkdownComponent/includes/RocketMarkdown";
import type { Phrase } from "@rocketlanguages/types";
import { MissileRatingButtons } from "../RateableTestUI/buttons/MissileRatingButtons";
import { getRecommendedRatingButton } from "../RateableTestUI/buttons/getRecommendedRatingButton";
import { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";
import { RoundedButton } from "@rocketlanguages/ui";
import usePhraseHotkeys from "../../../hooks/usePhraseHotkeys";
import type { RatingGroups } from "../../../hooks/usePhraseTest/useRatingGroups";

export function FlashcardsFacelift({
  rateableTestId,
  disableSettings,
}: {
  rateableTestId: number;
  disableSettings?: boolean;
}) {
  const t = useTranslation();
  const dispatch = useSharedDispatch();

  const lesson = useContext(LessonContext);
  const audioEnabled = useSharedSelector((store) => !!Number(store.user.preferences?.flashcard_volume));
  const writingSystemIds = useWritingSystemIds(lesson.id);

  const options: usePhraseTestProps = useMemo(
    () => ({
      testTypeId: RateableTestTypeIds.FLASHCARDS,
      lessonId: lesson.id,
      rateableTestId,
      mode: "unrated_components",
      onComponentRated({ componentId }) {
        // Dispatch add points for flashcard
        dispatch(
          requestAddPoints({
            rewardType: "rateFlashcard",
            data: { lesson: lesson.id, rateableTest: rateableTestId, component: componentId },
          }),
        );
      },
      phraseFilter: (phrase) => {
        return phrase.strings.some(
          (_phraseString) => _phraseString.writing_system_id === WritingSystemIds.english && _phraseString.text,
        );
      },
    }),
    [dispatch, lesson.id, rateableTestId],
  );

  const phraseTest = usePhraseTest(options);

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.FLASHCARDS}
      testName={t("flashcard")}
      testSubheading={t("flashcard-subheading")}
      instructions={<Instructions />}
      settings={
        !disableSettings ? <Settings audioEnabled={audioEnabled} writingSystemIds={writingSystemIds} /> : undefined
      }
    >
      <FlashcardPhraseTest audioEnabled={audioEnabled} />
    </RateableTest>
  );
}

export function FlashcardPhraseTest({ audioEnabled }: { audioEnabled: boolean }) {
  const { testContainerRef, phraseTest } = useContext(RateableTestContext);

  const [flipped, setFlipped] = useState(false);
  const phraseRef = useRef<HTMLAudioElement>(null);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const isRevealed = phraseTest.state.revealed.has(phraseTest.state.index);
  const t = useTranslation();

  useEffect(() => {
    const listenerFn = () => setFlipped(false);
    phraseTest.events.registerOnCompleteListener(listenerFn);
    return () => {
      phraseTest.events.unregisterOnCompleteListener(listenerFn);
    };
  }, [phraseTest.events]);

  const onRate = (ratingLabel: keyof RatingGroups) => {
    phraseTest.methods.rate(ratingLabel);
    setFlipped(false);
  };

  const onReveal = () => {
    phraseTest.methods.dispatch(phraseTestActions.reveal(0));
    setFlipped(true);
  };

  usePhraseHotkeys({ phraseTestRef: testContainerRef, revealed: isRevealed, onReveal, onRate });

  return (
    <div className="flex h-full flex-col gap-4">
      <div>
        {flipped && audioEnabled && (
          <audio ref={phraseRef} preload="metadata" src={currentPhrase.audio_url || undefined} autoPlay />
        )}
        <FlashcardWrapper
          phrase={currentPhrase}
          flipped={flipped}
          onFlip={() => {
            if (isRevealed) {
              setFlipped((flipped) => !flipped);
            }
          }}
          audioEnabled={audioEnabled}
        />
      </div>

      <div className="flex h-20 flex-col items-center justify-end">
        {isRevealed ? (
          <MissileRatingButtons
            text="How hard did you find this phrase?"
            recommended={getRecommendedRatingButton(phraseTest.state.currentSuggestedRatingLevel)}
            onRate={onRate}
            numEasy={phraseTest.computed.ratings.easy.size}
            numGood={phraseTest.computed.ratings.good.size}
            numHard={phraseTest.computed.ratings.hard.size}
          />
        ) : (
          <RoundedButton
            className="w-full max-w-60 border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
            onClick={onReveal}
          >
            {t("flip")}
          </RoundedButton>
        )}
      </div>
    </div>
  );
}

function FlashcardWrapper({
  phrase,
  flipped,
  onFlip,
}: {
  phrase: Phrase;
  flipped: boolean;
  onFlip: () => void;
  audioEnabled: boolean;
}) {
  const { frontStrings, backStrings, notationPhraseString } = useFlashcardSettings();

  const literalStringPlacement = frontStrings.some((str) => str.writing_system_id === WritingSystemIds.english)
    ? "front"
    : "back";
  return (
    <div className="mt-6 flex justify-center self-center">
      <Flashcard
        key={phrase.id}
        flipped={flipped}
        onClick={onFlip}
        notationPhraseString={notationPhraseString}
        literalString={phrase.literal_string}
        literalStringPlacement={literalStringPlacement}
      >
        {frontStrings.map((line, i) => (
          <FlashcardTextLine className={`dark:text-white ws-${line.writing_system_id}`} key={line.id} bold={i === 0}>
            {line.text}
          </FlashcardTextLine>
        ))}
        {backStrings.map((line, i) => (
          <FlashcardTextLine
            // Clickup issue: https://app.clickup.com/t/86cuuw96p
            // The screen reader should not read the back text when the card is not flipped
            aria-hidden={!flipped}
            className={`dark:text-white ws-${line.writing_system_id}`}
            key={line.id}
            bold={i === 0}
          >
            {line.text}
          </FlashcardTextLine>
        ))}
      </Flashcard>
    </div>
  );
}

export function FlashcardTextLine(props: {
  children: string;
  bold?: boolean;
  className?: string;
  "aria-hidden"?: boolean;
}) {
  const childStringContents = props.children.replace(/<[^>]*>/g, "");

  return (
    <div
      className={clsx(
        "text-center text-2xl text-brand",
        props.bold ? clsx("font-bold", styles.titlePhrase) : styles.subPhrase,
        props.className,
      )}
      aria-hidden={props["aria-hidden"]}
    >
      <RocketMarkdown
        // Don't use default options
        options={{}}
      >
        {childStringContents}
      </RocketMarkdown>
    </div>
  );
}

// Flashcard back/front strings
function useFlashcardSettings() {
  const { phraseTest } = useContext(RateableTestContext);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const activeCourseId = useSharedSelector((store) => store.preferences.activeCourse?.id);
  const hideRomanized = useSharedSelector((store) => !!Number(store.user.preferences?.hide_romanized));
  const hideKana = useSharedSelector((store) => !!Number(store.user.preferences?.hide_kana));
  const preferences = useSharedSelector((store) => store.user.preferences);

  const notationIndex = currentPhrase.strings.findIndex((string) => !!string.notations.length);
  const notationPhraseString = notationIndex !== -1 ? currentPhrase.strings[notationIndex] : undefined;

  const availablePhraseStrings = currentPhrase.strings.filter((_string) => {
    if (hideRomanized && RomanizedWritingSystemIds.includes(_string.writing_system_id)) {
      return false;
    }
    if (hideKana && WritingSystemIds.hiragana_katakana === _string.writing_system_id) {
      return false;
    }
    return true;
  });

  // User has no set preferences for writing systems to display on front/back
  if (!activeCourseId || !preferences?.courses?.[activeCourseId]?.flashcard_ws_front?.length) {
    // Translation (English) at the front, foreign language strings at the back
    return {
      frontStrings: availablePhraseStrings.slice(-1),
      backStrings: availablePhraseStrings.slice(0, -1),
      notationPhraseString,
    };
  }

  // Filter front strings by flashcard_ws_front & back
  let flashcardWsFront = preferences.courses[activeCourseId]?.flashcard_ws_front || [];

  // Default to English
  if (!flashcardWsFront?.length) {
    flashcardWsFront = [WritingSystemIds.english];
  }

  let flashcardWsBack = preferences.courses[activeCourseId]?.flashcard_ws_back || [];

  // Default to everything but whatever the front is
  if (!flashcardWsBack?.length) {
    flashcardWsBack = availablePhraseStrings
      .filter((str) => str.writing_system_id !== flashcardWsFront[0])
      .map((str) => str.writing_system_id);
  }

  return {
    frontStrings: availablePhraseStrings.filter((ps) => flashcardWsFront.some((wsId) => wsId === ps.writing_system_id)),
    backStrings: availablePhraseStrings.filter((ps) => flashcardWsBack.some((wsId) => wsId === ps.writing_system_id)),
    notationPhraseString,
  };
}
