import useElementObserver from "../../../../hooks/useElementObserver";
import { onlyText, slugify } from "../../../../utils";
import { useActiveMarkdownHeading } from "./useActiveMarkdownHeading";

const headingOptions = { rootMargin: "0px 0px -200px 0px" };

/** Make h1's into h2's for best practice dom nesting */
export function H1Override({ children }: { children: React.ReactNode }) {
  const id = slugify(onlyText(children));

  const ref = useElementObserver<HTMLHeadingElement>((visible) => {
    if (visible) {
      useActiveMarkdownHeading.setState({ value: id });
    }
  }, headingOptions);

  // H1 should have slugified id
  return (
    <h2 ref={ref} id={id} className="scroll-mt-24">
      {children}
    </h2>
  );
}

/** Make h2's into h3's for best practice dom nesting */
export function H2Override({ children }: { children: React.ReactNode }) {
  return <h3>{children}</h3>;
}

export function H3Override({ children }: { children: React.ReactNode }) {
  return <h3>{children}</h3>;
}

/** Remove thead if all th's in a thead are empty */
export function THeadOverride({ children }: { children: React.ReactElement }) {
  //@ts-ignore
  const hasContent = children.props.children?.some((child) => Boolean(child.props.children[0]));
  return hasContent ? <thead>{children}</thead> : null;
}
