import {
  Button,
  Centered,
  CircularProgress,
  CourseFlagCircle,
  ModalDeprecated,
  PromiseResolver,
  Stack,
} from "@rocketlanguages/ui";
import { Fragment, type ReactNode, useMemo, useState } from "react";
import { useStoreDispatch, useStoreSelector } from "~/store";
import API from "@rocketlanguages/shared/res/Api";
import type APISchema from "@rocketlanguages/types/api/schema";
import { CourseSelectionButton } from "~/components/ui/CourseSelectionButton";
import Courses from "@rocketlanguages/shared/res/courses";
import { CoursesTopBar } from "~/layouts/components/TopBar/TopBar";
import FaceliftPage from "~/components/FaceliftPage";
import { ProductCardLink } from "~/pages/members/courses/includes/ProductCardLink";
import type { Course, UserCourseProductLevel } from "@rocketlanguages/types";
import { asyncSelectCourse } from "~/store/user/actions";
import { stopLoading } from "@rocketlanguages/shared/store/user/actions";
import useCanSeePurchaseCTA from "@rocketlanguages/shared/hooks/useCanSeePurchaseCTA";
import useMediaQuery from "~/hooks/useMediaQuery";
import usePromise from "@rocketlanguages/shared/hooks/usePromise";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import {
  NavArrowDown as NavArrowDownIcon,
  NavArrowRight as NavArrowRightIcon,
  PlusCircle as PlusCircleIcon,
} from "iconoir-react";
import { StickySidebarContainer } from "~/components/ui/SidebarFacelift";
import { clsx } from "clsx";
import { useDispatch } from "react-redux";

export default function CoursesPage() {
  const { state } = usePromise(() => API.getJson("v2/courses"), { once: true, cacheKey: "v2/courses" });

  return (
    <>
      <CoursesTopBar />
      <FaceliftPage title="Courses">
        <PromiseResolver
          state={state}
          loading={
            <Centered>
              <CircularProgress />
            </Centered>
          }
          Component={LoadedCoursesView}
        />
      </FaceliftPage>
    </>
  );
}

function LoadedCoursesView(props: APISchema["GET"]["v2/courses"]["response"]) {
  const defaultSelectedCourse = props.user_courses[0]?.id || 0;
  const [selectedCourse, setSelectedCourse] = useState(defaultSelectedCourse);
  const { user_courses, inactive_course_ids } = props;

  const isMobileResolution = useMediaQuery("(max-width: 768px)");

  const inactiveCourses = useMemo(() => {
    return Courses.filter((c) => inactive_course_ids.includes(c.id));
  }, [inactive_course_ids]);

  if (!isMobileResolution && selectedCourse === -1) {
    setSelectedCourse(defaultSelectedCourse);
  }

  const CourseAndProductSelector = isMobileResolution
    ? CourseAndProductSelectorMobile
    : CourseAndProductSelectorDesktop;

  return (
    <div>
      <CourseSignupStatusModal />
      {user_courses.length > 0 && (
        <CourseAndProductSelector
          onSelectCourse={setSelectedCourse}
          selectedCourse={selectedCourse}
          userCourses={user_courses}
          inactiveCourses={inactiveCourses}
        />
      )}
      {user_courses.length === 0 && selectedCourse === 0 && inactive_course_ids.length > 0 ? (
        <div className="m-auto my-4 flex flex-1 justify-center">
          <TrialPicker inactiveCourses={inactiveCourses} />
        </div>
      ) : null}
    </div>
  );
}

type CourseAndProductSelectorProps = {
  userCourses: APISchema["GET"]["v2/courses"]["response"]["user_courses"];
  selectedCourse: number;
  onSelectCourse: (courseId: number) => void;
  inactiveCourses: Course[];
};

function CourseAndProductSelectorDesktop({
  selectedCourse,
  onSelectCourse,
  userCourses,
  inactiveCourses,
}: CourseAndProductSelectorProps) {
  const productLevelCards = useMemo(() => {
    return userCourses.find((userCourse) => userCourse.id === selectedCourse)?.product_levels ?? [];
  }, [userCourses, selectedCourse]);

  const hasInactiveCourses = inactiveCourses.length > 0;

  const hasStartedAnyLevel = productLevelCards.some((productLevel) => productLevel.resume);
  return (
    <div className="flex h-full items-stretch gap-2 overflow-x-hidden md:overflow-visible">
      <div className="hidden min-w-80 md:block print:md:hidden">
        <StickySidebarContainer className="pb-8 pt-4">
          <div className="space-y-1 rounded-3xl bg-surface2 p-5">
            <CourseSelectionButtons
              courses={userCourses}
              selectedCourse={selectedCourse}
              onSelectCourse={onSelectCourse}
            />

            {hasInactiveCourses ? (
              <button
                type="button"
                className={clsx(
                  "flex h-16 w-full items-center justify-between rounded-full px-5 py-4 text-sm font-bold hover:bg-missilesurfacedark focus:outline-none focus:ring hover:dark:bg-neutral-700",
                  selectedCourse === 0 ? "bg-missilesurfacedark dark:bg-neutral-700" : "bg-surface2",
                )}
                onClick={() => onSelectCourse(selectedCourse === 0 ? -1 : 0)}
              >
                <div className="flex items-center gap-2">
                  <PlusCircleIcon />
                  <span>Try another language</span>
                </div>
                <div className="md:hidden">{!selectedCourse ? <NavArrowDownIcon /> : <NavArrowRightIcon />}</div>
              </button>
            ) : null}
          </div>
        </StickySidebarContainer>
      </div>

      <div className="flex w-full scroll-mt-20 flex-col p-2 pt-4">
        {selectedCourse === 0 && hasInactiveCourses ? (
          <TrialPicker inactiveCourses={inactiveCourses} />
        ) : (
          <div className="flex flex-col gap-y-4 pb-4">
            {productLevelCards.map((productLevel, index) => (
              <ProductLevelCard
                key={`pl.${productLevel.course_id}.${productLevel.id}`}
                productLevel={productLevel}
                shouldStart={!hasStartedAnyLevel && index === 0}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function TrialPicker(props: { inactiveCourses: Course[] }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  return (
    <div className="space-y-4 rounded-3xl bg-surface2 p-5">
      <div className="text-3xl font-bold">{t("try-it-for-free")}</div>
      <div className="text-sm">Just choose the language that you want to learn and let's go!</div>
      <div className="grid grid-cols-2 justify-items-center gap-4 lg:grid-cols-4 xl:grid-cols-5">
        {props.inactiveCourses.map((course) => {
          return (
            <button
              key={course.id}
              type="button"
              className="flex size-36 flex-col items-center justify-center gap-2 rounded-[46px] border border-missilestroke bg-missilesurfacelight p-5 hover:bg-missilesurfacedark"
              onClick={() => {
                // Find the hardcoded course
                const fullCourse = Courses.find((c) => c.id === course.id);
                if (fullCourse) {
                  dispatch(asyncSelectCourse(fullCourse, true));
                }
              }}
            >
              <CourseFlagCircle courseSlug={course.slug} courseName={course.name} />
              <div className="text-center text-sm font-bold">{course.name}</div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

function CourseAndProductSelectorMobile({
  selectedCourse,
  onSelectCourse,
  userCourses,
  inactiveCourses,
}: CourseAndProductSelectorProps) {
  const productLevelCards = useMemo(() => {
    return userCourses.find(({ id }) => id === selectedCourse)?.product_levels ?? [];
  }, [userCourses, selectedCourse]);

  const hasStartedAnyLevel = productLevelCards.some((productLevel) => productLevel.resume);
  const hasInactiveCourses = inactiveCourses.length > 0;

  return (
    <div className="flex w-full flex-col gap-2 pb-2 pt-4">
      <CourseSelectionButtons
        courses={userCourses}
        selectedCourse={selectedCourse}
        // on Mobile, toggle the course
        onSelectCourse={(courseId) => onSelectCourse(courseId === selectedCourse ? -1 : courseId)}
      >
        <div className="flex flex-1 flex-col gap-4 py-2 sm:py-0">
          {productLevelCards?.map((productLevel, index) => (
            <ProductLevelCard
              key={`pl.${productLevel.course_id}.${productLevel.id}`}
              productLevel={productLevel}
              shouldStart={!hasStartedAnyLevel && index === 0}
            />
          ))}
        </div>
      </CourseSelectionButtons>

      {hasInactiveCourses ? (
        <button
          type="button"
          className={clsx(
            "flex h-16 w-full items-center justify-between rounded-full px-5 py-4 text-sm font-bold text-missilebrand hover:bg-missilesurfacedark focus:outline-none focus:ring dark:text-white hover:dark:bg-brand hover:dark:bg-neutral-700",
            selectedCourse === 0 ? "bg-missilesurfacedark dark:bg-neutral-700" : "bg-surface2",
          )}
          onClick={() => onSelectCourse(selectedCourse === 0 ? -1 : 0)}
        >
          <div className="flex items-center gap-2">
            <PlusCircleIcon />
            <span>Try another language</span>
          </div>
          <div className="md:hidden">{!selectedCourse ? <NavArrowDownIcon /> : <NavArrowRightIcon />}</div>
        </button>
      ) : null}
      {hasInactiveCourses && selectedCourse === 0 ? <TrialPicker inactiveCourses={inactiveCourses} /> : null}
    </div>
  );
}

function ProductLevelCard({
  productLevel,
  shouldStart,
}: {
  productLevel: UserCourseProductLevel;
  shouldStart: boolean;
}) {
  const canSeePurchaseCTA = useCanSeePurchaseCTA();

  if (!productLevel.product_id && !canSeePurchaseCTA) {
    return null;
  }

  const title = productLevel.label;
  const description = productLevel.description || "";
  const moduleLabel = productLevel.module_label;
  const imageUrl =
    productLevel.image_url ||
    "https://images.unsplash.com/photo-1605653799416-595e2b020d08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80";

  if (!productLevel.product_id) {
    return (
      <ProductCardLink
        status="locked"
        to={getPricingLink({ courseId: productLevel.course_id, productLevelId: productLevel.id })}
        title={title}
        imageUrl={imageUrl}
        moduleLabel={moduleLabel}
        description={description}
      />
    );
  }

  const link = `/members/products/${productLevel.product_id}/dashboard`;

  if (productLevel.resume) {
    return (
      <ProductCardLink
        status="resume"
        to={link}
        title={title}
        imageUrl={imageUrl}
        moduleLabel={moduleLabel}
        description={description}
      />
    );
  }

  return (
    <ProductCardLink
      status={shouldStart ? "getStarted" : undefined}
      to={link}
      title={title}
      imageUrl={imageUrl}
      moduleLabel={moduleLabel}
      description={description}
    />
  );
}

function getPricingLink(options: { courseId: number; productLevelId: number }) {
  const courseSlug = Courses.find((c) => c.id === options.courseId)?.slug;

  if (!courseSlug) {
    return "https://www.rocketlanguages.com/pricing";
  }

  // Travelogues
  if (options.productLevelId === 4) {
    return `https://www.rocketlanguages.com/${courseSlug}/rocket-${courseSlug}-travelogues`;
  }

  if (options.productLevelId === 10) {
    return `https://www.rocketlanguages.com/${courseSlug}/rocket-${courseSlug}-play-the-part`;
  }

  return `https://www.rocketlanguages.com/pricing?language=${courseSlug}`;
}

type CourseSelectionButtonsProps = {
  courses: APISchema["GET"]["v2/courses"]["response"]["user_courses"];
  selectedCourse: number;
  onSelectCourse: (courseId: number) => void;
  children?: ReactNode;
};

function CourseSelectionButtons(props: CourseSelectionButtonsProps) {
  return (
    <>
      {props.courses.map((course) => {
        const selected = course.id === props.selectedCourse;
        return (
          <Fragment key={`select.${course.id}`}>
            <CourseSelectionButton
              onClick={() => props.onSelectCourse(course.id)}
              courseSlug={course.slug}
              title={course.name}
              dialect={course.dialect}
              selected={selected}
            />
            {selected && props.children}
          </Fragment>
        );
      })}
    </>
  );
}

function CourseSignupStatusModal() {
  const t = useTranslation();
  const courseSignupStatus = useStoreSelector((store) => store.user.requestStatus.courseSignup);
  const dispatch = useStoreDispatch();

  const handleCloseError = () => {
    dispatch(stopLoading({ key: "courseSignup" }));
  };

  return (
    <>
      <ModalDeprecated.CardContainer
        verticallyCentered
        isOpen={courseSignupStatus === "error"}
        onClose={handleCloseError}
      >
        <ModalDeprecated.Title>{t("whoops")}</ModalDeprecated.Title>
        <ModalDeprecated.Body>{t("error-navigating-to-course")}</ModalDeprecated.Body>
        <ModalDeprecated.Actions>
          <Button color="secondary" onClick={handleCloseError}>
            {t("close")}
          </Button>
        </ModalDeprecated.Actions>
      </ModalDeprecated.CardContainer>

      <ModalDeprecated.CardContainer verticallyCentered isOpen={courseSignupStatus === "loading"}>
        <ModalDeprecated.Body>
          <Stack>
            <Centered>
              <CircularProgress />
            </Centered>
            <div className="text-center">{t("getting-course-ready")}</div>
          </Stack>
        </ModalDeprecated.Body>
      </ModalDeprecated.CardContainer>
    </>
  );
}
