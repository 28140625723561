import * as constants from "./constants";
import type { ModuleTestState } from "./types";
import type { SharedRootAction } from "../types";
import { getRatingLevel } from "../../utils/utils";

const initialState: ModuleTestState = {
  page: {},
  tests: {},
  entities: {
    modules: {},
    user_module_subtest_ratings: {},
  },
};

export default function moduleTestReducer(
  state: ModuleTestState = initialState,
  action: SharedRootAction,
): ModuleTestState {
  switch (action.type) {
    case constants.RESET_TEST_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          user_module_subtest_ratings: {
            ...state.entities.user_module_subtest_ratings,
            [action.payload.moduleId]: {},
          },
        },
      };
    }

    case constants.SAGA_RATE_SUBTEST: {
      const { testTypeId, rating, moduleId } = action.payload;
      const newRating = {
        value: rating,
        ratingLevel: getRatingLevel(rating),
      };

      return {
        ...state,
        entities: {
          ...state.entities,
          user_module_subtest_ratings: {
            ...state.entities.user_module_subtest_ratings,
            [moduleId]: {
              ...(state.entities.user_module_subtest_ratings[moduleId] || {}),
              [testTypeId]: newRating,
            },
          },
        },
      };
    }

    case constants.SAGA_REQUEST_PAGE_DATA:
      return {
        ...state,
        page: {
          [action.payload.productId]: {
            status: "loading",
          },
        },
      };
    case constants.PAGE_DATA_REQUEST_SUCCESS: {
      const { entities, ...data } = action.payload.data;
      return {
        ...state,
        page: {
          [action.payload.productId]: {
            status: "loaded",
            data,
          },
        },
        entities: {
          user_module_subtest_ratings: entities.user_module_subtest_ratings,
          modules: entities.modules,
        },
      };
    }
    case constants.PAGE_DATA_REQUEST_ERROR:
      return {
        ...state,
        page: {
          [action.payload.productId]: {
            status: "error",
            errorText: action.payload.errorText,
          },
        },
      };

    case constants.SAGA_REQUEST_MODULE_TESTS:
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.moduleId]: { status: "loading" },
        },
      };
    case constants.MODULE_TESTS_REQUEST_SUCCESS:
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.moduleId]: {
            status: "loaded",
            data: action.payload.data.tests,
          },
        },
      };
    case constants.MODULE_TESTS_REQUEST_ERROR:
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.moduleId]: {
            status: "error",
            errorText: action.payload.errorText,
          },
        },
      };

    default:
      return state;
  }
}
