import type { HTMLAttributes } from "react";
import styles from "./Highlight.module.scss";

export type HighlightProps = HTMLAttributes<HTMLElement>;

export default function Highlight(props: HighlightProps) {
  return (
    <mark
      {...props}
      className={`${styles.Highlight} text-text1 cursor-pointer bg-brand2 dark:bg-sky-900 dark:hover:after:border-b-sky-300`}
    />
  );
}
