import { createContext } from "react";

export interface LessonContextProps {
  /** Lesson ID. Required for phrase boxes to add points */
  id: number;
  /**
   * Lesson Title. Example: "Online Shopping".
   *
   * Retrieved from navigation screen props and API call
   **/
  title: string;
  /** IAC, L&C, Travelogue */
  lesson_type_id: number;
  /** Lesson description */
  description?: string;
  /** Lesson number, e.g. "12.4" */
  number: string;
  /** Whether the lesson is marked as "done" (only used when there's no rateable tests) */
  isDone: boolean;
  /** Whether the specific lesson is a travelogue (custom component filtering applies) */
  isTravelogue: boolean;
  /** Image thumbnail of the lesson */
  image_url: string | null;
  /** IAC image thumbnail of the lesson */
  iac_image_url: string | null;
}

/**
 * Lesson context is available in each lesson component (& header/footer)
 *
 * Usage:
 *
 * Somwhere on the lesson screen:
 *
 * ```js
 * function LessonScreen() {
 *  <LessonContext.Provider value={{ id: 1, isDone: true, ...}}>
 *    <NestedComponents>
 *      <PhraseboxComponent />
 *    </NestedComponents>
 *  </LessonContext.Provider>
 * }
 * ```
 *
 * In a lesson component:
 *
 * ```js
 * function PhraseboxComponent() {
 *   const lesson = useContext(LessonContext)!;
 *   console.log(`Lesson ID: ${lesson.id}`)
 *   if (lesson.isDone) {
 *     console.log("Lesson is done!")
 *   }
 * }
 * ```
 */
const LessonContext = createContext<LessonContextProps>({
  id: 0,
  title: "",
  description: "",
  number: "",
  lesson_type_id: 1,
  isDone: false,
  isTravelogue: false,
  image_url: "",
  iac_image_url: "",
});

export default LessonContext;
