import { useContext, useMemo } from "react";

import LessonContext from "../../../../context/LessonContext";
import RateableTestUI from "../../../RateableTests/RateableTestUI";
import { getRatingLevel } from "../../../../utils";
import { useActiveMarkdownHeading } from "../../MarkdownComponent/includes/useActiveMarkdownHeading";
import useElementObserver from "../../../../hooks/useElementObserver";
import usePlayItComponents from "../../../../hooks/usePlayItComponents";
import usePlayItRatings from "../../../../hooks/usePlayItRatings";
import useTranslation from "../../../../hooks/useTranslation";
import { Group as GroupIcon } from "iconoir-react";
import { useGlobalPlayItStore } from "./includes/usePlayIt";

interface Props {
  transcriptId: number;
  characterName: string;
  onToggleDisplaySettings(): void;
  displaySettings: boolean;
}

export default function PlayItHeader(props: Props) {
  const t = useTranslation();
  const lessonId = useContext(LessonContext).id;
  const playItRatings = usePlayItRatings(props.transcriptId);

  const rating = (() => {
    if (!playItRatings) {
      return 0;
    }
    let rating = 0;
    const ratingsArray = [...Object.keys(playItRatings)];
    for (const _rating of ratingsArray) {
      rating = rating + (playItRatings[Number(_rating)] || 0);
    }
    return rating / ratingsArray.length;
  })();

  const playItComponents = usePlayItComponents(lessonId);

  const playItNumber = useMemo(() => {
    // Don't return a number if there's just one component
    if (playItComponents.length <= 1) {
      return;
    }
    // Travelogue components may have multiple play it components
    return playItComponents.findIndex((lc) => lc.component.id === props.transcriptId) + 1;
  }, [playItComponents, props.transcriptId]);

  const playIt = useGlobalPlayItStore();

  /** Check whether Play It is active on this component (3 sec buffer till started) */
  const isActive = Boolean(
    (playIt.status === "active" || playIt.status === "countdown") && playIt.transcriptId === props.transcriptId,
  );

  const title = (() => {
    if (isActive) {
      return props.characterName === "Unknown" ? t("playing") : `${t("playing-as")} ${props.characterName}`;
    }

    if (playItNumber) {
      return `${t("play-it")} #${playItNumber}`;
    }

    return t("play-it");
  })();

  const playItId = playItNumber ? `role-playing-#${playItNumber}` : "role-playing";

  const ref = useElementObserver<HTMLHeadingElement>(
    (visible) => {
      if (visible) {
        useActiveMarkdownHeading.setState({ value: playItId });
      }
    },
    // Add a 200px buffer
    { rootMargin: "0px 0px -200px 0px" },
  );

  return (
    <div ref={ref} id={playItId} className="scroll-mt-24">
      <RateableTestUI.Header
        testName={title}
        testSubheading={t("play-it-subheading")}
        icon={({ className }) => <GroupIcon className={className} />}
        hideProgress
        ratingLevel={getRatingLevel(rating)}
      />
    </div>
  );
}
