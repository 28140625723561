import * as authActions from "../store/auth/actions";
import * as uiActions from "../store/ui/actions";
import { push } from "../res/router";
import { put } from "redux-saga/effects";

/**
 * General handler for Axios errors in Sagas
 *
 * Logs the user out if the "error" is "token_expired"
 */
export default function* handleRequestError(e: unknown) {
  // lovely error handling
  // maybe use a schema validaor one day :)
  if (
    !e ||
    typeof e !== "object" ||
    !("response" in e) ||
    !e.response ||
    typeof e.response !== "object" ||
    !("data" in e.response) ||
    !e.response.data ||
    typeof e.response.data !== "object" ||
    !("code" in e.response.data) ||
    typeof e.response.data.code !== "string" ||
    !("message" in e.response.data) ||
    typeof e.response.data.message !== "string"
  ) {
    return;
  }
  // Toast error message
  yield put(uiActions.toast({ message: e.response.data.message, type: "error" }));

  /*
  type ApiError = {
    code: "license_expired" | "account_disabled" | "lesson_access";
    message: string;
  };
  */
  switch (e.response.data.code) {
    // Navigate to course selection
    case "license_expired":
      push("/members/courses");
      break;
    case "account_disabled":
      yield put(authActions.logout());
      break;
  }
}
