import type { Character } from "@rocketlanguages/types";
import { clsx } from "clsx";
import { getRatingLevel } from "../../../../../utils";
import { twMerge } from "tailwind-merge";

export function CharacterAvatar(props: {
  character: Character;
  rating: number;
  onClick: () => void;
  className?: string;
}) {
  const ratingLevel = getRatingLevel(props.rating);
  return (
    <button
      type="button"
      className={twMerge(clsx("relative h-52 overflow-hidden rounded-2xl bg-missilegray", props.className))}
      onClick={props.onClick}
      aria-label={`Talk to ${props.character.name}`}
    >
      {props.character.avatar_url ? (
        <img alt={props.character.name} src={props.character.avatar_url} className="h-full w-full object-cover" />
      ) : null}

      <div className="absolute bottom-0 left-0 right-0 top-0 transition-all hover:bg-black/30" />
      <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-black/70 via-black/10 to-transparent" />

      {ratingLevel > 0 ? (
        <div className="absolute left-0 top-0 p-2">
          <div
            className={clsx("flex size-9 items-center justify-center rounded-full p-1", {
              "bg-missileaccent text-white": ratingLevel === 1,
              "bg-orange-500 text-white": ratingLevel === 2,
              "bg-missilegreen text-white": ratingLevel === 3,
            })}
          >
            <div className="text-sm font-bold">{props.rating}</div>
          </div>
        </div>
      ) : null}

      <div className="pointer-events-none absolute bottom-0 left-0 right-0 flex-row items-center justify-between p-4">
        <div>
          <div className="text-sm text-white">Talk To</div>
          <div className="text-lg font-semibold text-white">{props.character.name}</div>
        </div>
      </div>
    </button>
  );
}
