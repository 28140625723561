import { Navigate } from "react-router-dom";
import useProductFromCourseParams from "~/hooks/useProductFromCourseParams";

/** Route: members/course/:courseId/level/:levelId/reviews/write*/
export default function RedirectToWriteReviewPage() {
  const product = useProductFromCourseParams();
  if (product) {
    return <Navigate to={`/members/products/${product.id}/reviews/write`} replace />;
  }

  return <Navigate to="/members/courses" replace />;
}
