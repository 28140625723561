import { useRef, useEffect } from "react";
import { noop } from "../utils/utils";

type AnyFunction = (action?: any) => void;

/**
 * Makes sure that dispatch/setState isn't called when the component dismounts
 */
export default function useSafeFn<T extends AnyFunction>(dispatch: T): React.MutableRefObject<T> {
  const dispatchSafe = useRef<T>(dispatch);

  useEffect(() => {
    dispatchSafe.current = dispatch;
    return function cleanup() {
      // @ts-ignore
      dispatchSafe.current = noop;
    };
  }, [dispatch]);

  return dispatchSafe;
}
