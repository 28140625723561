import {
  AnyAction,
  Dispatch,
  Middleware,
  Reducer,
  Store as ReduxStore,
  applyMiddleware,
  compose,
  createStore,
} from "redux";
import { PersistConfig, Persistor } from "redux-persist/lib/types";
import { SharedRootAction, SharedRootState } from "./types";
import createSagaMiddleware, { type Saga, type SagaMiddleware } from "redux-saga"; // Redux middleware
import { persistReducer, persistStore } from "redux-persist";

import { composeWithDevTools } from "@redux-devtools/extension";

/** Needs to have its own storage added for correct configuration */
export const defaultPersistOptions: Omit<PersistConfig<any>, "storage"> = {
  key: "app", // key is required
  // interval applied to storage calls (in ms). Improves performance on storage calls
  throttle: 1000,
  // keys (read: reducers) to include in persistence
  whitelist: ["auth", "user", "preferences", "media", "dashboard", "featureimage"],
  timeout: 12000, // 12 secs
  version: 3,
};

interface ConfigureStoreProps {
  rootReducer: Reducer<any>;
  rootSaga: Saga<any>;
  persistOptions?: PersistConfig<any>;
  extraMiddleware?: Middleware<unknown, any, Dispatch<AnyAction>>[];
  persist?: boolean;
  composer?: typeof compose;
}

/**
 * Configures the redux store and persists state
 */
export default function configureStore({
  rootReducer,
  rootSaga,
  persistOptions = undefined,
  persist = true,
  extraMiddleware = [],
  composer,
}: ConfigureStoreProps): {
  store: ReduxStore<SharedRootState, SharedRootAction>;
  persistor: Persistor;
  sagaMiddleware: SagaMiddleware<Record<string, never>>;
} {
  const sagaMiddleware = createSagaMiddleware();
  const reducer = persist && persistOptions ? persistReducer(persistOptions, rootReducer) : rootReducer;
  const composerToUse = (() => {
    if (composer) {
      return composer;
    }
    if (process.env.NODE_ENV === "development") {
      return composeWithDevTools({ trace: true });
    }
    return compose;
  })();
  const middleware = composerToUse(applyMiddleware(sagaMiddleware, ...extraMiddleware));
  // Create Redux store
  const store = createStore<SharedRootState, SharedRootAction, any, any>(reducer, middleware);

  // Persists our Redux store on the user's phone so that settings will be saved when the user starts the app again
  // Refer to persistOptions to see what store items are saved
  // Chain .purge() on this function call to clear all persisted data
  const persistor = typeof persist === "boolean" && !persist ? null : persistStore(store);

  // Run saga
  sagaMiddleware.run(rootSaga);

  // @ts-ignore
  return { persistor, store, sagaMiddleware };
}
