import useActiveElement from "./useActiveElement";
import { useEffect, useState } from "react";

export default function useIsFocusedWithin(elementRef: React.MutableRefObject<HTMLElement | null>) {
  const activeElement = useActiveElement();
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    setFocused(Boolean(elementRef.current === activeElement || elementRef.current?.contains(activeElement)));
  }, [activeElement, elementRef]);
  return focused;
}
