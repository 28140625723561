import {
  Button,
  Centered,
  CircularProgress,
  ErrorMessage,
  InputField,
  SimpleModal,
  RoundedButton,
} from "@rocketlanguages/ui";
import { FaBookmark, FaChevronDown, FaChevronUp } from "react-icons/fa";
import type { Phrase, UpdateVocabParams, Vocab } from "@rocketlanguages/types";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSharedDispatch, useSharedSelector } from "../../../../store";

import API from "../../../../res/Api";
import LessonContext from "../../../../context/LessonContext";
import { VocabContext } from "../VocabProvider";
import { clsx } from "clsx";
import { getComponentId } from "../AddToVocabPopover/AddToVocabPopover.utils";
import { prepareString } from "../../../../utils/stringUtils";
import { requestAddPoints } from "../../../../store/lesson/actions";
import { toast } from "react-toastify";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import usePromise from "../../../../hooks/usePromise";
import useTranslation from "../../../../hooks/useTranslation";
import { LessonPhrasebox } from "../../../PhraseboxFacelift/PhraseboxFacelift";

const getPhraseFromSelection = (allPhrases: { [id: number]: Phrase | undefined }) => {
  const selectedItemId = getComponentId(window.getSelection()?.anchorNode) || 0;
  return allPhrases[selectedItemId];
};

interface AddToVocabModalProps {
  /** User can either edit or create new vocab */
  mode:
    | {
        val: "selection";
        text: string;
      }
    | {
        val: "edit";
        vocab: Vocab;
      };
  onClose(): void;
  onDelete?: () => void;
  onSubmit?(vocab?: Vocab): void;
}

export default function AddToVocabModal({ mode, onClose, onSubmit }: AddToVocabModalProps) {
  const t = useTranslation();
  const vocab = useContext(VocabContext);
  const [showSimilarPhrases, setShowSimilarPhrases] = useState(false);
  const lessonId = useContext(LessonContext)?.id || 0;
  const courseId = useActiveCourse()?.id || 0;
  const allPhrases = useSharedSelector((store) => store.lesson.entities.phrases);
  const [selectedPhrase, setSelectedPhrase] = useState<Phrase | null>(
    (() => {
      if ("vocab" in mode) {
        return mode.vocab.phrase;
      }
      const phrase = getPhraseFromSelection(allPhrases);
      // User has selected a whole phrase string
      if (phrase?.strings.find((str) => prepareString(str.text) === prepareString(mode.text))) {
        return phrase;
      }
      return null;
    })(),
  );
  const dispatch = useSharedDispatch();

  // Form fields
  const [text, setText] = useState(mode.val === "selection" ? mode.text : mode.vocab.text);
  const [translation, setTranslation] = useState("vocab" in mode ? mode.vocab.translation || "" : "");
  const [notes, setNotes] = useState("vocab" in mode ? mode.vocab.notes || "" : "");

  const promise = useMemo(() => {
    if (mode.val === "edit") {
      return;
    }

    return () =>
      API.getJson("v2/phrase/search", {
        query: mode.text,
        course_id: courseId,
      }).then((data) => {
        const formattedSelectionText = prepareString(mode.text);
        for (const item of data.data) {
          if (item.phrase.strings.find((ps) => prepareString(ps.text) === formattedSelectionText)) {
            setSelectedPhrase(item.phrase);
          }
        }

        return data;
      });
  }, [mode, courseId]);

  const { state } = usePromise(promise);

  const { state: saveVocabState, execute } = usePromise();
  const { state: deleteRequestState, execute: executeDelete } = usePromise();

  useEffect(
    function onSelectedPhraseChange() {
      if (!selectedPhrase) {
        return;
      }
      const { strings } = selectedPhrase;
      // Update vocab and meaning if the user decides to change the phrase
      setText(strings[0]?.text || "");
      setTranslation(strings[strings.length - 1]?.text || "");
    },
    [selectedPhrase],
  );

  const similarPhrases = useMemo(() => {
    if (!state || state.status !== "loaded") {
      return [];
    }
    const phrases = state.data.data.filter((item) => item.phrase !== selectedPhrase);

    // In case nothing was matched, we'd probably want to add the phrase that was selected
    if (phrases.length === 0) {
      const phrase = getPhraseFromSelection(allPhrases);
      if (phrase) {
        return [
          {
            lesson_metadata: {
              course_id: 0,
              id: 0,
              name: "",
              product_ids: [],
            },
            phrase,
          },
        ];
      }
    }

    return phrases;
  }, [selectedPhrase, allPhrases, state]);

  function renderBody() {
    if (mode.val === "selection" && !selectedPhrase) {
      if (!state || state.status === "loading") {
        return (
          <Centered>
            <CircularProgress />
          </Centered>
        );
      }
      if (state.status === "error") {
        return <ErrorMessage title="Error" message={state.errorText} />;
      }
    }

    return (
      <div>
        {selectedPhrase ? (
          <LessonPhrasebox phrase={selectedPhrase} disableVocabUnderline />
        ) : (
          <div>
            <InputField name="vocab" label={t("vocab")} value={text} onChange={(e) => setText(e.target.value)} />
            <InputField
              name="meaning"
              label={t("meaning")}
              value={translation}
              onChange={(e) => setTranslation(e.target.value)}
            />
          </div>
        )}
        {mode.val === "selection" && (
          <button
            type="button"
            onClick={() => setShowSimilarPhrases((state) => !state)}
            className={clsx(similarPhrases.length <= 0 && "invisible", "flex items-center gap-2 py-4")}
            role="checkbox"
            aria-checked={showSimilarPhrases}
            disabled={similarPhrases.length <= 0}
          >
            {showSimilarPhrases ? <FaChevronUp /> : <FaChevronDown />}
            {t("similar-phrases")}
          </button>
        )}
        {showSimilarPhrases &&
          similarPhrases.map((item) => (
            <div key={item.phrase.id} className="relative my-4">
              <LessonPhrasebox phrase={item.phrase} disableVocabUnderline />
              <Button
                color="primary"
                size="small"
                style={{ lineHeight: 1, position: "absolute", right: "1rem", bottom: "1rem" }}
                onClick={() => {
                  setSelectedPhrase(item.phrase);
                  setShowSimilarPhrases(false);
                }}
              >
                {t("add")}
              </Button>
            </div>
          ))}

        <InputField
          use="textarea"
          name="notes"
          label={t("notes")}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          style={{ maxHeight: 300 }}
          spellCheck={false}
        />
      </div>
    );
  }

  function doAddOrEditVocabRequest() {
    if (mode.val === "selection") {
      const baseParams = {
        course_id: courseId,
        lesson_id: lessonId,
        notes,
      };

      // Either attach a phrase id, or text/translation to the params
      const customField = (() => {
        if (selectedPhrase) {
          return { phrase_id: selectedPhrase.id };
        }
        return {
          text,
          translation,
        };
      })();

      const params = {
        ...baseParams,
        ...customField,
      };

      // @ts-ignore
      return API.post("v2/vocab/add", params);
    }

    // Either attach a phrase id, or text/translation to the params
    const params = (() => {
      if (selectedPhrase) {
        return {
          notes,
          phrase_id: selectedPhrase.id,
        } as UpdateVocabParams;
      }
      return {
        notes,
        text,
        translation,
      } as UpdateVocabParams;
    })();

    // @ts-ignore
    return API.post(["v2/vocab/{vocab}/update", { vocab: mode.vocab.id }], params);
  }

  function addVocab() {
    // Wrap function in "execute()". usePromise will update the loading/finished states of this promise
    execute(async () => {
      try {
        const res = await doAddOrEditVocabRequest();
        // add points
        if (mode.val === "selection") {
          dispatch(requestAddPoints({ rewardType: "addVocab", data: { vocab: res.data.id } }));
        }
        // Display "save successful"
        toast.success(t("vocab-saved"));
        // Add to vocab map. Will highlight phrases in lesson that match selection
        vocab?.set({
          [res.data.text.trim().toLowerCase()]: res.data,
        });
        // Close the modal
        onSubmit ? onSubmit(res.data) : onClose();
      } catch (err) {
        // @ts-ignore
        if (typeof err === "object" && err?.response?.data?.errors?.Vocab?.[0]) {
          // {data: {errors: {Vocab: ["Vocab already exists"]}}}
          // @ts-ignore
          toast.error(err.response.data.errors.Vocab[0]);
        } else {
          toast.error(`${t("unknown-error")} ${t("please-try-again")}`);
        }
      }
    });
  }

  return (
    <SimpleModal isOpen onClose={onClose} onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
      <span className="flex items-center">
        <FaBookmark className="mr-4 text-brand dark:text-text2" size={20} />
        <h3 className="font-sans">{mode.val === "edit" ? t("edit-vocab") : "Save To Vocab"}</h3>
      </span>
      <div className="my-4">{renderBody()}</div>
      <div
        className={clsx(
          "grid w-full grid-cols-1 gap-2 sm:w-auto",
          mode.val === "edit" ? "sm:grid-cols-3" : "w-full sm:grid-cols-2",
        )}
      >
        {mode.val === "edit" && (
          <div className="flex">
            <button
              type="button"
              disabled={deleteRequestState?.status === "loading"}
              onClick={() => {
                executeDelete(async () => {
                  await API.delete(["v2/vocab/{vocab}", { vocab: mode.vocab.id }]);
                  toast.success(t("vocab-removed"));
                  // Remove from vocab map
                  vocab?.remove(mode.vocab.text.trim().toLowerCase());
                  // onDelete?.();
                  onClose();
                });
              }}
              className={clsx(
                "font-medium text-red-500 hover:underline",
                deleteRequestState?.status === "loading" && "opacity-50",
              )}
            >
              {t("remove")}
            </button>
          </div>
        )}
        <RoundedButton
          onClick={onClose}
          className="hover:bg-missilebrand/90 w-full bg-missilebrand font-semibold text-white"
        >
          {t("cancel")}
        </RoundedButton>
        <RoundedButton
          onClick={addVocab}
          loading={saveVocabState?.status === "loading"}
          color="primary"
          className="hover:bg-missileaccent/90 w-full bg-missileaccent font-semibold text-white"
        >
          {t("save")}
        </RoundedButton>
      </div>
    </SimpleModal>
  );
}
