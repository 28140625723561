import { createBrowserHistory, createMemoryHistory, type Transition } from "history";
import { useCallback, useEffect } from "react";

export const history =
  typeof window === "undefined" || typeof document === "undefined" ? createMemoryHistory() : createBrowserHistory();

interface PromptProps {
  when: boolean;
  message: string;
}

/**
 * A declarative interface for showing a window.confirm dialog with the given
 * message when the user tries to navigate away from the current page.
 *
 * This also serves as a reference implementation for anyone who wants to
 * create their own custom prompt component.
 */
export function Prompt({ message, when }: PromptProps) {
  usePrompt(message, when);
  return null;
}

/**
 * Prevents navigation away from the current page using a window.confirm prompt
 * with the given message.
 */
export function usePrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message],
  );

  useBlocker(blocker, when);
}

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @see https://reactrouter.com/api/useBlocker
 */
function useBlocker(blocker: (tx: Transition) => void, when = true): void {
  useEffect(() => {
    if (!when) return;

    const unblock = history.block((tx) => {
      blocker({
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      });
    });

    return unblock;
  }, [blocker, when]);
}

/*
// @ts-ignore
history.listen(({ location }) => {
  if (gtag && process.env.NODE_ENV === "production") {
    gtag("config", "UA-xxxxxx", { page_path: location.pathname });
  }
});
*/

// We are exporting the navigate method to use it arbitrary in the app
// @see https://reach.tech/router/api/LocationProvider
export const { push, replace } = history;
