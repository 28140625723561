import { clsx } from "clsx";
import { useEffect } from "react";
import { FiCheck } from "react-icons/fi";

type PlaybackSpeedDialogProps = {
  onClose(value?: number): void;
  value: number;
  className?: string;
  options?: Array<{ label: string; value: number }>;
};

export function PlaybackSpeedDialog(props: PlaybackSpeedDialogProps) {
  const speeds = props.options || [
    { label: "0.5x", value: 0.5 },
    { label: "0.75x", value: 0.75 },
    { label: "Normal", value: 1 },
    { label: "1.25x", value: 1.25 },
    { label: "1.5x", value: 1.5 },
  ];

  useEffect(() => {
    if (window === undefined) {
      return;
    }

    const handler = () => {
      props.onClose();
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, [props.onClose]);

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 top-0" onClick={() => props.onClose()}></div>
      <div
        className={clsx(
          "absolute top-14 z-10 w-44 overflow-auto rounded-lg bg-surface2 shadow-lg transition-all duration-150",
          props.className,
        )}
      >
        <div
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              props.onClose();
            }
          }}
          className="text-text1 flex flex-col py-4"
        >
          {speeds.map((speed) => (
            <PlaybackSpeedButton
              key={speed.value}
              checked={speed.value === props.value}
              onClick={() => props.onClose(speed.value)}
            >
              {speed.label}
            </PlaybackSpeedButton>
          ))}
        </div>
      </div>
    </>
  );
}

type PlaybackSpeedButtonProps = {
  checked: boolean;
  children: string;
  onClick(): void;
};

function PlaybackSpeedButton({ checked, onClick, children }: PlaybackSpeedButtonProps) {
  return (
    <button
      role="checkbox"
      aria-checked={checked}
      aria-label={`Playback Speed ${children}`}
      onClick={onClick}
      className="flex items-center overflow-hidden px-4 py-2 hover:bg-brand2 dark:hover:bg-neutral-700"
    >
      <span className="mr-2 w-5">{checked && <FiCheck />}</span>
      <span className="text-sm font-medium">{children}</span>
    </button>
  );
}
