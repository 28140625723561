import { forwardRef } from "react";
import classes from "./Stack.module.scss";
import { clsx } from "clsx";
import type { HTMLAttributes } from "react";

type Props = {
  /* Whether the spaces apply recursively (i.e. regardless of nesting level) */
  recursive?: boolean;
  row?: boolean;
} & HTMLAttributes<HTMLDivElement>;
/**
 * Adds spacing between Flex elements
 *
 * Source: https://every-layout.dev/layouts/stack/
 *
 Usage

 ```jsx
 <Stack recursive>
  <div><!-- first level child --></div>
  <div><!-- first level sibling --></div>
  <div>
    <div><!-- second level child --></div>
    <div><!-- second level sibling --></div>
  </div>
</Stack>
 ```
 */
export default forwardRef(function Stack(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { className, recursive, row, ...rest } = props;
  return (
    <div
      ref={ref}
      className={clsx(classes.stack, recursive && classes.recursive, row && classes.row, className)}
      {...rest}
    />
  );
});
