import { useCallback, useContext } from "react";
import LessonContext from "../../../../context/LessonContext";
import type { RocketRecordState } from "../../../../hooks/useRocketRecord/useRocketRecordStore";
import { getRatingLevel } from "../../../../utils";
import { shallowEqual } from "react-redux";
import { useSharedSelector } from "../../../../store";
import { PhraseboxContext } from "../../../PhraseboxFacelift/context";

export default function useGetUserRating(props: { lessonId?: number; initializeWithRating: boolean }) {
  const context = useContext(PhraseboxContext);
  const lessonId = useContext(LessonContext)?.id || props.lessonId || 0;

  const ratingPercentage = useSharedSelector(
    (store) => store.lesson.entities.user_phrase_recognition_ratings[lessonId]?.[context?.phrase.id || 0],
  );

  const getResultDisplay = useCallback(
    (state: RocketRecordState): { percentage: number; ratingLevel: number } | null => {
      if (!state.ratingVisible) {
        return null;
      }

      // If it's inside a phrase test, don't use store rating level
      if (!props.initializeWithRating) {
        return state.result
          ? {
              ratingLevel: state.result.ratingLevel,
              percentage: state.result.percentageDisplay,
            }
          : null;
      }

      // Outside a phrase test, display rating level while inactive
      if (state.flag.status !== "ACTIVE" && ratingPercentage) {
        return {
          ratingLevel: getRatingLevel(ratingPercentage),
          percentage: ratingPercentage,
        };
      }
      return null;
    },
    [props.initializeWithRating, ratingPercentage],
  );

  const resultDisplay = context?.rocketRecord.useRocketRecordState(getResultDisplay, shallowEqual);

  return resultDisplay;
}
