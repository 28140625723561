import { create } from "zustand";

type BannerStoreData = {
  visible: boolean;
  isPromo: boolean;
  pricingPageUrl: string | undefined;

  modalImageUrl: string | undefined;
  expiresAtEpochSeconds: number;

  subtitle: string | undefined;
  buttonText: string;
};

type BannerStore = BannerStoreData & {
  hide(): void;
  show(): void;
  reset(): void;
};

const defaultState: BannerStoreData = {
  visible: false,
  pricingPageUrl: undefined,
  isPromo: false,
  modalImageUrl: undefined,
  expiresAtEpochSeconds: 0,
  subtitle: undefined,
  buttonText: "",
};

export const useBannerStore = create<BannerStore>((set) => ({
  ...defaultState,

  hide() {
    set({ visible: false });
  },

  show() {
    set({ visible: true });
  },

  reset() {
    set(defaultState);
  },
}));
