import type { QuizAnswer, VideoComponent } from "@rocketlanguages/types";
import { useContext, useState } from "react";
import usePhraseTest, { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";

import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { RateableTestTypeIds } from "../../../utils/constants";
import { RateableTestUIContext } from "../RateableTestUI/includes/context";
import TranslateItVideoPlayer from "./includes/TranslateItVideoPlayer";
import useTranslateItQuestions from "../../../hooks/useTranslateItQuestions";
import useTranslation from "../../../hooks/useTranslation";
import { clsx } from "clsx";
import { RoundedButton } from "@rocketlanguages/ui";

export function TranslateItFacelift({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  const phraseTest = usePhraseTest({
    testTypeId: RateableTestTypeIds.TRANSLATE_IT,
    lessonId: lesson.id,
    rateableTestId,
    mode: "unrated_components",
  });

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.TRANSLATE_IT}
      testName={t("translate-it")}
      testSubheading="Watch the video and choose the correct translation"
    >
      <TranslateItTest />
    </RateableTest>
  );
}

function TranslateItTest() {
  const phraseTest = useContext(RateableTestContext).phraseTest;
  const [selectedQuizAnswer, setSelectedQuizAnswer] = useState<QuizAnswer>();
  const uiContext = useContext(RateableTestUIContext);

  const hasRevealed = phraseTest.state.revealed.has(phraseTest.state.index);

  /* override component type - avoids type issues in all other rateable tests */
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index] as unknown as
    | VideoComponent
    | undefined;

  if (!currentPhrase) {
    return null;
  }

  return (
    <div className="space-y-4">
      <TranslateItVideoPlayer
        src={currentPhrase.file}
        poster={currentPhrase.thumbnail}
        preload={false}
        videoId={currentPhrase.id}
        autoPlay={!uiContext?.instructionsVisible}
        muted
      />
      <QuizOptionWrapper
        phraseTest={phraseTest}
        hasRevealed={hasRevealed}
        selectedQuizAnswer={selectedQuizAnswer}
        setSelectedQuizAnswer={setSelectedQuizAnswer}
      />
      <RevealContinueButtons
        state={hasRevealed ? "continue" : "reveal"}
        onContinue={() => {
          phraseTest.methods.rate(selectedQuizAnswer?.is_correct ? "easy" : "hard");
          setSelectedQuizAnswer(undefined);
        }}
        onReveal={() => {
          const revealAction = phraseTestActions.reveal(selectedQuizAnswer?.is_correct ? 3 : 1);
          phraseTest.methods.dispatch(revealAction);
        }}
        revealDisabled={typeof selectedQuizAnswer === "undefined"}
      />
    </div>
  );
}

function QuizOptionWrapper(props: {
  phraseTest: ReturnType<typeof usePhraseTest>;
  hasRevealed: boolean;
  selectedQuizAnswer: QuizAnswer | undefined;
  setSelectedQuizAnswer: (answer: QuizAnswer) => void;
}) {
  const { phraseTest } = props;

  const questions = useTranslateItQuestions(
    phraseTest.components.videoComponents,
    phraseTest.components.testPhrases as unknown as VideoComponent[],
    phraseTest.state.gameSessionId,
  );

  const currentQuestion = questions[props.phraseTest.state.index];

  return (
    <div key={props.phraseTest.state.index} className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
      {currentQuestion?.map((answer) => {
        return (
          <QuizOption
            key={answer.id}
            answer={answer}
            answered={props.hasRevealed}
            selected={props.selectedQuizAnswer?.id === answer.id}
            onClick={() => props.setSelectedQuizAnswer(answer)}
          />
        );
      })}
    </div>
  );
}

function QuizOption({
  answer,
  onClick,
  answered,
  selected,
}: {
  answer: QuizAnswer;
  onClick: () => void;
  answered?: boolean;
  selected?: boolean;
}) {
  return (
    <button
      type="button"
      className={clsx(
        "flex flex-row space-x-4 rounded-2xl border px-4 py-5",
        answered
          ? {
              "border-missilegreen bg-green-50 dark:bg-green-950": answer.is_correct,
              "border-missileaccent bg-red-50 dark:bg-red-950": selected && !answer.is_correct,
              "border-missilesurfacedark bg-missilesurfacelight": !selected && !answer.is_correct,
            }
          : {
              "border-missileaccent": selected,
              "border-missilesurfacedark": !selected || !answer.is_correct,
            },
      )}
      onClick={onClick}
      disabled={answered}
    >
      <div
        className={clsx(
          "flex size-6 min-h-6 min-w-6 items-center justify-center rounded-full border bg-white",
          answered
            ? {
                "border-missilegreen": answer.is_correct,
                "border-missilesurfacedark": !answer.is_correct && !selected,
                "border-missileaccent": !answer.is_correct && selected,
              }
            : {
                "border-missileaccent": selected,
                "border-missilesurfacedark": !selected,
              },
        )}
      >
        <div
          className={clsx(
            "size-3 rounded-full",
            answered
              ? {
                  "bg-missileaccent": selected && !answer.is_correct,
                  "bg-missilegreen": answer.is_correct,
                }
              : {
                  "bg-missileaccent": selected,
                },
          )}
        />
      </div>
      <div className="flex flex-col items-start space-y-1">
        <span className="text-start font-semibold leading-5" dangerouslySetInnerHTML={{ __html: answer.text }} />
        {answered && answer.explanation ? (
          <span
            className={clsx(
              "text-start text-xs",
              answered && answer.is_correct ? "text-missiledark" : "text-missilegray2",
            )}
            dangerouslySetInnerHTML={{
              // Note: this is set as HTML entities because it includes html entities
              __html: answer.explanation,
            }}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </button>
  );
}

function RevealContinueButtons({
  state,
  onContinue,
  onReveal,
  revealDisabled,
}: {
  state: "reveal" | "continue";
  onContinue(): void;
  onReveal(): void;
  revealDisabled?: boolean;
}) {
  const t = useTranslation();

  return (
    <RoundedButton
      onClick={state === "continue" ? onContinue : onReveal}
      disabled={revealDisabled}
      className="hover:bg-missileaccent/90 mx-auto w-full max-w-60 bg-missileaccent font-semibold text-white"
    >
      {state === "continue" ? t("continue") : "Check"}
    </RoundedButton>
  );
}
