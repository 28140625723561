import type { TranscriptLine } from "@rocketlanguages/types";
import { getLanguages } from "./utils";
import { useMemo } from "react";
import { useSharedSelector } from "../../../../../store";

export default function usePlayItLanguages(lines: TranscriptLine[]) {
  const phrases = useSharedSelector((store) => store.lesson.entities.phrases);
  const romanizationHidden = useSharedSelector((state) => !!Number(state.user.preferences?.hide_romanized));
  const hideKana = useSharedSelector((state) => !!Number(state.user.preferences?.hide_kana));
  // const kanaHidden = hideKana ? !!Number(hideKana) : false;
  return useMemo(
    () => getLanguages(lines, phrases, romanizationHidden, hideKana),
    [lines, phrases, romanizationHidden, hideKana],
  );
}
