import * as actions from "./actions";
import * as constants from "./constants";

import { SagaReturnType, call, put, takeEvery } from "redux-saga/effects";

import API from "../../res/Api";
import { ActionType } from "typesafe-actions";
import handleRequestError from "../../utils/handleRequestError";

/**
 * ASYNC_DASHBOARD_REQUEST is dispatched as soon as a user visits the Dashboard Screen.
 */
export function* asyncDashboardRequest(action: ActionType<typeof actions.asyncDashboardRequest>) {
  const { productId, timezone } = action.payload;
  try {
    yield put(actions.dashboardRequestStart(productId));
    const request = () =>
      API.get(["v2/product/{product}/dashboard", { product: productId }], { timezone, api_version: 2 });
    const response: SagaReturnType<typeof request> = yield call(request);
    yield put(actions.dashboardRequestSuccess(response.data, productId));
  } catch (error: any) {
    console.warn("[asyncDashboardRequest]", error);

    const [errorText, statusCode, errorCode] = ((): [string, number, string] => {
      if (error.response && error.response.status === 403) {
        return ["Your session has expired. Please log in again.", 403, "session_expired"];
      }
      return [
        error.response?.data?.error || error.response?.data?.message || "Network Error",
        error.response?.status || 0,
        error.response?.data?.code || "unknown_error",
      ];
    })();

    yield put(actions.dashboardRequestFail({ errorText, productId, statusCode, errorCode }));

    if (error.response) {
      // Logs the user out if their token expired
      yield handleRequestError(error);
    }
  }
}

export function* asyncAdminDashboardRequest(action: ActionType<typeof actions.asyncAdminDashboardRequest>) {
  const { productId } = action.payload;
  try {
    yield put(actions.dashboardRequestStart(productId));
    const request = () => API.get(["v2/admin/product/{product}/dashboard", { product: productId }]);
    const response: SagaReturnType<typeof request> = yield call(request);
    yield put(actions.dashboardRequestSuccess(response.data, productId));
  } catch (error: any) {
    console.warn("[asyncDashboardRequest]", error);
    const errorText = error.response?.data?.error || error.response?.data?.message || "Network Error";
    const statusCode = error.response?.status || 0;
    yield put(actions.dashboardRequestFail({ errorText, productId, errorCode: "unknown", statusCode }));
    if (error.response) {
      // Logs the user out if their token expired
      yield handleRequestError(error);
    }
  }
}

// Any watchers go in here. They get forked in the Root Saga
export default [
  takeEvery(constants.ASYNC_DASHBOARD_REQUEST, asyncDashboardRequest),
  takeEvery(constants.ASYNC_ADMIN_DASHBOARD_REQUEST, asyncAdminDashboardRequest),
];
