import { Button, Checkbox } from "@rocketlanguages/ui";

import { DrawItContext } from "./context";
import { RateableTestUIContext } from "../../RateableTestUI/includes/context";
import { useContext } from "react";

export default function Settings() {
  const context = useContext(DrawItContext);
  const uiContext = useContext(RateableTestUIContext);

  return (
    <div>
      <h4 className="mb-2">Options</h4>
      {/* <div className="mb-4">
          Select what you want to appear on the FRONT of the Flashcards. Note that you can not select all, as something
          must show on the reverse side.
        </div> */}
      <div className="mb-4">
        <div className="mb-2 sm:mb-4">
          <Checkbox
            checked={!!context?.showShadow}
            onChange={() => {
              context?.setShowShadow(!context?.showShadow);
            }}
            label={"Show shadow"}
          />
        </div>
        <div className="mb-2 sm:mb-4">
          <Checkbox
            checked={!!context?.showGrid}
            onChange={() => {
              context?.setShowGrid(!context?.showGrid);
            }}
            label={"Show grid"}
          />
        </div>
        <div className="mb-2 sm:mb-4">
          <Checkbox
            checked={!!context?.enableHints}
            onChange={() => {
              context?.setEnableHints(!context?.enableHints);
            }}
            label={"Enable hints (appears after two failed attempts)"}
            disabled={context?.quizState === "started"}
          />
        </div>
      </div>
      <div className="my-4 flex justify-center sm:my-6">
        <Button
          color="primary"
          onClick={() => {
            uiContext?.toggleSettings();
          }}
        >
          CONFIRM
        </Button>
      </div>
    </div>
  );
}
