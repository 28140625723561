/** Performs binary search on cues */
export function getTranscriptCueIndex(arr: TextTrackCue[], timestamp: number) {
  // The cues have startTime/endTime in 3dp
  // Sometimes the timestamp may be between the two cues (e.g. Safari reports media timestamps with higher precision)
  // So we need to round the timestamp to 3dp
  const formattedTimestamp = Number(timestamp.toFixed(3));

  function doSearch(startIndex: number, endIndex: number): number {
    // boundary check
    if (startIndex > endIndex) {
      return -1;
    }
    // get pivot
    const pivot = Math.floor((startIndex + endIndex) / 2);

    const el = arr[pivot];
    if (!el) {
      return -1;
    }

    // Element start time & end time is between given timestamp
    if (formattedTimestamp >= el.startTime && formattedTimestamp <= el.endTime) {
      return pivot;
    }

    // Search left side of array
    if (formattedTimestamp < el.startTime) {
      return doSearch(startIndex, pivot - 1);
    }

    // Search right side of arr
    if (formattedTimestamp > el.endTime) {
      return doSearch(pivot + 1, endIndex);
    }

    // not found
    return -1;
  }

  return doSearch(0, arr.length - 1);
}
