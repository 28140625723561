import { create } from "zustand";

export const useActiveMarkdownHeading = create<{
  value: string | null;
  setActiveHeading(heading: string | null): void;
}>((set) => {
  return {
    value: null,
    setActiveHeading: (heading) => {
      set({ value: heading });
    },
  };
});
