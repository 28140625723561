import useProductFromCourseParams from "~/hooks/useProductFromCourseParams";
import { Navigate } from "react-router-dom";

/** Route: members/course/:courseId/level/:levelId/progress */
export function RedirectToProgressPage() {
  const product = useProductFromCourseParams();
  if (product) {
    return <Navigate to={`/members/products/${product.id}/progress`} replace />;
  }
  return <Navigate to="/members/courses" replace />;
}
