import type { PhraseString } from "@rocketlanguages/types";
import { PhraseStringNotationButton } from "../../Notation";
import { sortNotations } from "../../../utils";

interface Props {
  phraseString: PhraseString;
  variant?: "faded";
  hiddenNotationIds?: number[] | undefined;
}

export default function PhraseStringNotationButtons({ phraseString, variant, hiddenNotationIds }: Props) {
  const filtered = hiddenNotationIds
    ? phraseString.notations.filter((n) => !hiddenNotationIds.includes(n.id))
    : phraseString.notations;

  return (
    <div className="flex flex-row items-center space-x-2">
      {sortNotations(filtered).map((psNotation) => (
        <PhraseStringNotationButton
          key={psNotation.id}
          phraseStringNotation={psNotation}
          phraseText={phraseString.text}
          variant={variant}
          ws={phraseString.writing_system_id}
        />
      ))}
    </div>
  );
}
