import type { KeyMapperObject } from "./keymaps";

export type Locale = {
  lower: KeyMapperObject;
  upper: KeyMapperObject;
};

export const english: Locale = {
  lower: {
    Backquote: "`",
    Digit1: "1",
    Digit2: "2",
    Digit3: "3",
    Digit4: "4",
    Digit5: "5",
    Digit6: "6",
    Digit7: "7",
    Digit8: "8",
    Digit9: "9",
    Digit0: "0",
    Minus: "-",
    Equal: "=",
    KeyQ: "q",
    KeyW: "w",
    KeyE: "e",
    KeyR: "r",
    KeyT: "t",
    KeyY: "y",
    KeyU: "u",
    KeyI: "i",
    KeyO: "o",
    KeyP: "p",
    BracketLeft: "[",
    BracketRight: "]",
    Backslash: "\\",
    KeyA: "a",
    KeyS: "s",
    KeyD: "d",
    KeyF: "f",
    KeyG: "g",
    KeyH: "h",
    KeyJ: "j",
    KeyK: "k",
    KeyL: "l",
    Semicolon: ";",
    Quote: "'",
    ShiftLeft: "Shift",
    KeyZ: "z",
    KeyX: "x",
    KeyC: "c",
    KeyV: "v",
    KeyB: "b",
    KeyN: "n",
    KeyM: "m",
    Comma: ",",
    Period: ".",
    Slash: "/",
  },
  upper: {
    Backquote: "~",
    Digit1: "!",
    Digit2: "@",
    Digit3: "#",
    Digit4: "$",
    Digit5: "%",
    Digit6: "^",
    Digit7: "&",
    Digit8: "*",
    Digit9: "(",
    Digit0: ")",
    Minus: "_",
    Equal: "+",
    KeyQ: "Q",
    KeyW: "W",
    KeyE: "E",
    KeyR: "R",
    KeyT: "T",
    KeyY: "Y",
    KeyU: "U",
    KeyI: "I",
    KeyO: "O",
    KeyP: "P",
    BracketLeft: "{",
    BracketRight: "}",
    Backslash: "|",
    KeyA: "A",
    KeyS: "S",
    KeyD: "D",
    KeyF: "F",
    KeyG: "G",
    KeyH: "H",
    KeyJ: "J",
    KeyK: "K",
    KeyL: "L",
    Semicolon: ":",
    Quote: '"',
    ShiftLeft: "Shift",
    KeyZ: "Z",
    KeyX: "X",
    KeyC: "C",
    KeyV: "V",
    KeyB: "B",
    KeyN: "N",
    KeyM: "M",
    Comma: "<",
    Period: ">",
    Slash: "?",
  },
};

export const russian: Locale = {
  lower: {
    Backquote: "ё",
    Digit1: "1",
    Digit2: "2",
    Digit3: "3",
    Digit4: "4",
    Digit5: "5",
    Digit6: "6",
    Digit7: "7",
    Digit8: "8",
    Digit9: "9",
    Digit0: "0",
    Minus: "-",
    Equal: "=",
    KeyQ: "й",
    KeyW: "ц",
    KeyE: "у",
    KeyR: "к",
    KeyT: "е",
    KeyY: "н",
    KeyU: "г",
    KeyI: "ш",
    KeyO: "щ",
    KeyP: "з",
    BracketLeft: "х",
    BracketRight: "ъ",
    Backslash: "\\",
    KeyA: "ф",
    KeyS: "ы",
    KeyD: "в",
    KeyF: "а",
    KeyG: "п",
    KeyH: "р",
    KeyJ: "о",
    KeyK: "л",
    KeyL: "д",
    Semicolon: "ж",
    Quote: "э",
    ShiftLeft: "Shift",
    KeyZ: "я",
    KeyX: "ч",
    KeyC: "с",
    KeyV: "м",
    KeyB: "и",
    KeyN: "т",
    KeyM: "ь",
    Comma: "б",
    Period: "ю",
    Slash: ".",
  },
  upper: {
    Backquote: "Ё",
    Digit1: "!",
    Digit2: "@",
    Digit3: "#",
    Digit4: "$",
    Digit5: "%",
    Digit6: "^",
    Digit7: "&",
    Digit8: "*",
    Digit9: "(",
    Digit0: ")",
    Minus: "_",
    Equal: "+",
    KeyQ: "Й",
    KeyW: "Ц",
    KeyE: "У",
    KeyR: "К",
    KeyT: "Е",
    KeyY: "Н",
    KeyU: "Г",
    KeyI: "Ш",
    KeyO: "Щ",
    KeyP: "З",
    BracketLeft: "Х",
    BracketRight: "Ъ",
    Backslash: "\\",
    KeyA: "Ф",
    KeyS: "Ы",
    KeyD: "В",
    KeyF: "А",
    KeyG: "П",
    KeyH: "Р",
    KeyJ: "О",
    KeyK: "Л",
    KeyL: "Д",
    Semicolon: "Ж",
    Quote: "Э",
    ShiftLeft: "Shift",
    KeyZ: "Я",
    KeyX: "Ч",
    KeyC: "С",
    KeyV: "М",
    KeyB: "И",
    KeyN: "Т",
    KeyM: "Ь",
    Comma: "Б",
    Period: "Ю",
    Slash: "?",
  },
};
