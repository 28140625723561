export default function PocketIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill={"none"} xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Pocket Icon</title>
      <path
        d="M4.66732 3.5H23.334C23.9528 3.5 24.5463 3.74583 24.9839 4.18342C25.4215 4.621 25.6673 5.21449 25.6673 5.83333V12.8333C25.6673 15.9275 24.4382 18.895 22.2502 21.0829C20.0623 23.2708 17.0948 24.5 14.0007 24.5C12.4686 24.5 10.9515 24.1982 9.53601 23.6119C8.12055 23.0256 6.83442 22.1663 5.75107 21.0829C3.56315 18.895 2.33398 15.9275 2.33398 12.8333V5.83333C2.33398 5.21449 2.57982 4.621 3.0174 4.18342C3.45499 3.74583 4.04848 3.5 4.66732 3.5V3.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 11.666L14.0007 16.3327L18.6673 11.666"
        stroke={"currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
