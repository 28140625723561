// Saga initiators
export const ASYNC_DASHBOARD_REQUEST = "Dashboard/ASYNC_REQUEST";
// Regular actions
export const DASHBOARD_REQUEST_START = "Dashboard/REQUEST_START";
export const DASHBOARD_REQUEST_FAIL = "Dashboard/REQUEST_FAIL";
export const DASHBOARD_REQUEST_SUCCESS = "Dashboard/REQUEST_SUCCESS";
export const ADMIN_DASHBOARD_REQUEST_START = "Dashboard/ADMIN_DASHBOARD_REQUEST_START";

export const ASYNC_ADMIN_DASHBOARD_REQUEST = "Dashboard/ASYNC_ADMIN_DASHBOARD_REQUEST";

export const DASHBOARD_RESET = "Dashboard/DASHBOARD_RESET";
