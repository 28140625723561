import type { AudioComponent, LessonComponent } from "@rocketlanguages/types";
import { useContext, useEffect, useRef, useState } from "react";
import AudioContext from "../../../ui/Audio/AudioContext";
import LessonContext from "../../../context/LessonContext";
import { PlaybackSpeedDialog } from "@rocketlanguages/ui";
import AudioSlider from "./includes/Slider";
import SearchResultsOverlay from "./includes/SearchResultsOverlay";
import TranscriptList from "./includes/TranscriptList";
import { clsx } from "clsx";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useActiveCourse from "../../../hooks/useActiveCourse";
import { useGlobalTranscriptRocketRecordState } from "./includes/state";
import type useMedia from "../../../hooks/useMedia";
import useObserver from "../../../hooks/useObserver";
import { useSharedSelector } from "../../../store";
import useSubtitles from "./includes/useSubtitles";
import useTranslation from "../../../hooks/useTranslation";
import { Pause as PauseIcon, Play as PlayIcon } from "iconoir-react/solid";
import { Search as SearchIcon, Xmark as XmarkIcon } from "iconoir-react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";

dayjs.extend(duration);

/** Retrieved from the DB */
const AudioTypeId = {
  IAC: 1,
  REVIEW: 2,
  ROLE_PLAY: 3,
  CONVERSATION: 4,
};

export type Props = {
  lessonComponent: LessonComponent<"audio", AudioComponent>;
};

export default function InteractiveAudioComponent(props: Props) {
  const { component } = props.lessonComponent;
  const lesson = useContext(LessonContext);
  const activeProduct = useSharedSelector((store) => store.preferences.activeProduct);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { contentVisibility } = useObserver(containerRef);
  const context = useContext(AudioContext);
  const player = context.players[component.id];
  const audioTitle = useAudioTitle(props.lessonComponent);

  const isTravelogue = activeProduct?.level_id === 4;

  useEffect(() => {
    if (activeProduct) {
      context.setProductId(activeProduct.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct]);

  useEffect(() => {
    if (lesson) {
      context.registerComponent({
        component: props.lessonComponent.component,
        lessonId: lesson.id,
        title: `${lesson.number} ${audioTitle}`,
        lessonTitle: `${lesson.number} ${lesson.title}`,
        isVisible: contentVisibility,
      });
      return () => {
        context.unregisterComponent(props.lessonComponent.component.id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentVisibility, lesson, props.lessonComponent.component, audioTitle]);

  const hasTranscript = !!component.audio_transcript;

  return (
    <>
      {isTravelogue && props.lessonComponent.title_show && <h3 className="pb-2">{props.lessonComponent.title_text}</h3>}
      <div
        className={clsx(
          "relative flex flex-col gap-4 rounded-2xl border border-missilestroke p-4 lg:flex-row print:hidden",
          hasTranscript && "xl:grid xl:flex-none xl:grid-cols-2",
        )}
        ref={containerRef}
      >
        <PlayerControls player={player} component={component} lessonImage={lesson.iac_image_url || lesson.image_url} />
        {hasTranscript ? <TranscriptListContainer player={player} component={component} /> : null}
      </div>
    </>
  );
}

const useAudioTitle = (lessonComponent: LessonComponent<"audio", AudioComponent>) => {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  return (() => {
    const { title_text, title_show, component } = lessonComponent;
    if (title_text && title_show) {
      return title_text;
    }
    const isReviewOrConversations = lesson.title === "All Conversations" || lesson.title === "All Reviews";
    // TODO: check this logic??
    if (lesson.isTravelogue || isReviewOrConversations) {
      return title_text || "";
    }

    const isIAC = component.audio_type_id === AudioTypeId.IAC;

    if (isIAC) {
      return lesson.title;
    }

    switch (component.audio_type_id) {
      case AudioTypeId.ROLE_PLAY: {
        const { character } = component;
        if (typeof character === "object" && character !== null && typeof character.name === "string") {
          return `Role Play - ${character.name}`;
        }
        return "Role Play";
      }
      case AudioTypeId.CONVERSATION:
        return t("the-conversation");
      case AudioTypeId.REVIEW:
        return t("review");
    }

    return "";
  })();
};

function HeaderSearch() {
  const { open, query, setQuery, setOpen } = useSearchStore(
    (store) => ({
      open: store.open,
      query: store.query,
      setQuery: store.setQuery,
      setOpen: store.setOpen,
    }),
    shallow,
  );

  if (!open) {
    return null;
  }

  return (
    <div className="relative flex flex-row items-center gap-x-2 rounded-full bg-missilesurfacelight px-3 py-1 text-missilegray2">
      <SearchIcon strokeWidth={2} />
      <input
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="h-12 flex-1 bg-transparent outline-none"
        autoFocus
      />
      <button
        aria-label="Clear"
        onClick={() => {
          setQuery("");
          setOpen(false);
        }}
        title="Clear Search"
      >
        <XmarkIcon strokeWidth={2} />
      </button>
    </div>
  );
}

const useSearchStore = create<{
  open: boolean;
  query: string;
  setQuery: (query: string) => void;
  setOpen: (open: boolean) => void;
}>((set) => ({
  open: false,
  query: "",
  setQuery: (query: string) => set({ query }),
  setOpen: (open: boolean) => set({ open }),
}));

/** Parses the subtitles and renders the list */
function TranscriptListContainer({
  player,
  component,
}: {
  player: ReturnType<typeof useMedia> | undefined;
  component: AudioComponent;
}) {
  const isHindi = useActiveCourse()?.slug === "hindi";
  const subtitles = useSubtitles(player?.trackRef);
  const { open, query, setQuery, setOpen } = useSearchStore(
    (store) => ({
      open: store.open,
      query: store.query,
      setQuery: store.setQuery,
      setOpen: store.setOpen,
    }),
    shallow,
  );

  const height = open ? 243 : 315;
  return (
    <div className="flex w-full flex-col space-y-4">
      <HeaderSearch />
      <div className={clsx("relative overflow-hidden", isHindi && "font-serif")} style={{ height: height }}>
        {player ? (
          <>
            {component.audio_transcript && !!subtitles.length && query && (
              <SearchResultsOverlay
                subtitles={subtitles}
                query={query}
                height={height}
                player={player}
                clearSearch={() => {
                  setQuery("");
                  setOpen(false);
                }}
              />
            )}
            <TranscriptList subtitles={subtitles} height={height} component={component} player={player} />
          </>
        ) : null}
      </div>
    </div>
  );
}

function PlayerControls({
  player,
  component,
  lessonImage,
}: {
  player?: ReturnType<typeof useMedia>;
  component: AudioComponent;
  lessonImage?: string | null;
}) {
  const context = useContext(AudioContext);
  const isPlaying = player?.isPlaying;
  const isRecording = useGlobalTranscriptRocketRecordState((s) => s.isRecording());
  const [playbackSpeedVisible, setPlaybackSpeedVisible] = useState(false);
  const { searchSetOpen, isSearchOpen } = useSearchStore(
    (s) => ({ searchSetOpen: s.setOpen, isSearchOpen: s.open }),
    shallow,
  );

  return (
    <div className="relative min-h-60 w-full min-w-72 rounded-2xl sm:min-w-80">
      <img
        className="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-2xl object-cover"
        src={lessonImage || "https://cdn-rocketlanguages.s3.us-east-2.amazonaws.com/tmp/columns.jpg"}
        alt="lesson poster"
      />
      <div className="absolute mx-auto flex h-full w-full flex-col justify-end gap-2 rounded-2xl bg-gradient-to-t from-black/70 to-transparent p-5 text-white">
        <div className="mx-auto flex w-full max-w-96 items-center justify-evenly gap-1">
          <div className="relative">
            <button
              type="button"
              aria-label={`${player?.state?.trackSpeed} times track speed`}
              onClick={() => setPlaybackSpeedVisible((s) => !s)}
              title="Adjust speed"
              className="flex size-10 min-h-10 min-w-10 items-center justify-center rounded-full bg-white font-bold text-black"
            >
              {player?.state?.trackSpeed}x
            </button>
            {playbackSpeedVisible && (
              <PlaybackSpeedDialog
                value={player?.state.trackSpeed || 0}
                onClose={(value) => {
                  if (value) {
                    player?.speed(value);
                  }
                  setPlaybackSpeedVisible(false);
                }}
              />
            )}
          </div>
          <button
            type="button"
            aria-label="Jump back"
            onClick={() => {
              player?.skip("back");
            }}
            disabled={isRecording}
            className="flex size-10 min-h-10 min-w-10 items-center justify-center rounded-full bg-white text-black"
          >
            <Back10SecondsIcon />
          </button>
          <PlayPauseButton
            isPlaying={!!isPlaying}
            disabled={isRecording}
            onPlay={() => context.play(component.id)}
            onPause={player?.pause}
          />
          <button
            type="button"
            aria-label="Jump forward"
            onClick={() => {
              player?.skip("ahead");
            }}
            className="flex size-10 min-h-10 min-w-10 items-center justify-center rounded-full bg-white text-black"
          >
            <Forward10SecondsIcon />
          </button>
          <button
            type="button"
            aria-label="Search"
            onClick={() => searchSetOpen(!isSearchOpen)}
            className="flex size-10 min-h-10 min-w-10 items-center justify-center rounded-full bg-white text-black"
          >
            <SearchIcon />
          </button>
        </div>
        {player && (
          <AudioSlider
            player={player}
            disabled={isRecording}
            syncProgress={!context.activeTrack || context.activeTrack?.isVisible}
          />
        )}
      </div>
    </div>
  );
}

function PlayPauseButton({
  isPlaying,
  disabled,
  onPlay,
  onPause,
}: {
  isPlaying: boolean;
  onPause?: () => void;
  onPlay: () => void;
  disabled: boolean;
}) {
  if (isPlaying) {
    return (
      <button
        type="button"
        onClick={onPause}
        title="Pause"
        className="flex size-16 min-h-16 min-w-16 items-center justify-center rounded-full bg-missileaccent text-white"
      >
        <PauseIcon className="text-3xl" />
      </button>
    );
  }
  return (
    <button
      type="button"
      onClick={onPlay}
      title="Play"
      disabled={disabled}
      className="flex size-16 min-h-16 min-w-16 items-center justify-center rounded-full bg-missileaccent text-white"
    >
      <PlayIcon className="text-3xl" />
    </button>
  );
}

function Back10SecondsIcon() {
  return (
    <svg strokeWidth={1.5} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Back 10 seconds</title>
      <path d="M5.25 6.75L3 4.5L5.25 2.25" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.9977 13.4385C13.1085 13.2484 14.1076 12.6486 14.7976 11.7576C15.4876 10.8666 15.8183 9.74923 15.7244 8.62622C15.6304 7.50321 15.1187 6.45629 14.2902 5.69231C13.4618 4.92833 12.3769 4.50287 11.25 4.5H3"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 10.5V15" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.75 11.625V13.875C6.75 14.1734 6.86853 14.4595 7.0795 14.6705C7.29048 14.8815 7.57663 15 7.875 15C8.17337 15 8.45952 14.8815 8.6705 14.6705C8.88147 14.4595 9 14.1734 9 13.875V11.625C9 11.3266 8.88147 11.0405 8.6705 10.8295C8.45952 10.6185 8.17337 10.5 7.875 10.5C7.57663 10.5 7.29048 10.6185 7.0795 10.8295C6.86853 11.0405 6.75 11.3266 6.75 11.625Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Forward10SecondsIcon() {
  return (
    <svg strokeWidth={1.5} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Forward 10 seconds</title>
      <path d="M12.75 6.75L15 4.5L12.75 2.25" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6 13.4385C4.95099 13.2616 3.99863 12.7187 3.31207 11.9061C2.62551 11.0935 2.24921 10.0638 2.25 9C2.25 7.80653 2.72411 6.66193 3.56802 5.81802C4.41193 4.97411 5.55653 4.5 6.75 4.5H15"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 10.5V15" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.25 11.625V13.875C11.25 14.1734 11.3685 14.4595 11.5795 14.6705C11.7905 14.8815 12.0766 15 12.375 15C12.6734 15 12.9595 14.8815 13.1705 14.6705C13.3815 14.4595 13.5 14.1734 13.5 13.875V11.625C13.5 11.3266 13.3815 11.0405 13.1705 10.8295C12.9595 10.6185 12.6734 10.5 12.375 10.5C12.0766 10.5 11.7905 10.6185 11.5795 10.8295C11.3685 11.0405 11.25 11.3266 11.25 11.625Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
