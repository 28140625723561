import type { Phrase } from "@rocketlanguages/types";
import { WritingSystemIds } from "../../../../utils/constants";
import { purgeString } from "../../../../utils/stringUtils";

const characterSplits = {
  // primary split character for the specific language - removes the split character
  default: " ",
  [WritingSystemIds.hiragana_katakana]: " ",
  [WritingSystemIds.kanji]: "",
  [WritingSystemIds.hanzi]: "",
};

/**
 * Take a split character string specific for a language and return a scrambled or shuffled array
 */
export default function getCharacterButtons({
  phrase,
  phraseStringIndex,
}: {
  /** the current phrase for the current rateable test phrase */
  phrase: Phrase;
  /**
   * The index of the specific string to take from a phrases phrase string list,
   *
   * eg: in Japanese kana is phrase.strings[0] vs kanji as phrase.strings[1]
   * */
  phraseStringIndex: number;
}) {
  const phraseString = phrase.strings[phraseStringIndex];
  if (!phraseString) {
    return [];
  }
  const splitCharacter = characterSplits[phraseString.writing_system_id] ?? characterSplits.default;

  // const punctuationRegex = /(»|«|…|‘|’|"|'|“|”|\||\.|／|～|〜|~|¿|,|،|！|、|!|。|؟|？|，|¡|\?|\*|!|-)/;
  // eslint-disable-next-line no-irregular-whitespace
  const punctuationRegex = new RegExp(`[${splitCharacter}»«…‘’'“”/।|.／～〜~¿,،！、!。؟？，¡?*!　「」]`, "gi");

  const phraseStringText = purgeString(phraseString.text, { html: true, markdown: true });
  // Remove html / markdown

  const phraseArray = phraseStringText.split(splitCharacter).flatMap((el, i) => {
    if (
      el === "[" &&
      phraseString.text.split(splitCharacter)[i + 1] === "." &&
      phraseString.text.split(splitCharacter)[i + 2] === "." &&
      phraseString.text.split(splitCharacter)[i + 3] === "." &&
      phraseString.text.split(splitCharacter)[i + 4] === "]"
    ) {
      return "[...]";
    }
    if (el === " [...] " || el === "[...]") {
      return el;
    }
    return el.split(punctuationRegex).filter((el) => {
      if (el === " [" || el === "[" || el === "] " || el === "]" || el === "." || el === " ") {
        return false;
      }
      return !!el;
    });
  });

  return phraseArray;
}
