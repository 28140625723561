import { FaMicrophone, FaSquare } from "react-icons/fa";
import { type MutableRefObject, useContext } from "react";
import AudioButton from "../Phrasebox/includes/AudioButton";
import AudioContext from "../../ui/Audio/AudioContext";
import { clsx } from "clsx";
import { shallowEqual } from "react-redux";
import usePhraseBoxHotkey from "../../hooks/usePhraseBoxHotkey";
import useGetUserRating from "../Phrasebox/includes/utils/useGetUserRating";
import { PhraseboxContext } from "./context";
import { useComposedRefs } from "../../hooks/useComposedRefs";

export function ButtonGrid(props: { children: React.ReactNode }) {
  return (
    <div className={clsx("print:hidden", "grid grid-cols-phrasebox-2 grid-rows-phrasebox-2 gap-2")}>
      {props.children}
    </div>
  );
}

export function RocketRecordRatingButton(props: {
  /** if true, renders an empty <div> if no rating is available */
  renderEmptyElementIfNoRating?: boolean;
}) {
  const context = useContext(PhraseboxContext);
  if (!context) {
    throw new Error("RocketRecordContext not found. Please wrap in PhraseboxContext");
  }
  const results = context.rocketRecord.useRocketRecordState((state) => state.result);
  const isInactiveOrError = context.rocketRecord.useRocketRecordState(
    (state) => state.flag.status === "INACTIVE" || state.flag.status === "ERROR",
  );

  if (!results || !isInactiveOrError) {
    if (props.renderEmptyElementIfNoRating) {
      return <div className="h-6 w-10" />;
    }
    return null;
  }

  return (
    <div
      className={clsx("flex h-6 w-10 items-center justify-center rounded-lg", {
        "bg-missilegreen text-white": results.ratingLevel === 3,
        "bg-[#FBDB33] text-missilegray": results.ratingLevel === 2,
        "bg-missileaccent text-white": results.ratingLevel !== 2 && results.ratingLevel !== 3,
      })}
    >
      <div className="text-center text-xs font-semibold">{results.percentageDisplay}%</div>
    </div>
  );
}

/**
 * Use this component over `RocketRecordRatingButton` when you need to display the rating sourced from the store
 */
export function StoreRatingButton(props: {
  /** Manually pass in lesson id for phrases accessed outside of the lesson, eg: in Search */
  lessonId?: number;
}) {
  const results = useGetUserRating({ lessonId: props.lessonId, initializeWithRating: true });

  const ratingLevel = results?.ratingLevel || 0;
  const ratingPercentage = results?.percentage || 0;

  return (
    <div
      className={clsx(
        "pointer-events-none flex h-5 w-10 min-w-10 items-center justify-center rounded-lg text-xs font-semibold",
        {
          "bg-missilesurfacedark text-missilegray2 dark:text-white": !ratingLevel,
          "bg-missileaccent text-white": ratingLevel === 1,
          "bg-orange-500 text-white": ratingLevel === 2,
          "bg-missilegreen text-white": ratingLevel === 3,
        },
        !results && "invisible",
      )}
    >
      {ratingPercentage}%
    </div>
  );
}

export function PlayButton(props: {
  audioRef?: MutableRefObject<HTMLAudioElement | null>;
  disabled?: boolean;
  onPlayFinish?: () => void;
  playOnMount?: boolean;
  className?: string;
  useDarkWaveform?: boolean;
}) {
  const { audioRef, disabled } = props;
  const context = useContext(PhraseboxContext);
  if (!context) {
    throw new Error("PhraseboxContext not found. Please wrap in RocketRecordProvider");
  }
  const isRecognizing = context.rocketRecord.useRocketRecordState((state) => state.flag.status === "ACTIVE");
  const phrase = context.phrase;

  const customAudioRef = useComposedRefs(audioRef, context.phraseAudioRef);

  return (
    <AudioButton
      audioRef={customAudioRef}
      title="Play Tutor Audio"
      phraseId={phrase.id}
      disabled={isRecognizing || disabled}
      source={phrase.audio_url || undefined}
      className={props.className}
      onPlayFinish={props.onPlayFinish}
      playOnMount={props.playOnMount}
      useDarkWaveform={props.useDarkWaveform}
    />
  );
}

export function RecordButton(props: { disabled?: boolean; className?: string }) {
  const audioContext = useContext(AudioContext);
  const context = useContext(PhraseboxContext);
  if (!context) {
    throw new Error("PhraseboxContext not found. Please wrap in RocketRecordProvider");
  }
  const { recordButtonDisabled, isRecognizing } = context.rocketRecord.useRocketRecordState(({ flag }) => {
    return {
      recordButtonDisabled: props.disabled || ["STARTING", "FINISHING"].includes(flag.status),
      recordButtonActive: flag.status === "STARTING" || flag.status === "ACTIVE",
      isRecognizing: flag.status === "ACTIVE",
    };
  }, shallowEqual);

  const { methods, phrase } = context.rocketRecord;

  return (
    <button
      type="button"
      title="Record Voice"
      disabled={recordButtonDisabled}
      // active={recordButtonActive}
      className={clsx("flex size-10 min-h-10 min-w-10 items-center justify-center rounded-xl", props.className)}
      onClick={() => {
        audioContext.activePlayer?.pause();
        isRecognizing ? methods.abort() : methods.start();
      }}
      id={`phrase-record-${phrase.id}`}
    >
      {isRecognizing ? <FaSquare /> : <FaMicrophone />}
    </button>
  );
}

export function RecordPlaybackButton(props: { className?: string; useDarkWaveform?: boolean }) {
  const context = useContext(PhraseboxContext);
  if (!context) {
    throw new Error("PhraseboxContext not found. Please wrap in RocketRecordProvider");
  }

  const { shouldDisplayRecordingPlayback, blobUrl, isRecognizing } = context.rocketRecord.useRocketRecordState(
    (state) => {
      return {
        shouldDisplayRecordingPlayback:
          context.rocketRecord.computed.canUseAudioRecorder &&
          state.blobUrl &&
          (!!state.result || state.flag.status === "INACTIVE"),
        blobUrl: state.blobUrl,
        isRecognizing: state.flag.status === "ACTIVE",
      };
    },
  );

  return (
    <AudioButton
      audioRef={context.replayAudioRef}
      useDarkWaveform={props.useDarkWaveform}
      className={clsx(props.className, !shouldDisplayRecordingPlayback && "invisible")}
      disabled={isRecognizing}
      title="Play Recording"
      source={blobUrl}
    />
  );
}

/**
 * Registers hotkeys "P" to play, and "R" to record, while the phrasebox is focused
 */
export function Hotkeys({ playDisabled }: { playDisabled?: boolean }) {
  const context = useContext(PhraseboxContext);

  if (!context) {
    throw new Error("PhraseboxContext not found. Please wrap in RocketRecordProvider");
  }

  usePhraseBoxHotkey({
    enabled: true,
    callbacks: {
      play() {
        const audio = context.phraseAudioRef.current;
        if (!audio || playDisabled) {
          return;
        }
        audio.currentTime = 0;
        audio.play()?.catch((e) => {
          console.warn(e);
        });
      },
      record() {
        const isRecognizing = context.rocketRecord.useRocketRecordState.getState().flag.status === "ACTIVE";
        if (isRecognizing) {
          context.rocketRecord.methods.abort();
        } else {
          context.rocketRecord.methods.start();
        }
      },
      replay() {
        const audio = context.replayAudioRef.current;
        if (!audio) {
          return;
        }

        const recordState = context.rocketRecord.useRocketRecordState.getState();
        if (!recordState.blobUrl) {
          return;
        }

        audio.currentTime = 0;
        audio.play()?.catch((e) => {
          console.warn(e);
        });
      },
    },
  });

  return null;
}
