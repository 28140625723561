import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onEscapeKeyDown?: () => void;
  onPointerDownOutside?: () => void;
  className?: string;
  containerClassName?: string;
};

export function ModalFacelift({
  children,
  isOpen,
  onEscapeKeyDown,
  onPointerDownOutside,
  className,
  containerClassName,
}: ModalProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onKeyPress = (ev: KeyboardEvent) => {
      if (ev.key === "Escape" && isOpen) {
        onEscapeKeyDown?.();
      }
    };

    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isOpen, onEscapeKeyDown]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={modalRef} tabIndex={-1} className="fixed z-50">
      <div
        ref={overlayRef}
        className={clsx(
          "fixed inset-0 flex items-center justify-center overflow-auto bg-black/75 transition-all",
          isOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0",
        )}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onEscapeKeyDown?.();
          }
        }}
        onClick={(e) => {
          if (overlayRef.current === e.target) {
            onPointerDownOutside?.();
          }
        }}
      >
        <div
          className={twMerge(
            "z-[999] max-h-screen min-w-[100%] p-4 sm:min-w-[50%] sm:max-w-screen-sm lg:min-w-[40%] lg:max-w-screen-md xl:min-w-[30%]",
            containerClassName,
          )}
          aria-modal
          aria-haspopup
        >
          <div className={clsx("relative overflow-hidden shadow-xl", className)}>{children}</div>
        </div>
      </div>
    </div>,
    document.querySelector("body") as Element,
  );
}
