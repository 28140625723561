import Courses from "@rocketlanguages/shared/res/courses";
import type { BasicProduct } from "@rocketlanguages/types";
import { useParams } from "react-router-dom";
import { useStoreSelector } from "../store";

/** Resolves a product from :courseId and :levelId route params */
export default function useProductFromCourseParams() {
  const levelId = Number(useParams().levelId);
  const courseId = Number(useParams().courseId);
  const course = Courses.find((course) => course.id === courseId);
  const userProducts = useStoreSelector((store) => store.user.products);

  if (course) {
    const getProduct = (products: BasicProduct[]) => {
      return products.find((product) =>
        course.products.find((courseProduct) => courseProduct.id === product.id && courseProduct.level_id === levelId),
      );
    };
    for (const type of ["paid", "trial"] as const) {
      const product = getProduct(userProducts[type]);
      if (product) {
        return product;
      }
    }
  }

  return null;
}
