const isChrome = typeof window !== "undefined" && /chrome/gi.test(window.navigator.userAgent);

// In Chrome, the client rects will include the entire bounds of all nodes that
// begin (have a start tag) within the selection, even if the selection does
// not overlap the entire node. To resolve this, we split the range at each
// start tag and join the client rects together.
// https://code.google.com/p/chromium/issues/detail?id=324437
/* eslint-disable consistent-return */
function getRangeClientRectsChrome(range: Range) {
  const tempRange = range.cloneRange();
  const clientRects = [];

  for (
    let ancestor = range.endContainer;
    ancestor !== null;
    // @ts-ignore
    ancestor = ancestor.parentNode
  ) {
    // If we've climbed up to the common ancestor, we can now use the
    // original start point and stop climbing the tree.

    const atCommonAncestor = Boolean(ancestor === range.commonAncestorContainer);
    if (atCommonAncestor) {
      tempRange.setStart(range.startContainer, range.startOffset);
    } else {
      tempRange.setStart(tempRange.endContainer, 0);
    }

    const rects = Array.from(tempRange.getClientRects());
    clientRects.push(rects);

    if (atCommonAncestor) {
      clientRects.reverse();
      return ([] as DOMRect[]).concat(...clientRects);
    }

    tempRange.setEndBefore(ancestor);
  }
}

/**
 * Like range.getClientRects() but normalizes for browser bugs.
 */
const getRangeClientRects = isChrome ? getRangeClientRectsChrome : (range: Range) => Array.from(range.getClientRects());

/**
 * Like range.getBoundingClientRect() but normalizes for browser bugs.
 */
function getRangeBoundingClientRect(range: Range) {
  // "Return a DOMRect object describing the smallest rectangle that includes
  // the first rectangle in list and all of the remaining rectangles of which
  // the height or width is not zero."
  // http://www.w3.org/TR/cssom-view/#dom-range-getboundingclientrect
  const rects = getRangeClientRects(range) as DOMRect[];
  let top = 0;
  let right = 0;
  let bottom = 0;
  let left = 0;

  if (rects.length) {
    // If the first rectangle has 0 width, we use the second, this is needed
    // because Chrome renders a 0 width rectangle when the selection contains
    // a line break.
    let target;
    if (rects.length > 1 && rects[0]?.width === 0) {
      target = rects[1];
    } else {
      target = rects[0];
    }
    if (!target) {
      return {
        top,
        right,
        bottom,
        left,
        width: right - left,
        height: bottom - top,
      };
    }
    top = target.top;
    bottom = target.bottom;
    left = target.left;
    right = target.right;

    for (const rect of rects) {
      if (rect.height !== 0 && rect.width !== 0) {
        top = Math.min(top, rect.top);
        right = Math.max(right, rect.right);
        bottom = Math.max(bottom, rect.bottom);
        left = Math.min(left, rect.left);
      }
    }
  }

  return {
    top,
    right,
    bottom,
    left,
    width: right - left,
    height: bottom - top,
  };
}

/**
 * Return the bounding ClientRect for the visible DOM selection, if any.
 * In cases where there are no selected ranges or the bounding rect is
 * temporarily invalid, return null.
 */
function getVisibleSelectionRect() {
  const selection = window.getSelection();
  if (!selection || !selection.rangeCount) {
    return null;
  }

  const range = selection.getRangeAt(0);
  const boundingRect = getRangeBoundingClientRect(range);
  const { top, right, bottom, left } = boundingRect;

  // When a re-render leads to a node being removed, the DOM selection will
  // temporarily be placed on an ancestor node, which leads to an invalid
  // bounding rect. Discard this state.
  if (top === 0 && right === 0 && bottom === 0 && left === 0) {
    return null;
  }

  return boundingRect;
}

export default getVisibleSelectionRect;
