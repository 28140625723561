import { Button, RoundedButton } from "@rocketlanguages/ui";
import { useState } from "react";
import API from "@rocketlanguages/shared/res/Api";
import { FiCheckCircle } from "react-icons/fi";
import type { SetStateFunction } from "@rocketlanguages/types";
import { getErrorMessage } from "@rocketlanguages/shared/utils";
import { toast } from "react-toastify";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { SimpleModal } from "@rocketlanguages/ui/ModalFacelift/ModalFacelift";
import { Send as SendIcon } from "iconoir-react";

interface FeedbackModalProps {
  isOpen: boolean;
  setIsOpen: SetStateFunction<boolean>;
  lessonId?: number;
}

export function FeedbackModal({ isOpen, setIsOpen, lessonId }: FeedbackModalProps) {
  const t = useTranslation();

  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    await API.post("v2/content-feedback", { lesson_id: lessonId, message: feedback, context: window.location.href })
      .then(() => {
        setSubmitted(true);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onEscapeKeyDown={() => setIsOpen(false)}
      onPointerDownOutside={() => setIsOpen(false)}
      className="space-y-4"
    >
      <h4 className="text-2xl font-semibold">{t("feedback")}</h4>
      {!submitted ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className="flex flex-col gap-y-4"
          >
            <div>
              <label className="flex flex-col gap-y-4">
                <div className="flex gap-1 text-sm font-bold text-missilegray dark:text-gray-300">
                  <span>
                    {lessonId ? t("send-feedback-placeholder-text") : t("send-feedback-placeholder-text-non-lesson")}
                  </span>
                </div>
                <textarea
                  required
                  className="w-full resize-none rounded-2xl border border-missilestroke bg-transparent px-4 py-3 leading-5 text-missilegray dark:text-gray-300"
                  rows={10}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </label>
            </div>

            <div className="mt-4 flex flex-row items-center justify-end gap-8">
              <button
                className="text-sm font-bold leading-5 text-missiledark hover:underline dark:text-white"
                onClick={() => setIsOpen(false)}
                type="button"
              >
                {t("cancel")}
              </button>
              <RoundedButton
                className="gap-2 bg-missileaccent px-6 font-semibold text-white hover:bg-missileaccent/90"
                loading={loading}
                type="submit"
              >
                <SendIcon />
                {t("send-feedback")}
              </RoundedButton>
            </div>
          </form>
        </>
      ) : (
        <div className="my-4 flex flex-col items-center justify-center text-lg text-brand sm:my-6">
          <FiCheckCircle className="mb-2 text-rocketgreen-light" size={32} />
          <p className="">{t("thank-you-for-your-feedback")}</p>
          <div className="my-4 sm:my-6">
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("close")}
            </Button>
          </div>
        </div>
      )}
    </SimpleModal>
  );
}
