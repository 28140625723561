import { type MutableRefObject, useContext, useEffect, useRef } from "react";
import AudioContext from "../../../ui/Audio/AudioContext";
import audioWaveformSmall from "./res/audio-waveform-small";
import audioWaveformSmallDark from "./res/audio-waveform-small-dark";
import { requestAddPoints } from "../../../store/lesson/actions";
import useAudioMinified, { type MinifiedAudioState } from "../../../hooks/useAudioMinified";
import { useSharedDispatch } from "../../../store";
import { usePreferenceValue } from "../../../hooks/usePreference";
import { isSafari } from "../../../utils/browser";
import { useComposedRefs } from "../../../hooks/useComposedRefs";
import { Play as PlayIcon } from "iconoir-react/solid";
import { CircularProgress } from "@rocketlanguages/ui";
import { clsx } from "clsx";

type AudioButtonProps = {
  audioRef?: MutableRefObject<HTMLAudioElement | null>;
  title: string;
  disabled?: boolean;
  /** Audio URL */
  source: string | undefined;
  onPlayStateChange?: (status: "init" | "loading" | "playing" | "paused" | "stopped") => void;
  /** Occurs when playback finishes */
  onPlayFinish?: () => void;
  /** Initializes playback on render */
  playOnMount?: boolean;
  /** Uses this phrase ID to request to add points */
  phraseId?: number;
  className?: string;
  useDarkWaveform?: boolean;
};

/**
 * Square button audio player
 *
 * Requires: <LessonContext.Provider value={...} /> as a parent in the tree to get the active lesson id
 */
function AudioButton(props: AudioButtonProps) {
  const dispatch = useSharedDispatch();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const composedAudioRef = useComposedRefs(audioRef, props.audioRef);
  const audioContext = useContext(AudioContext);
  // On Safari 16.1, there seems to be an issue when too many audio elements are on a page
  // would cause no audio to play and indefinitely be in a loading state.
  // This even persists on other websites after closing the tab.
  const preload = isSafari ? "none" : "auto";
  const playbackRate = usePreferenceValue("rocket_record_speed", 1);
  const { source, phraseId } = props;

  const audio = useAudioMinified(audioRef, props.playOnMount, playbackRate);

  const { status, playAudio, pauseAudio, stopAudio } = audio;

  useEffect(
    () => {
      if (status === "init") {
        return;
      }

      props.onPlayStateChange?.(status);

      if (status === "stopped") {
        // Play It only cares about when playing has finished
        props.onPlayFinish?.();

        dispatch(
          requestAddPoints({
            rewardType: "phrasePlay",
            data: {},
          }),
        );
      }
    },
    // eslint-disable-next-line
    [phraseId, status],
  );

  const handleClick = () => {
    if (status === "playing") {
      pauseAudio();
      return;
    }

    if (status === "paused") {
      playAudio();
      return;
    }

    if (status === "loading") {
      stopAudio();
      return;
    }

    audioContext.activePlayer?.pause();
    playAudio();
  };

  return (
    <div>
      <audio ref={composedAudioRef} src={source} preload={preload} />
      <button
        type="button"
        title={props.title}
        disabled={props.disabled}
        aria-busy={status === "loading"}
        aria-label={props.title}
        className={clsx("flex size-10 min-h-10 min-w-10 items-center justify-center rounded-xl", props.className)}
        onClick={handleClick}
      >
        <AudioButtonIcon status={status} dark={props.useDarkWaveform} />
      </button>
    </div>
  );
}

const getColorSchemeFromLocalstorage = (): "light" | "dark" | "device" | undefined => {
  const colorScheme =
    typeof window !== "undefined" &&
    "localStorage" in window &&
    window.localStorage.getItem("theme")?.replace(/"/g, "");
  return colorScheme as "light" | "dark" | "device" | undefined;
};

const isMediaDarkMode = () =>
  typeof window !== "undefined" && "matchMedia" in window && window.matchMedia("(prefers-color-scheme: dark)")?.matches;

const AudioButtonIcon = (props: { status: MinifiedAudioState; dark?: boolean }) => {
  if (props.status === "playing") {
    const imageSource = (() => {
      if (!props.dark) {
        return audioWaveformSmall;
      }
      const localStorageColorScheme = getColorSchemeFromLocalstorage();

      const isDarkMode =
        !localStorageColorScheme || localStorageColorScheme === "device"
          ? isMediaDarkMode()
          : localStorageColorScheme === "dark";

      return isDarkMode ? audioWaveformSmall : audioWaveformSmallDark;
    })();

    return (
      <div className="flex h-full w-full items-center justify-center">
        <img src={imageSource} alt="soundwave" />
      </div>
    );
  }

  if (props.status === "loading") {
    return <CircularProgress className="!h-[24px] !w-[24px] text-missilebrand dark:text-white" />;
  }

  if (props.status === "paused") {
    return <PlayIcon />;
  }

  return <PlayIcon />;
};

export default AudioButton;
