import { CourseFlagCircle } from "@rocketlanguages/ui";
import { clsx } from "clsx";
import { NavArrowDown as NavArrowDownIcon, NavArrowRight as NavArrowRightIcon } from "iconoir-react";

type CourseSelectionButtonProps = {
  selected: boolean;
  courseSlug: string;
  title: string;
  onClick?: () => void;
  dialect?: string | null;
};

export function CourseSelectionButton({ selected, courseSlug, title, dialect, ...rest }: CourseSelectionButtonProps) {
  return (
    <button
      {...rest}
      type="button"
      className={clsx(
        "flex h-16 w-full items-center justify-between rounded-full px-5 py-4 hover:bg-missilesurfacedark focus:outline-none focus:ring hover:dark:bg-neutral-700",
        selected && "bg-missilesurfacedark dark:bg-neutral-700",
      )}
      // for puppeteer
      id={`course-selection-${courseSlug}`}
      aria-label={title}
    >
      <div className="flex items-center">
        <div className="scale-75 md:mr-1" aria-hidden>
          <CourseFlagCircle courseSlug={courseSlug} courseName={title} />
        </div>
        <div className="text-left">
          <span className="font-semibold">{title}</span>
          {dialect && <small className="text-brand-secondary ml-1 text-sm">({dialect})</small>}
        </div>
      </div>
      <div className="md:hidden">{selected ? <NavArrowDownIcon /> : <NavArrowRightIcon />}</div>
    </button>
  );
}
