import type { CSSProperties } from "react";
import type { MarkdownToJSX } from "markdown-to-jsx";
import RocketMarkdown from "../../Lesson/MarkdownComponent/includes/RocketMarkdown";

interface PhraseStringTableProps {
  id: number;
  text: string;
  textProps: { className: string; style: CSSProperties };
  markdownOptions: MarkdownToJSX.Options | undefined;
}

/**
 * Markdown column table representation of a phrase string. Every "|" is split in to an even column.
 */
export function PhraseStringTable({ id, text, textProps, markdownOptions }: PhraseStringTableProps) {
  return (
    <>
      {text
        .split("|")
        .filter((_text) => !!_text)
        .map((t, i) => {
          return (
            <div key={`table.${id}.${i}`} className="flex flex-1">
              <RocketMarkdown className={textProps.className} style={textProps.style} options={markdownOptions}>
                {t.replace("&nbsp;", "").trim()}
              </RocketMarkdown>
            </div>
          );
        })}
    </>
  );
}
