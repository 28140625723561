export default function LogoIcon() {
  return (
    <svg
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1"
      viewBox="0 0 1262 1262"
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
    >
      <title>Rocket Languages Logo</title>
      <circle transform="matrix(1.9535 0 0 1.9535 -8018 -18652)" cx="4427.4" cy="9871" r="323" fill="#EF233C" />
      <path
        d="m789.13 848.74c10.568 66.878 8.631 138.06-2.809 211.68-111.23-46.577-196.76-121.7-252.28-211.68m-65.319-168.1c-30.191-159.44 18.148-335.68 161.23-477.32 222.8 220.57 215.89 525.01 40.451 719.27m-86.25 76.619c-33.346 23.717-70.269 44.34-110.57 61.21-29.767-191.5 4.809-366.51 156.36-492.26 41.092 34.089 73.591 71.809 98.536 112.49"
        fill="none"
        stroke="#fff"
        strokeWidth="38.416"
      />
    </svg>
  );
}
