import type { Course } from "@rocketlanguages/types";

// NOTE: Once adding a new course, you'll need to add an image
// with the same name as the course locale to the ~/res/images/legacy-course-icons/ directory

const Courses: Course[] = [
  {
    id: 13,
    name: "Spanish",
    locale: "es-CL",
    slug: "spanish",
    writing_system_ids: [5, 1],
    products: [
      {
        id: 1,
        name: "Rocket Spanish Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 13,
      },
      {
        id: 6,
        name: "Rocket Spanish Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 13,
      },
      {
        id: 9,
        name: "Rocket Spanish Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 13,
      },
      {
        id: 121,
        name: "Rocket Spanish Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 13,
      },
      {
        id: 153,
        name: "Rocket Spanish Level 1 (Free)", // (All Content)
        type_id: 2,
        level_id: 1,
        course_id: 13,
      },
      {
        id: 182,
        name: "Rocket Spanish Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 13,
      },
      {
        id: 183,
        name: "Rocket Spanish Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 13,
      },
      {
        id: 192,
        name: "Rocket Spanish Travelogue",
        type_id: 8,
        level_id: 4,
        course_id: 13,
      },
      {
        id: 238,
        name: "Rocket Spanish Travelogue (Free)",
        type_id: 2,
        level_id: 4,
        course_id: 13,
      },
      {
        id: 249,
        name: "Rocket Spanish Verb Trainer",
        type_id: 1,
        level_id: 5,
        course_id: 13,
      },
      {
        id: 193,
        name: "Rocket Spanish Level 1 Guest",
        type_id: 10,
        level_id: 1,
        course_id: 13,
      },
      {
        id: 194,
        name: "Rocket Spanish Level 2 Guest",
        type_id: 10,
        level_id: 2,
        course_id: 13,
      },
      {
        id: 195,
        name: "Rocket Spanish Level 3 Guest",
        type_id: 10,
        level_id: 3,
        course_id: 13,
      },
      {
        id: 224,
        name: "Ebook",
        type_id: 11,
        level_id: 7,
        course_id: 13,
      },
      {
        id: 262,
        name: "Rocket Spanish Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 13,
      },
      {
        id: 273,
        name: "Rocket Spanish Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 13,
      },
      {
        id: 279,
        name: "Rocket Spanish Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 13,
      },
      {
        id: 65,
        name: "Rocket Spanish Library Edition", // Rocket Spanish Library Edition
        type_id: 3,
        level_id: 1,
        course_id: 13,
      },
      {
        id: 325,
        name: "Rocket Spanish Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 13,
      },
      {
        id: 331,
        name: "Rocket Spanish Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 13,
      },
    ],
  },
  {
    id: 7,
    name: "French",
    locale: "fr-FR",
    slug: "french",
    writing_system_ids: [10, 1],
    products: [
      {
        id: 3,
        name: "Rocket French Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 7,
      },
      {
        id: 4,
        name: "Rocket French Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 7,
      },
      {
        id: 16,
        name: "Rocket French Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 7,
      },
      {
        id: 119,
        name: "Rocket French Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 7,
      },
      {
        id: 176,
        name: "Rocket French Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 7,
      },
      {
        id: 177,
        name: "Rocket French Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 7,
      },
      {
        id: 191,
        name: "Rocket French Travelogue",
        type_id: 8,
        level_id: 4,
        course_id: 7,
      },
      {
        id: 239,
        name: "Rocket French Travelogue (Free)",
        type_id: 2,
        level_id: 4,
        course_id: 7,
      },
      {
        id: 250,
        name: "Rocket French Verb Trainer",
        type_id: 1,
        level_id: 5,
        course_id: 7,
      },
      {
        id: 216,
        name: "Rocket French Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 7,
      },
      {
        id: 217,
        name: "Rocket French Level 2 Guest (Free)",
        type_id: 10,
        level_id: 2,
        course_id: 7,
      },
      {
        id: 218,
        name: "Rocket French Level 3 Guest (Free)",
        type_id: 10,
        level_id: 3,
        course_id: 7,
      },
      {
        id: 66,
        name: "Rocket French", // Language+Culture Only
        type_id: 5,
        level_id: 6, // NOTE: NULL on database
        course_id: 7,
      },
      {
        id: 225,
        name: "Ebook",
        type_id: 11,
        level_id: 7, // Note: NULL on database
        course_id: 7,
      },
      {
        id: 263,
        name: "Rocket French Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 7,
      },
      {
        id: 272,
        name: "Rocket French Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 7,
      },
      {
        id: 278,
        name: "Rocket French Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 7,
      },
      {
        id: 60,
        name: "Rocket French Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 7,
      },
      {
        id: 326,
        name: "Rocket French Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 7,
      },
      {
        id: 327,
        name: "Rocket French Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 7,
      },
    ],
  },
  {
    id: 12,
    name: "Italian",
    locale: "it-IT",
    slug: "italian",
    writing_system_ids: [14, 1],
    products: [
      {
        id: 37,
        name: "Rocket Italian Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 12,
      },
      {
        id: 40,
        name: "Rocket Italian Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 12,
      },
      {
        id: 88,
        name: "Rocket Italian Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 12,
      },
      {
        id: 122,
        name: "Rocket Italian Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 12,
      },
      {
        id: 180,
        name: "Rocket Italian Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 12,
      },
      {
        id: 181,
        name: "Rocket Italian Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 12,
      },
      {
        id: 247,
        name: "Rocket Italian Travelogue",
        type_id: 8,
        level_id: 4,
        course_id: 12,
      },
      {
        id: 248,
        name: "Rocket Italian Travelogue (Free)",
        type_id: 2,
        level_id: 4,
        course_id: 12,
      },
      {
        id: 251,
        name: "Rocket Italian Verb Trainer",
        type_id: 1,
        level_id: 5,
        course_id: 12,
      },
      {
        id: 209,
        name: "Rocket Italian Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 12,
      },
      {
        id: 210,
        name: "Rocket Italian Level 2 Guest (Free)",
        type_id: 10,
        level_id: 2,
        course_id: 12,
      },
      {
        id: 211,
        name: "Rocket Italian Level 3 Guest (Free)",
        type_id: 10,
        level_id: 3,
        course_id: 12,
      },
      {
        id: 265,
        name: "Rocket Italian Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 12,
      },
      {
        id: 270,
        name: "Rocket Italian Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 12,
      },
      {
        id: 276,
        name: "Rocket Italian Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 12,
      },
      {
        id: 63,
        name: "Rocket Italian Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 12,
      },
      {
        id: 329,
        name: "Rocket Italian Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 12,
      },
      {
        id: 330,
        name: "Rocket Italian Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 12,
      },
    ],
  },
  {
    id: 8,
    name: "German",
    locale: "de-DE",
    slug: "german",
    writing_system_ids: [11, 1],
    products: [
      {
        id: 28,
        name: "Rocket German Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 8,
      },
      {
        id: 31,
        name: "Rocket German Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 8,
      },
      {
        id: 34,
        name: "Rocket German Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 8,
      },
      {
        id: 120,
        name: "Rocket German Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 8,
      },
      {
        id: 178,
        name: "Rocket German Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 8,
      },
      {
        id: 179,
        name: "Rocket German Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 8,
      },
      {
        id: 213,
        name: "Rocket German Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 8,
      },
      {
        id: 214,
        name: "Rocket German Level 2 Guest (Free)",
        type_id: 10,
        level_id: 2,
        course_id: 8,
      },
      {
        id: 215,
        name: "Rocket German Level 3 Guest (Free)",
        type_id: 10,
        level_id: 3,
        course_id: 8,
      },
      {
        id: 264,
        name: "Rocket German Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 8,
      },
      {
        id: 271,
        name: "Rocket German Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 8,
      },
      {
        id: 277,
        name: "Rocket German Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 8,
      },
      {
        id: 61,
        name: "Rocket German Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 8,
      },
      {
        id: 313,
        name: "Rocket German Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 8,
      },
      {
        id: 323,
        name: "Rocket German Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 8,
      },
    ],
  },
  {
    id: 1,
    name: "Japanese",
    locale: "ja",
    slug: "japanese",
    writing_system_ids: [3, 2, 4, 1],
    products: [
      {
        id: 5,
        name: "Rocket Japanese Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 1,
      },
      {
        id: 22,
        name: "Rocket Japanese Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 1,
      },
      {
        id: 25,
        name: "Rocket Japanese Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 1,
      },
      {
        id: 117,
        name: "Rocket Japanese Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 1,
      },
      {
        id: 173,
        name: "Rocket Japanese Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 1,
      },
      {
        id: 174,
        name: "Rocket Japanese Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 1,
      },
      {
        id: 206,
        name: "Rocket Japanese Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 1,
      },
      {
        id: 207,
        name: "Rocket Japanese Level 2 Guest (Free)",
        type_id: 10,
        level_id: 2,
        course_id: 1,
      },
      {
        id: 208,
        name: "Rocket Japanese Level 3 Guest (Free)",
        type_id: 10,
        level_id: 3,
        course_id: 1,
      },
      {
        id: 266,
        name: "Rocket Japanese Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 1,
      },
      {
        id: 269,
        name: "Rocket Japanese Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 1,
      },
      {
        id: 275,
        name: "Rocket Japanese Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 1,
      },
      {
        id: 57,
        name: "Rocket Japanese Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 1,
      },
      {
        id: 317,
        name: "Rocket Japanese Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 1,
      },
      {
        id: 321,
        name: "Rocket Japanese Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 1,
      },
    ],
  },
  {
    id: 5,
    name: "Chinese",
    locale: "zh-CN",
    slug: "chinese",
    dialect: "Mandarin",
    writing_system_ids: [8, 9, 1],
    products: [
      {
        id: 43,
        name: "Rocket Chinese Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 5,
      },
      {
        id: 46,
        name: "Rocket Chinese Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 5,
      },
      {
        id: 244,
        name: "Rocket Chinese Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 5,
      },
      {
        id: 118,
        name: "Rocket Chinese Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 5,
      },
      {
        id: 175,
        name: "Rocket Chinese Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 5,
      },
      {
        id: 245,
        name: "Rocket Chinese Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 5,
      },
      {
        id: 220,
        name: "Rocket Chinese Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 5,
      },
      {
        id: 221,
        name: "Rocket Chinese Level 2 Guest (Free)",
        type_id: 10,
        level_id: 2,
        course_id: 5,
      },
      {
        id: 246,
        name: "Rocket Chinese Level 3 Guest (Free)",
        type_id: 10,
        level_id: 3,
        course_id: 5,
      },
      {
        id: 267,
        name: "Rocket Chinese Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 5,
      },
      {
        id: 268,
        name: "Rocket Chinese Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 5,
      },
      {
        id: 274,
        name: "Rocket Chinese Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 5,
      },
      {
        id: 59,
        name: "Rocket Chinese Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 5,
      },
      {
        id: 316,
        name: "Rocket Chinese Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 5,
      },
      {
        id: 322,
        name: "Rocket Chinese Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 5,
      },
    ],
  },
  {
    id: 26,
    name: "Russian",
    locale: "ru",
    slug: "russian",
    writing_system_ids: [20, 19, 1],
    products: [
      {
        id: 171,
        name: "Rocket Russian Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 26,
      },
      {
        id: 253,
        name: "Rocket Russian Level 2",
        type_id: 1,
        level_id: 2,
        course_id: 26,
      },
      {
        id: 254,
        name: "Rocket Russian Level 3",
        type_id: 1,
        level_id: 3,
        course_id: 26,
      },
      {
        id: 186,
        name: "Rocket Russian Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 26,
      },
      {
        id: 256,
        name: "Rocket Russian Level 2 (Free)",
        type_id: 2,
        level_id: 2,
        course_id: 26,
      },
      {
        id: 258,
        name: "Rocket Russian Level 3 (Free)",
        type_id: 2,
        level_id: 3,
        course_id: 26,
      },
      {
        id: 190,
        name: "Rocket Russian Travelogue",
        type_id: 8,
        level_id: 4,
        course_id: 26,
      },
      {
        id: 237,
        name: "Rocket Russian Travelogue (Free)",
        type_id: 2,
        level_id: 4,
        course_id: 26,
      },
      {
        id: 203,
        name: "Rocket Russian Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 26,
      },
      {
        id: 255,
        name: "Rocket Russian Level 2 Guest (Free)",
        type_id: 10,
        level_id: 2,
        course_id: 26,
      },
      {
        id: 257,
        name: "Rocket Russian Level 3 Guest (Free)",
        type_id: 10,
        level_id: 3,
        course_id: 26,
      },
      {
        id: 233,
        name: "Rocket Russian Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 26,
      },
      {
        id: 314,
        name: "Rocket Russian Level 2", // Plan
        type_id: 7,
        level_id: 2,
        course_id: 26,
      },
      {
        id: 315,
        name: "Rocket Russian Level 3", // Plan
        type_id: 7,
        level_id: 3,
        course_id: 26,
      },
      {
        id: 187,
        name: "Rocket Russian Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 26,
      },
    ],
  },
  {
    id: 16,
    name: "Korean",
    locale: "ko",
    slug: "korean",
    writing_system_ids: [15, 16, 1],
    products: [
      {
        id: 68,
        name: "Rocket Korean Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 16,
      },
      {
        id: 125,
        name: "Rocket Korean Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 16,
      },
      {
        id: 205,
        name: "Rocket Korean Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 16,
      },
      {
        id: 229,
        name: "Rocket Korean Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 16,
      },
      {
        id: 67,
        name: "Rocket Korean Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 16,
      },
      {
        id: 318,
        name: "Rocket Korean Play the Part",
        type_id: 13,
        level_id: 10,
        course_id: 16,
      },
      {
        id: 324,
        name: "Rocket Korean Play the Part (Free)",
        type_id: 2,
        level_id: 10,
        course_id: 16,
      },
    ],
  },
  {
    id: 15,
    name: "Hindi",
    locale: "hi",
    slug: "hindi",
    writing_system_ids: [12, 13, 1],
    products: [
      {
        id: 52,
        name: "Rocket Hindi Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 15,
      },
      {
        id: 124,
        name: "Rocket Hindi Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 15,
      },
      {
        id: 212,
        name: "Rocket Hindi Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 15,
      },
      {
        id: 230,
        name: "Rocket Hindi Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 15,
      },
      {
        id: 62,
        name: "Rocket Hindi Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 15,
      },
    ],
  },
  {
    id: 14,
    name: "Arabic",
    locale: "ar-EG",
    slug: "arabic",
    dialect: "Egyptian",
    writing_system_ids: [6, 7, 1],
    products: [
      {
        id: 49,
        name: "Rocket Arabic Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 14,
      },
      {
        id: 123,
        name: "Rocket Arabic Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 14,
      },
      {
        id: 222,
        name: "Rocket Arabic Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 14,
      },
      {
        id: 231,
        name: "Rocket Arabic Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 14,
      },
      {
        id: 58,
        name: "Rocket Arabic Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 14,
      },
    ],
  },
  {
    id: 34,
    name: "Arabic (MSA)",
    locale: "ar-SA",
    slug: "arabic-msa",
    dialect: "MSA",
    writing_system_ids: [6, 7, 1],
    products: [
      {
        id: 328,
        name: "Rocket Arabic MSA Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 34,
      },
    ],
  },
  {
    id: 20,
    name: "Portuguese",
    locale: "pt-BR",
    slug: "portuguese",
    dialect: "Brazilian",
    writing_system_ids: [17, 1],
    products: [
      {
        id: 138,
        name: "Rocket Portuguese Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 20,
      },
      {
        id: 136,
        name: "Rocket Portuguese Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 20,
      },
      {
        id: 204,
        name: "Rocket Portuguese Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 20,
      },
      {
        id: 232,
        name: "Rocket Portuguese Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 20,
      },
      {
        id: 137,
        name: "Rocket Portuguese Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 20,
      },
    ],
  },
  {
    id: 17,
    name: "Sign Language",
    locale: "en-US",
    slug: "sign-language",
    writing_system_ids: [18, 1],
    dialect: "ASL",
    products: [
      {
        id: 55,
        name: "Rocket Sign Language Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 17,
      },
      {
        id: 151,
        name: "Rocket Sign Language Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 17,
      },
      {
        id: 202,
        name: "Rocket Sign Language Guest Trial",
        type_id: 10,
        level_id: 1,
        course_id: 17,
      },
      {
        id: 235,
        name: "Rocket Sign Language Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 17,
      },
      {
        id: 64,
        name: "Rocket Sign Language Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 17,
      },
    ],
  },
  {
    id: 25,
    name: "English",
    locale: "en-US",
    slug: "english", // changed to match pricing page
    writing_system_ids: [1],
    products: [
      {
        id: 155,
        name: "Rocket English Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 25,
      },
      {
        id: 185,
        name: "Rocket English Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 25,
      },
      {
        id: 219,
        name: "Rocket English Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 25,
      },
      {
        id: 234,
        name: "Rocket English Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 25,
      },
      {
        id: 156,
        name: "Rocket English Library Edition",
        type_id: 3,
        level_id: 1,
        course_id: 25,
      },
    ],
  },
  {
    id: 18,
    name: "Inglés",
    locale: "en-US",
    slug: "ingles",
    writing_system_ids: [1, 5],
    products: [
      {
        id: 81,
        name: "Rocket Inglés Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 18,
      },
      {
        id: 184,
        name: "Rocket Inglés Level 1 (Free)",
        type_id: 2,
        level_id: 1,
        course_id: 18,
      },
      {
        id: 223,
        name: "Rocket Inglés Level 1 Guest (Free)",
        type_id: 10,
        level_id: 1,
        course_id: 18,
      },
      {
        id: 236,
        name: "Rocket Inglés Level 1", // Plan
        type_id: 7,
        level_id: 1,
        course_id: 18,
      },
      {
        id: 82,
        name: "Rocket Inglés Library Edition", // La Edición para Bibliotecas de Rocket Inglés
        type_id: 3,
        level_id: 1,
        course_id: 18,
      },
    ],
  },
  {
    id: 19,
    name: "英語", // "ロケット" = "Roketto"
    locale: "en-US",
    slug: "eigo",
    writing_system_ids: [1, 3, 2, 4],
    products: [
      {
        id: 92,
        name: "Rocket Eigo Level 1",
        type_id: 1,
        level_id: 1,
        course_id: 19,
      },
    ],
  },
  {
    id: 33,
    name: "Audio Book",
    locale: "en-US",
    slug: "ebook",
    writing_system_ids: [1],
    products: [
      {
        id: 261,
        name: "Rocket Audio Book",
        type_id: 12,
        level_id: 9,
        course_id: 33,
      },
    ],
  },
];

export default Courses;
