/**
 * Rocket Record Provider UI.
 *
 * Usage
 * import RocketRecordProvider, { RocketRecordContext } from './RocketRecord'
 */

import { createContext } from "react";
import type { ReactNode } from "react";
import useRocketRecord from "../hooks/useRocketRecord/useRocketRecord";
import type { UseRocketRecordProps } from "../hooks/useRocketRecord/useRocketRecord";

/**
 * Rocket Record Context
 *
 * Usage
 *
 * ```js
 * function RocketRecordStatus() {
 *    const RocketRecord = useContext(RocketRecordContext);
 *    if (!RocketRecord) {
 *        console.warn("Missing <RocketRecordProvider /> parent");
 *        return null;
 *    }
 *    const { state, methods } = RocketRecord;
 *    return (
 *      <View>
 *        <Text>Speech Recognition Error? {state.isError ? "Yes" : "No"}</Text>
 *        <Text>Current state flag: {state.flag}</Text>
 *        <Text>Audio Player Error? {state.playerState.error ? "Yes" : "No"}</Text>
 *        <Button title="Start recognition" onPress={methods.startRecognition}/>
 *      </View>
 *    );
 * }
 *
 * /// <RocketRecordProvider><RocketRecordStatus /></RocketRecordProvider>
 *
 * ```
 */
export const RocketRecordContext = createContext<ReturnType<typeof useRocketRecord> | null>(null);

/**
 * Provides a single instance of Rocket Record which shares state & methods to all child components
 *
 * Usage
 *
 * ```js
 * import RocketRecordProvider, { RocketRecordContext } from "./RocketRecordProvider";
 *
 * function Phrasebox() {
 *   return (
 *     <RocketRecordProvider {...props}>
 *       <RocketRecordControls />
 *     </RocketRecordProvider>
 *   );
 * }
 *
 * function RocketRecordControls() {
 *   const RocketRecord = useContext(RocketRecordContext)!;
 *   return (
 *     <View>
 *       <Button title="Start recognition" onPress={RocketRecord.methods.startRecognition} />
 *       <Text>State flag: {RocketRecord.state.flag}</Text>
 *     </View>
 *   )
 * }
 * ```
 */
export interface RocketRecordProviderProps {
  children: ReactNode;
  rocketRecord: UseRocketRecordProps;
}

export function RocketRecordProvider({ children, rocketRecord }: RocketRecordProviderProps) {
  const rocketRecordValue = useRocketRecord(rocketRecord);
  return <RocketRecordContext.Provider value={rocketRecordValue}>{children}</RocketRecordContext.Provider>;
}

export interface RocketRecordWithValueProviderProps {
  children: ReactNode;
  rocketRecord: ReturnType<typeof useRocketRecord>;
}

export function RocketRecordWithValueProvider({ children, rocketRecord }: RocketRecordWithValueProviderProps) {
  return <RocketRecordContext.Provider value={rocketRecord}>{children}</RocketRecordContext.Provider>;
}
