import type { ReactNode } from "react";
import useMediaQuery from "../hooks/useMediaQuery";

export function BreakpointComponent({ mediaQuery, children }: { mediaQuery: string; children: ReactNode }) {
  const canShow = useMediaQuery(mediaQuery);
  if (!canShow) {
    return null;
  }

  return <>{children}</>;
}
