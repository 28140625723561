import Button, { ButtonProps } from "../Button";
import { forwardRef } from "react";

import { clsx } from "clsx";

export type LinkButtonProps = ButtonProps & {
  download?: string;
  target?: string;
  href?: string;
  to?: string;
  rel?: string;
  type?: string;
};

const defaultProps: LinkButtonProps = {
  color: "primary",
  size: "medium",
};

const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>((props, ref) => {
  return (
    <Button use="a" ref={ref} {...props} className={clsx(!props.fill && "w-min", props.className)}>
      {props.children}
    </Button>
  );
});

LinkButton.defaultProps = defaultProps;

export default LinkButton;
