import type { CSSProperties } from "react";
import { clsx } from "clsx";
import styles from "./CircularProgress.module.scss";

interface CircularProgressProps {
  color?: string;
  colored?: boolean;
  className?: string;
  style?: CSSProperties;
}

export default function CircularProgress(props: CircularProgressProps) {
  return (
    <div className={clsx(styles.container, "text-brand dark:text-white", props.className)} style={props.style}>
      <svg viewBox="22 22 44 44">
        <circle
          fill="none"
          strokeWidth="3.6"
          strokeLinecap="round"
          cx="44"
          cy="44"
          r="20.2"
          stroke={props.color ? props.color : "currentColor"}
          className={clsx(styles.path, props.colored && styles.pathColored)}
        ></circle>
      </svg>
    </div>
  );
}
