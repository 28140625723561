import FaceliftPage from "~/components/FaceliftPage";
import { LoginForm } from "./includes/LoginForm";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { Link } from "react-router-dom";

export default function LoginPage() {
  const t = useTranslation();
  return (
    <FaceliftPage title="Members Area Login" className="mx-auto max-w-2xl space-y-5 p-4">
      <div className="space-y-8 overflow-hidden rounded-3xl border border-missilestroke bg-surface2 p-12">
        <div className="space-y-4">
          <h1 className="text-3xl font-bold text-missilebrand dark:text-white">👋 {t("hi-there-good-to-see-you")}</h1>
          <h2 className="font-normal text-missilegray dark:text-gray-300">{t("log-in-and-let-s-get-talking")}</h2>
        </div>

        <LoginForm />
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-center text-sm text-missilegray dark:text-gray-300">{t("don't-have-an-account")}</div>
        <Link to="/signup" className="text-sm font-bold text-black underline dark:text-white">
          {t("sign-up-for-a-free-trial")}
        </Link>
      </div>
    </FaceliftPage>
  );
}
