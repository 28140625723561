import Body from "./Body";
import CompleteFacelift from "./CompleteFacelift";
import Container from "./Container";
import Header from "./Header";
import Position from "./Position";
import { Icon } from "./Icon";

const RateableTestUI = {
  Header,
  Container,
  Position,
  Body,
  CompleteFacelift,
  Icon,
};

export default RateableTestUI;
