import { createContext, useCallback, useMemo, useState } from "react";

import type { SetStateFunction } from "@rocketlanguages/types";
import { noop } from "../utils";

/** Wraps all reinforcement activities */
export const ReinforcementContext = createContext<{
  activePhraseTest: number | null;
  setActivePhraseTest: SetStateFunction<number | null>;
  registeredExtraTests: Set<number>;
  /** Registers an extra test by rateable test type id */
  registerExtraTest: (rateableTestTypeId: number) => void;
  /** Switch certain elements out depending on mode */
  mode: "onboarding" | "benchmark" | "certification" | undefined;
}>({
  activePhraseTest: null,
  setActivePhraseTest: noop,
  registeredExtraTests: new Set(),
  registerExtraTest: noop,
  mode: undefined,
});

interface ReinforcementContextProviderProps {
  mode?: "onboarding" | "benchmark" | "certification";
  children: React.ReactNode;
}

export default function ReinforcementContextProvider({ mode, children }: ReinforcementContextProviderProps) {
  const [activePhraseTest, setActivePhraseTest] = useState<number | null>(null);
  const [registeredExtraTests, setRegisteredExtraTests] = useState<Set<number>>(new Set());

  const registerExtraTest = useCallback((rateableTestTypeId: number) => {
    setRegisteredExtraTests((currentState) => {
      const newState = new Set([...currentState]);
      newState.add(rateableTestTypeId);
      return newState;
    });
  }, []);

  const value = useMemo(
    () => ({ mode, activePhraseTest, setActivePhraseTest, registeredExtraTests, registerExtraTest }),
    [activePhraseTest, mode, registerExtraTest, registeredExtraTests],
  );

  return <ReinforcementContext.Provider value={value}>{children}</ReinforcementContext.Provider>;
}
