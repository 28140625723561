import { Navigate } from "react-router-dom";
import useProductFromCourseParams from "~/hooks/useProductFromCourseParams";

/** Route: members/course/:courseId/level/:levelId/reviews */
export default function RedirectToReviewsPage() {
  const product = useProductFromCourseParams();
  if (product) {
    return <Navigate to={`/members/products/${product.id}/reviews`} replace />;
  }
  return <Navigate to="/members/courses" replace />;
}
