import { MouseEventHandler, ReactNode, useEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import ReactDOM from "react-dom";
import classes from "./Modal.module.scss";
import { clsx } from "clsx";
import { FaXmark } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  hideCloseButton?: boolean;
  onEscapeKeyDown?: () => void;
  onPointerDownOutside?: () => void;
  onClose?: () => void;
  className?: string;
};

export function SimpleModal({
  children,
  isOpen,
  hideCloseButton,
  onEscapeKeyDown,
  onPointerDownOutside,
  onClose,
  className,
}: ModalProps) {
  return (
    <Modal isOpen={isOpen} onEscapeKeyDown={onEscapeKeyDown}>
      <ModalOverlay
        className={clsx(!isOpen ? "pointer-events-none" : "pointer-events-auto")}
        onPointerOutside={onPointerDownOutside}
      ></ModalOverlay>
      <ModalContent className={twMerge(clsx("bg-surface2 p-12", className))}>
        {!hideCloseButton ? <ModalCloseButton onClick={() => onClose?.()} /> : null}
        {children}
      </ModalContent>
    </Modal>
  );
}

export function Modal({
  isOpen,
  onEscapeKeyDown,
  children,
}: {
  isOpen: boolean;
  onEscapeKeyDown?: VoidFunction;
  children: ReactNode;
}) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }

    return () => {
      enablePageScroll();
    };
  }, [isOpen]);

  useEffect(() => {
    const onKeyPress = (ev: KeyboardEvent) => {
      if (ev.key === "Escape" && isOpen) {
        onEscapeKeyDown?.();
      }
    };

    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isOpen, onEscapeKeyDown]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={modalRef} tabIndex={-1} className="fixed z-50">
      {children}
    </div>,
    document.querySelector("body") as Element,
  );
}

export function ModalCloseButton({ onClick, className }: { onClick: () => void; className?: string }) {
  return (
    <button
      type="button"
      aria-label="Close"
      className={twMerge(clsx("absolute right-6 top-6", className))}
      onClick={onClick}
    >
      <FaXmark className="size-5" />
    </button>
  );
}

export function ModalContent({ children, className }: { children?: ReactNode; className?: string }) {
  return (
    <div
      className="fixed inset-0 z-[999] mx-auto flex max-h-screen min-w-[100%] items-center justify-center p-4 sm:min-w-[50%] sm:max-w-screen-sm lg:min-w-[40%] lg:max-w-screen-md xl:min-w-[30%]"
      aria-modal
      aria-haspopup
    >
      <div className={twMerge(clsx("relative w-full overflow-hidden rounded-2xl shadow-xl", className))}>
        {children}
      </div>
    </div>
  );
}

export function ModalOverlay({
  onPointerOutside,
  className,
}: {
  onPointerOutside?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}) {
  const overlayRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={overlayRef}
      className={twMerge(clsx("fixed inset-0 bg-black/75", className, classes.overlay))}
      onClick={(e) => {
        if (overlayRef.current === e.target) {
          onPointerOutside?.(e);
        }
      }}
    />
  );
}
