export default function BookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Book Icon</title>
      <path
        d="M26.5625 6.375H11.6875C11.4057 6.375 11.1355 6.48694 10.9362 6.6862C10.7369 6.88546 10.625 7.15571 10.625 7.4375C10.625 7.71929 10.7369 7.98954 10.9362 8.1888C11.1355 8.38806 11.4057 8.5 11.6875 8.5H26.5625C26.8443 8.5 27.1145 8.38806 27.3138 8.1888C27.5131 7.98954 27.625 7.71929 27.625 7.4375C27.625 7.15571 27.5131 6.88546 27.3138 6.6862C27.1145 6.48694 26.8443 6.375 26.5625 6.375Z"
        fill="currentColor"
      />
      <path
        d="M26.5632 10.625H11.8369C11.1513 10.6 10.4871 10.3798 9.92245 9.99014C9.35777 9.60052 8.91611 9.05773 8.64943 8.42562C8.49319 7.94645 8.4528 7.43706 8.53158 6.93926C8.61036 6.44145 8.80606 5.96943 9.10262 5.56191C9.39919 5.1544 9.78815 4.82302 10.2376 4.59497C10.6871 4.36691 11.1842 4.24869 11.6882 4.25H26.5632C26.845 4.25 27.1152 4.13806 27.3145 3.9388C27.5138 3.73954 27.6257 3.46929 27.6257 3.1875C27.6257 2.90571 27.5138 2.63546 27.3145 2.4362C27.1152 2.23694 26.845 2.125 26.5632 2.125H11.6882C10.9833 2.12491 10.2855 2.26509 9.63535 2.53736C8.9852 2.80964 8.39574 3.20858 7.9013 3.71093C7.40686 4.21328 7.01733 4.809 6.75541 5.46338C6.49349 6.11777 6.3644 6.81773 6.37568 7.5225V30.8125C6.37568 31.0943 6.48762 31.3645 6.68688 31.5638C6.88614 31.7631 7.15639 31.875 7.43818 31.875H26.5632C26.845 31.875 27.1152 31.7631 27.3145 31.5638C27.5138 31.3645 27.6257 31.0943 27.6257 30.8125V11.6875C27.6257 11.4057 27.5138 11.1355 27.3145 10.9362C27.1152 10.7369 26.845 10.625 26.5632 10.625ZM12.7507 12.75H14.8757V17.6375L14.5676 17.3187C14.4688 17.2192 14.3513 17.1401 14.2218 17.0862C14.0923 17.0322 13.9535 17.0045 13.8132 17.0045C13.6729 17.0045 13.534 17.0322 13.4046 17.0862C13.2751 17.1401 13.1576 17.2192 13.0588 17.3187L12.7507 17.6375V12.75ZM25.5007 29.75H8.50068V11.5706C9.13764 12.038 9.85935 12.3772 10.6257 12.5694V20.1875C10.6267 20.3973 10.6898 20.602 10.8071 20.776C10.9243 20.9499 11.0904 21.0853 11.2844 21.165C11.4779 21.2464 11.6912 21.2686 11.8973 21.2289C12.1034 21.1892 12.2931 21.0893 12.4426 20.9419L13.8132 19.5606L15.1838 20.9419C15.2831 21.0403 15.4008 21.1183 15.5303 21.1711C15.6597 21.224 15.7984 21.2508 15.9382 21.25C16.0776 21.2536 16.2158 21.2245 16.3419 21.165C16.536 21.0853 16.7021 20.9499 16.8193 20.776C16.9365 20.602 16.9996 20.3973 17.0007 20.1875V12.75H25.5007V29.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
