import { RoundedButton } from "@rocketlanguages/ui";
import { TrophyConfettiSvg, TrophySvg } from "../../../../RateableTests/RateableTestUI/CompleteFacelift";
import CongratulationsMessage from "../../../../RateableTests/RateableTestUI/includes/CongratulationsMessage";
import { clsx } from "clsx";
import { getRatingLevel } from "../../../../../utils";
import { useGlobalPlayItStore } from "./usePlayIt";
import type { Character } from "@rocketlanguages/types";

export type CharacterRating = { name: string; id: number; rating: number; avatarUrl: string | null };

export function PlayItComplete(props: {
  characters: [Character, Character];
  ratings: { [characterId: number]: number };
  onPlayAgain: () => void;
  onClose: () => void;
}) {
  const { mode, characterId } = useGlobalPlayItStore();
  const character = mode === "test" ? props.characters.find((c) => c.id !== characterId) : undefined;
  return (
    <div className="flex h-full flex-col justify-between">
      <div className="space-y-8">
        <div className="relative h-20">
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <TrophySvg />
          </div>
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <TrophyConfettiSvg />
          </div>
        </div>
        <CongratulationsMessage />
        {mode === "test" && !!character ? (
          <CharacterRating character={character} rating={props.ratings[character.id] || 0} />
        ) : null}
      </div>
      <div className="space-y-2">
        <RoundedButton
          className="w-full border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
          onClick={props.onPlayAgain}
        >
          Play Again
        </RoundedButton>
        <RoundedButton
          className="hover:bg-missileaccent/90 w-full bg-missileaccent font-semibold text-white"
          onClick={props.onClose}
        >
          Close
        </RoundedButton>
      </div>
    </div>
  );
}

function CharacterRating(props: { character: Character; rating: number }) {
  const ratingLevel = getRatingLevel(props.rating);
  return (
    <div className="rounded-2xl border border-missilestroke p-3" key={props.character.id}>
      <div className="flex items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          {props.character.avatar_url && (
            <img alt={props.character.name} src={props.character.avatar_url} className="size-7 rounded-lg" />
          )}
          <span className="text-sm font-bold leading-5">{props.character.name}</span>
        </div>
        <div
          className={clsx("h-5 w-9 rounded-lg px-2 py-1", {
            "bg-missileaccent text-white": ratingLevel === 1,
            "bg-orange-500 text-white": ratingLevel === 2,
            "bg-missilegreen text-white": ratingLevel === 3,
          })}
        >
          <div className="text-center text-xs font-semibold leading-3">{props.rating}</div>
        </div>
      </div>
    </div>
  );
}
