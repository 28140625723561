import { useEffect } from "react";

type Props = {
  onSelectionStart(): void;
  onSelectionChange(selection: string): void;
  onSelectionEnd(e: MouseEvent): void;
};

/**
 * Attaches selection listeners on an element
 */
export default function useSelectionListener(props: Props) {
  useEffect(() => {
    const onSelectionChange = () => props.onSelectionChange(window.getSelection()?.toString() || "");

    document.addEventListener("mouseup", props.onSelectionEnd);
    document.addEventListener("selectstart", props.onSelectionStart);
    document.addEventListener("selectionchange", onSelectionChange);

    return () => {
      document.removeEventListener("selectstart", props.onSelectionStart);
      document.removeEventListener("selectionchange", onSelectionChange);
      document.removeEventListener("mouseup", props.onSelectionEnd);
    };
  }, [props]);
}
