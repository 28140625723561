import { RoundedButton } from "@rocketlanguages/ui";
import { SimpleModal } from "@rocketlanguages/ui/ModalFacelift/ModalFacelift";
import API from "@rocketlanguages/shared/res/Api";
import type { FormEvent } from "react";
import type { LaravelValidationError } from "@rocketlanguages/types";
import { updateProfile } from "@rocketlanguages/shared/store/user/actions";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import { useState } from "react";
import { useStoreDispatch, useStoreSelector } from "../store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";

export default function CompleteAccountModal() {
  const t = useTranslation();
  const dispatch = useStoreDispatch();
  const [user] = useStoreSelector((store) => [store.user, store.preferences.activeCourse?.name]);
  const [firstName, setFirstName] = useState(user.profile?.first_name || "");
  const [lastName, setLastName] = useState(user.profile?.last_name || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<LaravelValidationError | null>(null);
  const activeCourse = useActiveCourse();
  const isTrial = user.products.paid.length === 0;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (user.hasMissingName) {
      if (firstName === "Rocket") {
        setError({ message: "Please update your first name", errors: {} });
        return;
      }

      if (lastName === "Learner") {
        setError({ message: "Please update your last name", errors: {} });
        return;
      }
    }

    const params: { [key: string]: string } = {
      first_name: firstName,
      last_name: lastName,
    };

    if (user.hasMissingPassword) {
      params.password = password;
    }

    try {
      setLoading(true);
      await API.post("v2/user/update-blank-details", params);
      dispatch(
        updateProfile({
          first_name: firstName,
          last_name: lastName,
        }),
      );
    } catch (e) {
      setError({ message: `${t("unknown-error")} ${t("please-try-again")}`, errors: {} });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal isOpen className="max-w-screen-sm space-y-4" hideCloseButton>
      <h5 className="text-2xl font-semibold">
        {isTrial
          ? t("final-step-trial-{language}", { language: activeCourse?.name ? activeCourse.name : "Languages" })
          : `Rocket ${activeCourse?.name || "Languages"}: ${t("final-step")}`}
      </h5>
      {error && <div className="mb-2 text-missileaccent">{error.message}</div>}
      <p>
        {isTrial
          ? t("set-your-account-password-and-get-instant-access-to-rocket-trial-{language}", {
              language: activeCourse?.name || "Languages",
            })
          : t("set-your-account-password-and-get-instant-access-to-rocket-{language}", {
              language: activeCourse?.name || "Languages",
            })}
      </p>
      <form onSubmit={handleSubmit} className={"flex flex-col gap-4"}>
        {user.hasMissingName && (
          <>
            <div>
              <label className="space-y-1">
                <div className="flex gap-1 font-bold">
                  <span>{t("first-name")}</span>
                  <span className="text-missileaccent">*</span>
                </div>
                <input
                  type="text"
                  name="first-name"
                  required
                  className="w-full rounded-full border border-missilestroke bg-transparent px-5 py-3 leading-5"
                  placeholder="Your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </label>
            </div>

            <div>
              <label className="space-y-1">
                <div className="flex gap-1 font-bold">
                  <span>{t("last-name")}</span>
                  <span className="text-missileaccent">*</span>
                </div>
                <input
                  type="text"
                  name="last-name"
                  required
                  className="w-full rounded-full border border-missilestroke bg-transparent px-5 py-3 leading-5"
                  placeholder="Your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
        {user.hasMissingPassword && (
          <>
            <div>
              <label className="space-y-1">
                <div className="flex gap-1 font-bold">
                  <span>{t("password")}</span>
                  <span className="text-missileaccent">*</span>
                </div>
                <input
                  type="password"
                  name="password"
                  required
                  className="w-full rounded-full border border-missilestroke bg-transparent px-5 py-3 leading-5"
                  placeholder={t("set-your-account-password")}
                  minLength={6}
                  maxLength={255}
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="mt-1 lowercase">*{t("minimum-of-six-characters")}</p>
              </label>
            </div>
          </>
        )}
        <RoundedButton
          className="hover:bg-missileaccen/90 bg-missileaccent font-semibold text-white"
          type="submit"
          loading={loading}
        >
          {!user.hasMissingName && user.hasMissingPassword ? t("save-password") : t("save")}
        </RoundedButton>
        <div>
          {isTrial
            ? t("complete-account-email-reminder-trial-{language}", {
                language: activeCourse?.name || t("your-language-of-choice"),
              })
            : t("complete-account-email-reminder-{language}", {
                language: activeCourse?.name || t("your-language-of-choice"),
              })}
        </div>
      </form>
    </SimpleModal>
  );
}
