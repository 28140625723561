import { Calendar } from "./icons/Calendar";
import { Link } from "react-router-dom";
import { Medal } from "./icons/Medal";
import { Podium } from "./icons/Podium";
import { clsx } from "clsx";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { twMerge } from "tailwind-merge";

export default function ProgressWidget(props: { className?: string }) {
  const t = useTranslation();
  const userPreferences = useStoreSelector((store) => store.user.preferences);
  const stats = useStoreSelector((store) => store.user.stats);
  const activeProductId = useActiveProduct()?.id || 0;
  const dailyPointsGoal = useStoreSelector((store) => Number(store.user.preferences?.daily_points_goal) || 100);
  // const todaysPoints = getTodaysPointsText(stats.dailyPoints, dailyPointsGoal);
  const streak = stats.currentStreak || 0;
  const isEbook = useActiveCourse()?.slug === "ebook";

  if (isEbook || Number(userPreferences?.hide_topbar_settings)) {
    return null;
  }

  return (
    <div className={twMerge(clsx("flex h-14 gap-4 text-sm text-white print:hidden", props.className))}>
      <Link
        to={`/members/products/${activeProductId}/tools/leaderboard`}
        aria-label={`${stats.position || "No"} leaderboard position.`}
      >
        <div
          className="flex w-32 flex-row items-center justify-center gap-2 whitespace-nowrap rounded-2xl bg-[#21586E] px-2 py-3"
          title="Leaderboard"
        >
          <Podium />
          <div>
            <div>
              <span className="font-bold">{stats.position}</span>
              {(stats.position || 0) > 0 ? getOrdinal(stats.position) : "--"}
            </div>
            <div className="text-xs leading-3">Leaderboard</div>
          </div>
        </div>
      </Link>

      <Link
        to={`/members/products/${activeProductId}/tools/leaderboard`}
        aria-label={`${stats.dailyPoints || 0} points today.`}
      >
        <div
          className="flex w-32 flex-row items-center justify-center gap-2 whitespace-nowrap rounded-2xl bg-[#21586E] px-2 py-3"
          title="Daily Points"
        >
          <Medal />
          <div>
            <div className={clsx(stats.dailyPoints >= dailyPointsGoal ? "text-missileyellow" : "text-white")}>
              <span className="font-bold">{stats.dailyPoints || 0}</span>
              <span>/{dailyPointsGoal || "-"}</span>
            </div>
            <div className="text-xs leading-3">Daily Points</div>
          </div>
        </div>
      </Link>

      <Link to={`/members/products/${activeProductId}/tools/leaderboard`} aria-label={`${streak} days streak.`}>
        <div
          className="flex w-32 flex-row items-center justify-center gap-2 whitespace-nowrap rounded-2xl bg-[#21586E] px-2 py-3"
          title="Streak"
        >
          <Calendar />
          <div>
            <div>
              <span className="font-bold">{streak}</span> {t("day|days", undefined, streak)}
            </div>
            <div className="text-xs leading-3">Streak</div>
          </div>
        </div>
      </Link>
    </div>
  );
}

function getOrdinal(n: number): string {
  const s = ["th", "st", "nd", "rd"] as const;
  const v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
}
