import type { UserComponentRatingEntity } from "@rocketlanguages/types";
import { action } from "typesafe-actions";
import * as constants from "./constants";
import type { CustomFlashcardsReduxCollection } from "./types";

export function updateCollection(payload: { collection: CustomFlashcardsReduxCollection; collectionId: number }) {
  return action(constants.UPDATE_COLLECTION, payload);
}

export function updateCurrentTest(payload: {
  collectionId: number;
  flashcardId: number;
  rating: UserComponentRatingEntity;
}) {
  return action(constants.UPDATE_CURRENT_TEST, payload);
}

export function updateCurrentTestPosition(payload: { collectionId: number; position: number }) {
  return action(constants.UPDATE_CURRENT_TEST_POSITION, payload);
}

export function rateCurrentTest(payload: { collectionId: number; totalRating: number }) {
  return action(constants.RATE_CURRENT_TEST, payload);
}

export function asyncResetTest(payload: { collectionId: number; rateableTestId: number }) {
  return action(constants.ASYNC_RESET_TEST, payload);
}

export function resetTest(payload: { collectionId: number }) {
  return action(constants.RESET_TEST, payload);
}

export function resetTestSuccess(payload: { collectionId: number }) {
  return action(constants.RESET_TEST_SUCCESS, payload);
}

export function resetTestError(payload: { collectionId: number }) {
  return action(constants.RESET_TEST_ERROR, payload);
}

export function updateSettings(payload: {
  collectionId: number;
  front: number[];
  back: number[];
  audioEnabled: boolean;
}) {
  return action(constants.UPDATE_SETTINGS, payload);
}
