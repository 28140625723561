import { clsx } from "clsx";
import { forwardRef } from "react";
import classes from "./Card.module.scss";

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  nopadding?: boolean;
  variant?: "rounded";
};

const Card = forwardRef(({ variant, className, nopadding, ...rest }: CardProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div
      {...rest}
      ref={ref}
      className={clsx(
        classes.card,
        "bg-surface2",
        nopadding && classes.nopadding,
        variant && classes[variant],
        className,
      )}
    />
  );
});

export default Card;
