// Saga initiator
export const ASYNC_SELECT_COURSE = "User/ASYNC_SELECT_COURSE";
export const ASYNC_SELECT_PRODUCT = "User/ASYNC_SELECT_PRODUCT";
export const SAGA_SYNC_USER = "User/SAGA_SYNC_USER";
export const COURSE_SELECTION_ERROR = "User/COURSE_SELECTION_ERROR";
export const SYNC_BASIC_USER = "User/SYNC_BASIC_USER";
export const SYNC_USER_AND_PRODUCT = "User/SYNC_USER_AND_PRODUCT";

// Stats
export const PUT = "User/PUT";
export const UPDATE_STATS = "User/UPDATE_STATS";
export const UPDATE_LEADERBOARD_POSITION = "User/UPDATE_LEADERBOARD_POSITION";
export const UPDATE_STREAK = "User/UPDATE_STREAK";
export const UPDATE_DAILY_POINTS = "User/UPDATE_DAILY_POINTS";
export const UPDATE_DAILY_POINTS_GOAL = "User/UPDATE_DAILY_POINTS_GOAL";
export const UPDATE_RR_DIFFICULTY = "User/UPDATE_RR_DIFFICULTY";
export const SHOW_RANK_NOTIFICATION = "User/SHOW_RANK_NOTIFICATION";
export const REMOVE_RANK_NOTIFICATION = "User/REMOVE_RANK_NOTIFICATION";
export const UPDATE_PROFILE = "User/UPDATE_PROFILE";

// User Preferences
export const ASYNC_UPDATE_PREFERENCE = "User/ASYNC_UPDATE_PREFERENCE";
export const ASYNC_UPDATE_PREFERENCES = "User/ASYNC_UPDATE_PREFERENCES";
export const ASYNC_UPDATE_COURSE_PREFERENCES = "User/ASYNC_UPDATE_COURSE_PREFERENCES";
export const UPDATE_PREFERENCES = "User/UPDATE_PREFERENCES";
export const UPDATE_FLASHCARD_PREFERENCE = "User/UPDATE_FLASHCARD_PREFERENCE";
export const ASYNC_UPDATE_FORUM_NOTIFICATIONS_PREFERENCES = "User/ASYNC_UPDATE_FORUM_NOTIFICATIONS_PREFERENCES";
export const UPDATE_FORUM_NOTIFICATIONS_PREFERENCES = "User/UPDATE_FORUM_NOTIFICATIONS_PREFERENCES";

// User Notifications
export const ASYNC_GET_NOTIFICATIONS = "User/ASYNC_GET_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "User/UPDATE_NOTIFICATIONS";

// User Profile
export const ASYNC_UPDATE_ACCOUNT = "User/ASYNC_UPDATE_ACCOUNT";
export const UPDATE_EXTRA_PROFILE = "User/UPDATE_EXTRA_PROFILE";
export const ASYNC_UPDATE_AVATAR = "User/ASYNC_UPDATE_AVATAR";
export const UPDATE_AVATAR = "User/UPDATE_AVATAR";

// Loading states
export const START_LOADING = "User/START_LOADING";
export const STOP_LOADING = "User/STOP_LOADING";
export const ERROR_LOADING = "user/ERROR_LOADING";

// Actions
export const SHOW_SIGNUP_MODAL = "User/SHOW_SIGNUP_MODAL";
export const HIDE_SIGNUP_MODAL = "User/HIDE_SIGNUP_MODAL";
