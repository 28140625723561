import { useEffect, useState } from "react";

export default function useActiveElement(): Element | null {
  const [activeElement, setActiveElement] = useState(document.activeElement);

  useEffect(() => {
    const onElementChange = () => {
      setActiveElement(document.activeElement);
    };
    document.addEventListener("focusin", onElementChange);
    document.addEventListener("focusout", onElementChange);
    document.addEventListener("blur", onElementChange);
    return () => {
      document.removeEventListener("focusin", onElementChange);
      document.removeEventListener("focusout", onElementChange);
      document.removeEventListener("blur", onElementChange);
    };
  }, []);

  // Handles state updates where focusin/focusout/blur
  // isn't aware of the document active element changing
  // eslint-disable-next-line
  useEffect(() => {
    setActiveElement(document.activeElement);
  });

  return activeElement;
}
