import type { HTMLAttributes, ReactNode, Ref } from "react";
import classes from "./PageUI.module.scss";
import { clsx } from "clsx";
import { forwardRef } from "react";

export const Container = forwardRef((props: HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return <div ref={ref} className={clsx(classes.container, "p-2 sm:p-6", className)} {...rest} />;
});

export const Card = (props: { children: ReactNode; centered?: boolean }) => {
  return <div className={clsx(classes.card, props.centered && classes.cardCentered, "rounded")} {...props} />;
};

interface HeadingProps {
  left?: JSX.Element;
  children: ReactNode;
  right?: JSX.Element;
  centered?: boolean;
}

export const Heading = (props: HeadingProps) => {
  return (
    <div className={classes.heading} style={{ textAlign: props.centered ? "center" : "left" }}>
      {props.left || null}
      <h1 className="mb-3 font-sans text-brand dark:text-white">{props.children}</h1>
      {props.right ? <div className={classes.headingRight}>{props.right}</div> : null}
    </div>
  );
};

export const Content = (props: { children: ReactNode }) => {
  return <>{props.children}</>;
};
