import * as constants from "./constants";
import type { AuthState } from "./types";
import type { SharedRootAction } from "../types";

// import { REHYDRATE } from "redux-persist/lib/constants";
const REHYDRATE = "persist/REHYDRATE";

export const initialState: AuthState = {
  onboarding: null,
  loading: false, // Whether the user is logging in or signing in
  status: "loggedout",
  token: "", // JSON Web Token
  errorMessage: undefined,
  requester: null,
};

export default function auth(state: AuthState = initialState, action: SharedRootAction): AuthState {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload?.auth) {
        return {
          ...action.payload.auth,
          loading: initialState.loading,
          errorMessage: initialState.errorMessage,
        };
      }
      return state; // action.payload.auth;
    case constants.ASYNC_LOGIN:
      return {
        ...state,
        loading: true,
        errorMessage: initialState.errorMessage,
        requester: "login",
      };
    case constants.ASYNC_SIGNUP:
      return {
        ...state,
        loading: true,
        errorMessage: initialState.errorMessage,
        requester: "signup",
      };
    case constants.ASYNC_MULTIUSER_SIGNUP:
      return {
        ...state,
        loading: true,
        errorMessage: initialState.errorMessage,
        requester: "multiuser-signup",
      };
    case constants.ASYNC_GUEST_SIGNUP:
      return {
        ...state,
        loading: true,
        errorMessage: initialState.errorMessage,
        requester: "guest-signup",
      };
    case constants.SUCCESS:
      return {
        ...state,
        ...action.payload.data.auth,
        status: "loggedin",
        loading: initialState.loading,
        onboarding: action.payload.data.onboarding || null,
        requester: null,
      };
    case constants.FAIL:
      return {
        ...state,
        status: "error",
        errorMessage: action.payload,
        loading: initialState.loading,
      };
    case constants.GUEST_AUTH_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
        loading: initialState.loading,
      };
    case constants.CLEAR_ERROR_MESSAGE:
      return {
        ...initialState,
        errorMessage: undefined,
        requester: null,
      };
    case constants.LOGOUT:
      return {
        ...initialState,
      };
    case constants.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}
