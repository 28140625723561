import { useEffect } from "react";
import useGetter from "../hooks/useGetter";

type cacheListener = () => void;

export default class Cache<T = any> {
  __cache: Map<string, T>;
  __keyListeners: Map<string, cacheListener[]>;
  __listeners: cacheListener[];

  constructor(initialData: any = {}) {
    this.__cache = new Map(Object.entries(initialData));
    this.__keyListeners = new Map();
    this.__listeners = [];
  }

  get(key: string): T | undefined {
    return this.__cache.get(key);
  }

  has(key: string) {
    return this.__cache.has(key);
  }

  set(key: string, value: any, notify = true) {
    this.__cache.set(key, value);
    if (notify) {
      this.notify(key);
    }
  }

  delete(key: string, notify = true) {
    this.__cache.delete(key);
    if (notify) {
      this.notify(key);
    }
    // Unsubscribe all key listeners
    // this.__keyListeners.delete(key);
  }

  deletePrefix(prefix: string, notify = true) {
    const keys = Array.from(this.__cache.keys());
    for (const key of keys) {
      if (key.startsWith(prefix)) {
        this.__cache.delete(key);
        if (notify) {
          this.notify(key);
        }
      }
    }
  }

  /** Completely clear the cache of all data */
  clear() {
    this.__cache.clear();
  }

  /** Listens for changes to a specific key */
  subscribeKey(key: string, listener: cacheListener) {
    const listeners = this.__keyListeners.get(key);

    if (listeners) {
      listeners.push(listener);
    } else {
      this.__keyListeners.set(key, [listener]);
    }
  }

  /** Unsubscribes from a specific key */
  unsubscribeKey(key: string, listener: cacheListener) {
    const listeners = this.__keyListeners.get(key);
    if (listeners) {
      this.__keyListeners.set(
        key,
        listeners.filter((fn) => fn !== listener),
      );
    }
  }

  /** Subscribes to all changes in the cache */
  subscribe(listener: cacheListener) {
    this.__listeners.push(listener);
  }

  /** Unsubscribes to all changes in the cache */
  unsubscribe(listener: cacheListener) {
    const index = this.__listeners.indexOf(listener);
    if (index >= 0) {
      this.__listeners.splice(index, 1);
    }
  }

  /** Notifies cache subscribers about a change in the cache */
  notify(key: string) {
    for (const listener of this.__listeners) {
      listener();
    }

    for (const listener of this.__keyListeners.get(key) || []) {
      listener();
    }
  }
}

/** Hook to listen for changes in a cache instance */
export function useCacheListener(cache: Cache, onUpdate: () => void) {
  const getListener = useGetter(onUpdate);

  useEffect(
    function watchCache() {
      const listener = getListener();

      // When the cache updates, we want to update our local state
      cache.subscribe(listener);

      return () => {
        cache.unsubscribe(listener);
      };
    },
    [cache, getListener],
  );
}

/** Hook to listen for changes in a cache instance */
export function useCacheKeyListener(cache: Cache, cacheKey: string, onUpdate: () => void) {
  const getListener = useGetter(onUpdate);

  useEffect(
    function watchCache() {
      if (!cacheKey) {
        return;
      }

      const key = cacheKey;
      const listener = getListener();

      // When the cache updates, we want to update our local state
      cache.subscribeKey(key, listener);

      return () => {
        cache.unsubscribeKey(key, listener);
      };
    },
    [cache, cacheKey, getListener],
  );
}
