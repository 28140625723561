import HanziWriterClass from "hanzi-writer";
import { useEffect, useState } from "react";

import { noop } from "../../../../../utils/utils";
import useCurrentRef from "../../../../../hooks/useCurrentRef";
import usePrevious from "../../../../../hooks/usePrevious";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";

export type HanziWriterAnimationState = "playing" | "paused" | "stopped";

export function useHanziWriterAnimation(character: string, hanziWriterRef: React.RefObject<HanziWriterClass>) {
  const state = useState<HanziWriterAnimationState>("stopped");
  // hanziWriterRef.current?._character.symbol

  const [animationState, setAnimationState] = state;
  const previousState = usePreviousStateRef(animationState);

  // Reset state on character change
  useUpdateEffect(() => {
    state[1]("stopped");
  }, [character]);

  useEffect(
    function onAnimationStateChange() {
      // Note: onComplete can call when pausing, so we're storing a loose reference here
      let onComplete = () => {
        setAnimationState("stopped");
      };

      if (hanziWriterRef.current?._loadingManager?.loadingFailed) {
        console.warn("Not animating because loading has failed");
        return;
      }
      if (animationState === "playing") {
        if (previousState.current === "paused") {
          hanziWriterRef.current?.resumeAnimation();
        } else {
          hanziWriterRef.current?.animateCharacter({ onComplete });
        }
      }

      if (animationState === "paused") {
        hanziWriterRef.current?.pauseAnimation();
      }

      return () => {
        onComplete = noop;
      };
    },
    [animationState, hanziWriterRef, previousState, setAnimationState],
  );

  return state;
}

function usePreviousStateRef<T>(value: T) {
  const previousState = usePrevious(value);
  return useCurrentRef(previousState);
}
