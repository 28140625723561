import styles from "./Rocket.module.scss";
import { clsx } from "clsx";

export default function Rocket() {
  return (
    <div className={styles.rocket}>
      <div className={styles["rocket-body"]}>
        <div className={styles.body} />
        <div className={clsx(styles.fin, styles["fin-left"])} />
        <div className={clsx(styles.fin, styles["fin-right"])} />
        <div className={styles.window} />
      </div>
      <div className={styles["exhaust-flame"]} />
      <ul className={styles["exhaust-fumes"]}>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      <ul className={styles.star}>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
}
