import { optedIntoNodeSpeechApi, shouldUseNodeSpeechApi } from "./utils";
import { isMobileDevice, isSafari } from "../../../../utils/browser";
import type { RRStateErrorCode } from "../../../../hooks/useRocketRecord/types";
import { type Browser, detect } from "detect-browser";
import type { Phrase } from "@rocketlanguages/types";

type CallbackSubscriptions = {
  onSpeechStart: () => void;
  onSpeechResults(transcripts: string[]): void;
  onSpeechPartialResults(transcripts: string[]): void;
  onSpeechError(flag: RRStateErrorCode): void;
};

export type SpeechError =
  /** No speech was detected. */
  | "no-speech"
  /** Speech input was aborted in some manner, perhaps by some user-agent-specific behavior like a button the user can press to cancel speech input. */
  | "aborted"
  /** Audio capture failed. */
  | "audio-capture"
  /**  Network communication required for completing the recognition failed. */
  | "network"
  /** The user agent disallowed any speech input from occurring for reasons of security, privacy or user preference. */
  | "not-allowed"
  /** The user agent disallowed the requested speech recognition service, either because the user agent doesn't support it or because of reasons of security, privacy or user preference. In this case it would allow another more suitable speech recognition service to be used instead. */
  | "service-not-allowed"
  /** There was an error in the speech recognition grammar or semantic tags, or the chosen grammar format or semantic tag format was unsupported. */
  | "bad-grammar"
  /** The language was not supported. */
  | "language-not-supported";

type SpeechRecognitionErrorEvent = {
  error: SpeechError;
};

/**
 * Handles speech recognition & recording
 */
class Voice {
  static recognizer: any | undefined; /* SpeechRecognition */
  static recognizing = false;
  static speechResults: string[] = [];
  static interimResults: string[] = [];

  /**
   * Speech errors that may be thrown
   */
  static SpeechErrorFlags: { [key in SpeechError]?: RRStateErrorCode } = {
    // Web Speech API specific errors
    "no-speech": "ERROR_SPEECH_INPUT",
    "not-allowed": "ERROR_PERMISSIONS",
    "service-not-allowed": "ERROR_PERMISSIONS",
    network: "ERROR_NETWORK",
    "language-not-supported": "ERROR_UNAVAILABLE",
  };

  // Browsers that have "SpeechRecognition" but isn't actually implemented
  static unsupportedBrowsers: (Browser | "bot" | "node" | "react-native")[] = [
    "edge",
    "edge-chromium",
    "edge-ios",
    "opera",
    "crios",
  ];

  /**
   * Checks either if API is supported, or is safari desktop
   */
  static isAnyKindOfRecognitionSupported() {
    return shouldUseNodeSpeechApi() || Voice.hasNativeAPISupport();
  }

  /**
   * Checks if the browser supports Speech Recognition
   */
  static hasNativeAPISupport() {
    // This indicates that the user failed to start the speech recognition
    if (optedIntoNodeSpeechApi.getState().value) {
      return false;
    }

    const browser = detect();
    if (!browser) {
      return false;
    }
    // @ts-ignore
    const supportsChrome = Boolean(typeof webkitSpeechRecognition !== "undefined");
    // @ts-ignore
    const supportsFirefox = Boolean(typeof SpeechRecognition !== "undefined");

    return (supportsChrome || supportsFirefox) && !Voice.unsupportedBrowsers.includes(browser.name);
  }

  /**
   * Whether recognition should be allowed for the given phrase.
   *
   * This is used primarily on the mobile app to determine whether
   * to use speech recognition or only recording
   */
  static shouldRecognizeForPhrase() {
    return true;
  }

  /**
   * Initializes speech recognition
   */
  static initSpeechRecognition() {
    // @ts-ignore
    const Recognizer: typeof SpeechRecognition = webkitSpeechRecognition || SpeechRecognition;
    Voice.recognizer = new Recognizer();
    Voice.recognizer!.continuous = true;
    Voice.recognizer!.maxAlternatives = 20;
    Voice.recognizer!.interimResults = true;
  }

  static async start(args: {
    phrase: Phrase;
    locale: string;
    callbacks: CallbackSubscriptions;
    isUsingRemoteSpeechAPI: boolean;
  }) {
    const { locale, callbacks } = args;
    console.warn("[Voice@start]", { locale });
    if (!Voice.hasNativeAPISupport()) {
      throw new Error("ERROR_UNAVAILABLE");
    }

    if (Voice.recognizing) {
      throw new Error("ERROR_BUSY");
    }

    Voice.recognizing = true;
    // Reset any speech/interim results
    Voice.speechResults = [];
    Voice.interimResults = [];

    if (!Voice.recognizer) {
      Voice.initSpeechRecognition();
    }

    /*
    const grammar = "#JSGF V1.0; grammar phrasestring; public <phrasestring> = " + strings.join(" | ") + ";";
    // @ts-ignore
    const GrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
    if (GrammarList) {
      // @ts-ignore
      const speechRecognitionList = new GrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      Voice.recognizer!.grammars = speechRecognitionList;
    }
    */

    // Register Events

    Voice.recognizer!.onstart = () => {
      console.warn("[Voice.onstart]: call");
      callbacks.onSpeechStart();
    };

    // Voice.recognizer!.onsoundstart = callbacks.onSpeechStart;
    Voice.recognizer!.onerror = (err: SpeechRecognitionErrorEvent) => {
      console.warn("[Voice.onerror]", err);
      const { error } = err;
      if (error !== "aborted") {
        console.warn("[RR] error:", error);
        callbacks.onSpeechError(Voice.SpeechErrorFlags[error] || "ERROR_UNKNOWN");
        Voice.recognizing = false;
        if (error === "service-not-allowed") {
          console.warn("Opting user in to Node Speech API");
          optedIntoNodeSpeechApi.getState().setValue(true);
        }
      }
    };

    Voice.recognizer!.onresult = function (event: any) {
      // Concatenate every speech result transcript
      const result = event.results[event.resultIndex];

      // Fetch every transcript of the result if finalized
      if (result.isFinal) {
        const transcripts = [];
        for (let i = 0; i < result.length; i++) {
          transcripts.push(result[i].transcript);
        }

        Voice.speechResults = (() => {
          // On mobile, WebSpeechApi interim results are coming back as final results
          if (isMobileDevice() || Voice.speechResults.length === 0) {
            return transcripts;
          }
          return getCombinations(Voice.speechResults, transcripts);
        })();

        console.warn(`[Final] Speech recognized:`, transcripts, `(${Voice.speechResults.length})`);
        callbacks.onSpeechResults(Voice.speechResults);
        return;
      }

      // Safari doesn't process interim results like Chrome does
      if (isSafari) {
        Voice.interimResults = [result[0].transcript];
        callbacks.onSpeechPartialResults([result[0].transcript]);
      } else {
        // Interim results
        const interimResults = (
          Voice.speechResults.length === 0
            ? [result[0].transcript]
            : getCombinations(Voice.speechResults, [result[0].transcript])
        )
          // Remove duplicates
          .filter((result) => !Voice.interimResults.includes(result));

        // Don't rate duplicate results
        if (interimResults.length === 0) {
          return;
        }

        Voice.interimResults = [...Voice.interimResults, ...interimResults];
        callbacks.onSpeechPartialResults(interimResults);
      }
      console.warn(
        `[Interim] Speech recognized: '${result[0].transcript}' (combinations: (${Voice.interimResults.length})`,
      );
    };

    // Set locale
    Voice.recognizer!.lang = locale;

    console.warn("Voice.recognizer: calling .start()..");
    // Start recognition
    try {
      Voice.recognizer!.start();
    } catch (err: any) {
      Voice.recognizing = false;
      // "Error: InvalidStateError: Failed to execute 'start' on 'SpeechRecognition': recognition has already started."
      callbacks.onSpeechError(err.name || "ERROR_UNKNOWN");
    }
  }

  /**
   * Gracefully stops
   */
  static stop() {
    return Voice.abort();
  }

  static abort(): Promise<void> {
    console.warn(
      "[voice] abort",
      JSON.stringify({ recognizerSet: Boolean(Voice.recognizer), recognizing: Voice.recognizing }),
    );
    Voice.speechResults = [];
    Voice.interimResults = [];

    if (!Voice.recognizer || !Voice.recognizing) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        console.warn("[Voice@abort] onend never called, resolving manually..");
        resolve();
      }, 1500);

      Voice.recognizer!.onend = () => {
        Voice.recognizing = false;
        console.warn("[Voice@onend] Recognition: end");
        clearTimeout(timeout);
        resolve();
      };
      if (isMobileDevice()) {
        Voice.recognizer?.abort();
        Voice.recognizing = false;
      } else {
        Voice.recognizer?.stop();
      }
    });
  }
}

/**
 * Gets combinations between two arrays
 */
function getCombinations(a1: string[], a2: string[]) {
  const combos: string[] = [];

  for (const i of a1) {
    for (const j of a2) {
      const phrase = i.trim() + j;
      if (combos.includes(phrase)) {
        continue;
      }
      combos.push(phrase);
    }
  }

  return combos;
}

export default Voice;
