import { useContext, useEffect } from "react";

import LessonContext from "../../../context/LessonContext";
import { requestAddPoints } from "../../../store/lesson/actions";
import { useSharedDispatch } from "../../../store";
import useVideoPlayer from "../../../hooks/useVideoPlayer";

export default function useVideoPlayerEvents(
  Player: ReturnType<typeof useVideoPlayer>,
  props: { src: string; autoPlay?: boolean; videoId?: number; lessonId?: number },
) {
  const dispatch = useSharedDispatch();
  const lessonId = useContext(LessonContext)?.id;

  useEffect(() => {
    // force re-render each new player to start at 00:00
    Player.seek(0);
    if (props.autoPlay) {
      Player.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autoPlay, props.src]);

  useEffect(
    function onStatusChange() {
      if (Player.status === "stopped" && props.videoId) {
        dispatch(
          requestAddPoints({
            rewardType: "videoPlay",
            data: {
              video: props.videoId,
              lesson: lessonId,
            },
          }),
        );
      }
    },
    [Player.status, dispatch, lessonId, props.videoId],
  );
}
