import { type ForwardedRef, forwardRef, type HTMLAttributes, useMemo } from "react";

interface VirtualizedListProps<T> extends HTMLAttributes<HTMLDivElement> {
  height: number;
  index: number;
  data: T[];
  windowSize: number;
  renderItem: (item: T, index: number) => JSX.Element | null;
  ref: React.RefObject<HTMLDivElement>;
}

function VirtualizedList<T>(props: VirtualizedListProps<T>, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
  const { height, index, data, renderItem, windowSize, ...rest } = props;
  const startIndex = Math.max(0, index - 5);
  const endIndex = Math.min(data.length, index + windowSize);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = useMemo(() => data.slice(startIndex, endIndex), [data, startIndex, endIndex]);

  return (
    <div style={{ height }} {...rest} ref={ref}>
      {items.map((item, index) => renderItem(item, index + startIndex))}
    </div>
  );
}

// @ts-ignore
const ForwardedVirtualizedList = forwardRef(VirtualizedList);

export default ForwardedVirtualizedList;
