/**
 * When selecting text on the page, we would like to only show the "add to vocab" on phrase boxes
 *
 * Phrasebox.tsx wraps it's phrase strings with "<div data-canaddtovocab">...</div>.
 *
 * In the future, we can add this in other contexts
 */
export function getComponentId(node: Element | Node | null | undefined, levels = 1): number | null {
  // Limit: check up to five levels up from the selected node
  if (!node?.parentElement || levels > 5) {
    return null;
  }

  // data-addtovocab is a property in Phrasebox.tsx that wraps the phrase strings
  let id =
    node.parentElement.getAttribute("data-phraseid") || node.parentElement.getAttribute("data-markdown-travelogue");

  if (!id && "getAttribute" in node) {
    id = node.getAttribute("data-phraseid");
  }

  return Number(id) || getComponentId(node.parentElement, levels + 1);
}
