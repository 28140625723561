import { useEffect, useRef } from "react";

type Callback = () => any;

/** Note: cancels timeout if the callback function reference changes */
export default function useTimeout(callback: Callback, delay: number | null) {
  useEffect(() => {
    if (delay !== null) {
      const id = setTimeout(callback, delay);
      return () => clearTimeout(id);
    }
  }, [callback, delay]);
}

/** Same as `useTimeout` but doesn't re-execute after the callback changes */
export function useSingleTimeout(callback: Callback, delay: number | null) {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay !== null && savedCallback.current) {
      const id = setTimeout(savedCallback.current, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}
