import * as authConstants from "../auth/constants";
import * as constants from "./constants";
import * as dashboardConstants from "../dashboard/constants";
import * as moduleTestConstants from "../moduletest/constants";

import type { LessonState } from "./types";
import type { Notation } from "@rocketlanguages/types";
import type { SharedRootAction } from "../types";
import { omit } from "../../utils";
import produce from "immer";

const initialState: LessonState = {
  entities: {
    lessons: {},
    lesson_components: {},
    notations: {},
    phrases: {},
    lesson_phrase_ids: {},
    lesson_links: {},
    lesson_quiz: {},
    lesson_rateable_test_ids: {},
    lesson_headings: {},
    rateable_tests: {},
    user_lesson_tags: {},
    user_audio_component_seek_times: {},
    user_rateable_test_ratings: {},
    user_lesson_page_data: {},
    user_phrase_recognition_ratings: {},
    user_rateable_test_component_ratings: {},
    user_rateable_test_progress: {},
    user_play_it_ratings: {},
    user_lesson_status: {},
    user_lesson_progress: {},
  },
  loading: {},
  testResetting: {},
  error: {},
};

function getUpdatedEntities(state: LessonState, entities: Partial<LessonState["entities"]>) {
  const newEntities = { ...state.entities };
  const keys = Object.keys(entities) as Array<keyof typeof entities>;

  for (const key of keys) {
    // @ts-ignore
    newEntities[key] = {
      ...newEntities[key],
      ...entities[key],
    };
  }

  return newEntities;
}

export default function lessonReducer(state: LessonState = initialState, action: SharedRootAction): LessonState {
  switch (action.type) {
    // User logs out
    case authConstants.LOGOUT:
      return { ...initialState };
    // Lesson request has started
    case constants.REQUEST_START:
      return {
        ...state,
        error: initialState.error,
        loading: {
          ...state.loading,
          [action.payload.lessonId]: true,
        },
      };
    // An error occurred with requesting a lesson
    case constants.REQUEST_FAIL:
      return {
        ...state,
        loading: omit(state.loading, action.payload.lessonId),
        error: {
          ...state.error,
          [action.payload.lessonId]: action.payload.error,
        },
      };
    // Lesson request was successful
    case constants.REQUEST_SUCCESS: {
      const { payload, lessonId } = action.payload;
      return {
        ...state,
        loading: omit(state.loading, lessonId),
        entities: getUpdatedEntities(state, payload.entities),
      };
    }
    // Admin lesson request was successful
    case constants.ADMIN_REQUEST_SUCCESS: {
      const { payload, lessonId } = action.payload;
      return {
        ...state,
        loading: omit(state.loading, lessonId),
        entities: getUpdatedEntities(state, payload.entities),
      };
    }
    case constants.SET_PHRASE_RATING_DISPLAY: {
      const { lesson, phraseId, ratingPercentage } = action.payload;
      return produce(state, (draft) => {
        const lessonRatings = draft.entities.user_phrase_recognition_ratings[lesson];
        if (lessonRatings) {
          lessonRatings[phraseId] = ratingPercentage;
        } else {
          draft.entities.user_phrase_recognition_ratings[lesson] = { [phraseId]: ratingPercentage };
        }
      });
    }
    // Occurs after REQUEST_RATE_TEST when a test has completed
    case constants.RATE_TEST: {
      const { rateableTestId, rating, markComplete } = action.payload;

      return produce(state, (draft) => {
        draft.entities.user_rateable_test_ratings[rateableTestId] = {
          value: rating,
          marked_complete: markComplete || null,
        };
      });
    }
    case constants.UPDATE_ENTITY: {
      const newEntities = { ...state.entities };
      for (const key of Object.keys(action.payload)) {
        if (!(key in newEntities)) {
          // @ts-ignore
          newEntities[key] = action.payload[key];
        } else {
          // @ts-ignore
          newEntities[key as keyof typeof newEntities] = {
            ...newEntities[key as keyof typeof newEntities],
            ...action.payload[key as keyof typeof action.payload],
          };
        }
      }
      return {
        ...state,
        entities: newEntities,
      };
    }
    /**
     * Updates component ratings (e.g. after resetting a test)
     */
    case constants.UPDATE_COMPONENT_RATINGS: {
      const { rateableTestId, componentRatings } = action.payload;
      return produce(state, (draft) => {
        draft.entities.user_rateable_test_component_ratings[rateableTestId] = componentRatings;
        draft.entities.user_rateable_test_progress[rateableTestId] = {
          num_components_rated: componentRatings.length > 0 ? 1 : 0,
        };
      });
    }
    case constants.ASYNC_RESET_TEST: {
      const { rateableTestId } = action.payload;

      return {
        ...state,
        entities: produce(state.entities, (draft) => {
          draft.user_rateable_test_component_ratings[rateableTestId] = [];
        }),
        testResetting: {
          ...state.testResetting,
          [rateableTestId]: true,
        },
      };
    }
    case dashboardConstants.DASHBOARD_REQUEST_SUCCESS: {
      return {
        ...state,
        entities: getUpdatedEntities(state, action.payload.payload.entities),
      };
    }
    // A specific lesson was reset
    case constants.RESET_TEST_RATING: {
      return produce(state, (draft) => {
        const { rateableTestId } = action.payload;
        draft.entities.user_rateable_test_ratings[rateableTestId] = {
          value: 0,
          marked_complete: false,
        };
      });
    }
    // Lesson has been marked as complete or incomplete
    case constants.UPDATE_LESSON_COMPLETION: {
      const { lessonId, isDone } = action.payload;
      return produce(state, (draft) => {
        const status = draft.entities.user_lesson_status[lessonId];
        if (status) {
          status.is_done = isDone;
        }
      });
    }
    case constants.RESET_TEST_SUCCESS: {
      const { rateableTestId } = action.payload;

      return {
        ...state,
        testResetting: omit(state.testResetting, rateableTestId),
        entities: {
          ...state.entities,
          user_rateable_test_progress: {
            ...state.entities.user_rateable_test_progress,
            [rateableTestId]: {
              ...state.entities.user_rateable_test_progress[rateableTestId],
              num_components_rated: 0,
            },
          },
        },
      };
    }
    case constants.RESET_TEST_FAIL: {
      const { rateableTestId } = action.payload;

      return {
        ...state,
        testResetting: omit(state.testResetting, rateableTestId),
      };
    }
    // Optimistic update of play it while server request starts
    case constants.RATE_PLAY_IT: {
      const { transcriptId, characterId, rating } = action.payload;

      return produce(state, (draft) => {
        const transcriptRating = draft.entities.user_play_it_ratings[transcriptId];
        if (!transcriptRating) {
          draft.entities.user_play_it_ratings = {
            [transcriptId]: {
              [characterId]: rating,
            },
          };
        } else {
          transcriptRating[characterId] = rating;
        }
      });
    }
    // Update state with current audio progress
    case constants.SAVE_AUDIO_PROGRESS: {
      const { seekTimeSeconds, audioComponentId } = action.payload;

      return {
        ...state,
        entities: getUpdatedEntities(state, {
          user_audio_component_seek_times: {
            [audioComponentId]: seekTimeSeconds,
          },
        }),
      };
    }
    case constants.SET_NOTATIONS: {
      const notationMap: { [key: number]: Notation } = {};
      for (const notation of action.payload.notations) {
        notationMap[notation.id] = notation;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          notations: {
            ...state.entities.notations,
            ...notationMap,
          },
        },
      };
    }
    case constants.UPDATE_NOTE: {
      const { lesson_id: lessonId } = action.payload;

      return produce(state, (draft) => {
        const lessonPageData = draft.entities.user_lesson_page_data[lessonId];
        if (!lessonPageData) {
          return;
        }
        lessonPageData.note = action.payload;
      });
    }
    case constants.CLEAR_NOTE: {
      return produce(state, (draft) => {
        const lessonPageData = draft.entities.user_lesson_page_data[action.payload.lessonId];
        if (!lessonPageData) {
          return;
        }
        lessonPageData.note = null;
      });
    }
    case constants.SET_LESSON_COMPONENTS: {
      return produce(state, (draft) => {
        draft.entities.lesson_components[action.payload.lessonId] = action.payload.components;
      });
    }
    case constants.VISIT: {
      const { lessonId } = action.payload;
      const tags = (() => {
        const { user_lesson_tags: userTags } = state.entities;
        // Lesson has no tags
        if (!userTags[lessonId]) {
          return userTags;
        }

        const hasSeen = userTags?.[lessonId]?.some((tag) => tag.seen);

        // First time hit, add a 'seen' flag to the tag to hide it on the dashboard
        if (!hasSeen) {
          return {
            ...userTags,
            [lessonId]: (userTags[lessonId] || []).map((tag) => ({ ...tag, seen: true })),
          };
        }

        // Second time hit, remove it completely
        return omit(userTags, lessonId);
      })();

      return {
        ...state,
        entities: {
          ...state.entities,
          user_lesson_tags: tags,
        },
      };
    }
    case constants.ADMIN_ADD_PHRASE_STRING_NOTATION: {
      const { phraseId, phraseStringId, notation } = action.payload;
      return produce(state, (draft) => {
        const phrase = draft.entities.phrases[phraseId];
        if (!phrase) return draft;
        const phraseStringIndex = phrase.strings.findIndex((_phraseString) => _phraseString.id === phraseStringId);
        const phraseString = phrase.strings[phraseStringIndex];
        if (phraseString) {
          phrase.strings.splice(phraseStringIndex, 1, {
            ...phraseString,
            notations: [...phraseString.notations, notation],
          });
        }
        return draft;
      });
    }
    case constants.ADMIN_DELETE_PHRASE_STRING_NOTATION: {
      const { phraseId, phraseStringId, notationId } = action.payload;
      return produce(state, (draft) => {
        const phrase = draft.entities.phrases[phraseId];
        if (!phrase) return draft;
        const phraseStringIndex = phrase.strings.findIndex((_phraseString) => _phraseString.id === phraseStringId);
        const phraseString = phrase.strings[phraseStringIndex];
        if (phraseString) {
          phraseString.notations.splice(
            phraseString.notations.findIndex((_notation) => _notation.id === notationId),
            1,
          );
        }
        return draft;
      });
    }
    case constants.ADMIN_UPDATE_PHRASE_STRING_NOTATION: {
      const { phraseId, phraseStringId, notation } = action.payload;
      return produce(state, (draft) => {
        const phrase = draft.entities.phrases[phraseId];
        if (!phrase) return draft;
        const phraseStringIndex = phrase.strings.findIndex((_phraseString) => _phraseString.id === phraseStringId);
        const phraseString = phrase.strings[phraseStringIndex];
        if (phraseString) {
          const phraseStringNotations = phraseString.notations;
          phraseStringNotations.splice(
            phraseStringNotations.findIndex((_notation) => _notation.id === notation.id),
            1,
            notation,
          );
        }
        return draft;
      });
    }
    case moduleTestConstants.PAGE_DATA_REQUEST_SUCCESS: {
      const { rateable_tests, user_rateable_test_ratings, notations } = action.payload.data.entities;
      return {
        ...state,
        entities: getUpdatedEntities(state, {
          rateable_tests,
          user_rateable_test_ratings,
          notations,
        }),
      };
    }
    default:
      return state;
  }
}
