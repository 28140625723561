import { LabelRatingValues, randomize } from "../useRateableTest/utils";
import type {
  LessonComponent,
  Phrase,
  PhraseboxComponent,
  RateableTestMode,
  TranscriptComponent,
  UserComponentRatingEntity,
  VideoComponent,
} from "@rocketlanguages/types";

import { RateableTestTypeIds } from "../../utils/constants";
import type { useAllComponents } from "./useAllComponents";
import { useMemo } from "react";
import { useSharedStore } from "../../store";
import { can } from "../../hooks/useHasPermission";

function createSorter<T>(getValue: (item: T) => string) {
  return (a: T, b: T) => {
    const foo = randomize(getValue(a).length);
    const bar = randomize(getValue(b).length);
    return foo < bar ? -1 : 1;
  };
}

export const filterAndSortPhrases = <T extends { id: number }>(options: {
  arr: T[];
  onlyNonEasyPhrases: boolean;
  componentRatings: UserComponentRatingEntity[];
  sort?: (item: T) => string;
}): T[] => {
  const { arr, onlyNonEasyPhrases, componentRatings, sort } = options;

  const filtered = ((): T[] => {
    // Only return non-easy phrases
    if (onlyNonEasyPhrases) {
      return [...arr].filter((phrase) =>
        componentRatings.find((c) => c.component_id === phrase.id && c.value < LabelRatingValues.easy),
      );
    }
    // Only return non-rated components
    return [...arr].filter((phrase) => !componentRatings.find((c) => c.component_id === phrase.id));
  })();

  return sort ? filtered.sort(createSorter(sort)) : filtered;
};

export default function useTestPhrases(props: {
  phraseFilter?: (phrase: Phrase) => boolean;
  allComponents: ReturnType<typeof useAllComponents>;
  memoizeKey: string;
  lessonId: number;
  rateableTestId: number;
  rateableTestTypeId: number;
  mode: RateableTestMode;
}): Phrase[] | VideoComponent[] {
  const store = useSharedStore();

  function getTestPhrases() {
    const rootStore = store.getState();
    const onlyNonEasyPhrases = Boolean(props.mode === "non_easy_components");
    const isTranslateIt = props.rateableTestTypeId === RateableTestTypeIds.TRANSLATE_IT;
    const componentRatings = rootStore.lesson.entities.user_rateable_test_component_ratings[props.rateableTestId] || [];

    const useablePhrases = props.phraseFilter
      ? props.allComponents.phrases.filter(props.phraseFilter)
      : props.allComponents.phrases;

    if (isTranslateIt) {
      return filterAndSortPhrases({
        arr: props.allComponents.videoComponents,
        onlyNonEasyPhrases,
        componentRatings,
        sort: (item) => item.caption,
      });
    }

    return filterAndSortPhrases({
      arr: useablePhrases,
      onlyNonEasyPhrases,
      componentRatings,
      // @ts-ignore
      sort: (item) => item.strings[0].text,
    });
  }

  // Update only when game session id or status changes
  // eslint-disable-next-line
  return useMemo(getTestPhrases, [props.memoizeKey]);
}

export function useCustomTestPhrases(props: {
  phraseFilter?: (phrase: Phrase) => boolean;
  phrases: Phrase[];
  memoizeKey: string;
  lessonId: number;
  rateableTestId: number;
  rateableTestTypeId: number;
  mode: RateableTestMode;
}): Phrase[] {
  const store = useSharedStore();

  function getTestPhrases() {
    const rootStore = store.getState();

    const onlyNonEasyPhrases = Boolean(props.mode === "non_easy_components");
    const componentRatings = rootStore.lesson.entities.user_rateable_test_component_ratings[props.rateableTestId] || [];

    return filterAndSortPhrases({
      arr: props.phrases,
      onlyNonEasyPhrases,
      componentRatings,
      sort: (item) => item.strings[0]?.text || "",
    });
  }

  // Update only when game session id or status changes
  // eslint-disable-next-line
  return useMemo(getTestPhrases, [props.memoizeKey]);
}

export function useLivePhrases({ phrases, lessonId }: { phrases: Phrase[]; lessonId: number }) {
  const store = useSharedStore();
  const canEditContent = can(store.getState().user, "edit_content");

  // filter out phrases by the status of the component/line hosting them, otherwise they appear in tests as they have no status of their own
  if (!canEditContent) {
    return phrases;
  }

  const phraseComponents = store
    .getState()
    .lesson.entities.lesson_components[
      lessonId
    ]?.filter((_component) => _component.type === "phrasebox" || _component.type === "transcript") as Array<
    LessonComponent<"phrasebox", PhraseboxComponent> | LessonComponent<"transcript", TranscriptComponent>
  >;

  // get lines within a draft phrasebox or with line status as 0
  const draftPhraseIds: Set<number> = new Set();
  phraseComponents.forEach((_component) => {
    // if lesson component isn't live, all phrases within it should be considered draft
    if (_component.status !== "live") {
      _component.component.lines.forEach((_line) => {
        draftPhraseIds.add(_line.phrase_id);
      });
    }
    // otherwise map through individual lines and extract those that are individually draft
    else {
      _component.component.lines.forEach((_line) => {
        if (!_line.status) {
          draftPhraseIds.add(_line.phrase_id);
        }
      });
    }
  });
  // only include phrases that are not considered draft
  return phrases.filter((_phrase) => !draftPhraseIds.has(_phrase.id));
}

export function useLiveVideoComponents({ components, lessonId }: { components: VideoComponent[]; lessonId: number }) {
  const store = useSharedStore();
  const canEditContent = can(store.getState().user, "edit_content");

  if (!canEditContent) {
    return components;
  }

  const videoLessonComponents = store
    .getState()
    .lesson.entities.lesson_components[lessonId]?.filter((_component) => _component.type === "video") as Array<
    LessonComponent<"video", VideoComponent>
  >;
  const draftVideoIds: Set<number> = new Set();
  videoLessonComponents.forEach((_component) => {
    if (_component.status !== "live") {
      draftVideoIds.add(_component.component.id);
    }
  });

  return components.filter((_component) => !draftVideoIds.has(_component.id));
}
