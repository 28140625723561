import { ButtonHTMLAttributes, createContext, useContext } from "react";
import styles from "./SegmentedControl.module.scss";

interface SegmentedControlProps {
  value: unknown;
  onValueChange: (value: unknown) => void;
}

const SegmentedControlContext = createContext<SegmentedControlProps | null>(null);

interface SegmentedControlGroupProps<TValue = string> {
  onValueChange: (value: TValue) => void;
  value: TValue | undefined;
  children: React.ReactNode;
}

const SegmentedControl = {
  Group<TValue>({ onValueChange, value, children }: SegmentedControlGroupProps<TValue>) {
    return (
      <div className={`${styles.container} flex items-center justify-center`}>
        <SegmentedControlContext.Provider
          value={{
            value,
            // @ts-ignore
            onValueChange,
          }}
        >
          {children}
        </SegmentedControlContext.Provider>
      </div>
    );
  },
  Button: (props: ButtonHTMLAttributes<HTMLButtonElement> & { value: string }) => {
    const context = useContext(SegmentedControlContext)!;
    const { value, ...rest } = props;
    return <button {...rest} onClick={() => context.onValueChange(value)} />;
  },
};

export default SegmentedControl;
