import { WritingSystemIds } from "@rocketlanguages/shared/utils/constants";
import { Switch, TooltipButton } from "@rocketlanguages/ui";
import { asyncUpdatePreference, updateFlashcardPreferences } from "@rocketlanguages/shared/store/user/actions";
import { useCallback, useMemo } from "react";
import styles from "./LanguageToggle.module.scss";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import { useDispatch } from "react-redux";
import { useStoreSelector } from "~/store";
import { clsx } from "clsx";

type Toggle = {
  id: string;
  label: string;
  tooltip: string;
  value: boolean;
  action: () => void;
};

const Countries: { [key: string]: string } = {
  "es-CL": "Latin America",
  "fr-FR": "France",
  "it-IT": "Italy",
  "de-DE": "Germany",
  ja: "Japan",
  "zh-CN": "China",
  ru: "Russia",
  ko: "Korea",
  hi: "India",
  "ar-EG": "Egypt",
  "ar-SA": "MSA",
  "pt-BR": "Brazil",
  "en-US": "USA",
};

export default function LanguageToggle() {
  const dispatch = useDispatch();
  const activeCourse = useActiveCourse();
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const translationsHidden = useStoreSelector((store) => !!Number(store.user?.preferences?.hide_translations));
  const englishHidden = useStoreSelector((store) => !!Number(store.user.preferences?.hide_english));
  const romanizationHidden = useStoreSelector((store) => !!Number(store.user.preferences?.hide_romanized));
  const kanaHidden = useStoreSelector((store) => !!Number(store.user?.preferences?.hide_kana));
  const flashcardPreferences = useStoreSelector((store) => store.user.preferences?.courses?.[activeCourse?.id || 0]);

  const isTravelogue = activeProduct?.level_id === 4;

  // if romanized is supposed to be displayed on the front of flashcards, change it to the back to update checkbox options
  // then if there are no longer any front flashcard options, set it back to default (which is english)
  const handleFlashcardPreferences = useCallback(
    (targetWritingSystems: number[]) => {
      if (flashcardPreferences) {
        const newPreferences = { ...flashcardPreferences };
        if (newPreferences.flashcard_ws_front && newPreferences.flashcard_ws_back) {
          const index = newPreferences.flashcard_ws_front.findIndex((_ws) => targetWritingSystems.includes(_ws));
          if (Number(index) >= 0) {
            const romajiWs = newPreferences.flashcard_ws_front.splice(index, 1);
            newPreferences.flashcard_ws_back.push(...romajiWs);
            if (newPreferences.flashcard_ws_front.length === 0) {
              const englishWs = newPreferences.flashcard_ws_back.splice(
                newPreferences.flashcard_ws_back.findIndex((_ws) => _ws === WritingSystemIds.english),
                1,
              );
              newPreferences.flashcard_ws_front.push(...englishWs);
            }
            if (activeCourse) {
              dispatch(updateFlashcardPreferences(activeCourse.id, flashcardPreferences));
            }
          }
        }
      }
    },
    [activeCourse, dispatch, flashcardPreferences],
  );

  const toggles: Toggle[] = useMemo(() => {
    if (isTravelogue) {
      return [
        {
          id: "see_translations",
          label: `See ${activeCourse?.name} Translations`,
          tooltip: `Turn this on to display the ${activeCourse?.name} translations.`,
          value: translationsHidden,
          action: () => {
            dispatch(asyncUpdatePreference("hide_translations", translationsHidden ? "0" : "1"));
          },
        },
        {
          id: "see_english",
          label: "See English",
          tooltip: "Turn this on to display English",
          value: !englishHidden,
          action: () => {
            dispatch(asyncUpdatePreference("hide_english", englishHidden ? "0" : "1"));
          },
        },
      ];
    }

    const romanizationConfig = (() => {
      if (activeCourse?.id === 1) {
        return {
          id: "show_romaji",
          label: "Rōmaji",
          tooltip:
            "Turn this on to display Rōmaji, the romanized spelling system that will help you pronounce Japanese characters. Use it sparingly as you won't find Rōmaji used in Japan!",
        };
      }

      if (activeCourse?.id === 5) {
        return {
          id: "show_pinyin",
          label: "Pinyin",
          tooltip:
            "Turn this on to display Pinyin, the standard system of romanized Chinese that will help you read characters with the correct tones. You won't find Pinyin used in China, so use it as a reading guide only!",
        };
      }
      return {
        id: "toggle_romanization",
        label: "Romanization",
        tooltip: `Turn this on to display a romanized spelling system that will help you read ${
          activeCourse?.name
        }. Use this as a reading guide only, as you won't find romanizations in ${
          Countries[activeCourse?.locale || ""]
        }!`,
      };
    })();

    const togglesArray: Toggle[] = [
      {
        id: "show_romanized",
        label: `Show ${romanizationConfig.label}`,
        tooltip: romanizationConfig.tooltip,
        value: !romanizationHidden,
        action: () => {
          dispatch(asyncUpdatePreference("hide_romanized", romanizationHidden ? "0" : "1"));
          !romanizationHidden &&
            handleFlashcardPreferences([
              WritingSystemIds.romaji,
              WritingSystemIds.hindi_romanized,
              WritingSystemIds.korean_romanized,
              WritingSystemIds.russian_romanized,
              WritingSystemIds.arabic_romanized,
            ]);
        },
      },
    ];

    // Japanese
    if (activeCourse?.id === 1) {
      togglesArray.unshift({
        id: "show_kana",
        label: "Show Kana",
        tooltip:
          "Turn this on to display phonetic kana characters (hiragana and katakana) with spacing for easy reading. Use it as a guide to read kanji, but get used to reading kanji as that's what you'll need in Japan!",
        value: !kanaHidden,
        action: () => {
          dispatch(asyncUpdatePreference("hide_kana", kanaHidden ? "0" : "1"));
          !kanaHidden && handleFlashcardPreferences([WritingSystemIds.hiragana_katakana]);
        },
      });
    }

    return togglesArray;
  }, [
    activeCourse?.id,
    activeCourse?.locale,
    activeCourse?.name,
    dispatch,
    englishHidden,
    handleFlashcardPreferences,
    isTravelogue,
    kanaHidden,
    romanizationHidden,
    translationsHidden,
  ]);

  return (
    <div className={"flex flex-col gap-4"}>
      {toggles.map((toggle, i) => (
        <div key={i} className={"flex items-center justify-between"}>
          <p className={"flex flex-1 items-center gap-1 font-semibold"}>
            {toggle.label}
            <TooltipButton
              tooltipProps={{
                side: "bottom",
                className: "max-w-52",
              }}
            >
              {toggle.tooltip}
            </TooltipButton>
          </p>
          <div className={clsx(styles.switch, "flex")}>
            <Switch id={toggle.id} value={toggle.value} action={toggle.action} />
          </div>
        </div>
      ))}
    </div>
  );
}
