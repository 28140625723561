import { useEffect, useRef } from "react";

import useCurrentRef from "./useCurrentRef";

export default function useElementObserver<TElement extends Element>(
  onVisiblityChange: (isVisible: boolean) => void,
  options?: IntersectionObserverInit,
) {
  const ref = useRef<TElement>(null);
  const onVisiblityChangeRef = useCurrentRef(onVisiblityChange);
  const optionsRef = useCurrentRef(options);

  useEffect(() => {
    // Older browsers don't have this
    if (!window.IntersectionObserver) {
      onVisiblityChangeRef.current?.(true);
      return;
    }
    const observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        onVisiblityChangeRef.current?.(entry.isIntersecting);
      });
    }, optionsRef?.current || {});
    const element = ref.current;
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
      observer.disconnect();
    };
  }, [ref, onVisiblityChangeRef, optionsRef]);

  return ref;
}
