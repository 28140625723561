import Courses from "@rocketlanguages/shared/res/courses";
import { useMemo } from "react";
import { useStoreSelector } from "~/store";
import { useBannerStore } from "../Banner/Banner.store";

export const usePricingLink = () => {
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const userTrials = useStoreSelector((store) => store.user.products.trial);
  const isGuest = useStoreSelector((store) => store.user.isGuest);
  const banner = useBannerStore((store) => ({ visible: store.visible, pricingPageUrl: store.pricingPageUrl }));

  return useMemo(() => {
    if (banner.visible && banner.pricingPageUrl) {
      return banner.pricingPageUrl;
    }
    const suffix = !isGuest ? "&coupon=earlybird" : "";
    if (activeCourse) {
      // Travelogue & PTP have a different pricing page
      if (activeProduct?.level_id === 4) {
        return `https://www.rocketlanguages.com/${activeCourse.slug}/rocket-${activeCourse.slug}-travelogues`;
      }
      if (activeProduct?.level_id === 10) {
        return `https://www.rocketlanguages.com/${activeCourse.slug}/rocket-${activeCourse.slug}-play-the-part`;
      }

      return `https://www.rocketlanguages.com/pricing?language=${activeCourse.slug}${suffix}`;
    }

    // If there is no active course, we are likely on the courses page
    // so we should show the pricing page for the first trial course
    if (userTrials[0]) {
      const course = Courses.find((_course) => _course.id === userTrials[0]?.course_id);
      if (course) {
        return `https://www.rocketlanguages.com/pricing?language=${course.slug}${suffix}`;
      }
    }
    return "https://www.rocketlanguages.com/pricing";
  }, [activeCourse, activeProduct?.level_id, banner.pricingPageUrl, banner.visible, isGuest, userTrials]);
};
