import { Suspense, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { Outlet } from "react-router-dom";
import PageLoader from "@rocketlanguages/shared/ui/PageLoader";
import { SentryErrorBoundary } from "../components/ErrorBoundary";
import { history } from "@rocketlanguages/shared/res/router";
import { useStoreSelector } from "../store";
import { AvatarCircle, PricingButtonLink, Topbar } from "~/layouts/components/TopBar/TopBar";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";

export default function DefaultLayout(props: { children?: React.ReactNode; preventLoginRedirect?: boolean }) {
  const isLoggedIn = useStoreSelector((store) => store.auth.status === "loggedin");
  const location = useLocation();
  const t = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (isLoggedIn && !props.preventLoginRedirect) {
    // This allows us to redirect back to an authenticated page
    if (
      history.location.state &&
      typeof history.location.state === "object" &&
      "redirectLocation" in history.location.state
    ) {
      return <Navigate to={history.location.state.redirectLocation as string} replace />;
    }
    return <Navigate to="/members/courses" replace />;
  }

  return (
    <div>
      <Topbar>
        <Topbar.LogoLink hasSidebar={false} use={Link} title="Rocket Languages" subtitle="Let's get talking" path="/" />
        <div className="flex items-center gap-4">
          {/** Library users: don't show pricing page */}
          {!location.pathname.includes("library") && <PricingButtonLink />}
          <a
            href="https://www.rocketlanguages.com/aboutus"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-white"
          >
            {t("about-us")}
          </a>
          <a
            href="https://www.rocketlanguages.com/help"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-white"
          >
            {t("help")}
          </a>
          {isLoggedIn && <AvatarCircle />}
        </div>
      </Topbar>
      <article className="m-auto w-full max-w-screen-xl flex-1 items-center justify-center py-2 md:p-6">
        <SentryErrorBoundary key={1}>
          <Suspense fallback={<PageLoader />}>{props.children || <Outlet />}</Suspense>
        </SentryErrorBoundary>
      </article>
    </div>
  );
}
