import styles from "./ImageFrame.module.scss";
import { clsx } from "clsx";

interface ImageFrameProps {
  imageUrl: string;
  size?: "extra-small" | "small" | "medium" | "large" | "responsive";
  background?: string;
  variant?: "shadowless";
  badge?: {
    url: string;
    title: string;
    size?: number;
  };
}

export default function ImageFrame({ imageUrl, size = "medium", background, variant, badge }: ImageFrameProps) {
  return (
    <div
      className={clsx(styles.container, styles[size], variant && styles[variant])}
      style={{ backgroundImage: `url(${imageUrl})`, backgroundColor: background }}
    >
      {badge && (
        <img
          src={badge.url}
          title={badge.title}
          alt=""
          className={styles.badge}
          style={badge.size ? { height: badge.size, width: badge.size } : undefined}
        />
      )}
    </div>
  );
}
