import { forwardRef } from "react";

import IconButton from "./IconButton";
import { LinkButtonProps } from "./LinkButton";

const defaultProps: Omit<LinkButtonProps, "title"> = {
  color: "primary",
  size: "medium",
};

type IconLinkButtonProps = LinkButtonProps & ({ title: string } | { "aria-label": string });

const IconLinkButton = forwardRef<HTMLButtonElement, IconLinkButtonProps>((props, ref) => {
  return (
    // @ts-ignore
    <IconButton use="a" ref={ref} {...props}>
      {props.children}
    </IconButton>
  );
});

IconLinkButton.defaultProps = defaultProps;

export default IconLinkButton;
