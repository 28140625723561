import { FaExpand, FaPause, FaPlay, FaSquare } from "react-icons/fa";
import { GrBackTen, GrForwardTen } from "react-icons/gr";
import { IconButton, PlaybackSpeedDialog } from "@rocketlanguages/ui";
import Video, { VideoProps } from "./includes/Video";
import { useRef, useState } from "react";

import { IconOutlineButton } from "@rocketlanguages/ui/Button";
import Markdown from "markdown-to-jsx";
import { Slider } from "../Slider/Slider";
import { clsx } from "clsx";
import { secondsToMinutesAndSeconds } from "../../utils";
import styles from "./VideoPlayer.module.scss";
import useVideoPlayer from "../../hooks/useVideoPlayer";
import useVideoPlayerEvents from "./utils/useVideoPlayerEvents";

type VideoPlayerProps = {
  /** Used for adding points. Usually component id */
  videoId?: number;
  url: string;
  className?: string;
  title?: string;
  autoPlay?: boolean;
  muted?: boolean;
  /** Don't preload the video's metadata */
  preload?: boolean;
  /** Url of image to use as thumbnail is not preloaded */
  thumbnail?: string;
  /** Make title bigger if the course is a scripted language */
  isScript?: boolean;
};

/**
 * Video player UI
 *
 * Adds points after playing if the lesson ID and video ID are set
 */
function VideoPlayer(props: VideoPlayerProps) {
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const Player = useVideoPlayer(videoElementRef);

  useVideoPlayerEvents(Player, { autoPlay: props.autoPlay, src: props.url, videoId: props.videoId });

  const [playbackSpeedVisible, setPlaybackSpeedVisible] = useState(false);

  return (
    <div className="rounded bg-brand">
      {props.title && (
        <div className="px-4 py-4 md:px-8">
          <h2 className={clsx("text-white")}>
            <Markdown>{props.title}</Markdown>
          </h2>
        </div>
      )}
      <Video
        src={props.url}
        muted={props.muted}
        poster={props.thumbnail}
        ref={videoElementRef}
        preload={props.preload}
      />
      <div className="px-4 py-4 sm:px-8 print:hidden">
        <div className="flex items-center justify-between">
          <div className="relative">
            <IconOutlineButton
              color="white"
              onClick={() => setPlaybackSpeedVisible((s) => !s)}
              aria-label="Adjust speed"
            >
              {Player.playbackRate}x
            </IconOutlineButton>
            {playbackSpeedVisible && (
              <PlaybackSpeedDialog
                value={Player.playbackRate}
                onClose={(value) => {
                  if (value) {
                    Player?.setPlaybackRate(value);
                  }
                  setPlaybackSpeedVisible(false);
                }}
              />
            )}
          </div>
          <IconButton
            color="error"
            size="large"
            aria-label={Player.status === "stopped" || Player.status === "paused" ? "Play" : "Pause"}
            onClick={() => {
              Player.status === "stopped" || Player.status === "paused" ? Player.play() : Player.pause();
            }}
          >
            {Player.status === "stopped" || Player.status === "paused" ? <FaPlay /> : <FaPause />}
          </IconButton>
          <IconOutlineButton color="white" aria-label="Fullscreen" onClick={Player.setFullscreen}>
            <FaExpand />
          </IconOutlineButton>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <IconButton
            color="transparent"
            aria-label="Jump back"
            onClick={() => {
              Player.skip("back");
            }}
          >
            <GrBackTen className={styles.forceSVGWhite} />
          </IconButton>
          <div className="flex w-full items-center justify-center gap-2 text-sm font-semibold text-white">
            <div className="min-w-12 max-w-12 text-center">{secondsToMinutesAndSeconds(Player.currentTime)}</div>
            <Slider
              min={0}
              max={Player.duration}
              value={[Player.currentTime]}
              onValueChange={(value) => {
                Player.seek(value[0]);
              }}
            />
            <div className="min-w-12 max-w-12 text-center">{secondsToMinutesAndSeconds(Player.duration)}</div>
          </div>
          <IconButton
            color="transparent"
            aria-label="Jump forward"
            onClick={() => {
              Player.skip("back");
            }}
          >
            <GrForwardTen className={styles.forceSVGWhite} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export function SimpleVideoPlayer(props: VideoProps & { autoPlay?: boolean; videoId?: number }) {
  const { autoPlay, videoId, ...rest } = props;
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const Player = useVideoPlayer(videoElementRef);
  useVideoPlayerEvents(Player, { src: props.src, autoPlay, videoId });

  const OverlayElement =
    Player.status === "playing" ? <FaSquare size={78} color="white" /> : <FaPlay color="white" size={78} />;

  return (
    <Video
      ref={videoElementRef}
      {...rest}
      onClick={() => {
        if (Player.status === "playing") {
          Player.pause();
          Player.seek(0);
        } else {
          Player.play();
        }
      }}
      overlay={OverlayElement}
    />
  );
}
export default VideoPlayer;
