import * as jsdiff from "diff"; // Calculates diff between two strings
import type { Phrase, VoiceRecognitionDifficulty } from "@rocketlanguages/types";
import { prepareString, replaceAmbiguousCharacters, stripSpaces, leven } from "../../utils/stringUtils";
import type { RatedPhrase, RRStateErrorCode } from "./types";
import { getRatingPercentageDisplay } from "../../utils";
import type { RocketRecordState } from "./useRocketRecordStore";

// Some courses are harder for speech recognition to detect, so we can specify an offset
const DIFFICULTY_OFFSETS: { [key: string]: number } = {
  "ar-EG": -10,
};

const DIFFICULTY_THRESHOLDS: { [key in VoiceRecognitionDifficulty]: [number, number, number] } = {
  beginner: [
    0, // Hard
    40, // So-so
    80, // Easy
  ],
  intermediate: [
    0, // Hard
    50, // So-so
    90, // Easy
  ],
  advanced: [
    0, // Hard
    55, // So-so
    95, // Easy
  ],
};

function getDifficultyThresholds(difficulty: VoiceRecognitionDifficulty, locale: string): number[] {
  const thresholds = DIFFICULTY_THRESHOLDS[difficulty] || DIFFICULTY_THRESHOLDS.beginner;

  // Add difficulty offset
  const offset = DIFFICULTY_OFFSETS[locale];
  if (offset) {
    return thresholds.map((threshold) => threshold + offset);
  }

  return thresholds;
}

function getRatingLevelFromDifficultyThreshold(
  difficulty: VoiceRecognitionDifficulty,
  percentage: number,
  locale: string,
): number {
  // Write it has a different way to check the results, based on the dist
  const thresholds = getDifficultyThresholds(difficulty || "beginner", locale);
  // const langInArray = asianLanguages.includes(locale);
  for (let i = thresholds.length - 1; i > 0; i -= 1) {
    if (percentage >= (thresholds[i] || 0)) {
      return i + 1; // Return a 1 - 3 rating level (not 0 - 2)
    }
  }
  // less-than-0 percentage which shouldn't happen
  return 1;
}

const stripSlashesRegex = new RegExp(/\/|\./g);

const RATED_PHRASE_MATCH = { ratingLevel: 3, percentage: 100, percentageDisplay: 100 };

export const regexFromString = (str: string) => {
  const input = str
    .replace(stripSlashesRegex, "")
    // Escape currency, plus symbols
    .replace(/(\$|\+)/g, "\\$1");

  try {
    return new RegExp(input, "i");
  } catch (e) {
    return undefined;
  }
};

/** Goes through all transcripts and gets the best result */
export function getBestResultFromTranscripts(props: {
  transcripts: string[];
  currentFlag: RocketRecordState["flag"];
  difficulty: VoiceRecognitionDifficulty | undefined;
  phrase: Phrase;
  locale: string;
}) {
  const { currentFlag, phrase, locale, difficulty, transcripts } = props;

  const isActive = currentFlag.status === "ACTIVE";
  const isFetchingTranscript = currentFlag.status === "FINISHING" && currentFlag.fetchingTranscript;
  const shouldStillProcessFinalResult = currentFlag.status === "FINISHING" && currentFlag.shouldStillProcessFinalResult;

  // Make sure speech is active, fetching transcript, or can still process final result
  // This avoids cases where partial/final results are emitted after an error
  if (!isActive && !isFetchingTranscript && !shouldStillProcessFinalResult) {
    return;
  }

  /** Best rating from provided transcripts */
  let bestResult: RatedPhrase | undefined;

  // Go through each transcript and get rating
  for (const transcription of transcripts) {
    const result = rateTranscription({
      rawTranscription: transcription,
      phrase,
      locale,
      difficulty: difficulty || "beginner",
    });

    // Update best result
    if (!bestResult || result.percentage > bestResult.percentage) {
      bestResult = result;
      continue;
    }

    if (
      result.percentage === bestResult.percentage &&
      // We want to prioritize the longest strings to display to the user
      (result.rawTranscription || "").length > (bestResult.rawTranscription || "").length
    ) {
      bestResult = result;
    }
  }
  return bestResult;
}

/**
 * Compares user input with all phrase strings (except last) and returns a suggested rating level.
 */
export function rateTranscription(opts: {
  /**  A transcript of the speech recognized */
  rawTranscription: string;
  /** The phrase to which the PhraseString belongs */
  phrase: Phrase;
  /** The locale of the phrase, eg 'de-DE' */
  locale: string;
  /** The difficulty setting to weight the rating. */
  difficulty: VoiceRecognitionDifficulty;
}): RatedPhrase {
  const { rawTranscription, phrase, locale, difficulty } = opts;
  const transcription = prepareString(rawTranscription);

  // Make sure that the user actually said something
  if (transcription.length === 0) {
    return { ratingLevel: 0, percentage: 0, percentageDisplay: 0 };
  }

  // Compare the string with the phrase answer rather than the text.
  const isRegex = phrase.answer?.[0] === "/";

  const phraseAnswer = prepareString(phrase.answer || "", {
    isRegexString: isRegex,
  });

  /*
  if (isRegex) {
   console.log({ transcription, stripped: phraseAnswer.replace(stripSlashesRegex, "") });
  }
  */

  // Is regex, one of the conditions match
  if (isRegex && regexFromString(phraseAnswer)?.test(transcription)) {
    return RATED_PHRASE_MATCH;
  }

  // Try with stripped spaces
  if (stripSpaces(phraseAnswer) === stripSpaces(transcription)) {
    return RATED_PHRASE_MATCH;
  }

  // answer_alt: Check whether we have any full matches
  if (phrase.answer_alt) {
    try {
      const arr = JSON.parse(phrase.answer_alt);
      if (Array.isArray(arr)) {
        for (const answer of arr) {
          const res = rateAgainstText({
            transcription,
            rawTranscription,
            expectedText: answer,
            locale,
            difficulty,
          });
          // Only return if it's a full match
          if (res.percentage === 100) {
            return RATED_PHRASE_MATCH;
          }
        }
      }
    } catch {
      // This _may_ just happen if the value exceeds the column size
    }
  }

  let bestRating: RatedPhrase = {
    ratingLevel: 0,
    percentage: 0,
    percentageDisplay: 0,
  };

  // Compare against every phrase string besides last, unless there's only 1 phrase string
  const psLimit = Math.max(1, phrase.strings.length - 1);
  const phraseStrings = phrase.strings.slice(0, psLimit);

  for (const phraseString of phraseStrings) {
    const newRating = rateAgainstText({
      transcription,
      rawTranscription,
      expectedText: phraseString.text,
      locale,
      difficulty,
    });

    if (newRating.percentage === 100) {
      return newRating;
    }

    if (bestRating.percentage < newRating.percentage) {
      bestRating = newRating;
      continue;
    }

    if (bestRating.percentage === newRating.percentage) {
      // We want to prefer longer strings over shorter strings
      // Showing the user the longest string is more likely to be closest to the correct answer
      const isLonger = (bestRating.rawTranscription || "").length < (newRating.rawTranscription || "").length;

      if (isLonger) {
        bestRating = newRating;
      }
    }
  }

  return bestRating;
}

type RateAgainstTextParams = {
  /** Prepared transcription */
  transcription: string;
  /** A transcript of the speech recognized */
  rawTranscription: string;
  /** The expected text */
  expectedText: string;
  /** The locale of the phrase, eg 'de-DE' */
  locale: string;
  /** The difficulty setting to weight the rating. */
  difficulty: VoiceRecognitionDifficulty;
};

export function rateAgainstText({
  expectedText,
  transcription,
  rawTranscription,
  locale,
  difficulty,
}: RateAgainstTextParams): RatedPhrase {
  const preparedExpectedText = prepareString(expectedText);
  // Compare user's speech with answer, and mainstring, then take the better match
  const dist = leven(stripSpaces(transcription), stripSpaces(preparedExpectedText));

  // Percentage, formatted as a whole number (0-100)
  const percentage = Number(100 - (dist / Math.max(preparedExpectedText.length, transcription.length)) * 100);

  if (percentage === 100) {
    return RATED_PHRASE_MATCH;
  }

  let diffResult = jsdiff.diffChars(
    replaceAmbiguousCharacters(rawTranscription),
    replaceAmbiguousCharacters(expectedText),
    { ignoreCase: true },
  );

  // Re-order arabic results
  if (locale === "ar-EG") {
    diffResult = diffResult.reverse();
  }

  const percentageToDisplay = getRatingPercentageDisplay(percentage);

  const ratingLevel = getRatingLevelFromDifficultyThreshold(difficulty, percentageToDisplay, locale);

  return {
    percentage,
    percentageDisplay: ratingLevel === 3 ? 100 : percentageToDisplay,
    ratingLevel,
    diffResult,
    rawTranscription,
  };
}

export const config = {
  /** If phrase duration is unknown, changes min recording duration to 6.25 seconds */
  defaultDuration: 3500,
  /** Gives users 2.2 times the amount of time to speak as the recorded duration */
  multiplier: 2.2,
  /**
   * If phrase.duration > 0ms, this is the absolute minimum duration users should be allowed to speak
   *
   * (e.g. phrase.duration = 1000 then 4.5s overrides 2.75s (1000 * 1.75 + 1000)
   */
  minRecordingTimeoutDuration: 4500,
  /** Time to wait before timing out */
  onSpeechStartTimeout: 5000,
};

/**
 * Calculate the time allowed for the user to record themselves
 * @param {number} duration
 * @return {number} Audio duration
 */
const getRecordingDuration = (duration: number): number => {
  const { defaultDuration, multiplier } = config;
  return (duration || defaultDuration) * multiplier + 1000;
};

export const getRecordingTimeoutDuration = (phrase: Phrase) => {
  // task https://app.clickup.com/t/860rj4ehc
  // "Record duration for use_remote_speech_recognition === 1 should be Math.max(1.5 x phrase duration, 2seconds)"
  if (phrase.use_remote_speech_recognition) {
    return Math.max(1.5 * (phrase.duration || 0), 3000);
  }
  return Math.max(getRecordingDuration(phrase.duration || 0), config.minRecordingTimeoutDuration);
};

type RecordErrorFlag =
  | "AbortError"
  | "NotAllowedError"
  | "NotReadableError"
  | "SecurityError"
  | "TypeError"
  | "OverconstrainedError"
  | "NotFoundError";

export const RecordErrorFlags: { [key in RecordErrorFlag]: RRStateErrorCode | "ABORTED" } = {
  AbortError: "ABORTED",
  NotAllowedError: "ERROR_PERMISSIONS",
  NotReadableError: "ERROR_AUDIO",
  SecurityError: "ERROR_PERMISSIONS",
  NotFoundError: "ERROR_DEVICE_INPUT",
  TypeError: "ERROR_UNKNOWN",
  OverconstrainedError: "ERROR_AUDIO",
};
