import { useContext, useMemo, useState } from "react";
import { PhraseboxContext } from "../context";
import {
  Check as CheckIcon,
  Microphone as MicrophoneIcon,
  MinusSquare as MinusSquareIcon,
  PlusSquare as PlusSquareIcon,
  Text as TextIcon,
} from "iconoir-react";
import PhraseTestContext from "../../RateableTests/includes/context";
import { TooltipContent, TooltipFacelift, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui";
import { clsx } from "clsx";

export function PhraseboxDebugPanel() {
  const context = useContext(PhraseboxContext);
  if (!context) {
    throw new Error("PhraseboxContext not found. Please wrap in PhraseboxProvider");
  }
  const { phrase } = context;
  const { result, flag } = context.rocketRecord.useRocketRecordState();
  const isInPhraseTest = useContext(PhraseTestContext).testTypeId !== 0;

  if (!phrase) {
    return null;
  }

  return (
    <div className="space-y-2 text-xs">
      <div>
        <span className="font-bold">ID:</span> {phrase.id}
      </div>
      {!isInPhraseTest && <AdminStatusTooltips />}
      <div>
        <span className="font-bold">Answer:</span> {phrase.answer || "*no answer set*"}
      </div>
      <div>
        <span className="font-bold">Answer alt:</span>{" "}
        {phrase.answer_alt
          ? JSON.parse(phrase.answer_alt)
              .map((s: string) => `"${s}"`)
              .join(", ")
          : "*no alt answers set*"}
      </div>
      <div>
        <span className="font-bold">use_remote_speech_recognition:</span>{" "}
        {phrase.use_remote_speech_recognition ? "true" : "false"}
      </div>
      <div>
        <span className="font-bold">Status:</span> {flag.status}
      </div>
      {flag.status !== "ACTIVE" && !!result?.percentage ? (
        <div>
          <span className="font-bold">Percentage:</span> {`${result.percentage.toFixed(0)}%`}
        </div>
      ) : null}
      <DebugLog />
    </div>
  );
}

function AdminStatusTooltips() {
  const { phrase } = useContext(PhraseboxContext)!;
  if (!phrase) {
    return null;
  }
  return (
    <div className="flex flex-row gap-2">
      <TooltipProvider delayDuration={200}>
        <TooltipFacelift>
          <TooltipContent side="bottom">
            {`Voice Recognition ${phrase.disable_voice_recognition ? "Disabled" : "Enabled"}`}
          </TooltipContent>
          <TooltipTrigger>
            <MicrophoneIcon className={clsx(phrase.disable_voice_recognition ? "text-slate-200" : "text-slate-600")} />
          </TooltipTrigger>
        </TooltipFacelift>
        <TooltipFacelift>
          <TooltipContent side="bottom">{`Notations ${phrase.show_notations ? "Shown" : "Hidden"}`}</TooltipContent>
          <TooltipTrigger>
            <TextIcon className={clsx(!phrase.show_notations ? "text-slate-200" : "text-slate-600")} />
          </TooltipTrigger>
        </TooltipFacelift>
        <TooltipFacelift>
          <TooltipContent side="bottom">{`${phrase.exclude_tests ? "Excluded from" : "Included in"} Reinforcement Activities`}</TooltipContent>
          <TooltipTrigger>
            <CheckIcon className={clsx(phrase.exclude_tests ? "text-slate-200" : "text-slate-600")} />
          </TooltipTrigger>
        </TooltipFacelift>
        <TooltipFacelift>
          <TooltipContent side="bottom">{`${phrase.exclude_module_tests ? "Excluded from" : "Included in"} Module/Benchmark Tests`}</TooltipContent>
          <TooltipTrigger>
            <CheckIcon className={clsx(phrase.exclude_module_tests ? "text-slate-200" : "text-slate-600")} />
          </TooltipTrigger>
        </TooltipFacelift>
      </TooltipProvider>
    </div>
  );
}

function DebugLog() {
  const { rocketRecord, phrase } = useContext(PhraseboxContext)!;
  const { debugLog } = rocketRecord.useRocketRecordState();
  const [expanded, setExpanded] = useState(false);

  const formattedTimedLog = useMemo(
    () =>
      debugLog.map((_log, i) => {
        const timestamp = i === 0 ? "0.00" : ((_log.time - (debugLog[0]?.time || 0)) / 1000).toFixed(2);
        return `[${timestamp}] ${_log.message}`;
      }),
    [debugLog],
  );

  if (debugLog.length === 0) {
    return null;
  }

  return (
    <div>
      <button type="button" className="flex flex-row items-center gap-1" onClick={() => setExpanded((e) => !e)}>
        <div>{expanded ? <MinusSquareIcon /> : <PlusSquareIcon />}</div>
        <span>Debug Log</span>
      </button>
      {expanded ? (
        <div>
          {formattedTimedLog.map((log, index) => {
            const [title, ...rest] = log.split(/\n(.+)/);
            const content = rest.join("\n");
            return (
              <div key={`debug.${phrase.id}.${index}`}>
                <div>{title}</div>
                {content ? <div className="pl-4">{content}</div> : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
