import type { Character } from "@rocketlanguages/types";
import { CharacterAvatar } from "./CharacterAvatar";
import usePlayItRatings from "../../../../../hooks/usePlayItRatings";

export function PlayItMenu(props: {
  lessonId: number;
  transcriptId: number;
  playableCharacters: [Character, Character];
  onSelectMode: (mode: { type: "listen" } | { type: "test"; characterId: number }) => void;
}) {
  const playItRatings = usePlayItRatings(props.transcriptId);

  return (
    <div className="grid grid-cols-1 grid-rows-2 gap-6 xl:flex">
      <button
        type="button"
        className="relative h-52 flex-[0.5] overflow-hidden rounded-2xl bg-missilegray"
        onClick={() => props.onSelectMode({ type: "listen" })}
        aria-label="Play conversation"
      >
        <div className="flex h-full">
          {props.playableCharacters.map((character) => {
            if (!character.avatar_url) {
              return null;
            }
            return (
              <img
                alt={character.name}
                key={character.id}
                src={character.avatar_url}
                className="w-full object-cover transition-all"
                style={{ width: `${100 / props.playableCharacters.length}%` }}
              />
            );
          })}
        </div>

        <div className="absolute bottom-0 left-0 right-0 top-0 transition-all hover:bg-black/30" />
        <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-black/70 via-black/10 to-transparent" />
        <div className="pointer-events-none absolute bottom-0 left-0 right-0 flex-row items-center justify-between p-4">
          <div>
            <div className="text-sm text-white">Watch the</div>
            <div className="text-lg font-semibold text-white">Full Conversation</div>
          </div>
        </div>
      </button>
      <div className="hidden xl:block">
        <div className="h-full w-[2px] rounded-full bg-missilestroke" />
      </div>
      <div className="flex flex-1 gap-6">
        {props.playableCharacters.map((character) => {
          const otherCharacterId =
            props.playableCharacters.find((c) => c.id !== character.id)?.id || props.playableCharacters.at(0)?.id || 0;
          return (
            <CharacterAvatar
              className="flex-1"
              key={character.id}
              onClick={() => {
                props.onSelectMode({ type: "test", characterId: otherCharacterId });
              }}
              character={character}
              rating={playItRatings[otherCharacterId]}
            />
          );
        })}
      </div>
    </div>
  );
}
