import Button, { type ButtonProps } from "../Button";
import { forwardRef } from "react";

import { clsx } from "clsx";
import styles from "./SquareButton.module.scss";

export type SquareButtonProps = Omit<ButtonProps, "left" | "fill">;

const defaultProps: SquareButtonProps = {
  color: "primary",
  size: "medium",
};

const SquareButton = forwardRef<HTMLButtonElement, Omit<ButtonProps, "left" | "fill">>((props, ref) => {
  const size = (() => {
    switch (props.size) {
      case "large":
        return "!h-[64px] !w-[64px] !min-w-[64px] !p-0";
      case "medium":
        return "!h-[48px] !w-[48px] !min-w-[48px] !p-0";
      case "small":
        return "!h-[28px] !w-[28px] !min-w-[28px] !p-0";
      default:
        return "!h-[28px] !w-[28px] !min-w-[28px] !p-0";
    }
  })();

  return (
    <Button
      ref={ref}
      {...props}
      className={clsx("!rounded-md", size, props.size && !props.loading && styles[props.size], props.className)}
    >
      {props.children}
    </Button>
  );
});

SquareButton.defaultProps = defaultProps;

export default SquareButton;
