import * as constants from "./constants";

import { action } from "typesafe-actions";

export * from "@rocketlanguages/shared/store/auth/actions";

// Handled by reducer

/** Clear onboarding data in store once tutorial page is hit to enable redirect routes, eg: redirecting to members/courses if no active course id set */
export function clearOnboarding() {
  return action(constants.CLEAR_ONBOARDING);
}

// Handled by sagas

/** First time login, from purchase(?) */
export function asyncLoginHashRequest(params: { hash: string }) {
  return action(constants.ASYNC_LOGIN_HASH_REQUEST, params);
}

/** URL that auto logs in with time expiry */
export function asyncAutoLoginRequest(params: {
  /**
   * "/api/v2/autologin/{user}/{source}?expires=xxxx&signature=xxxx"
   */
  url: string;
}) {
  return action(constants.ASYNC_AUTO_LOGIN_REQUEST, params);
}

/** First time login, from email link in rockgeneral_0 */
export function asyncAutoLoginEmailRequest(params: { email: string }) {
  return action(constants.ASYNC_AUTO_LOGIN_EMAIL_REQUEST, params);
}

/** Called when 403 is returned from routes, meaning the user was somehow unauthenticated. Removes the 'isLoggedIn' status from auth so that we can redirect to login */
export function fourOhThreeResponse() {
  return action(constants.FOUR_OH_THREE_RESPONSE);
}
