import { useState, useEffect } from "react";

const secondsInDay = 86400;
const secondsInHour = 3600;
const secondsInMinute = 60;

export default function useCountdown(untilEpochSeconds: number) {
  const [isRunning, setIsRunning] = useState(false);
  const [hoursMinutesSeconds, setHoursMinutesSeconds] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    const interval = setInterval(tick, 1000);

    setIsRunning(Boolean(untilEpochSeconds));

    function tick() {
      const currentTime = Date.now() / 1000;
      const remainingTime = untilEpochSeconds - currentTime;
      if (remainingTime <= 0) {
        setIsRunning(false);
        clearInterval(interval);
        return;
      }
      const days = Math.floor(remainingTime / secondsInDay);
      const daysRemainder = remainingTime % secondsInDay;
      const hours = Math.floor(daysRemainder / secondsInHour);
      const hoursRemainder = daysRemainder % secondsInHour;
      const mins = Math.floor(hoursRemainder / secondsInMinute);
      const secs = Math.floor(hoursRemainder % secondsInMinute);
      setHoursMinutesSeconds({
        days,
        hours,
        mins,
        secs,
      });
    }

    tick();

    return () => {
      clearInterval(interval);
    };
  }, [untilEpochSeconds]);

  return { isRunning, hoursMinutesSeconds };
}
