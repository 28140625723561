import { useCallback, type SetStateAction, useMemo } from "react";
import useLocalStorage from "./useLocalStorage";
import useCurrentRef from "./useCurrentRef";

export function useLocalStorageWithTimeout<TValue>(
  key: string,
  options: {
    defaultValue: TValue;
    timeoutInSeconds: number;
  },
) {
  const memoizedValue = useMemo(
    () => ({ value: options.defaultValue, expiryTime: Date.now() + options.timeoutInSeconds * 1000 }),
    [options.defaultValue, options.timeoutInSeconds],
  );

  const [lsValue, setLsValue] = useLocalStorage(key, memoizedValue);
  const setLsValueRef = useCurrentRef(setLsValue);

  const hasExpired = lsValue.expiryTime < Date.now();
  const value = hasExpired ? options.defaultValue : lsValue.value;

  const setValue = useCallback(
    (setStateAction: SetStateAction<TValue>) => {
      const newValue = setStateAction instanceof Function ? setStateAction(value) : setStateAction;
      setLsValueRef.current?.({
        value: newValue,
        expiryTime: Date.now() + options.timeoutInSeconds * 1000,
      });
    },
    [setLsValueRef, value, options.timeoutInSeconds],
  );

  return [value, setValue] as const;
}
