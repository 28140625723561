import { useEffect, useRef } from "react";
import { noop } from "../utils";

type Callback = () => any;

export default function useInterval(callback: Callback, delay: number | null): void {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return noop;
  }, [delay]);
}
