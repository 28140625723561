/* eslint-disable react-refresh/only-export-components */
import { clsx } from "clsx";
import type { RatingGroups } from "../../../../hooks/usePhraseTest/useRatingGroups";

type Props = {
  text: string;
  recommended?: "easy" | "good" | "hard";
  onRate: (ratingLevel: keyof RatingGroups) => void;
  numEasy?: number;
  numGood?: number;
  numHard?: number;
};

export function MissileRatingButtons({ recommended, text, onRate, numEasy, numGood, numHard }: Props) {
  return (
    <>
      <div className="text-center text-sm">{text}</div>
      <div className="flex w-full flex-row justify-center gap-2 pt-3">
        <button
          type="button"
          className={clsx(
            "hover:bg-missilegreen/90 h-12 w-full max-w-52 flex-1 items-center justify-center rounded-3xl px-3 py-2 text-sm",
            recommended === "easy" || !recommended ? "bg-missilegreen" : "bg-missilegreen/30",
          )}
          onClick={() => onRate("easy")}
        >
          <div className="text-sm font-bold text-white">
            Easy
            {numEasy ? <span className="ml-2">({numEasy})</span> : null}
          </div>
        </button>

        <button
          type="button"
          className={clsx(
            "h-12 w-full max-w-52 flex-1 items-center justify-center rounded-3xl px-3 py-2 text-sm hover:bg-orange-500/90",
            recommended === "good" || !recommended ? "bg-orange-500" : "bg-orange-500/30",
          )}
          onClick={() => onRate("good")}
        >
          <div className="text-sm font-bold text-white">
            Good
            {numGood ? <span className="ml-2">({numGood})</span> : null}
          </div>
        </button>

        <button
          type="button"
          className={clsx(
            "hover:bg-missileaccent/90 h-12 w-full max-w-52 flex-1 items-center justify-center rounded-3xl bg-missileaccent px-3 py-2 text-sm",
            recommended === "hard" || !recommended ? "bg-missileaccent" : "bg-missileaccent/30",
          )}
          onClick={() => onRate("hard")}
        >
          <div className="text-sm font-bold leading-5 text-white">
            Hard
            {numHard ? <span className="ml-2">({numHard})</span> : null}
          </div>
        </button>
      </div>
    </>
  );
}
