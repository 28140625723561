import type { Phrase, WriteItItem } from "@rocketlanguages/types";

interface Props {
  phrases: Phrase[];
  phrase: Phrase;
  phraseArray: string[];
  phraseStringIndex: number;
}

function shuffler({ phrases, phrase, phraseArray, phraseStringIndex }: Props) {
  if (!phraseArray || phrases.length === 0) {
    return null;
  }
  const destructablePhraseArray = [...phraseArray];
  const consumablePhraseArray = [...phraseArray];
  const scrambledArray: WriteItItem[] = [];

  while (scrambledArray.length < phraseArray.length) {
    // get a random index from the phraseArray
    const randomInsertIndex = Math.floor(Math.random() * destructablePhraseArray.length);
    // push the item at that index onto the scrambledArray, while preserving it's original order in the list to calculate ordering %

    const phraseString = phrase.strings[phraseStringIndex];
    const questionText = destructablePhraseArray[randomInsertIndex];

    if (phraseString && questionText) {
      scrambledArray.push({
        phraseItem: phraseString,
        questionText: questionText,
        originalOrder: consumablePhraseArray.indexOf(questionText),
      });

      // find the first instance of the string in the phrase array and set it to "consumed"
      // this allows the indexOf call above to find duplicate strings at their true original index
      // as it will find the next iteration of a duplicate instead of the first one every time
      consumablePhraseArray[consumablePhraseArray.findIndex((el) => el === questionText)] = "consumed";
    }

    // remove that item from the phraseArray
    destructablePhraseArray.splice(randomInsertIndex, 1);
  }
  return scrambledArray;
}
export default shuffler;
