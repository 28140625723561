import type * as jsdiff from "diff";
import { RateableTestTypeIds, WritingSystemLanguages } from "../../../utils/constants";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import usePhraseTest, {
  actions as phraseTestActions,
  type usePhraseTestProps,
} from "../../../hooks/usePhraseTest/usePhraseTest";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { ToneKeyboard } from "./includes/ToneKeyboardFacelift";
import { VirtualKeyboard } from "./includes/VirtualKeyboardFacelift";
import { getRatingLevel, getWritingSystemIndex, isRTL } from "../../../utils";
import { rateWriting } from "./utils";
import useActiveCourse from "../../../hooks/useActiveCourse";
import { useSharedSelector } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";
import { MissileRatingButtons } from "../RateableTestUI/buttons/MissileRatingButtons";
import { getRecommendedRatingButton } from "../RateableTestUI/buttons/getRecommendedRatingButton";
import { RoundedButton } from "@rocketlanguages/ui";
import type { Phrase, PhraseString } from "@rocketlanguages/types";
import { Phrasebox, useWebRocketRecord } from "../../PhraseboxFacelift/PhraseboxFacelift";
import { WriteItTextDiff } from "../../../ui/TextDiff";
import { clsx } from "clsx";
import usePhraseHotkeys from "../../../hooks/usePhraseHotkeys";
import type { RatingGroups } from "../../../hooks/usePhraseTest/useRatingGroups";

export type KeyboardState = {
  submitted: boolean;
  textInput: string;
  writingDiff: jsdiff.Change[];
  selectionStart: number;
  percent: number;
  showDiffAndRating: boolean;
};

type WriteItProps = {
  rateableTestId: number;
  rateableTestTypeId?: number;
};

export function WriteItFacelift({ rateableTestId, rateableTestTypeId }: WriteItProps) {
  const t = useTranslation();
  const activeCourse = useActiveCourse();
  const isNative = rateableTestTypeId === RateableTestTypeIds.WRITE_IT_NATIVE;
  const lesson = useContext(LessonContext);

  const phraseTestProps = useMemo(
    (): usePhraseTestProps => ({
      testTypeId: rateableTestTypeId || RateableTestTypeIds.WRITE_IT,
      lessonId: lesson.id,
      rateableTestId,
      mode: "unrated_components",
      phraseFilter(phrase) {
        const index = getWritingSystemIndex(activeCourse?.slug || "spanish", phrase, isNative);
        const psText = phrase.strings[index]?.text?.trim() || "";
        return Boolean(psText && psText !== "-");
      },
    }),
    [rateableTestTypeId, lesson.id, rateableTestId, activeCourse, isNative],
  );
  const phraseTest = usePhraseTest(phraseTestProps);

  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const phraseStringIndex = getWritingSystemIndex(activeCourse?.slug || "spanish", currentPhrase, false);

  // Get testName for romanized romantic languages, eg: Pinyin
  const testName =
    !currentPhrase || !phraseStringIndex
      ? ""
      : WritingSystemLanguages[currentPhrase.strings[phraseStringIndex]?.writing_system_id || 0];

  return (
    <RateableTest
      phraseTest={phraseTest}
      testName={`${t("write-it")} ${testName}`}
      testSubheading={t("write-it-subheading")}
      testTypeId={phraseTestProps.testTypeId}
      instructions={<Instructions />}
    >
      <WriteItPhraseTest />
    </RateableTest>
  );
}

const baseState: KeyboardState = {
  submitted: false,
  textInput: "",
  writingDiff: [],
  selectionStart: 0,
  percent: 0,
  showDiffAndRating: false,
};

export function WriteItPhraseTest() {
  const { testContainerRef, phraseTest } = useContext(RateableTestContext);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug || "spanish");
  const phraseStringIndex = getWritingSystemIndex(activeCourseSlug, currentPhrase, false);
  const [state, setState] = useState(baseState);
  const isRevealed = phraseTest.state.revealed.has(phraseTest.state.index);

  const handleReveal = () => {
    const rating = rateWriting({
      input: textRef.current?.value || "",
      target: currentPhrase?.strings[phraseStringIndex]?.text || "",
      courseSlug: activeCourseSlug,
    });

    setState((prevState) => ({
      ...prevState,
      submitted: true,
      showDiffAndRating: true,
      writingDiff: rating?.writingDiff || prevState.writingDiff,
      percent: rating?.percentageDisplay || 0,
    }));

    if (rating) {
      return rating.ratingLevel;
    }
  };

  const isRussian = activeCourseSlug === "russian";
  const Keyboard = isRussian ? VirtualKeyboard : ToneKeyboard;

  const onReveal = () => {
    const ratingLevel = handleReveal() ?? 0;
    phraseTest.methods.dispatch(phraseTestActions.reveal(ratingLevel));
  };

  const onRate = (ratingLabel: keyof RatingGroups) => {
    phraseTest.methods.rate(ratingLabel);
    setState(baseState);
  };

  usePhraseHotkeys({ phraseTestRef: testContainerRef, revealed: isRevealed, onReveal, onRate });

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex flex-col gap-4">
        <WriteitPhrasebox
          phrase={currentPhrase}
          phraseTest={phraseTest}
          writingDiff={state.writingDiff}
          percentage={state.percent}
          writingSystemId={currentPhrase?.strings[phraseStringIndex]?.writing_system_id || 0}
        />
        {!state.submitted ? (
          <Keyboard phraseTest={phraseTest} state={state} setState={setState} textRef={textRef} />
        ) : null}
      </div>

      <div className="flex flex-col items-center justify-end">
        {isRevealed ? (
          <MissileRatingButtons
            text="How hard did you find this phrase?"
            recommended={getRecommendedRatingButton(phraseTest.state.revealed.get(phraseTest.state.index))}
            onRate={onRate}
            numEasy={phraseTest.computed.ratings.easy.size}
            numGood={phraseTest.computed.ratings.good.size}
            numHard={phraseTest.computed.ratings.hard.size}
          />
        ) : (
          <RoundedButton className="w-full max-w-60 bg-missileaccent font-semibold text-white" onClick={onReveal}>
            Check
          </RoundedButton>
        )}
      </div>
    </div>
  );
}

function WriteitPhrasebox(props: {
  phrase: Phrase;
  phraseTest: ReturnType<typeof usePhraseTest>;
  writingDiff: jsdiff.Change[];
  percentage: number;
  writingSystemId: number;
}) {
  const isRevealed = props.phraseTest.state.revealed.has(props.phraseTest.state.index);

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const rocketRecord = useWebRocketRecord({
    phrase: props.phrase,
  });
  const hasNotations = !!props.phrase.literal_string || props.phrase.strings.some((s) => s.notations.length > 0);

  useEffect(() => {
    audioPlayerRef.current?.play();
  }, [props.phraseTest.state.index, isRevealed]);

  const phraseString = props.phrase?.strings.find((ps) => ps.writing_system_id === props.writingSystemId);
  const hasMoreThanOneWritingSystem = props.phrase.strings.length > 2;

  return (
    <div className="space-y-4 pt-4">
      <Phrasebox rocketRecord={rocketRecord}>
        <Phrasebox.Hotkeys />
        <div className="relative">
          <div
            className={clsx(
              "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
              hasNotations && "rounded-tr-none",
            )}
          >
            {hasNotations ? <Phrasebox.Notations /> : null}

            <Phrasebox.RowLayout>
              <Phrasebox.PlayButton
                audioRef={audioPlayerRef}
                className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
                useDarkWaveform
              />
              <Phrasebox.StringsContainer>
                <Phrasebox.Strings>
                  {(phraseString, index, items) => {
                    const isLast = index === items.length - 1;
                    const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;
                    return (
                      <div
                        className={clsx(
                          shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined,
                          !isRevealed && "select-none",
                        )}
                      >
                        <Phrasebox.String
                          {...phraseString}
                          text={!isRevealed ? "..." : phraseString.text}
                          disableVocabUnderline
                        />
                      </div>
                    );
                  }}
                </Phrasebox.Strings>
              </Phrasebox.StringsContainer>
            </Phrasebox.RowLayout>
            <Phrasebox.RowLayout className="h-6 items-center">
              <Phrasebox.RocketRecordRatingButton />
              <div className="mb-2 flex-1 border-b border-b-missilestroke pb-2" />
            </Phrasebox.RowLayout>

            <Phrasebox.RowLayout className="items-center pt-2">
              <Phrasebox.RecordButton
                disabled={!isRevealed}
                className={clsx("hover:bg-missileaccent/90 bg-missileaccent text-white", !isRevealed && "opacity-50")}
              />

              <Phrasebox.SpeechLayout className="flex-1">
                <Phrasebox.Speech />
                <Phrasebox.ErrorText />
                <Phrasebox.DebugPanel />
              </Phrasebox.SpeechLayout>

              <Phrasebox.RecordPlaybackButton
                className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
                useDarkWaveform
              />
            </Phrasebox.RowLayout>
          </div>
        </div>
      </Phrasebox>

      {isRevealed && (
        <WriteItDiffBox
          percentage={props.percentage}
          writingDiff={props.writingDiff}
          isRTL={isRTL(props.phrase.course_id)}
          phraseString={phraseString}
        />
      )}
    </div>
  );
}

function WriteItDiffBox({
  percentage,
  writingDiff,
  isRTL,
  phraseString,
}: {
  percentage: number;
  writingDiff: jsdiff.Change[];
  isRTL: boolean;
  phraseString?: PhraseString;
}) {
  const ratingLevel = getRatingLevel(percentage);
  return (
    <div className="flex flex-row items-center gap-2 rounded-2xl border-2 border-missilesurfacedark bg-missilesurfacelight p-4">
      <div
        className={clsx("flex w-10 items-center justify-center self-baseline rounded-lg px-2 py-1", {
          "bg-missilegreen text-white": ratingLevel === 3,
          "bg-orange-500 text-white": ratingLevel === 2,
          "bg-missileaccent text-white": ratingLevel !== 2 && ratingLevel !== 3,
        })}
      >
        <div className="text-center text-xs font-semibold">{percentage}%</div>
      </div>

      <div className="font-bold">
        {percentage === 100 && phraseString ? (
          <Phrasebox.String
            {...phraseString}
            // Remove formatting from the text
            text={phraseString.text.replace(/<[^>]*>/gim, "").replace(/\*/gim, "")}
            color="#00b050"
          />
        ) : (
          <WriteItTextDiff writingDiff={writingDiff} isRTL={isRTL} /* className="font-montserrat-bold text-sm" */ />
        )}
      </div>
    </div>
  );
}
