export const UPDATE_COLLECTION = "CustomFlashcards/UPDATE_COLLECTION";
export const UPDATE_CURRENT_TEST = "CustomFlashcards/UPDATE_CURRENT_TEST";
export const RATE_CURRENT_TEST = "CustomFlashcards/RATE_CURRENT_TEST";

export const ASYNC_RESET_TEST = "CustomFlashcards/ASYNC_RESET_TEST";
export const RESET_TEST = "CustomFlashcards/RESET_TEST";
export const RESET_TEST_SUCCESS = "CustomFlashcards/RESET_TEST_SUCCESS";
export const RESET_TEST_ERROR = "CustomFlashcards/RESET_TEST_ERROR";

export const UPDATE_CURRENT_TEST_POSITION = "CustomFlashcards/UPDATE_CURRENT_TEST_POSITION";

export const UPDATE_SETTINGS = "CustomFlashcards/UPDATE_SETTINGS";
