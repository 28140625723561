import { lazy, ComponentType } from "react";

export default function lazyRoute<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy(() =>
    // @ts-ignore
    factory().catch((err) => {
      if (process.env.NODE_ENV === "development") {
        console.error(err);
      } else {
        // Hack: reload page on failed bundle loads.
        // S3 has likely deleted the async bundle and cloudfront has no cache entry
        window.location.reload();
      }
      return {
        default: () => null,
      };
    }),
  );
}
