import { FaRegQuestionCircle } from "react-icons/fa";
import classes from "./TooltipButton.module.scss";
import type { ReactNode } from "react";
import { TooltipContent, Tooltip, TooltipProvider, TooltipTrigger } from "../Tooltip/Tooltip";
import type { Content as TooltipPrimitiveContent } from "@radix-ui/react-tooltip";

type TooltipButtonProps = {
  size?: number;
  color?: string;
  onClick?: () => void;
  children: ReactNode;
  tooltipProps?: React.ComponentPropsWithoutRef<typeof TooltipPrimitiveContent>;
};

export default function TooltipButton({ children, tooltipProps, size, onClick, color }: TooltipButtonProps) {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipContent {...tooltipProps}>{children}</TooltipContent>
        <TooltipTrigger asChild={!!onClick}>
          {onClick ? (
            <button
              type="button"
              className={classes.instructionsTooltipContainer}
              onClick={onClick}
              onKeyDown={(ev) => {
                // enter or space
                if (ev.key === "Enter" || ev.key === " ") {
                  onClick();
                }
              }}
            >
              <FaRegQuestionCircle size={size || 16} color={color} />
            </button>
          ) : (
            <span className={classes.instructionsTooltipContainer}>
              <FaRegQuestionCircle size={size || 16} color={color} />
            </span>
          )}
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
}
