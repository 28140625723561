import HanziWriterClass, { type HanziWriterOptions, type QuizOptions } from "hanzi-writer";
import { forwardRef, useImperativeHandle, useLayoutEffect, useMemo, useRef } from "react";

import useWindowSize from "../../../../../hooks/useWindowSize";
import { randomString } from "../../../../../utils/stringUtils";

export type StrokeData = {
  character: string;
  drawnPath: {
    pathString: string;
    points: Array<{ x: number; y: number }>;
  };
  strokeNum: number;
  mistakesOnStroke: number;
  totalMistakes: number;
  strokesRemaining: number;
};

export type HanziWriterProps = {
  className?: string;
  char: string;
  quiz?: {
    active: boolean;
    options: Partial<QuizOptions>;
  };
  children?: React.ReactNode;
  options: Partial<HanziWriterOptions>;
};

/**
 * React component for the `"hanzi-writer"` library
 */
const HanziWriter = forwardRef<HanziWriterClass | undefined, HanziWriterProps>((props, ref) => {
  const writer = useRef<HanziWriterClass>();
  const currentChar = useRef("");
  const options = useRef(props.options);
  const dimensions = useWindowSize();

  const id = useMemo(() => `char-${props.char}-${randomString()}`, [props.char]);

  useLayoutEffect(() => {
    if (!dimensions.width) {
      return;
    }

    if (options.current !== props.options && writer.current) {
      writer.current._options = {
        ...writer.current._options,
        ...props.options,
      };
      options.current = props.options;
    }

    if (props.char === currentChar.current) {
      return;
    }

    currentChar.current = props.char;

    if (writer.current) {
      writer.current?.setCharacter(props.char);
      return;
    }

    const dimension = dimensions.width && dimensions.width < 640 ? 300 : 400;

    // Create new character
    writer.current = HanziWriterClass.create(id, props.char, {
      ...(props.options || {}),
      width: dimension,
      height: dimension,
    });
  }, [dimensions.width, id, props.char, props.options]);

  useLayoutEffect(() => {
    if (props.quiz && props.quiz.active) {
      writer.current?.quiz(props.quiz.options);
    } else {
      writer.current?.cancelQuiz();
      // Reset display
      /*
      if (options.current.showCharacter) {
        writer.current?.showCharacter();
      }
      if (options.current.showOutline) {
        writer.current?.showOutline();
      }
      */
      // writer.current?.setCharacter(props.char);
    }
  }, [props.char, props.quiz]);

  useLayoutEffect(() => {
    if (!props.options.showCharacter) {
      writer.current?.hideCharacter();
    } else {
      writer.current?.showCharacter();
    }
  }, [props.options.showCharacter]);

  useLayoutEffect(() => {
    if (!props.options.showOutline) {
      writer.current?.hideOutline();
    } else {
      writer.current?.showOutline();
    }
  }, [props.options.showCharacter, props.options.showOutline]);

  useImperativeHandle(ref, () => writer.current);

  return (
    <div id={id} className={props.className}>
      {props.children || null}
    </div>
  );
});

export default HanziWriter;
