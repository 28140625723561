import { toast } from "react-toastify";
import { takeEvery } from "redux-saga/effects";
import type { ActionType } from "typesafe-actions";
import * as constants from "./constants";
import type * as uiActions from "./actions";

// The UI sagas allow for modifications to the UI state, such as showing/hiding toasts
export default [
  takeEvery(constants.TOAST, ({ payload }: ActionType<typeof uiActions.toast>) => {
    toast(payload.message, { type: payload.type });
  }),
];
