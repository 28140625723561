import { useEffect } from "react";

/** Asynchronously loads a script tag */
export function Script(props: { id: string; src: string }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = props.src;
    script.id = props.id;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [props.src, props.id]);

  return null;
}
