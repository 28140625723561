import { useState, useEffect } from "react";
import useInterval from "./useInterval";

type HTMLMediaElement = {
  audio: HTMLAudioElement;
  video: HTMLVideoElement;
};

export default function useMediaPlayer<MediaType extends keyof HTMLMediaElement>(
  type: MediaType,
  mediaRef: React.RefObject<HTMLMediaElement[MediaType] | null>,
) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolumeState] = useState(1);
  const [seeking, setSeeking] = useState(false);
  const [status, setStatus] = useState<"stopped" | "playing" | "paused">("paused");
  const [playbackRate, setPlaybackRateState] = useState(1);

  // Update the display time every 200ms
  useInterval(
    () => {
      if (!mediaRef.current || mediaRef.current.seeking || seeking) {
        return;
      }
      setCurrentTime(mediaRef.current.currentTime);
    },
    status === "playing" ? 200 : null,
  );

  useEffect(() => {
    const { current: ref } = mediaRef;
    if (!ref) {
      return;
    }

    const handlePlay = () => {
      const { current } = mediaRef;
      setDuration(current ? current.duration : 0);
      setStatus("playing");
    };

    const handleLoad = () => {
      if (mediaRef.current) {
        setDuration(mediaRef.current.duration);
      }
    };

    const handleProgress = () => {
      if (mediaRef.current) {
        setDuration(mediaRef.current.duration);
      }
    };

    const handlePause = () => setStatus("paused");

    const handleEnd = () => {
      if (duration) {
        setCurrentTime(duration);
      }
      setStatus("stopped");
    };

    ref.onprogress = handleProgress;
    ref.onload = handleLoad;
    ref.onloadedmetadata = handleLoad;
    ref.ondurationchange = handleLoad;
    ref.onplay = handlePlay;
    ref.onpause = handlePause;
    ref.onended = handleEnd;
    ref.onabort = handleEnd;
    return () => {
      ref.removeEventListener("progress", handleProgress);
      ref.removeEventListener("load", handleLoad);
      ref.removeEventListener("loadedmetadata", handleLoad);
      ref.removeEventListener("durationchange", handleLoad);
      ref.removeEventListener("play", handlePlay);
      ref.removeEventListener("pause", handlePause);
      ref.removeEventListener("ended", handleEnd);
      ref.removeEventListener("abort", handleEnd);
    };
  }, [duration, mediaRef]);

  function seek(seconds: number): void {
    setCurrentTime(seconds);
    if (mediaRef.current && Number.isFinite(seconds)) {
      mediaRef.current.currentTime = Math.floor(seconds);
    }
  }

  function skip(direction: "ahead" | "back"): void {
    if (!mediaRef.current) {
      return;
    }

    const currentPosition = mediaRef.current.currentTime || 0;

    const seekTime = ((): number => {
      if (direction === "ahead") {
        return Math.min(mediaRef.current.duration, currentPosition + 10);
      }
      return Math.max(0, currentPosition - 10);
    })();

    const flooredSeekTime = Math.floor(seekTime);

    if (Number.isFinite(flooredSeekTime)) {
      mediaRef.current.currentTime = flooredSeekTime;
      // Manually dispatch timeupdate event to avoid jumpy seek
      mediaRef.current.dispatchEvent(new Event("timeupdate"));
    }
  }

  function setFullscreen(): void {
    if (!mediaRef.current || type !== "video") {
      return;
    }
    try {
      if (mediaRef.current.requestFullscreen) {
        mediaRef.current.requestFullscreen();
      } else {
        // @ts-ignore (Safari)
        mediaRef.current.webkitRequestFullScreen?.();
      }
    } catch (err) {
      console.warn(err);
    }
  }

  function play(): void {
    mediaRef.current?.play();
  }

  function pause(): void {
    mediaRef.current?.pause();
  }

  function setVolume(volume: number): void {
    if (mediaRef.current) {
      mediaRef.current.volume = volume;
      setVolumeState(volume);
    }
  }

  function setPlaybackRate(playbackRate: number): void {
    if (mediaRef.current) {
      mediaRef.current.playbackRate = playbackRate;
      setPlaybackRateState(playbackRate);
    }
  }

  return {
    status,
    currentTime,
    setCurrentTime,
    duration,
    seeking,
    setSeeking,
    seek,
    play,
    pause,
    setVolume,
    volume,
    setPlaybackRate,
    playbackRate,
    setFullscreen,
    skip,
  };
}
