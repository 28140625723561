import { useState, type FormEvent } from "react";
import { RoundedButton } from "@rocketlanguages/ui";
import { asyncLoginRequest } from "@rocketlanguages/shared/store/auth/actions";
import { useDispatch } from "react-redux";
import { useStoreSelector } from "../../../store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { Eye as EyeIcon, EyeClosed as EyeClosedIcon } from "iconoir-react";
import { Link } from "react-router-dom";

export function LoginForm() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const auth = useStoreSelector((store) => store.auth);
  const [passwordButtonType, setPasswordButtonType] = useState<"text" | "password">("password");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = new FormData(e.currentTarget);
    dispatch(
      asyncLoginRequest({
        email: payload.get("email") as string,
        password: payload.get("password") as string,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="space-y-1">
          <div className="flex gap-1 font-bold text-missilegray dark:text-gray-300">
            <span>{t("email-address")}</span>
            <span className="text-missileaccent">*</span>
          </div>
          <input
            type="email"
            name="email"
            required
            className="w-full rounded-full border border-missilestroke bg-transparent px-5 py-3 leading-5 text-missilegray dark:text-gray-300"
            placeholder="Your email address"
          />
        </label>
      </div>
      <div>
        <label className="space-y-1">
          <div className="flex gap-1 font-bold text-missilegray dark:text-gray-300">
            <span>{t("password")}</span>
            <span className="text-missileaccent">*</span>
          </div>
          <div className="relative">
            <input
              type={passwordButtonType}
              name="password"
              autoComplete="current-password"
              required
              minLength={3}
              maxLength={255}
              className="w-full rounded-full border border-missilestroke bg-transparent px-5 py-3 leading-5 text-missilegray dark:text-gray-300"
              placeholder="Your password"
            />
            <button
              className="absolute bottom-0 right-3 top-0 flex size-8 h-full items-center justify-center rounded-full"
              type="button"
              onClick={() => setPasswordButtonType((t) => (t === "password" ? "text" : "password"))}
            >
              {passwordButtonType === "password" ? <EyeClosedIcon /> : <EyeIcon />}
            </button>
          </div>
        </label>
      </div>

      {auth.requester === "login" && auth.errorMessage && (
        <div className="my-3 text-missileaccent">{auth.errorMessage}</div>
      )}

      <div className="flex justify-end">
        <Link to="/forgot-password" className="text-sm font-bold leading-5 underline hover:underline">
          {t("forgot-password")}
        </Link>
      </div>

      <RoundedButton
        className="hover:missileaccent/90 mx-auto w-full max-w-96 bg-missileaccent font-semibold text-white"
        type="submit"
        loading={auth.requester === "login" && auth.loading}
      >
        {t("log-in")}
      </RoundedButton>
    </form>
  );
}
