import { Link } from "react-router-dom";
import type { RRStateErrorCode } from "../../../hooks/useRocketRecord/types";
import { RocketRecordContext } from "../../../context/RocketRecordContext";
import { shallowEqual } from "react-redux";
import { useContext, useState } from "react";
import { isChromeiOS } from "../../../utils/browser";
import { sleep } from "../../../utils";
import { toast } from "react-toastify";
import APIFormData from "../../../res/APIFormData";
import API from "../../../res/Api";
import type { DebugRecordPayload } from "@rocketlanguages/types";
import { detect } from "detect-browser";
import { PhraseboxContext } from "../context";

/**
 * Renders any error text produced by Rocket Record
 */
export function ErrorText() {
  const context = useContext(PhraseboxContext);

  if (!context?.rocketRecord) {
    return null;
  }

  const state = context?.rocketRecord.useRocketRecordState(
    (state) => ({
      flag: state.flag,
      errorTally: state.errorTally,
      non100PctTally: state.non100PctTally,
    }),
    shallowEqual,
  );

  if (!state || !context) {
    return null;
  }

  const { flag, errorTally, non100PctTally } = state;

  if (non100PctTally >= 3 && state.flag.status === "INACTIVE") {
    return (
      <div className="flex items-center font-semibold text-missileaccent">
        <span>
          {"Still can't get it? "}
          <ReportThisPhraseButton />
        </span>
      </div>
    );
  }

  if (flag.status !== "ERROR") {
    return null;
  }

  if (errorTally >= 3) {
    // Speech recognition worked, but nothing was recognized
    if (flag.errorCode === "ERROR_NO_MATCH") {
      return (
        <div className="flex items-center font-semibold text-missileaccent">
          <span>
            {"Having problems? "}
            <ReportThisPhraseButton />
            {" or "}
            <Link to="/members/contact-us" className="underline hover:text-rocketred-dark">
              contact us
            </Link>
          </span>
        </div>
      );
    }
    // Speech recognition likely didn't work at all
    return (
      <div className="flex items-center font-semibold text-missileaccent">
        <span>
          {"Having problems? "}
          <Link to="/members/contact-us" className="underline hover:text-rocketred-dark">
            Let us know!
          </Link>
        </span>
      </div>
    );
  }
  if (errorTally >= 3 && flag.errorCode === "ERROR_NO_MATCH") {
    return (
      <div className="flex items-center font-semibold text-missileaccent">
        <span>
          {"Having problems? "}
          <ReportThisPhraseButton />
          {" or "}
          <Link to="/members/contact-us" className="underline hover:text-rocketred-dark">
            contact us
          </Link>
        </span>
      </div>
    );
  }

  if (flag.errorCode === "ERROR_UNAVAILABLE") {
    const message = `Your browser does not support speech recognition. We recommend using ${
      isChromeiOS ? "Safari on your iOS device or Chrome on desktop" : "Chrome"
    } in order to use our speech recognition feature.`;
    return <span className="font-semibold text-missileaccent">{message}</span>;
  }

  if (["ERROR_NO_MATCH", "ERROR_SPEECH_INPUT"].includes(flag.errorCode)) {
    return (
      <span className="font-semibold text-missileaccent">
        <span>No speech detected, check your mic settings or&nbsp;</span>
        <Link to="/members/help#225" className="underline hover:text-rocketred-dark">
          our FAQs.
        </Link>
      </span>
    );
  }

  const message = errorMessages[flag.errorCode] || `Sorry, I didn't catch that.`;

  return (
    <span className="font-semibold text-missileaccent">
      <span>{message} Please&nbsp;</span>
      <button
        type="button"
        className="underline hover:text-rocketred-dark"
        onClick={context.rocketRecord.methods.start}
      >
        try again.
      </button>
    </span>
  );
}

function ReportThisPhraseButton() {
  const context = useContext(RocketRecordContext);
  const [submittingFeedback, setSubmittingFeedback] = useState(false);

  const handleSubmitFeedback = async () => {
    setSubmittingFeedback(true);
    const browser = detect();

    const currentState = context?.useRocketRecordState.getState();
    const currentResult = currentState?.result;
    const phrase = currentState?.phrase;

    const isMockupMode = Boolean(process.env.root);

    if (!phrase || isMockupMode) {
      sleep(1000).then(() => {
        toast.success("Thank you for your feedback!");
        setSubmittingFeedback(false);
        context?.useRocketRecordState.setState({ non100PctTally: 0 });
      });
      return;
    }

    const data = new APIFormData<DebugRecordPayload>({
      device_name: browser?.name || "unknown",
      device_version: browser?.version || "",
      percentage: currentResult?.percentage || 0,
      best_result: currentResult?.rawTranscription?.toString() || "",
    });

    await API.post(["v2/phrase/{phrase}/debug-record", { phrase: phrase.id }], data);

    toast.success("Thank you for your feedback!");
    setSubmittingFeedback(false);
    context?.useRocketRecordState.setState({ non100PctTally: 0 });
  };

  return (
    <button
      type="button"
      className="underline hover:text-rocketred-dark disabled:opacity-50"
      disabled={submittingFeedback}
      onClick={handleSubmitFeedback}
    >
      Report this phrase
    </button>
  );
}

const errorMessages: { [K in RRStateErrorCode]?: string } = {
  ERROR_NETWORK: "There seems to be a network problem.",
  ERROR_BUSY: "Looks like speech recognition is currently busy.",
  ERROR_PERMISSIONS: "We can't access your microphone. Check your permissions.",
  ERROR_START_RECOGNITION_TIMEOUT: "Speech recognition wasn't able to start.",
  ERROR_START_RECORDING_TIMEOUT: "There was an issue trying to start recording.",
  ERROR_DEVICE_INPUT: "Unable to start speech recognition. Please check if you have a working microphone connected.",
  ERROR_TRANSCRIPTION: "Whoops! We were unable to transcribe your recording.",
  ERROR_NO_SOUND:
    "No sound was detected from your microphone. Please check if your microphone is connected and the volume is turned up.",
  ERROR_AUDIO: "Unable to access your device's microphone.",
};
