import { type AnchorHTMLAttributes, type ComponentType, forwardRef, type ReactNode, useMemo, useState } from "react";
import { type ColorScheme, useColorScheme } from "~/hooks/useColorScheme";
import { Search as SearchIcon, Settings as SettingsIcon } from "iconoir-react";
import { Play as PlayIcon } from "iconoir-react/solid";
import {
  FiCheck,
  FiChevronLeft,
  FiChevronRight,
  FiLock,
  FiLogOut,
  FiMail,
  FiMenu,
  FiMoon,
  FiStar,
  FiUser,
  FiX,
} from "react-icons/fi";
import {
  ImageFrame,
  PlaybackSpeedDialog,
  Popup,
  PopupFacelift,
  SimpleTooltip,
  Switch,
  TooltipButton,
} from "@rocketlanguages/ui";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import DynamicLink from "~/components/DynamicLink";
import type { IconType } from "react-icons";
import LanguageToggle from "../../../pages/members/lesson/includes/sections/LanguageToggle";
import { Link } from "react-router-dom";
import LogoIcon from "~/components/icons/LogoIcon";
import { OutlineButton } from "@rocketlanguages/ui/Button";
import ProgressWidget from "~/components/ui/ProgressWidget/ProgressWidget";
import { clsx } from "clsx";
import { isScriptLanguage } from "@rocketlanguages/shared/utils/constants";
import { logout } from "@rocketlanguages/shared/store/auth/actions";
import { omit } from "@rocketlanguages/shared/utils";
import { showSignupModal } from "../../../store/user/actions";
import styles from "./TopBar.module.scss";
import { toast } from "react-toastify";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import { useDispatch } from "react-redux";
import useHasPermission from "@rocketlanguages/shared/hooks/useHasPermission";
import useIsMackinUser from "@rocketlanguages/shared/hooks/useIsMackinUser";
import { useSidebar } from "~/components/ui/Sidebar.store";
import { useStoreSelector } from "../../../store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import useCanContactSupport from "@rocketlanguages/shared/hooks/useCanContactSupport";
import usePreference from "@rocketlanguages/shared/hooks/usePreference";
import useTimeout from "@rocketlanguages/shared/hooks/useTimeout";
import audioWaveformSmall from "@rocketlanguages/shared/components/Phrasebox/includes/res/audio-waveform-small";
import { usePricingLink } from "./TopBar.utils";

export function Topbar(props: { children: ReactNode }) {
  return (
    <>
      <div className="fixed z-10 h-16 w-full bg-missilebrand shadow-md sm:h-20 print:hidden">
        <header className="flex h-16 flex-1 items-center justify-between px-4 sm:h-20">{props.children}</header>
      </div>
      <div className="h-16 sm:h-20 print:hidden" />
    </>
  );
}

function SkipToContentButton() {
  return (
    <a
      href="#content"
      className={
        "text-md absolute z-10 h-10 translate-y-[-150px] bg-slate-100 px-4 py-2 focus:translate-y-0 dark:text-black"
      }
      tabIndex={0}
    >
      Skip to content
    </a>
  );
}

Topbar.LogoLink = function TopbarLogoLink(props: {
  /** Replaces the logo with a hamburger icon on mobile resolution */
  hasSidebar?: boolean;
  title: string;
  subtitle?: string;
  use: string | ComponentType<any>;
  path: string;
  onClick?: () => void;
}) {
  const { title, use: T = "a", path, subtitle, onClick, hasSidebar } = props;
  const user = useStoreSelector((store) => store.user);
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const isTrial = user.products.trial.some((_trial) => _trial.id === activeProduct?.id);
  const isGuest = useStoreSelector((store) => store.user.isGuest);

  const linkProps = path.startsWith("http") ? { href: path } : { to: path };

  return (
    <>
      <SkipToContentButton />
      <div className="flex gap-2">
        {hasSidebar && <SidebarMenuButton />}
        <T
          {...linkProps}
          className={clsx("h-[40px] items-center gap-2", hasSidebar ? "hidden sm:flex" : "flex")}
          onClick={onClick}
        >
          <span className={hasSidebar ? "hidden md:inline-block" : ""}>
            <LogoIcon />
          </span>
          <div className="h-[40px] flex-col justify-center sm:flex">
            <div>
              <h3 className="font-sans text-lg font-medium leading-5 text-white">{title}</h3>
            </div>
            <div className="text-xs font-medium leading-4 text-white">
              {subtitle ? (
                <span>{subtitle}</span>
              ) : (
                <>
                  {isGuest && <span>Guest User</span>}
                  {!isGuest && isTrial && <span>Trial</span>}
                </>
              )}
            </div>
          </div>
        </T>
      </div>
    </>
  );
};

/** Visible on mobile */
function SidebarMenuButton() {
  const sidebar = useSidebar();
  const isOpenMobile = sidebar.state === "open";
  const isOpenMd = !sidebar.state || sidebar.state === "open";
  return (
    <>
      <div className="block md:hidden">
        <NavItem
          use="button"
          icon={isOpenMobile ? <FiX size={24} /> : <FiMenu size={24} />}
          title="Open Menu"
          role="checkbox"
          active={isOpenMobile}
          aria-checked={isOpenMobile}
          onClick={() => sidebar.setState(isOpenMobile ? "closed" : "open")}
        />
      </div>
      <div className="hidden md:block lg:hidden">
        <NavItem
          className="block md:hidden"
          use="button"
          icon={sidebar.state === "open" ? <FiX size={24} /> : <FiMenu size={24} />}
          title="Open Menu"
          role="checkbox"
          active={sidebar.state === "open"}
          aria-checked={sidebar.state === "open"}
          onClick={() => sidebar.setState(isOpenMd ? "closed-md" : "open")}
        />
      </div>
    </>
  );
}

export function CoursesTopBar() {
  const activeProduct = useActiveProduct();
  const isGuest = useStoreSelector((store) => store.user.isGuest);
  return (
    <Topbar>
      <Topbar.LogoLink
        hasSidebar={false}
        use={Link}
        path={"/members/courses"}
        title="Rocket Languages"
        subtitle="Let's get talking"
      />
      <div className="flex items-center gap-4">
        {isGuest && activeProduct && (
          <div className="hidden sm:block">
            <SignupLink />
          </div>
        )}
        <AvatarCircle />
      </div>
    </Topbar>
  );
}

export function EbookTopBar() {
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);

  if (!activeProduct) {
    return null;
  }

  return (
    <Topbar>
      <Topbar.LogoLink
        hasSidebar
        use={Link}
        path={`/members/products/${activeProduct.id}/dashboard`}
        title={`Rocket ${activeCourse?.name || "Languages"}`}
      />

      <AvatarCircle />
    </Topbar>
  );
}

export function ActiveProductTopbar() {
  const t = useTranslation();
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const user = useStoreSelector((store) => store.user);
  const isASL = activeCourse?.slug === "sign-language";
  const isGuest = user.isGuest;

  const showPricing = useMemo(() => {
    if (isGuest) {
      return true;
    }
    return user.products.trial.some((_trial) => _trial.id === activeProduct?.id);
  }, [activeProduct?.id, isGuest, user.products.trial]);

  if (!activeProduct) {
    return null;
  }

  return (
    <Topbar>
      <Topbar.LogoLink
        hasSidebar
        use={Link}
        path={`/members/products/${activeProduct.id}/dashboard`}
        title={`Rocket ${activeCourse?.name || "Languages"}`}
      />
      <div className="flex items-center gap-3 sm:gap-5">
        <ProgressWidget className="hidden px-3 md:flex" />

        <SimpleTooltip content={"Search"} asChild>
          <NavItem
            use={Link}
            className="hidden sm:block"
            to={`/members/products/${activeProduct.id}/tools/search`}
            icon={<SearchIcon className="size-6" />}
            title={t("search")}
          />
        </SimpleTooltip>

        {!isASL && (
          <Popup
            id="topbar-settings"
            placement="bottom-left"
            offsetVertical={20}
            offsetHorizontal={60}
            triangle={false}
            className="flex"
            contentClassName="bg-surface2"
            trigger={<NavItem icon={<SettingsIcon className="size-6" />} title={t("settings")} />}
            content={<SettingsDialog />}
          />
        )}

        {showPricing && (
          <div className="hidden sm:block">
            <PricingButtonLink />
          </div>
        )}
        {isGuest && (
          <div className="hidden sm:block">
            <SignupLink />
          </div>
        )}
        <AvatarCircle />
      </div>
    </Topbar>
  );
}

function SettingsDialog() {
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const canViewLanguageToggle =
    Boolean(isScriptLanguage[activeCourse?.slug || "spanish"]) || activeProduct?.level_id === 4;
  const [rocketRecordSpeedDialogOpen, setRocketRecordSpeedDialogOpen] = useState(false);
  const [rocketRecordSpeed, setRocketRecordSpeed] = usePreference("rocket_record_speed", 1);

  return (
    <div className={clsx(styles.dropdown, styles.settingsDropdown, "flex min-w-[320px] flex-col gap-4 px-4 py-2")}>
      <div className="relative flex flex-1 items-center justify-between gap-1 font-semibold">
        <span className="flex items-center gap-2">
          Tutor Audio Speed
          <TooltipButton
            tooltipProps={{
              side: "bottom",
              className: "max-w-52",
            }}
          >
            <TutorAudioButtonTooltip />
          </TooltipButton>
        </span>
        <div className="relative">
          <button
            type="button"
            className="h-10 w-10 rounded-full border border-slate-900 text-sm font-semibold hover:bg-slate-100 dark:bg-neutral-200 dark:text-black"
            onClick={() => setRocketRecordSpeedDialogOpen(true)}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setRocketRecordSpeedDialogOpen(false);
              }
            }}
          >
            {rocketRecordSpeed || 1}x
          </button>
          {rocketRecordSpeedDialogOpen && (
            <PlaybackSpeedDialog
              options={[
                { label: "0.5x", value: 0.5 },
                { label: "0.75x", value: 0.75 },
                { label: "Normal", value: 1 },
              ]}
              className="right-0 top-12 border border-slate-400"
              value={Number(rocketRecordSpeed)}
              onClose={(v) => {
                if (v) {
                  setRocketRecordSpeed(v);
                }
                setRocketRecordSpeedDialogOpen(false);
              }}
            />
          )}
        </div>
      </div>

      {canViewLanguageToggle && <LanguageToggle />}

      <AdminSettings />
    </div>
  );
}

function TutorAudioButtonTooltip() {
  const [playing, setPlaying] = useState(false);
  useTimeout(() => setPlaying(true), 1000);
  useTimeout(() => setPlaying(false), 4000);
  return (
    <div className="flex gap-2">
      <button
        type="button"
        title="Play button"
        aria-label="Play button"
        className="flex size-10 min-h-10 min-w-10 items-center justify-center rounded-xl bg-missilebrand text-white"
        tabIndex={-1}
      >
        {playing ? (
          <div className="flex h-full w-full items-center justify-center">
            <img src={audioWaveformSmall} alt="soundwave" />
          </div>
        ) : (
          <PlayIcon />
        )}
      </button>

      <div className="flex items-center text-left">Changes the playback speed of the tutor audio</div>
    </div>
  );
}

function AdminSettings() {
  const dispatch = useDispatch();
  const debugEnabled = useStoreSelector((store) => store.preferences.debugEnabled);
  const hasEditPermissions = useHasPermission("edit_content");

  if (!hasEditPermissions) {
    return null;
  }

  return (
    <div className="mt-2 border-2 border-dashed border-yellow-600">
      <h5 className="font-bold">Admin</h5>
      <div className="flex items-center">
        <div className="flex flex-1 items-center gap-2">
          Debug mode
          <TooltipButton tooltipProps={{ className: "max-w-52" }}>
            Shows hidden lessons, notations, & Rocket Record debugging controls
          </TooltipButton>
        </div>
        <div>
          <Switch
            id="debug_mode"
            value={debugEnabled}
            action={() => dispatch({ type: "Preferences/DEBUG", payload: !debugEnabled })}
          />
        </div>
      </div>
    </div>
  );
}

/** Circular Avatar with profile dropdown */
export function AvatarCircle() {
  const t = useTranslation();
  const user = useStoreSelector((store) => store.user);
  const [profileOptionsOpen, setProfileOptionsOpen] = useState(false);

  const AvatarIcon =
    user.avatarUrl && !user.avatarUrl.includes("default-avatar.png") ? (
      <ImageFrame imageUrl={user.avatarUrl} size="extra-small" variant="shadowless" />
    ) : (
      <div className={"rounded-full p-2 text-white hover:bg-white hover:text-brand focus:bg-white focus:text-brand"}>
        <FiUser size={24} />
      </div>
    );

  return (
    <div className="relative flex items-center rounded-full">
      <button title={t("toggle-more")} onClick={() => setProfileOptionsOpen(!profileOptionsOpen)}>
        {AvatarIcon}
      </button>
      {profileOptionsOpen && (
        <PopupFacelift className="right-0 top-12" onClose={() => setProfileOptionsOpen(false)}>
          <div className={"flex w-64 flex-col py-2 font-medium"}>
            <ProfileOptionsDialog />
          </div>
        </PopupFacelift>
      )}
    </div>
  );
}

function ProfileOptionsDialog() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const canAccessAdminArea = useHasPermission("access_admin_area");
  const canContactSupport = useCanContactSupport();
  const activeProduct = useActiveProduct();
  const [colorScheme] = useColorScheme();
  const [appearanceOpen, setAppearanceOpen] = useState(false);

  if (appearanceOpen) {
    return <AppearanceDialog onClose={() => setAppearanceOpen(false)} />;
  }

  return (
    <>
      {canAccessAdminArea && (
        <DropdownItem
          label="Admin"
          icon={FiLock}
          link="https://staff.rocketlanguages.com"
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
      <ProfileDropdownItem />
      <DropdownItemButton
        label={
          <>
            <span className="flex-1 text-left capitalize">{`${t("appearance")}: ${t(colorScheme || "device")}`}</span>
            <FiChevronRight />
          </>
        }
        icon={FiMoon}
        onClick={() => setAppearanceOpen(true)}
      />
      {canContactSupport && activeProduct && activeProduct.id !== 261 && (
        <DropdownItem label={t("reviews")} icon={FiStar} link={`/members/products/${activeProduct.id}/reviews`} />
      )}
      <DropdownItem label={t("help-center")} link="/members/help" icon={AiOutlineQuestionCircle} />
      {canContactSupport && <DropdownItem label={t("contact-us")} link="/members/contact-us" icon={FiMail} />}
      <DropdownItem
        label={t("log-out")}
        link="/"
        icon={FiLogOut}
        onClick={() => {
          dispatch(logout(true));
          toast.success(t("logged-out"));
        }}
      />
    </>
  );
}

export function AppearanceDialog(props: { onClose(): void }) {
  const t = useTranslation();

  const [colorScheme, setColorScheme] = useColorScheme();

  const options: Array<{
    label: "use-device-theme" | "dark-mode" | "light-mode";
    value: ColorScheme;
  }> = [
    {
      label: "use-device-theme",
      value: "device",
    },
    {
      label: "dark-mode",
      value: "dark",
    },
    {
      label: "light-mode",
      value: "light",
    },
  ];

  const handleColorSchemeChange = (value: ColorScheme) => {
    if (colorScheme !== value) {
      document.querySelector("html")?.classList.remove("dark", "light");
      if (value !== "device") {
        document.querySelector("html")?.classList.add(value);
      }
      setColorScheme(value);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <button
          type="button"
          className="flex w-64 items-center border-b border-b-slate-200 px-4 py-3 pb-4 text-sm font-medium dark:border-b-neutral-700"
          onClick={props.onClose}
        >
          <FiChevronLeft aria-hidden className="mr-2 h-6 w-6 md:h-4 md:w-4" />
          <h4 className="font-sans text-sm font-semibold">{t("appearance")}</h4>
        </button>
      </div>
      <div className="w-full pt-2">
        {options.map(({ label, value }) => (
          <button
            key={value}
            type="button"
            role="checkbox"
            aria-checked={colorScheme === value}
            onClick={() => handleColorSchemeChange(value)}
            className="flex w-full items-center overflow-hidden px-4 py-2 hover:bg-brand2 dark:hover:bg-neutral-700"
          >
            <span aria-hidden className="mr-2 w-5">
              {colorScheme === value && <FiCheck />}
            </span>

            <span className="text-sm font-medium">{t(`appearance-${label}`)}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

function ProfileDropdownItem() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const isMackinUser = useIsMackinUser();
  const user = useStoreSelector((store) => store.user);
  const isGuest = user.isGuest;
  if (isMackinUser) {
    return null;
  }
  if (isGuest) {
    return <DropdownItemButton label={t("profile")} icon={FiUser} onClick={() => dispatch(showSignupModal())} />;
  }
  return <DropdownItem label={t("profile")} icon={FiUser} link="/members/profile" />;
}

type DropdownItemProps = {
  label: string;
  link: string;
  icon?: IconType;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const dropdownItemClass =
  "flex items-center hover:bg-missilestroke focus:bg-missilestroke dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 px-8 py-3 w-full text-sm";

function DropdownItem({ label, link, icon: Icon, ...rest }: DropdownItemProps) {
  return (
    <DynamicLink path={link} className={dropdownItemClass} {...rest}>
      {Icon && <Icon className="mr-2" size={22} />}
      {label}
    </DynamicLink>
  );
}

type DropdownItemButtonProps = {
  label: ReactNode;
  icon?: IconType;
  onClick: () => void;
};

function DropdownItemButton({ label, icon: Icon, onClick }: DropdownItemButtonProps) {
  return (
    <button type="button" className={dropdownItemClass} onClick={onClick}>
      {Icon && <Icon className="mr-2" size={22} />}
      {label}
    </button>
  );
}

type NavItemProps = {
  icon: JSX.Element;
  title: string;
  tooltip?: string;
  to?: string;
  active?: boolean;
  use?: string | ComponentType<any>;
} & React.HTMLAttributes<HTMLDivElement>;

const NavItem = forwardRef((props: NavItemProps, ref: any) => {
  const { use: T = "div", icon, title, className, active, ...rest } = props;
  return (
    <T
      ref={ref}
      title={title}
      className={clsx(
        styles.navItem,
        "flex rounded-full p-2 text-white hover:bg-missilesurfacedark hover:text-missilebrand focus:bg-missilesurfacedark focus:text-missilebrand dark:hover:text-white focus:dark:text-white",
        className,
        active && "ring-2 ring-sky-300",
      )}
      {...omit(rest, "tooltip", "children")}
    >
      {icon}
    </T>
  );
});

export function PricingButtonLink() {
  const link = usePricingLink();
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="text-nowrap rounded bg-missileaccent p-2 px-4 font-semibold text-white"
    >
      Buy Now
    </a>
  );
}

function SignupLink() {
  const t = useTranslation();
  const dispatch = useDispatch();

  return (
    <OutlineButton
      onClick={() => dispatch(showSignupModal())}
      color="secondary"
      size="small"
      className="whitespace-nowrap"
    >
      {t("sign-up")}
    </OutlineButton>
  );
}
