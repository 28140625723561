// Actions handled by Saga
export const ASYNC_LOGIN = "Auth/ASYNC_LOGIN";
export const ASYNC_SIGNUP = "Auth/ASYNC_SIGNUP";
export const ASYNC_MULTIUSER_SIGNUP = "Auth/ASYNC_MULTIUSER_SIGNUP";
export const ASYNC_GUEST_SIGNUP = "Auth/ASYNC_GUEST_SIGNUP";
// Regular actions
export const SUCCESS = "Auth/SUCCESS"; // Either login or sign up action
export const FAIL = "Auth/FAIL"; // Sign up or logins
export const HIDE_ERROR = "Auth/HIDE_ERROR"; // Removes the error popup
export const LOGOUT = "Auth/LOGOUT";
export const CLEAR_ERROR_MESSAGE = "Auth/CLEAR_ERROR_MESSAGE";
export const GUEST_AUTH_FAIL = "Auth/GUEST_AUTH_FAIL";
export const SET_TOKEN = "Auth/SET_TOKEN";
