import type { ButtonProps } from "@rocketlanguages/ui/Button/Button";
import { SquareButton } from "@rocketlanguages/ui";
import { clsx } from "clsx";

export default function KeyButton(props: ButtonProps) {
  return (
    <SquareButton
      {...props}
      color="grey"
      className={clsx(
        "border-2 border-missilestroke text-xl font-semibold text-brand dark:border-text1 dark:text-text1",
        props.disabled && "opacity-40",
        props.className,
      )}
    >
      {props.children}
    </SquareButton>
  );
}
