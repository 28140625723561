import { useRef, useEffect } from "react";

/**
 * Basically emulates "this" in a class
 *
 * Watches for changes in the props and re-assigns the current ref
 *
 * Useful for tracking the current value of a reactive value inside async functions
 */
export default function useCurrentRef<T>(props: T): React.RefObject<T> {
  const latestProps = useRef(props);

  useEffect(() => {
    latestProps.current = props;
  }, [props]);

  return latestProps;
}
