import DashboardPage, { RedirectToDashboardPage } from "./pages/members/dashboard";
import LessonPage from "./pages/members/lesson/$lessonId";
import RedirectToProductLessonPage from "./pages/members/course.$courseId.level.$levelId.lesson.$lessonId";
import MembersFaceliftLayout, { MembersFaceliftProductIdLayout } from "~/layouts/MembersLayout/MembersFaceliftLayout";
import { Navigate, Route, Routes } from "react-router-dom";

import API from "@rocketlanguages/shared/res/Api";
import AutoLoginPage from "./pages/autologin/autologin";
import CoursesPage from "./pages/members/courses/courses";
import DefaultLayout from "./layouts/DefaultLayout";
import ForgotPasswordPage from "./pages/forgot-password";
import LoginPage from "./pages/login/login";
import { MembersCourseSlugLevelIdLayout } from "./layouts/MembersLayout/MembersLayout";
import { RedirectToProgressPage } from "./pages/members/progress";
import RedirectToReviewsPage from "./pages/members/user_utils/reviews/includes/RedirectToReviewsPage";
import RedirectToWriteReviewPage from "./pages/members/user_utils/reviews/write/includes/RedirectToWriteReviewPage";
import SignupPage from "./pages/signup/signup";
import ToolsLayout from "~/layouts/ToolsLayout/ToolsLayout";
import UtilsLayout from "~/layouts/UtilsLayout";
import { fourOhThreeResponse } from "./store/auth/actions";
import lazyRoute from "@rocketlanguages/shared/utils/lazyRoute";
import { useDispatch } from "react-redux";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";
import { useEffect } from "react";

// Lazy imports
const RedemptionPage = lazyRoute(() => import("./pages/redeem/$licenceCode"));
const VocabPage = lazyRoute(() => import("./pages/members/tools/vocab/vocab"));
const LibraryPage = lazyRoute(() => import("./pages/library/$libraryHash"));
const UnsubscribePage = lazyRoute(() => import("./pages/newsletters.$courseId.unsubscribe.$email"));
const BenchmarkPage = lazyRoute(() => import("./pages/members/tools/benchmark"));
const ProfilePage = lazyRoute(() => import("./pages/members/user_utils/profile"));
const UserProfilePage = lazyRoute(() => import("./pages/members/user_utils/profile/[userId]"));
const RocketCertificationPage = lazyRoute(() => import("./pages/members/tools/rocket-certification"));
const NotesPage = lazyRoute(() => import("./pages/members/tools/notes"));
const RocketRecommendsPage = lazyRoute(() => import("./pages/members/tools/rocket-recommends"));
const ForumPage = lazyRoute(() => import("./pages/members/forum"));
const ForumLanguagePage = lazyRoute(() => import("./pages/members/forum/[languageslug]/[languageslug]"));
const ForumCreateThreadPage = lazyRoute(() => import("./pages/members/forum/[languageslug]/create"));
const ForumCategoryPage = lazyRoute(() => import("./pages/members/forum/[languageslug]/[categorySlug]/[categorySlug]"));
const ForumThreadPage = lazyRoute(
  () => import("./pages/members/forum/[languageslug]/[categorySlug]/[threadSlug]/[threadSlug]"),
);
const HelpPage = lazyRoute(() => import("./pages/members/user_utils/help"));
const ContactPage = lazyRoute(() => import("./pages/members/user_utils/contact-us"));
const ReviewsPage = lazyRoute(() => import("./pages/members/user_utils/reviews"));
const CreateReviewPage = lazyRoute(() => import("./pages/members/user_utils/reviews/write"));
const SearchPage = lazyRoute(() => import("./pages/members/tools/search"));
const FlashcardsPage = lazyRoute(() => import("./pages/members/flashcards"));
const FlashcardsCreatePage = lazyRoute(() => import("./pages/members/flashcards/create"));
const PhrasePage = lazyRoute(() => import("./pages/members/phrase/[phraseId]"));
const LeaderboardPage = lazyRoute(() => import("./pages/members/tools/leaderboard"));
const PasswordResetPage = lazyRoute(() => import("./pages/password-reset"));
const DictionaryPage = lazyRoute(() => import("./pages/members/tools/dictionary"));
const LessonIdPage = lazyRoute(() => import("./pages/members/lessons/$lessonId"));

export default function AppRoutes(): JSX.Element {
  const dispatch = useDispatch();
  // Track page navigation
  useGoogleAnalytics("UA-3355083-1");

  useEffect(() => {
    const id = API.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 403) {
          dispatch(fourOhThreeResponse());
        }
        return Promise.reject(error);
      },
    );
    return () => {
      API.axios.interceptors.response.eject(id);
    };
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/autologin" element={<AutoLoginPage />} />

      {/** Unauthenticated routes with login redirects */}
      <Route path="/" element={<DefaultLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="library/:libraryHash" element={<LibraryPage />} />
      </Route>

      {/** Unauthenticated routes without login redirects */}
      <Route path="/" element={<DefaultLayout preventLoginRedirect />}>
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="password-reset" element={<PasswordResetPage />} />
        <Route path="redeem/:licenceCode" element={<RedemptionPage />} />
        <Route path="newsletters/:courseId/unsubscribe/:email" element={<UnsubscribePage />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Route>

      <Route path="/members" element={<MembersFaceliftLayout />}>
        <Route path=":courseSlug/lessons/:lessonId/*" element={<LessonIdPage />} />
        <Route path="courses" element={<CoursesPage />} />
        <Route path="phrase/:phraseId" element={<PhrasePage />} />
        {/* Dynamic redirects */}
        <Route path="course/:courseId/level/:levelId/dashboard" element={<RedirectToDashboardPage />} />
        <Route path="course/:courseId/level/:levelId/progress" element={<RedirectToProgressPage />} />
        <Route path="course/:courseId/level/:levelId/lesson/:lessonId" element={<RedirectToProductLessonPage />} />
        <Route path="course/:courseId/level/:levelId/reviews" element={<RedirectToReviewsPage />} />
        <Route path="course/:courseId/level/:levelId/reviews/write" element={<RedirectToWriteReviewPage />} />
      </Route>

      {/** Utils layout. Includes a sidebar */}
      <Route path="/members" element={<UtilsLayout />}>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="help" element={<HelpPage />} />
        <Route path="contact-us" element={<ContactPage />} />
        <Route path="forum" element={<ForumPage />} />
        <Route path="products/:productId/reviews" element={<ReviewsPage />} />
        <Route path="products/:productId/reviews/write" element={<CreateReviewPage />} />
        <Route path="profile/:userId" element={<UserProfilePage />} />
      </Route>

      <Route path="/members/products/:productId" element={<MembersFaceliftProductIdLayout />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="lesson/:lessonId" element={<LessonPage />} />
        <Route path="" element={<Navigate to="dashboard" replace />} />
        <Route path="*" element={<Navigate to="dashboard" replace />} />
      </Route>

      <Route path="/members/products/:productId/tools" element={<ToolsLayout />}>
        <Route path="" element={<LeaderboardPage />} />
        <Route path="leaderboard" element={<LeaderboardPage />} />
        <Route path="benchmark" element={<BenchmarkPage />} />
        <Route path="rocket-certification" element={<RocketCertificationPage />} />
        <Route path="flashcards" element={<FlashcardsPage />} />
        <Route path="flashcards/create" element={<FlashcardsCreatePage />} />
        <Route path="flashcards/edit/:collectionId" element={<FlashcardsCreatePage />} />
        <Route path="vocab" element={<VocabPage />} />
        <Route path="notes" element={<NotesPage />} />
        <Route path="rocket-recommends" element={<RocketRecommendsPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="dictionary" element={<DictionaryPage />} />
      </Route>

      {/** Note: uses same layout as the tools layout */}
      <Route path="/members/forum/:languageSlug" element={<ToolsLayout />}>
        <Route path=":categorySlug/:threadSlug" element={<ForumThreadPage />} />
        <Route path=":categorySlug" element={<ForumCategoryPage />} />
        <Route path="" element={<ForumLanguagePage />} />
        <Route path="create" element={<ForumCreateThreadPage />} />
      </Route>

      {/* These come from links within lesson components, where the product may not be known */}
      <Route path=":courseSlug/level/:levelId" element={<MembersCourseSlugLevelIdLayout />}>
        <Route path="lesson/:lessonId" element={<LessonPage />} />
      </Route>
      <Route path="/members/:courseSlug/level/:levelId" element={<MembersCourseSlugLevelIdLayout />}>
        <Route path="lesson/:lessonId" element={<LessonPage />} />
      </Route>
    </Routes>
  );
}
