import type { CoursePreferences, UserPreferences, UserPreferencesFull } from "@rocketlanguages/types";
import { useSharedDispatch, useSharedSelector } from "../store";
import { asyncUpdateCoursePreferences, asyncUpdatePreference, updatePreferences } from "../store/user/actions";
import useActiveCourse from "./useActiveCourse";

/**
 * Note: doesn't sync with server. Use useSyncedPreference for that.
 */
export default function usePreference<TKey extends keyof UserPreferences>(
  key: TKey,
  defaultValue: UserPreferences[TKey],
): [UserPreferences[TKey], (newValue: UserPreferences[TKey]) => void] {
  const value = useSharedSelector((s) => s.user.preferences?.[key]) || defaultValue;
  const dispatch = useSharedDispatch();
  return [value, (newValue: UserPreferences[TKey]) => dispatch(updatePreferences({ [key]: newValue }))];
}

/** Retrieves a preference from the store and syncs the preference to the server when it changes. */
export function useSyncedPreference<TKey extends keyof UserPreferencesFull>(
  key: TKey,
  defaultValue: UserPreferencesFull[TKey],
): [UserPreferencesFull[TKey], (newValue: UserPreferencesFull[TKey]) => void] {
  const value = useSharedSelector((s) => s.user.preferences?.[key]) || defaultValue;
  const dispatch = useSharedDispatch();
  return [value, (newValue: UserPreferencesFull[TKey]) => dispatch(asyncUpdatePreference(key, newValue))];
}

/**
 * Casts a `"0"|"1"` preference to a boolean and syncs the preference to the server when it changes.
 */
export function useSyncedBooleanPreference<
  TKey extends keyof UserPreferencesFull,
  TValue extends UserPreferencesFull[TKey],
>(key: TKey, defaultValue: TValue extends "0" | "1" ? boolean : never): [boolean, (newValue: boolean) => void] {
  const value = useSharedSelector((s) => s.user.preferences?.[key]) || defaultValue;
  const dispatch = useSharedDispatch();

  const setBooleanValue = (newValue: boolean) => {
    const stringValue = newValue ? "1" : "0";
    dispatch(asyncUpdatePreference(key, stringValue));
  };

  return [value === "1", setBooleanValue];
}

/** Retrieves a course-specific preference from the store and syncs the preference to the server when it changes. */
export function useSyncedCoursePreference<
  TKey extends keyof CoursePreferences,
  TDefaultValue extends NonNullable<CoursePreferences[TKey]>,
>(
  key: TKey,
  defaultValue: TDefaultValue,
): [NonNullable<CoursePreferences[TKey]>, (newValue: CoursePreferences[TKey]) => void] {
  const courseId = useActiveCourse()?.id || 0;
  const value = useSharedSelector((s) => s.user.preferences?.courses?.[courseId]?.[key]) || defaultValue;

  const dispatch = useSharedDispatch();
  return [
    value,
    (newValue: CoursePreferences[TKey]) =>
      dispatch(
        asyncUpdateCoursePreferences({
          courseId,
          preferences: { [key]: newValue },
        }),
      ),
  ];
}

export function usePreferenceValue<TKey extends keyof UserPreferences>(
  key: TKey,
  defaultValue: UserPreferences[TKey],
): UserPreferences[TKey] {
  return useSharedSelector((s) => s.user.preferences?.[key] || defaultValue);
}
