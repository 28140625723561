import { getOrdinal } from "../../../../utils";
import useRandomCongratulationsMessage from "../../../../hooks/useRandomCongratulationsMessage";
import { useSharedSelector } from "../../../../store";

export default function CongratulationsMessage() {
  const leaderboardPosition = useSharedSelector((store) => store.user.stats.position);
  const congratsMessage = useRandomCongratulationsMessage();

  return (
    <div className="space-y-2 text-center">
      <div className="text-lg font-bold leading-6 text-missilebrand dark:text-white">{congratsMessage}</div>
      {!!leaderboardPosition && (
        <p className="text-sm leading-5 dark:text-white">
          You are now {getOrdinal(leaderboardPosition)} on the Rocket Languages leaderboard
        </p>
      )}
    </div>
  );
}
