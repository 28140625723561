import { useMemo } from "react";
import { useSharedSelector } from "../store";

/** Gets the first phrase object in the lesson and obtains the writing system ids */
export default function useWritingSystemIds(lessonId: number) {
  const firstPhrase = useSharedSelector((store) => {
    const firstPhraseId = store.lesson.entities.lesson_phrase_ids[lessonId]?.[0];
    if (!firstPhraseId) {
      return null;
    }
    return store.lesson.entities.phrases[firstPhraseId];
  });

  return useMemo(() => firstPhrase?.strings.map((ps) => ps.writing_system_id) || [], [firstPhrase]);
}
