import type { Phrase } from "@rocketlanguages/types";
import type useRocketRecord from "../../hooks/useRocketRecord/useRocketRecord";
import { type MutableRefObject, createContext } from "react";

type PhraseboxContextValue = {
  rocketRecord: ReturnType<typeof useRocketRecord>;
  phrase: Phrase;
  phraseAudioRef: MutableRefObject<HTMLAudioElement | null>;
  replayAudioRef: MutableRefObject<HTMLAudioElement | null>;
};

export const PhraseboxContext = createContext<PhraseboxContextValue | null>(null);
