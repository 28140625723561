import { call, put, select, takeEvery, SagaReturnType, takeLatest } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import * as constants from "./constants";
import * as actions from "./actions";
import * as lessonActions from "../lesson/actions";
import API from "../../res/Api";
import { SharedRootState } from "../types";

function* requestModuleTests(action: ActionType<typeof actions.sagaRequestModuleTests>) {
  try {
    const request = () =>
      API.get(["v2/grouped-test/module/{module}/tests", { module: action.payload.moduleId }], {
        num_components_per_test: action.payload.numComponentsPerTest,
      });
    const res: SagaReturnType<typeof request> = yield call(request);
    yield put(actions.moduleTestsRequestSuccess({ moduleId: action.payload.moduleId, data: res.data }));
  } catch (error) {
    yield put(
      actions.moduleTestsRequestError({
        moduleId: action.payload.moduleId,
        errorText: "Unknown error",
      }),
    );
    console.warn(error);
  }
}

function* requestPageData(action: ActionType<typeof actions.sagaRequestPageData>) {
  try {
    const request = () => API.get(["v2/product/{product}/module-page-data", { product: action.payload.productId }]);
    const res: SagaReturnType<typeof request> = yield call(request);
    yield put(actions.pageRequestSuccess({ productId: action.payload.productId, data: res.data }));
  } catch (error) {
    yield put(
      actions.pageRequestError({
        productId: action.payload.productId,
        errorText: "Unknown error",
      }),
    );
    console.warn(error);
  }
}

/**
 * Rates a test after the test has completed (e.g. Quiz)
 */
function* rateSubtest(action: ActionType<typeof actions.sagaRateSubtest>) {
  try {
    const { rateableTestId, rating, testTypeId } = action.payload;

    const request = () =>
      API.post([
        "v2/rate/grouped-test/{rateableTest}/{rateableTestType}/{value}",
        {
          rateableTest: rateableTestId,
          rateableTestType: testTypeId,
          value: rating,
        },
      ]);

    yield call(request);
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Rates the whole module test
 */
function* rateTest(action: ActionType<typeof actions.sagaRateTest>) {
  const { productId, moduleId } = action.payload;
  const entitiesSelector = (store: SharedRootState) => store.moduletest.entities;

  const entities: ReturnType<typeof entitiesSelector> = yield select(entitiesSelector);

  const module = entities.modules[moduleId];

  if (!module?.rateable_test_id) {
    return;
  }

  const subTestRatings = entities.user_module_subtest_ratings[module.id] || {};

  // Average out ratings from subtest
  const testRatingValues = Object.values(subTestRatings);

  const averageRating = testRatingValues.reduce((acc, curr) => acc + curr!.value, 0) / testRatingValues.length;
  console.log("Average rating from subtests", testRatingValues, ": ", averageRating);

  // Send API request to rate test
  yield put(
    lessonActions.requestRateTest({
      productId,
      // lessonId,
      rateableTestId: module.rateable_test_id,
      rating: averageRating,
    }),
  );

  // Optimistic update locally
  yield put(
    actions.setTestRating({
      productId,
      rateableTestId: module.rateable_test_id,
      rating: averageRating,
    }),
  );
}

// Any watchers go in here. They get forked in the Root Saga
export default [
  takeEvery(constants.SAGA_REQUEST_PAGE_DATA, requestPageData),
  takeEvery(constants.SAGA_REQUEST_MODULE_TESTS, requestModuleTests),
  takeLatest(constants.SAGA_RATE_SUBTEST, rateSubtest),
  takeLatest(constants.SAGA_RATE_TEST, rateTest),
];
