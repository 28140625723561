import { clsx } from "clsx";
import classes from "./LoadingBar.module.scss";

interface LoadingBarProps {
  variant?: "page";
  className?: string;
}

export default function LoadingBar(props: LoadingBarProps) {
  return (
    <div
      role="progressbar"
      className={clsx(classes.loadingBar, props.variant === "page" && classes.page, props.className)}
    />
  );
}
