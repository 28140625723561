import { Button, SquareButton } from "@rocketlanguages/ui";
import { clsx } from "clsx";
import useCanMarkAsComplete from "../../../../hooks/useCanMarkAsComplete";
import useTranslation from "../../../../hooks/useTranslation";
import { RateableTestMetadata } from "../../../../utils/constants-web";
import { RateableTestIconWithoutTestData } from "../Header";

interface InstructionsHotkeysProps {
  rateableTestTypeId: number;
  play?: boolean;
  record?: boolean;
  reveal?: boolean;
  replay?: boolean;
  ratings?: boolean;
  icon?: boolean;
}

export default function InstructionsHotkeys({
  rateableTestTypeId,
  play,
  record,
  reveal,
  replay,
  ratings,
  icon,
}: InstructionsHotkeysProps) {
  const Icon = RateableTestMetadata[rateableTestTypeId]?.icon;
  const t = useTranslation();
  const canMarkAsComplete = useCanMarkAsComplete();
  const buttonClasses = "text-brand font-bold border-brand border-2 dark:text-gray-300 dark:border-gray-500";
  return (
    <div>
      <h4 className="mb-4">{t("hotkeys")}</h4>
      {play && (
        <div className="mt-2 flex items-center">
          <div className="mr-2">
            <SquareButton color="grey" className={buttonClasses}>
              P
            </SquareButton>
          </div>
          {t("play-tutor-audio")}
        </div>
      )}
      {record && (
        <div className="mt-2 flex items-center">
          <div className="mr-2">
            <SquareButton color="grey" className={buttonClasses}>
              R
            </SquareButton>
          </div>
          {t("record-yourself")}
        </div>
      )}
      {replay && (
        <div className="mt-2 flex items-center">
          <div className="mr-2">
            <SquareButton color="grey" className={buttonClasses}>
              L
            </SquareButton>
          </div>
          Replay Recording
        </div>
      )}
      {reveal && (
        <div className="mt-2 flex items-center">
          <div className="mr-2">
            <Button color="grey" className={clsx(buttonClasses, "h-[48px] rounded-md")}>
              Enter
            </Button>
          </div>
          {t("reveals-phrase")}
        </div>
      )}
      {ratings && (
        <div className="mt-2 flex items-center">
          <div className="mr-2">
            <SquareButton color="grey" className={buttonClasses}>
              1
            </SquareButton>
          </div>
          <div className="mr-2">
            <SquareButton color="grey" className={buttonClasses}>
              2
            </SquareButton>
          </div>
          <div className="mr-2">
            <SquareButton color="grey" className={buttonClasses}>
              3
            </SquareButton>
          </div>
          {t("rate-as-easy-good-or-hard")}
        </div>
      )}
      {Icon && icon && canMarkAsComplete && (
        <div className="mt-2 flex items-center">
          <div className="mr-2">
            <RateableTestIconWithoutTestData rating={0} icon={Icon} />
          </div>
          {t("mark-as-complete-instructions")}
        </div>
      )}
    </div>
  );
}
