import { history } from "@rocketlanguages/shared/res/router";
import { useEffect } from "react";

export default function useGoogleAnalytics(trackingId: string): void {
  useEffect(() => {
    const unlisten = history.listen(({ location }) => {
      if ("gtag" in window && import.meta.env.PROD && typeof window.gtag === "function") {
        window.gtag("config", trackingId, { page_path: location.pathname });
      }
    });

    return unlisten;
  }, [trackingId]);
}
