import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import Courses from "@rocketlanguages/shared/res/courses";
import type { Product } from "@rocketlanguages/types";
import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { useStoreSelector } from "~/store";
import type { RootState } from "~/store/types";

const productLevelLabel: Record<number, string | undefined> = {
  1: "Level 1",
  2: "Level 2",
  3: "Level 3",
  4: "Travelogue",
  5: "Verb Trainer",
  6: "Language & Culture",
  9: "Audio Book",
  10: "Play the Part",
};

const userProductsSelector = createSelector(
  [(state: RootState) => state.user, (state: RootState) => state.preferences.activeCourse],
  (user, activeCourse): { paid: Product[]; trial: Product[] } => {
    // If a user has an active course, get all products from the course
    const { products } = user;
    if (!activeCourse || !activeCourse.id || !products) {
      return { paid: [], trial: [] };
    }

    const courseId = activeCourse.id;
    const foundCourse = Courses.find((obj) => obj.id === courseId);

    // Course doesn't exist
    if (!foundCourse) {
      return { paid: [], trial: [] };
    }

    const paidBasicProducts = products.paid.filter((product) => product.course_id === courseId);
    const trialBasicProducts = products.trial.filter((product) => product.course_id === courseId);

    // Return full product information from basic products
    return {
      paid: foundCourse.products.filter((product) =>
        paidBasicProducts.some((basicProduct) => basicProduct.id === product.id),
      ),
      trial: foundCourse.products.filter((product) =>
        trialBasicProducts.some((basicProduct) => basicProduct.id === product.id),
      ),
    };
  },
);

const useSidebarProducts = () => {
  const userProducts = useStoreSelector(userProductsSelector, shallowEqual);
  const activeCourse = useActiveCourse();
  const activeProduct = useActiveProduct();
  const t = useTranslation();

  return useMemo(() => {
    if (!activeCourse) {
      return [];
    }
    const getFilteredProducts = (opts: { arr: Product[]; isTrial: boolean }) => {
      const filtered: { label: string; product: Product; isActive: boolean }[] = [];

      for (const product of opts.arr) {
        /* level 7 = downloadable ebook */
        if (product.level_id === 7 || !productLevelLabel[product.level_id]) {
          continue;
        }

        const baseLabel =
          product.level_id <= 3
            ? t("level-{number}", { number: String(product.level_id) || "1" })
            : productLevelLabel[product.level_id];

        filtered.push({
          label: baseLabel || "",
          product,
          isActive: Boolean(activeProduct && product.id === activeProduct.id),
        });
      }

      return filtered;
    };

    const paidProducts = getFilteredProducts({ arr: userProducts.paid, isTrial: false });
    const trialProducts = getFilteredProducts({ arr: userProducts.trial, isTrial: true });

    return [...paidProducts, ...trialProducts].sort((a, b) => a.product.level_id - b.product.level_id);
  }, [activeCourse, activeProduct, userProducts, t]);
};

export default useSidebarProducts;
