import { keyCodes as defaultKeys, keyboardLocales } from "../utils/keymaps";
import { emulatedBackspace, emulatedDelete, getNewTextValue } from "../utils";
import { type MouseEvent, type RefObject, memo, useCallback, useEffect, useState } from "react";
import KeyButton from "./KeyButton";
import type { KeyboardState } from "../WriteItFacelift";
import type { Course, SetStateFunction } from "@rocketlanguages/types";
import { clsx } from "clsx";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import type usePhraseTest from "../../../../hooks/usePhraseTest/usePhraseTest";
import { BreakpointComponent } from "../../../BreakpointComponent";
import { Check as CheckIcon } from "iconoir-react";

type Props = {
  phraseTest: ReturnType<typeof usePhraseTest>;
  state: KeyboardState;
  setState: SetStateFunction<KeyboardState>;
  textRef: React.MutableRefObject<HTMLTextAreaElement | null>;
};

export function VirtualKeyboard({ textRef, phraseTest, state }: Props) {
  const activeCourse = useActiveCourse();
  const [casing, setCasing] = useState<"lower" | "upper">("lower");
  const [currentKey, setCurrentKey] = useState("");
  const [virtualDisabled, setVirtualDisabled] = useState(false);
  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  const locale: string = activeCourse?.locale ? activeCourse?.locale : "default";

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      element.value = "";
    }
  }, [phraseTest.state.index, textRef]);

  useEffect(() => {
    // Refocus the text input after the answer is revealed
    if (!didReveal) {
      setTimeout(() => {
        textRef.current?.focus();
      }, 50);
    }
  }, [didReveal, textRef]);

  const handleKeyClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const languageKey = ev.currentTarget.dataset.languageKey;
      if (!languageKey) {
        return;
      }
      setCurrentKey("");
      if (languageKey === "Shift") {
        setCasing(casing === "lower" ? "upper" : "lower");
        return;
      }
      if (textRef.current) {
        const { selectionStart, value } = getNewTextValue({
          textRef,
          textInput: textRef.current.value,
          value: languageKey,
        });
        textRef.current.value = value;
        textRef.current.setSelectionRange(selectionStart, selectionStart);
        textRef.current?.focus();
      }
    },
    [casing, textRef],
  );

  return (
    <>
      <div className="flex items-center gap-2">
        <button
          type="button"
          className="flex size-8 items-center justify-center rounded-xl border border-missilestroke text-missilebrand"
          onClick={() => {
            setVirtualDisabled(!virtualDisabled);
          }}
        >
          {virtualDisabled ? <CheckIcon strokeWidth={2} /> : null}
        </button>
        <div className="text-sm">Use native keyboard</div>
      </div>
      <TextInput
        course={activeCourse}
        disabled={state.submitted}
        casing={casing}
        keyboardOnly={virtualDisabled}
        locale={locale}
        textRef={textRef}
        onKeyDown={setCurrentKey}
        onCasingChange={setCasing}
      />
      <BreakpointComponent mediaQuery="(min-width: 768px)">
        {!state.submitted && !virtualDisabled ? (
          <Keyboard currentKey={currentKey} casing={casing} locale={locale} onKeyClick={handleKeyClick} />
        ) : null}
      </BreakpointComponent>
    </>
  );
}

const TextInput = memo(function TextInput({
  course,
  disabled,
  keyboardOnly,
  locale,
  casing,
  onCasingChange,
  onKeyDown,
  textRef,
}: {
  textRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  disabled?: boolean;
  course: Course | undefined;
  keyboardOnly?: boolean;
  casing: "lower" | "upper";
  locale: string;
  onCasingChange: (casing: "lower" | "upper") => void;
  onKeyDown: (key: string) => void;
}) {
  const keydownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== "Enter") {
        e.nativeEvent.stopImmediatePropagation();
      }

      // Handle casing
      if (e.shiftKey && casing === "lower") {
        onCasingChange("upper");
      } else if (!e.shiftKey && casing === "upper") {
        onCasingChange("lower");
      }

      if (e.ctrlKey || e.key === "Shift") {
        return;
      }

      if (e.key === "CapsLock") {
        if (casing === "lower") {
          onCasingChange("upper");
        } else {
          onCasingChange("lower");
        }
      }

      const keyValue = (() => {
        if (keyboardLocales[locale]?.[casing][e.nativeEvent.code]) {
          return keyboardLocales[locale]?.[casing][e.nativeEvent.code];
        }
        if (e.key === "Backspace" || e.key === "Delete" || e.key === " ") {
          return e.key;
        }
        return null;
      })();
      if (keyValue === "Backspace") {
        e.preventDefault();
        updateTextInputValue(
          textRef,
          emulatedBackspace({
            textElement: textRef.current,
            textInput: textRef.current?.value || "",
          }),
        );
      } else if (keyValue === "Delete") {
        e.preventDefault();
        updateTextInputValue(
          textRef,
          emulatedDelete({
            textElement: textRef.current,
            textInput: textRef.current?.value || "",
          }),
        );
      } else if (keyValue) {
        e.preventDefault();
        updateTextInputValue(
          textRef,
          getNewTextValue({
            textRef,
            textInput: textRef.current?.value || "",
            value: keyValue,
          }),
        );
      }

      onKeyDown(e.key);
    },
    [casing, locale, onCasingChange, onKeyDown, textRef],
  );

  if (keyboardOnly) {
    return (
      <textarea
        ref={textRef}
        className="min-h-[115px] w-full resize-y rounded-2xl border border-missilesurfacedark bg-transparent px-6 py-4 font-serif text-sm font-semibold placeholder:font-sans placeholder:text-missilegray placeholder:text-slate-300 dark:focus:outline-none dark:focus:ring-2"
        name="Write It Textbox"
        placeholder={`Write it in ${course?.slug === "russian" ? "Cyrillic" : course?.name} here`}
        disabled={disabled}
        spellCheck={false}
      />
    );
  }

  return (
    <textarea
      ref={textRef}
      className="min-h-[115px] w-full resize-y rounded-2xl border border-missilesurfacedark bg-transparent px-6 py-4 font-serif text-sm font-semibold placeholder:font-sans placeholder:text-missilegray placeholder:text-slate-300 dark:focus:outline-none dark:focus:ring-2"
      name="Write It Textbox"
      placeholder={`Write it in ${course?.slug === "russian" ? "Cyrillic" : course?.name} here`}
      disabled={disabled}
      spellCheck={false}
      autoFocus
      onKeyDown={keydownHandler}
      onKeyUp={(e) => {
        if (e.key === "Shift") {
          onCasingChange(casing === "lower" ? "upper" : "lower");
        }
      }}
    />
  );
});

function updateTextInputValue(
  textRef: RefObject<HTMLTextAreaElement>,
  params: { selectionStart: number; value: string },
) {
  const { selectionStart, value } = params;
  if (textRef.current) {
    textRef.current.value = value;
    textRef.current.setSelectionRange(selectionStart, selectionStart);
    textRef.current?.focus();
  }
}

const Keyboard = memo(function MemoizedKeyboard(props: {
  casing: "upper" | "lower";
  locale: string;
  currentKey: string;
  onKeyClick: (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  const { casing, currentKey, locale, onKeyClick } = props;
  const keyboard = [];

  for (const [key, value] of defaultKeys) {
    keyboard.push(
      <div key={key} className="flex w-full justify-center">
        {value.map((_value: string) => {
          const defaultKey = keyboardLocales.default?.[casing][_value];
          const languageKey = keyboardLocales[locale]?.[casing][_value];
          const isActive = currentKey === defaultKey || (languageKey === "Shift" && casing === "upper");

          if (!defaultKey || !languageKey) {
            return null;
          }

          return (
            <Key
              key={_value}
              defaultKey={defaultKey}
              languageKey={languageKey}
              isActive={isActive}
              onClick={onKeyClick}
            />
          );
        })}
      </div>,
    );
  }

  return <>{keyboard}</>;
});

const excludedKeys = new Set(["!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "/", "?", "\\", "|"]);

interface KeyProps {
  defaultKey: string;
  languageKey: string;
  onClick: (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isActive: boolean;
}

function Key({ defaultKey, languageKey, onClick, isActive }: KeyProps) {
  return (
    <KeyButton
      data-language-key={languageKey}
      // @ts-ignore
      onClick={onClick}
      active={isActive}
      className={clsx(
        "m-0.5",
        !Number.isNaN(Number(defaultKey)) && "invisible",
        excludedKeys.has(defaultKey) && "invisible",
        defaultKey === "-" && "invisible",
        defaultKey === "=" && "invisible",
      )}
    >
      <div className="relative m-0.5 flex h-full w-full items-center justify-center">
        <div className="absolute right-0 top-0 text-xs">{defaultKey.length > 1 ? "" : defaultKey}</div>
        <div className={clsx("font-bold", languageKey === "Shift" && "text-sm")}>{languageKey}</div>
      </div>
    </KeyButton>
  );
}
