import type {
  LessonComponent,
  MarkdownComponent as MarkdownComponentType,
  MarkdownTipType,
} from "@rocketlanguages/types";
import { memo, useContext } from "react";
import LessonContext from "../../../context/LessonContext";
import RocketMarkdown from "./includes/RocketMarkdown";
import TravelogueMarkdownComponent from "./includes/TravelogueMarkdown";
import { clsx } from "clsx";
import styles from "./MarkdownComponent.module.scss";
import useCustomMarkdownTitleBodyParser from "./useCustomMarkdownTitleBodyParser";
import { GraduationCap as GraduationCapIcon, LightBulbOn as LightBulbOnIcon } from "iconoir-react";

export type Props = {
  lessonComponent: LessonComponent<"markdown", MarkdownComponentType>;
  /** (Used for Travelogue lessons on non-english components) */
  translation?: boolean;
};

export const MarkdownComponent = memo((props: Props) => {
  const { lessonComponent, translation } = props;
  const { component } = lessonComponent;

  // Custom markdown types include an icon, a title & body parser with custom layout rules
  if (component.type !== "default") {
    return (
      <CustomMarkdownTip isTranslation={translation} componentType={component.type}>
        {component.content}
      </CustomMarkdownTip>
    );
  }

  return (
    <MarkdownBody isTranslation={translation} componentType={component.type}>
      {component.content}
    </MarkdownBody>
  );
});

type CustomMarkdownTipProps = {
  children: string;
  componentType: MarkdownTipType;
  isTranslation?: boolean;
};

function CustomMarkdownTip({ children, isTranslation, componentType }: CustomMarkdownTipProps) {
  const markdown = useCustomMarkdownTitleBodyParser(children);

  return (
    <div className={"rounded-2xl bg-rocketpurple-extra-light p-6 dark:bg-slate-800"}>
      {markdown.title ? (
        <div className={clsx(styles.markdown, "mb-0 flex items-center pb-2")}>
          {componentType === "tip" && <LightBulbOnIcon strokeWidth={2} className="mr-2" />}
          {componentType === "alt" && <GraduationCapIcon strokeWidth={2} className="mr-2" />}
          <RocketMarkdown>{markdown.title}</RocketMarkdown>
        </div>
      ) : null}
      {markdown.body && (
        <MarkdownBody componentType={componentType} isTranslation={isTranslation}>
          {markdown.body}
        </MarkdownBody>
      )}
    </div>
  );
}

function MarkdownBody({
  componentType,
  isTranslation,
  children,
}: {
  componentType: MarkdownTipType;
  isTranslation?: boolean;
  children: string;
}) {
  const isTravelogue = useContext(LessonContext).isTravelogue;
  return (
    <div
      className={clsx(
        styles.markdown,
        componentType !== "default" && [styles[`markdown-${componentType}`], "dark:bg-slate-800 print:p-0"],
        isTranslation && styles.translation,
      )}
      // Used by Add to Vocab for selection DOM identification
      data-markdown-travelogue={isTravelogue ? true : undefined}
    >
      {isTravelogue ? (
        <TravelogueMarkdownComponent baseText={children} isTranslation={isTranslation} />
      ) : (
        <RocketMarkdown>{children}</RocketMarkdown>
      )}
    </div>
  );
}
