import classes from "./ComponentStatusWrapper.module.scss";
import { clsx } from "clsx";

export default function ComponentStatusWrapper({ children, draft }: { children: React.ReactNode; draft: boolean }) {
  return (
    <div className={clsx(classes.print, draft && classes.draft)}>
      {children}
      {draft && (
        <div className={classes.overlay}>
          <div className={classes.draftTag}>Draft</div>
        </div>
      )}
    </div>
  );
}
