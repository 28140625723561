export default function AwardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Award Icon</title>
      <path
        d="M14.0007 17.4993C18.511 17.4993 22.1673 13.843 22.1673 9.33268C22.1673 4.82236 18.511 1.16602 14.0007 1.16602C9.49033 1.16602 5.83398 4.82236 5.83398 9.33268C5.83398 13.843 9.49033 17.4993 14.0007 17.4993Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57768 16.205L8.16602 26.8334L13.9993 23.3334L19.8327 26.8334L18.421 16.1934"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
