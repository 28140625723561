import Markdown from "markdown-to-jsx";
import Notation from "../Notation";
import { TooltipContent, TooltipFacelift, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui";
import useTranslation from "../../../hooks/useTranslation";
import { TooltipPortal } from "@rocketlanguages/ui/Tooltip/Tooltip";
import { useRef, useState } from "react";

interface LiteralStringNotationButtonProps {
  literalString: string;
}

export default function LiteralStringNotationButton(props: LiteralStringNotationButtonProps) {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const isPointerDownRef = useRef(false);

  return (
    <TooltipProvider delayDuration={200}>
      <TooltipFacelift
        open={open}
        onOpenChange={(v) => {
          if (!isPointerDownRef.current) {
            setOpen(v);
          }
        }}
      >
        <TooltipPortal>
          <TooltipContent>
            <div className="max-w-96">
              <Markdown>{`${t("literally")}: ${props.literalString}`}</Markdown>
            </div>
          </TooltipContent>
        </TooltipPortal>
        <TooltipTrigger
          onPointerDown={() => {
            isPointerDownRef.current = true;
            setOpen(true);
          }}
          onPointerLeave={() => {
            // The library will call `onOpenChange(false)` when the pointer leaves
            // We'd still like to keep it around on mobile, so we'll defer the ref change
            // this avoids the tooltip from closing when the user taps
            setTimeout(() => {
              isPointerDownRef.current = false;
            }, 16);
          }}
        >
          <Notation title={t("lit")} />
        </TooltipTrigger>
      </TooltipFacelift>
    </TooltipProvider>
  );
}
