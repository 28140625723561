import { clsx } from "clsx";
import styles from "./TestIcon.module.scss";
import type { IconType } from "react-icons";

interface TestIconProps {
  /** FontAwesome or Ionicons from react-icons*/
  icon?: IconType;
  /** 0, 1, 2, 3 */
  ratingLevel: number;
  /** circle or square */
  shape: "circle" | "square";
  /** small, medium or large */
  size: "small" | "medium" | "large" | "extra-large";
  faded?: boolean;
}

export default function TestIcon(props: TestIconProps) {
  const { ratingLevel } = props;

  const containerClassName = clsx(
    styles.container,
    // `level-${ratingLevel}`,
    !props.faded && styles[`background-level-${ratingLevel}`],
    props.shape && styles[props.shape],
    props.size && styles[props.size],
    props.faded && "bg-brand2 dark:bg-neutral-800 shadow-none",
  );

  return <div className={containerClassName}>{props.icon && <props.icon size={24} />}</div>;
}
