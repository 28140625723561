export function getRecommendedRatingButton(suggestedRatingLevel: number | undefined) {
  if (!suggestedRatingLevel || suggestedRatingLevel === 0) return;
  if (suggestedRatingLevel === 2) {
    return "good";
  }
  if (suggestedRatingLevel === 1) {
    return "hard";
  }
  return "easy";
}
