import type { ImageComponent as ImageComponentType, LessonComponent } from "@rocketlanguages/types";

export type Props = {
  lessonComponent: LessonComponent<"image", ImageComponentType>;
};

export default function ImageComponent(props: Props) {
  const { lessonComponent } = props;
  return (
    <div className="flex justify-center">
      <div>
        <img
          src={lessonComponent.component.image_url}
          alt={lessonComponent.component.alt || ""}
          className="m-auto mt-2 max-h-[18rem] max-w-full rounded-md object-contain"
        />
        <div className="w-full p-2">
          {lessonComponent.component.caption && (
            <div className="">
              <h5 className="font-medium">{lessonComponent.component.caption}</h5>
              {lessonComponent.component.subcaption && (
                <p className="text-sm">{lessonComponent.component.subcaption}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
