import * as jsdiff from "diff";
import {
  allPunctuationRegex,
  leven,
  prepareString,
  purgeString,
  withoutBracketsRegex,
} from "../../../../utils/stringUtils";

import { getRatingLevel, getRatingPercentageDisplay } from "../../../../utils";

export const textColors = {
  correct: "var(--color-rocketgreen)",
  neutral: "",
  incorrect: "var(--color-rocketred)",
};

type RateWritingProps = {
  input: string;
  target: string;
  courseSlug?: string;
};

const punctuationMap = {
  "’": "'",
  //  "‘": "'",
  "\t": " ",
  //  "“": '"',
  // "”": '"',
  // "\u" + "è".charCodeAt(0).toString(16).padStart(4, '0');
  // è: "ѐ",
  "\u00e8": "\u0450",
};

export function rateWriting({ input, target, courseSlug }: RateWritingProps) {
  // Phrase not found...
  if (!target || !courseSlug) {
    return;
  }

  const [formattedInput, formattedTarget] = (() => {
    let formattedInput = input;
    let formattedTarget = target
      // Remove brackets (e.g. "poder (present tense)" => "poder")
      .replace(withoutBracketsRegex, "")
      // Replace "poder/querer" => "poder querer"
      .replace(/(\w)\/(\w)/g, "$1 $2")
      .trim();

    // Replace certain punctuation (e.g. ' => ’) to match target (so that diffing doesn't display red for punctuation)
    for (const [key, value] of Object.entries(punctuationMap)) {
      if (target.includes(key)) {
        formattedInput = formattedInput.replace(new RegExp(value, "g"), key);
      } else if (target.includes(value)) {
        formattedInput = formattedInput.replace(new RegExp(key, "g"), value);
      }
    }

    // For arabic, remove all punctuation when comparing diff
    if (courseSlug === "arabic") {
      formattedInput = formattedInput.replace(allPunctuationRegex, "");
      formattedTarget = formattedTarget.replace(allPunctuationRegex, "");
    }

    // On Japanese, we want to replace normal spaces with Japanese spaces
    if (courseSlug === "japanese") {
      const replaceNormalSpaces = (str: string) => str.replace(/ /g, "　");
      input = replaceNormalSpaces(input);
      formattedTarget = replaceNormalSpaces(formattedTarget);
    }

    // On French, we want to replace all Cedilla looking characters with the
    // correct Cedillas (Ç and ç).
    if (courseSlug === "french") {
      const replaceCedilla = (str: string) =>
        str
          .replaceAll("Ҫ", "Ç") // utf-8(1194) -> 199
          .replaceAll("ҫ", "ç"); // utf-8(1195) -> 231

      formattedInput = replaceCedilla(formattedInput);
      formattedTarget = replaceCedilla(formattedTarget);
    }

    return [
      purgeString(formattedInput, { html: true, markdown: true }).normalize(),
      purgeString(formattedTarget, { html: true, markdown: true }).normalize(),
    ];
  })();

  const writingDiff = jsdiff.diffChars(formattedInput, formattedTarget, {
    ignoreCase: true,
  });

  const percent = (() => {
    if (prepareString(formattedInput).length === 0) {
      return 1;
    }
    // Convert to lowercase, and remove all punctuation for distance calculation
    const dist = leven(prepareString(formattedTarget), prepareString(formattedInput));
    const maxPhraseLength = Math.max(formattedTarget.length, formattedInput.length);
    return Math.min((1 - dist / maxPhraseLength) * 100 + 1, 100);
  })();

  const ratingLevel = getRatingLevel(percent);

  const percentageDisplay = (() => {
    // Percentage value is 1 if the input is empty
    if (percent <= 1) {
      return 0;
    }
    // Don't round up to 100% in case of an actual typo
    if (percent > 95 && percent < 100) {
      return 95;
    }
    return getRatingPercentageDisplay(percent);
  })();

  return {
    percentageDisplay,
    ratingLevel,
    writingDiff,
  };
}

type ToneValueProps = {
  textRef: React.MutableRefObject<HTMLTextAreaElement> | React.MutableRefObject<null>;
  textInput: string;
  value: string;
};

export function getNewTextValue({ textRef, textInput, value }: ToneValueProps) {
  if (textRef.current) {
    const textInputArray = textInput.split("");
    const { selectionStart } = textRef.current;
    textInputArray.splice(selectionStart, 0, value);
    return {
      value: textInputArray.join(""),
      selectionStart: selectionStart + 1,
    };
  }
  return { value: textInput, selectionStart: 1 };
}

type EmulationProps = {
  textElement: HTMLTextAreaElement | null;
  textInput: string;
};

export function emulatedBackspace({ textElement, textInput }: EmulationProps) {
  if (textElement) {
    const textInputArray = textInput.split("");
    const selectionStart = (() => {
      if (textElement.selectionStart === textElement.selectionEnd) {
        textInputArray.splice(textElement.selectionStart - 1, 1);
        return textElement.selectionStart - 1;
      }
      textInputArray.splice(textElement.selectionStart, textElement.selectionEnd - textElement.selectionStart);
      return textElement.selectionStart;
    })();
    return { value: textInputArray.join(""), selectionStart };
  }
  return { value: textInput, selectionStart: textInput.length };
}

export function emulatedDelete({ textElement, textInput }: EmulationProps) {
  if (textElement) {
    const textInputArray = textInput.split("");
    textInputArray.splice(textElement.selectionStart, 1);
    return {
      value: textInputArray.join(""),
      selectionStart: textElement.selectionStart,
    };
  }
  return { value: textInput, selectionStart: textInput.length };
}
