import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { getNewTextValue } from "../utils";
import { useEffect, useLayoutEffect, useState } from "react";
import writeItTones, { type WriteItTone } from "../utils/tones";

import { FiChevronDown } from "react-icons/fi";
import KeyButton from "./KeyButton";
import type { KeyboardState } from "../WriteItFacelift";
import type { SetStateFunction } from "@rocketlanguages/types";
import { clsx } from "clsx";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import type usePhraseTest from "../../../../hooks/usePhraseTest/usePhraseTest";
import useTranslation from "../../../../hooks/useTranslation";

type ToneKeyboardProps = {
  phraseTest: ReturnType<typeof usePhraseTest>;
  state: KeyboardState;
  setState: SetStateFunction<KeyboardState>;
  textRef: React.RefObject<HTMLTextAreaElement>;
};

function getTones(options: { activeCourseName?: string; activeCourseSlug?: string }) {
  const isChinese = options.activeCourseSlug === "chinese";
  if (isChinese && options.activeCourseName) {
    return writeItTones.Pinyin;
  }

  if (options.activeCourseName && writeItTones[options.activeCourseName]) {
    return isChinese ? writeItTones.Pinyin : writeItTones[options.activeCourseName];
  }

  return null;
}

export function ToneKeyboard({ textRef, phraseTest, state, setState }: ToneKeyboardProps) {
  const t = useTranslation();
  const activeCourse = useActiveCourse();
  const [upperCase, setUpperCase] = useState(false);
  // const uiContext = useContext(RateableTestUIContext);

  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  const tones = getTones({
    activeCourseName: activeCourse?.name,
    activeCourseSlug: activeCourse?.slug,
  });

  useEffect(() => {
    if (textRef.current && state.selectionStart > 0) {
      textRef.current.focus();
      setTimeout(() => {
        textRef.current?.setSelectionRange(state.selectionStart, state.selectionStart);
      }, 0);
    }
  }, [state.selectionStart, state.textInput, textRef]);

  useLayoutEffect(() => {
    if (!didReveal) {
      setToneVisibility((tones ? Object.keys(tones)[0] : "") || "");
      setTimeout(() => {
        textRef.current?.focus();
      }, 50);
    }
    if (didReveal) {
      setToneVisibility("");
    }
  }, [didReveal, textRef, tones]);

  const [toneVisibility, setToneVisibility] = useState<string>((tones ? Object.keys(tones)[0] : "") || "");

  const isCollapsible = activeCourse?.slug === "chinese";

  const toneVowels = tones ? (Object.keys(tones) as (keyof WriteItTone)[]) : [];

  return (
    <>
      <textarea
        className={clsx(
          "w-full resize-y rounded-2xl border border-missilesurfacedark px-6 py-4 dark:bg-neutral-900 dark:focus:outline-none dark:focus:ring-2",
          {
            "text-missilegray": state.textInput === "",
            "font-semibold": state.textInput.length > 0,
          },
        )}
        ref={textRef}
        name="Write It Textbox"
        placeholder={
          activeCourse?.slug === "chinese"
            ? "Write it in Pinyin here"
            : `${t("write-it-in-{language}-name-here", { language: activeCourse?.name || "" })}`
        }
        value={state.textInput}
        onKeyDown={(e) => {
          if (e.key !== "Enter") {
            e.nativeEvent.stopImmediatePropagation();
          }
        }}
        onChange={(e) => {
          setState((prevState) => ({ ...prevState, selectionStart: 0, textInput: e.target.value }));
        }}
        disabled={state.submitted}
        spellCheck={false}
      />
      <span className={clsx("my-4 block w-full text-center text-sm font-medium dark:text-white")}>
        Use these special characters if required.
      </span>
      <div className={clsx("mb-2 flex items-center justify-center whitespace-nowrap", !isCollapsible && "flex-wrap")}>
        {activeCourse?.slug === "chinese" && <p className="mb-2 mr-2 hidden sm:block">Add Tones</p>}
        {toneVowels.map((vowel, i) => {
          const WriteItButton = isCollapsible ? WriteItToneButtonCollapsible : WriteItToneButton;
          if (!tones) {
            return null;
          }
          return (
            <WriteItButton
              tone={tones}
              vowel={vowel}
              upperCase={upperCase}
              onClick={(char: string) => {
                // Don't update text if revealed
                if (didReveal) {
                  return;
                }
                setState((currentState) => {
                  const { value, selectionStart } = getNewTextValue({
                    textRef,
                    textInput: currentState.textInput,
                    value: char,
                  });
                  return {
                    ...currentState,
                    textInput: value,
                    selectionStart,
                  };
                });
              }}
              toneVisibility={toneVisibility}
              setToneVisibility={setToneVisibility}
              key={vowel}
              disabled={didReveal}
              vowelOrder={i}
            />
          );
        })}
        {toneVowels.length > 0 ? (
          <KeyButton
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.nativeEvent.stopImmediatePropagation();
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              if (textRef.current) {
                textRef.current.focus();
              }
              setUpperCase(!upperCase);
            }}
            disabled={didReveal}
            className="mx-1 mb-2"
          >
            {!upperCase ? <MdArrowDropUp size={24} /> : <MdArrowDropDown size={24} />}
          </KeyButton>
        ) : null}
      </div>
      {/** Because writeit buttons are absolutely positioned, not adding a height will overflow */}
      {activeCourse?.slug === "chinese" && !didReveal ? <div className="h-12" /> : null}
    </>
  );
}

type ToneButtonProps = {
  tone: WriteItTone;
  vowel: string;
  upperCase: boolean;
  onClick: (key: string) => void;
  toneVisibility: string;
  setToneVisibility: (arg: string) => void;
  disabled?: boolean;
  vowelOrder?: number;
};

function WriteItToneButton({ tone, vowel, upperCase, onClick, disabled }: ToneButtonProps) {
  // @ts-ignore
  const toneArr = upperCase ? tone[vowel].upperCase : tone[vowel].lowerCase;

  return (
    <div className="mb-2 flex justify-center self-center" key={vowel}>
      {toneArr.map((_tone: any) => (
        <KeyButton
          key={_tone}
          disabled={disabled}
          onClick={() => onClick(_tone)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.nativeEvent.stopImmediatePropagation();
            }
          }}
          // active={active}
          className="mx-1"
        >
          {_tone}
        </KeyButton>
      ))}
    </div>
  );
}

function WriteItToneButtonCollapsible({
  tone,
  vowel,
  upperCase,
  onClick,
  toneVisibility,
  setToneVisibility,
  disabled,
  vowelOrder,
}: ToneButtonProps) {
  return (
    <div className="relative mb-2 flex flex-col justify-center self-center" key={vowel}>
      <div className="relative">
        <KeyButton
          onClick={() => {
            if (toneVisibility === vowel) {
              setToneVisibility("");
            } else {
              setToneVisibility(vowel);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.nativeEvent.stopImmediatePropagation();
            }
          }}
          className="mx-1"
          active={toneVisibility === vowel}
          disabled={disabled}
        >
          {upperCase ? vowel.toUpperCase() : vowel}
        </KeyButton>
        <span className="pointer-events-none absolute top-[32px] flex w-full justify-center">
          {toneVisibility === vowel && <FiChevronDown className="!h-[12px] !w-[12px] text-brand dark:text-text1" />}
        </span>
      </div>
      <div
        className={clsx("absolute bottom-0 right-0 top-[80px] mt-1 flex", !(toneVisibility === vowel) && "hidden")}
        style={{
          left: `${vowelOrder ? -(100 * vowelOrder - 50) : 50}%`,
        }}
      >
        <WriteItToneButton
          tone={tone}
          vowel={vowel}
          upperCase={upperCase}
          onClick={onClick}
          toneVisibility={toneVisibility}
          setToneVisibility={setToneVisibility}
          key={vowel}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
