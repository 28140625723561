import { H1Override, H2Override, THeadOverride } from "./overrides";

const defaultOverrides: { [key: string]: { component: any } } = {
  /** Correct dom ordering by converting h1's to h2's and h2's to h3's */
  h1: {
    component: H1Override,
  },
  h2: {
    component: H2Override,
  },
  thead: {
    component: THeadOverride,
  },
};

export const defaultOptions = {
  overrides: defaultOverrides,
  namedCodesToUnicode: { zwsp: "\u200B" },
};
