import type { Character, LessonComponent, TranscriptComponent, TranscriptRatings } from "@rocketlanguages/types";
import { useContext, useMemo, useState } from "react";
import Instructions from "./includes/Instructions";
import PlayItHeader from "./PlayItHeader";
import PlayItLines from "./PlayItLines";
import PlaybackButtons from "./PlaybackButtons";
import RateableTestUI from "../../../RateableTests/RateableTestUI";
import Settings from "./includes/Settings";
import { getPlayableCharacters } from "./includes/utils";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import { useSharedSelector } from "../../../../store";
import { actions as playItActions, useGlobalPlayItStore, usePlayItEvents } from "./includes/usePlayIt";
import useInterval from "../../../../hooks/useInterval";
import { PlayItMenu } from "./includes/Menu";
import LessonContext from "../../../../context/LessonContext";
import { ModalFacelift } from "./includes/Modal";
import { PlayItVideoPlayer } from "./includes/PlayItVideoPlayer";
import { Xmark } from "iconoir-react";
import { PlayItComplete } from "./includes/PlayItComplete";
import usePlayItRatings from "../../../../hooks/usePlayItRatings";
import useHasPermission from "../../../../hooks/useHasPermission";
import { PlayItContext } from "./includes/context";

type Props = {
  lessonComponent: LessonComponent<"transcript", TranscriptComponent>;
};

export default function PlayIt({ lessonComponent }: Props) {
  const lessonId = useContext(LessonContext)?.id;
  const playIt = useGlobalPlayItStore();
  const activeCourse = useActiveCourse();
  const [displaySettings, setDisplaySettings] = useState(false);
  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);
  const isAdmin = useHasPermission("edit_content");
  const isThisPlayIt = playIt.format === "audio" && playIt.transcriptId === lessonComponent.component.id;
  const [hasFinished, setHasFinished] = useState(false);
  const playItRatings = usePlayItRatings(lessonComponent.component.id);
  const [playing, setPlaying] = useState<{ type: "listen" } | { type: "test"; characterId: number } | undefined>(
    undefined,
  );
  const ctx = useContext(PlayItContext);

  if (!ctx) {
    throw new Error("PlayItContext not found. Please wrap in PlayItProvider");
  }

  // show draft lines if debug mode enabled or on admin domain
  const filteredLines = useMemo(() => {
    if (debugEnabled || (typeof window !== "undefined" && window.location.hostname === "staff.rocketlanguages.com"))
      return lessonComponent.component.lines;
    return lessonComponent.component.lines.filter((_line) => !!_line.status);
  }, [debugEnabled, lessonComponent.component.lines]);

  const activeCourseSlug = activeCourse ? activeCourse.slug : "";

  const characters = useMemo(() => getPlayableCharacters(filteredLines), [filteredLines]);

  useInterval(
    () => useGlobalPlayItStore.dispatch(playItActions.tick()),
    isThisPlayIt && playIt.secondsCounter >= 1 ? 1000 : null,
  );

  usePlayItEvents({
    onEnd(state) {
      if (
        state.status !== "finished" ||
        state.transcriptId !== lessonComponent.component.id ||
        state.format !== "video"
      ) {
        return;
      }
      setHasFinished(true);
    },
  });

  if (!characters) {
    return null;
  }

  const getCharacterNameById = (id: number) => {
    const char = characters.find((c) => c.id === id);
    if (char) {
      return char.name;
    }
    return "Unknown";
  };

  const allLinesHaveVideos = filteredLines.every((line) => !!line.video_url && !!line.waiting_video_url);
  const hasVideosEnabled = Boolean(lessonComponent.component.roleplay_video_enabled);
  const isAdminWithDebugEnabled = isAdmin && debugEnabled;
  const canSeeVideos = !ctx.audioOnly && allLinesHaveVideos && (hasVideosEnabled || isAdminWithDebugEnabled);

  const handleModalClose = () => {
    setHasFinished(false);
    setPlaying(undefined);
    useGlobalPlayItStore.dispatch({ type: "RESET" });
  };

  return (
    <div className="space-y-6 rounded-2xl border border-missilestroke p-4">
      <RateableTestUI.Container
        id={`role-playing-${lessonComponent.component.id}`}
        instructions={<Instructions />}
        settings={<Settings lessonComponent={lessonComponent} />}
      >
        <PlayItHeader
          characterName={getCharacterNameById(Number(playIt.characterId))}
          transcriptId={lessonComponent.component.id}
          onToggleDisplaySettings={() => setDisplaySettings((state) => !state)}
          displaySettings={displaySettings}
        />
        <RateableTestUI.Body>
          <div className="py-4">
            {canSeeVideos ? (
              <PlayItMenu
                transcriptId={lessonComponent.component.id}
                lessonId={lessonId}
                onSelectMode={setPlaying}
                playableCharacters={characters}
              />
            ) : (
              <PlaybackButtons
                characters={characters}
                numLines={filteredLines.length}
                transcriptId={lessonComponent.component.id}
              />
            )}
          </div>
          <div className="py-4">
            <div className="w-full border-t border-missilestroke dark:border-gray-600" />
          </div>
          <PlayItLines
            lines={filteredLines}
            activeCourseSlug={activeCourseSlug}
            transcriptId={lessonComponent.component.id}
          />
          {isThisPlayIt && playIt.secondsCounter ? (
            <div className="absolute inset-0 bg-black bg-opacity-50 text-center text-white">
              <div className="mt-8 text-6xl">{playIt.secondsCounter}</div>
            </div>
          ) : null}
        </RateableTestUI.Body>
      </RateableTestUI.Container>

      <ModalFacelift
        isOpen={!!playing}
        onEscapeKeyDown={handleModalClose}
        containerClassName="h-full p-0 sm:p-4 bg-black sm:bg-transparent"
        className="mx-auto h-full sm:aspect-[9/16] sm:h-[95vh] sm:max-h-[95vh] sm:rounded-2xl"
      >
        {hasFinished ? (
          <div className="h-full bg-surface2 p-4">
            <div className="absolute right-4 top-4 z-30">
              <button
                type="button"
                className="rounded-full bg-black/50 p-1 text-white hover:bg-black/80"
                aria-label="Close"
                onClick={handleModalClose}
              >
                <Xmark name="xmark" className="size-11" />
              </button>
            </div>
            <PlayItComplete
              characters={characters}
              ratings={getFlippedPlayerRatings(playItRatings, characters)}
              onPlayAgain={() => setHasFinished(false)}
              onClose={handleModalClose}
            />
          </div>
        ) : (
          <div className="h-full">
            <div className="absolute right-4 top-4 z-30">
              <button
                type="button"
                className="rounded-full bg-black/50 p-1 text-white hover:bg-black/80"
                aria-label="Close"
                onClick={handleModalClose}
              >
                <Xmark name="xmark" className="size-11" />
              </button>
            </div>
            <PlayItVideoPlayer
              transcriptId={lessonComponent.component.id}
              lessonId={lessonId}
              characterId={playing?.type === "test" ? playing?.characterId : undefined}
            />
          </div>
        )}
      </ModalFacelift>
    </div>
  );
}

function getFlippedPlayerRatings(ratings: TranscriptRatings, players: [Character, Character]) {
  const [firstPlayer, secondPlayer] = players;
  return {
    [firstPlayer.id]: ratings[secondPlayer.id] ?? 0,
    [secondPlayer.id]: ratings[firstPlayer.id] ?? 0,
  };
}
