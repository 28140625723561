import { WritingSystemIds, WritingSystemLanguages } from "../../../../utils/constants";

export type WriteItTones = {
  [key: string]: WriteItTone;
};

export type WriteItTone = {
  a: { lowerCase: string[]; upperCase: string[] };
  e: { lowerCase: string[]; upperCase: string[] };
  i: { lowerCase: string[]; upperCase: string[] };
  o: { lowerCase: string[]; upperCase: string[] };
  u: { lowerCase: string[]; upperCase: string[] };
  ü?: { lowerCase: string[]; upperCase: string[] };
  other?: { lowerCase: string[]; upperCase: string[] };
};

const writeItTones: WriteItTones = {
  [WritingSystemLanguages[WritingSystemIds.pinyin]]: {
    a: { lowerCase: ["ā", "á", "ǎ", "à"], upperCase: ["Ā", "Á", "Ǎ", "À"] },
    e: { lowerCase: ["ē", "é", "ě", "è"], upperCase: ["Ē", "É", "Ě", "È"] },
    i: { lowerCase: ["ī", "í", "ǐ", "ì"], upperCase: ["Ī", "Í", "Ǐ", "Ì"] },
    o: { lowerCase: ["ō", "ó", "ǒ", "ò"], upperCase: ["Ō", "Ó", "Ǒ", "Ò"] },
    u: { lowerCase: ["ū", "ú", "ǔ", "ù"], upperCase: ["Ū", "Ú", "Ǔ", "Ù"] },
    ü: { lowerCase: ["ǖ", "ǘ", "ǚ", "ǜ"], upperCase: ["Ǖ", "Ǘ", "Ǚ", "Ǜ"] },
  },
  [WritingSystemLanguages[WritingSystemIds.french]]: {
    a: { lowerCase: ["â", "à"], upperCase: ["Â", "À"] },
    e: { lowerCase: ["ê", "è", "ë", "é"], upperCase: ["Ê", "È", "Ë", "É"] },
    i: { lowerCase: ["î", "ï"], upperCase: ["Î", "Ï"] },
    o: { lowerCase: ["ô"], upperCase: ["Ô"] },
    u: { lowerCase: ["û", "ù"], upperCase: ["Û", "Ù"] },
    other: { lowerCase: ["ç", "œ"], upperCase: ["Ç", "Œ"] },
  },
  [WritingSystemLanguages[WritingSystemIds.spanish]]: {
    a: { lowerCase: ["á"], upperCase: ["Á"] },
    e: { lowerCase: ["é"], upperCase: ["É"] },
    i: { lowerCase: ["í"], upperCase: ["Í"] },
    o: { lowerCase: ["ó"], upperCase: ["Ó"] },
    u: { lowerCase: ["ú", "ü"], upperCase: ["Ú", "Ü"] },
    other: { lowerCase: ["ñ", "¿", "¡"], upperCase: ["Ñ", "¿", "¡"] },
  },
  [WritingSystemLanguages[WritingSystemIds.german]]: {
    a: { lowerCase: ["ä"], upperCase: ["Ä"] },
    e: { lowerCase: [], upperCase: [] },
    i: { lowerCase: [], upperCase: [] },
    o: { lowerCase: ["ö"], upperCase: ["Ö"] },
    u: { lowerCase: ["ü"], upperCase: ["Ü"] },
    other: { lowerCase: ["ß"], upperCase: ["ß"] },
  },
  [WritingSystemLanguages[WritingSystemIds.italian]]: {
    a: { lowerCase: ["à"], upperCase: ["À"] },
    e: { lowerCase: ["é", "è"], upperCase: ["É", "È"] },
    i: { lowerCase: ["ì"], upperCase: ["Ì"] },
    o: { lowerCase: ["ò"], upperCase: ["Ò"] },
    u: { lowerCase: ["ù"], upperCase: ["Ù"] },
  },
  [WritingSystemLanguages[WritingSystemIds.portuguese]]: {
    a: { lowerCase: ["á", "à", "â", "ã"], upperCase: ["Á", "À", "Â", "Ã"] },
    e: { lowerCase: ["é", "ê"], upperCase: ["É", "Ê"] },
    i: { lowerCase: ["í"], upperCase: ["Í"] },
    o: { lowerCase: ["ó", "ô", "õ"], upperCase: ["Ó", "Ô", "Õ"] },
    u: { lowerCase: ["ú", "ü"], upperCase: ["Ú", "Ü"] },
    other: { lowerCase: ["ç"], upperCase: ["Ç"] },
  },
};

export default writeItTones;
