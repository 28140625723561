import { useEffect } from "react";

export default function usePageTitle(title: string): void {
  useEffect(() => {
    if (!title) {
      window.document.title = "Rocket Languages";
    } else {
      window.document.title = `${title} - Rocket Languages`;
    }
  }, [title]);
}
