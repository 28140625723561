import { RoundedButton } from "@rocketlanguages/ui";
import { rateLesson, requestAddPoints } from "../../store/lesson/actions";
import { useEffect, useState } from "react";

import { useSharedDispatch, useSharedSelector } from "../../store";
import useTranslation from "../../hooks/useTranslation";
import { clsx } from "clsx";

interface MarkCompleteButtonProps {
  lessonId: number;
}

export default function MarkCompleteButton(props: MarkCompleteButtonProps) {
  const t = useTranslation();
  const lessonStatus = useSharedSelector((s) => s.lesson.entities.user_lesson_status[props.lessonId]);
  const complete = lessonStatus?.is_done;
  const [loading, setLoading] = useState(false);
  const dispatch = useSharedDispatch();

  useEffect(() => {
    setLoading(false);
  }, [complete]);

  function toggleComplete() {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(rateLesson(props.lessonId, complete ? 0 : 100));
    if (!complete) {
      dispatch(requestAddPoints({ rewardType: "completeLesson", data: { lesson: props.lessonId } }));
    }
  }

  return (
    <RoundedButton
      onClick={toggleComplete}
      // className="h-12 print:hidden"
      loading={loading}
      className={clsx(
        complete
          ? "hover:bg-missilegreen/90 bg-missilegreen font-semibold text-white print:hidden"
          : "hover:bg-missilebrand/90 bg-missilebrand font-semibold text-white print:hidden",
      )}
    >
      {complete ? t("mark-as-incomplete") : t("mark-as-complete")}
    </RoundedButton>
  );
}
