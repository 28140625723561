import { forwardRef, useRef } from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

export const Slider = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ ...props }, ref) => {
  const isActive = useRef(false);
  return (
    <SliderPrimitive.Root
      ref={ref}
      className="relative flex w-full cursor-pointer touch-none select-none items-center"
      {...props}
      onPointerDown={() => {
        isActive.current = true;
      }}
      onPointerUp={() => {
        isActive.current = false;
      }}
      onPointerLeave={() => {
        if (isActive.current) {
          props.onValueCommit?.([props.value?.[0] ?? 0]);
          isActive.current = false;
        }
      }}
    >
      <SliderPrimitive.Track className="relative h-[5px] w-full grow overflow-hidden rounded-full bg-missilesurfacedark">
        <SliderPrimitive.Range className="absolute h-full bg-missileaccent" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="block size-3 rounded-full bg-white transition-shadow hover:shadow-[0_0_0_8px_rgb(255_255_255_/16%)] focus:shadow-[0_0_0_14px_rgb(255_255_255_/16%)] focus:outline-none focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;
