export function Medal() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.46386 13.8345L2.47559 27.0007L8.82156 26.4144L11.893 31.999L18.8813 18.8328L9.46386 13.8345Z"
        fill="#E95454"
      />
      <path
        d="M10.2645 28.1652C10.4605 27.744 10.2554 27.2407 9.84526 27.0226L9.44307 26.8085C9.04088 26.5794 8.88781 26.0702 9.10691 25.658C9.32901 25.2388 9.85126 25.0787 10.2705 25.3008L11.407 25.9041C11.7582 26.0902 12.1984 25.9551 12.3794 25.602L12.4075 25.5479C12.6156 25.2148 12.4925 24.7746 12.1463 24.5915L8.74674 22.7876C8.33255 22.5675 8.12945 22.0663 8.32254 21.6441C8.53564 21.1949 9.07389 21.0178 9.5071 21.2479L12.6606 22.9217C13.0117 23.1078 13.4519 22.9727 13.638 22.6216L13.656 22.5755L13.661 22.5655C13.8621 22.1974 13.7211 21.7351 13.3499 21.5381L11.5811 20.5996C11.1669 20.3795 10.9638 19.8783 11.1599 19.4511C11.371 19.0069 11.9112 18.8248 12.3444 19.0549L14.2983 20.0924C14.6635 20.2865 15.0997 20.1244 15.2888 19.7562C15.2918 19.7512 15.2918 19.7512 15.2938 19.7462C15.2968 19.7412 15.2968 19.7412 15.2988 19.7362C15.4979 19.373 15.3879 18.9208 15.0217 18.7277L12.9137 17.6092C12.5045 17.3921 12.3014 16.8909 12.4945 16.4687C12.7076 16.0195 13.2458 15.8424 13.6791 16.0725L18.8795 18.8328L11.8912 31.999L9.96531 28.4983C10.0894 28.4143 10.1974 28.3102 10.2645 28.1652Z"
        fill="#ED6362"
      />
      <path
        d="M22.5366 13.8345L29.5248 27.0007L23.1789 26.4144L20.1074 31.999L13.1191 18.8328L22.5366 13.8345Z"
        fill="#ED6362"
      />
      <path
        d="M21.7361 28.1651C21.5401 27.7439 21.7451 27.2407 22.1553 27.0226L22.5575 26.8085C22.9597 26.5794 23.1128 26.0702 22.8937 25.658C22.6716 25.2388 22.1493 25.0787 21.7301 25.3008L20.5936 25.9041C20.2424 26.0902 19.8022 25.9551 19.6212 25.6019L19.5931 25.5479C19.385 25.2148 19.5081 24.7745 19.8543 24.5915L23.2549 22.7866C23.669 22.5665 23.8721 22.0653 23.6791 21.6431C23.466 21.1939 22.9277 21.0168 22.4945 21.2469L19.341 22.9207C18.9899 23.1068 18.5497 22.9717 18.3636 22.6205L18.3456 22.5745L18.3406 22.5645C18.1395 22.1963 18.2805 21.7341 18.6517 21.537L20.4205 20.5986C20.8347 20.3785 21.0378 19.8772 20.8417 19.45C20.6306 19.0058 20.0904 18.8238 19.6572 19.0539L17.7013 20.0934C17.3361 20.2874 16.8999 20.1254 16.7108 19.7572C16.7078 19.7522 16.7078 19.7522 16.7058 19.7472C16.7028 19.7422 16.7028 19.7422 16.7008 19.7372C16.5017 19.374 16.6117 18.9218 16.9779 18.7287L19.0859 17.6102C19.4951 17.3931 19.6982 16.8919 19.5051 16.4697C19.292 16.0204 18.7538 15.8434 18.3205 16.0735L13.1201 18.8338L20.1084 31.9999L22.0343 28.4993C21.9112 28.4143 21.8032 28.3102 21.7361 28.1651Z"
        fill="#E95454"
      />
      <path
        d="M16.0007 23.3529C22.4494 23.3529 27.6771 18.1252 27.6771 11.6765C27.6771 5.22773 22.4494 0 16.0007 0C9.55195 0 4.32422 5.22773 4.32422 11.6765C4.32422 18.1252 9.55195 23.3529 16.0007 23.3529Z"
        fill="#FFCC5B"
      />
      <path
        d="M17.5836 10.3369H10.1841C9.60888 10.3369 9.13666 9.86468 9.13666 9.28941L9.14466 9.21738V9.20137C9.13666 8.59408 9.63189 8.09785 10.2402 8.09785H15.3686C16.0479 8.09785 16.6482 7.59462 16.6882 6.9143C16.7202 6.20296 16.1529 5.60268 15.4406 5.60268H10.0081C9.40878 5.60268 8.96058 5.09945 8.96858 4.49916C8.96858 4.49116 8.96858 4.49116 8.96858 4.48316C8.96858 4.47515 8.96858 4.47515 8.96858 4.46715C8.96058 3.86787 9.40778 3.36363 10.0081 3.36363H15.6927C16.364 3.36363 16.9643 2.8604 17.0043 2.18808C17.0364 1.46874 16.4691 0.876465 15.7568 0.876465H11.5938C7.34882 2.62629 4.35742 6.80024 4.35742 11.6765C4.35742 16.5528 7.34882 20.7258 11.5948 22.4736H16.8803C17.5516 22.4736 18.1519 21.9704 18.1919 21.298C18.2239 20.5787 17.6566 19.9874 16.9443 19.9874L10.9315 19.9864C10.2222 19.9864 9.6509 19.3931 9.68892 18.6748C9.72393 18.0025 10.3282 17.4973 11.0015 17.4973H13.3326C14.0029 17.4783 14.5442 16.929 14.5442 16.2537C14.5442 15.5664 13.9849 15.0061 13.2966 15.0061H9.76295C9.18768 15.0061 8.71546 14.5349 8.72346 13.9586V13.8706C8.68344 13.3033 9.13966 12.8231 9.70692 12.8231H17.5116C18.1909 12.8231 18.7912 12.3198 18.8312 11.6475C18.8632 10.9292 18.2949 10.3369 17.5836 10.3369Z"
        fill="#FDBC4B"
      />
      <path
        d="M15.9999 20.0106C11.4038 20.0106 7.66504 16.2709 7.66504 11.6757C7.66504 7.08056 11.4038 3.3418 15.9999 3.3418C20.5961 3.3418 24.3348 7.08156 24.3348 11.6767C24.3348 16.2719 20.5961 20.0106 15.9999 20.0106Z"
        fill="#FFDB70"
      />
      <path
        opacity="0.2"
        d="M24.3356 11.6766C24.3356 7.24254 20.852 3.61584 16.4789 3.36572H15.7025C15.1903 3.36572 14.7331 3.7499 14.7021 4.26214C14.6781 4.8104 15.1103 5.26261 15.6535 5.26261L20.2397 5.26362C20.7809 5.26362 21.2161 5.71583 21.1871 6.26409C21.1601 6.77733 20.6999 7.16251 20.1856 7.16251H18.4078C17.8966 7.17752 17.4834 7.59571 17.4834 8.11095C17.4834 8.6352 17.9106 9.0624 18.4348 9.0624H21.1301C21.5693 9.0624 21.9285 9.42257 21.9225 9.86078V9.92681C21.9525 10.36 21.6053 10.7252 21.1721 10.7252H15.2203C14.7021 10.7252 14.2449 11.1094 14.2138 11.6216C14.1898 12.1699 14.622 12.6221 15.1653 12.6221H20.8079C21.2471 12.6221 21.6063 12.9822 21.6063 13.4205L21.6003 13.4755V13.4875C21.6063 13.9507 21.2281 14.3289 20.7649 14.3289H16.8531C16.3348 14.3289 15.8776 14.7131 15.8466 15.2313C15.8226 15.7736 16.2548 16.2318 16.7981 16.2318H20.941C21.3982 16.2318 21.7394 16.616 21.7334 17.0732C21.7334 17.0792 21.7334 17.0792 21.7334 17.0852C21.7334 17.0912 21.7334 17.0912 21.7334 17.0972C21.7394 17.5544 21.3982 17.9386 20.941 17.9386H16.606C16.0937 17.9386 15.6365 18.3228 15.6055 18.835C15.5815 19.3843 16.0137 19.8355 16.5569 19.8355H17.7005C21.4853 19.0471 24.3356 15.6885 24.3356 11.6766Z"
        fill="white"
      />
      <path
        d="M21.8183 9.83075H17.7644L16.5108 5.97494C16.3497 5.47971 15.6484 5.47971 15.4873 5.97494L14.2347 9.83075H10.1808C9.6596 9.83075 9.4425 10.4981 9.86469 10.8042L13.1442 13.1873L11.8916 17.0431C11.7306 17.5384 12.2978 17.9506 12.72 17.6444L15.9996 15.2613L19.2791 17.6444C19.7013 17.9506 20.2686 17.5384 20.1075 17.0431L18.8549 13.1873L22.1344 10.8042C22.5556 10.4981 22.3395 9.83075 21.8183 9.83075Z"
        fill="#EC9922"
      />
    </svg>
  );
}
