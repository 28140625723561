import { RatingCircle, RoundedButton } from "@rocketlanguages/ui";
import type { Character, TranscriptRatings } from "@rocketlanguages/types";
import { actions as playItActions, useGlobalPlayItStore, usePlayItEvents } from "./includes/usePlayIt";
import { useContext, useEffect, useState } from "react";
import AudioContext from "../../../../ui/Audio/AudioContext";
import { clsx } from "clsx";
import usePlayItRatings from "../../../../hooks/usePlayItRatings";
import useTranslation from "../../../../hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  characters: Character[];
  numLines: number;
  transcriptId: number;
};

export default function PlaybackButtons({ characters, numLines, transcriptId }: Props) {
  const t = useTranslation();
  const playIt = useGlobalPlayItStore();
  const audioContext = useContext(AudioContext);
  const location = useLocation();
  const navigate = useNavigate();

  const playItRatings = usePlayItRatings(transcriptId);

  /** Shows or hides character buttons */
  const [displayCharacterButtons, setDisplayCharacterButtons] = useState(hasRatings(playItRatings));

  // Effect to show the character buttons if transcript ratings are updated
  useEffect(() => {
    if (!displayCharacterButtons && hasRatings(playItRatings)) {
      setDisplayCharacterButtons(true);
    }
  }, [displayCharacterButtons, playItRatings]);

  usePlayItEvents({
    onStart: (state) => {
      if (state.transcriptId === transcriptId) {
        audioContext.activePlayer?.pause();
      }
    },
    onEnd: (state) => {
      if (state.transcriptId === transcriptId) {
        // this is to re-enable the popups while doing this activity
        navigate(location.pathname, { replace: true, preventScrollReset: true });
      }
    },
  });

  /**
   * Dispatches a `start` action to the PlayIt context
   *
   * @see PlayIt.tsx for state reducer updates
   */
  const startPlayIt = (characterId: number) => {
    useGlobalPlayItStore.dispatch(
      playItActions.start({
        transcriptId,
        characterId,
        numLines,
        format: "audio",
      }),
    );
  };

  const isActive = playIt.transcriptId === transcriptId && playIt.status === "active";
  const isListening = playIt.transcriptId === transcriptId && playIt.mode === "listen";
  const isTest = playIt.transcriptId === transcriptId && playIt.mode === "test";

  return (
    <div className="grid w-full grid-cols-2 gap-2 lg:grid-cols-3 lg:items-end print:hidden">
      <RoundedButton
        className={clsx(
          "hover:bg-missilebrand/90 order-2 col-span-2 w-full whitespace-nowrap bg-missilebrand px-8 font-semibold text-white lg:order-none lg:col-span-1",
          isActive && isTest && "opacity-50",
        )}
        onClick={() => {
          if (isListening && isActive) {
            useGlobalPlayItStore.dispatch({ type: "ABORT" });
          } else {
            useGlobalPlayItStore.dispatch(
              playItActions.listenConversation({
                transcriptId,
                numLines,
                format: "audio",
              }),
            );
          }
        }}
      >
        {isListening && isActive ? t("stop") : t("play-conversation")}
      </RoundedButton>
      <div className="col-span-2 flex w-full items-center gap-2">
        {characters.map((char) => {
          const rating = playItRatings[char.id];

          return (
            <div key={`char.${char.id}`} className="w-full space-y-2">
              <div className="flex flex-1 justify-center">
                <RatingCircle percentage={rating || 0} />
              </div>
              <RoundedButton
                className={clsx(
                  "dark:hover:bg-missilebrand/90 w-full whitespace-nowrap border-2 border-missilebrand px-8 font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white",
                  isActive && (isListening || !(playIt.characterId === char.id && isTest)) && "opacity-50",
                )}
                onClick={() => {
                  audioContext.activePlayer?.pause();
                  if (isActive && playIt.characterId === char.id) {
                    useGlobalPlayItStore.dispatch({ type: "ABORT" });
                  } else {
                    startPlayIt(char.id);
                  }
                }}
              >
                {isActive && playIt.characterId === char.id && isTest
                  ? t("stop")
                  : t("play-as-{hostname}", { hostname: char.name })}
              </RoundedButton>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function hasRatings(ratings?: TranscriptRatings) {
  if (!ratings || Array.isArray(ratings) || typeof ratings !== "object") {
    return false;
  }

  return Object.keys(ratings).length > 0;
}
