import type { Phrase, VideoComponent } from "@rocketlanguages/types";
import memoizeOne from "memoize-one";
import { purgeString } from "../../utils/stringUtils";
import { RateableTestTypeIds } from "../../utils/constants";
import { ComponentTypeIds } from "../useRateableTest/utils";
import { updateComponentRatings } from "../../store/lesson/actions";
import { useLivePhrases } from "./useTestPhrases";
import { useMemo } from "react";
import { useSharedDispatch, useSharedSelector, useSharedStore } from "../../store";
import { translateItVideosSelector } from "../../store/selectors";
import { UniqueSet } from "../../utils";

/**
 * This will be the total list of phrases that can be in the test. Used for showing the number of phrases.
 *
 * `usePhraseTest.computed.testPhrases` is a filtered & sorted list of this object based on user ratings & test mode.
 */
export function useAllComponents({
  rateableTestId,
  lessonId,
  testTypeId,
  phraseFilter,
}: {
  rateableTestId: number;
  lessonId: number;
  testTypeId: number;
  phraseFilter?: (phrase: Phrase) => boolean;
}) {
  const store = useSharedStore();
  const lessonPhraseIds = useSharedSelector((state) => state.lesson.entities.lesson_phrase_ids[lessonId]);
  const allPhrases = useSharedSelector((state) => state.lesson.entities.phrases);
  // Note: globally memoized so we don't have to re-filter on every phrase test
  const lessonPhrases = getUniqueNonExcludedLessonPhrases(lessonPhraseIds, allPhrases);

  const livePhrases = useLivePhrases({
    phrases: lessonPhrases,
    lessonId,
  });

  // Phrase tests can have custom filters (e.g. sort it)
  const phrases = useMemo(() => {
    return phraseFilter ? livePhrases.filter(phraseFilter) : livePhrases;
  }, [livePhrases, phraseFilter]);

  const isTranslateIt = testTypeId === RateableTestTypeIds.TRANSLATE_IT;

  const allComponents = useMemo(
    () => ({
      phrases,
      videoComponents: isTranslateIt ? translateItVideosSelector(store, lessonId) : [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phrases, isTranslateIt],
  );

  // Remove user component ratings that aren't part of the current test in redux store
  // Because ratings come from our server, our client-side phrase filtering can change at any moment:
  // - we don't want to show phrases no longer part of the test under the review "non easy phrases" list
  // - we don't want to show extra numbers next to the rating buttons (returned by useRatingGroups)
  useComponentRatingFilter(rateableTestId, allComponents, isTranslateIt);

  return allComponents;
}

/** Note: Globally memoized as we don't want go through the same filtering process for all phrase tests in the lesson */
export const getUniqueNonExcludedLessonPhrases = memoizeOne(
  (lessonPhraseIds: number[] = [], allPhrases: { [key: number]: Phrase | undefined } = {}) => {
    const uniqueSet = new UniqueSet<Phrase>();

    for (const phraseId of lessonPhraseIds) {
      const phrase = allPhrases[phraseId];
      if (phrase && phrase.exclude_tests !== 1 && phrase.strings[0]) {
        const key = purgeString(phrase.strings[0].text, {
          spaces: true,
          punctuation: true,
          html: true,
          markdown: true,
        });
        uniqueSet.add(key, phrase);
      }
    }

    const uniquePhrases = uniqueSet.toArray();

    /*
    if (lessonPhraseIds.length !== uniquePhrases.length) {
      const removedPhrases = lessonPhraseIds.reduce((acc, curr) => {
        const phrase = allPhrases[curr];
        if (phrase && !uniquePhrases.some((phrase) => phrase.id === curr)) {
          acc.push(phrase);
        }
        return acc;
      }, [] as Phrase[]);

      console.warn("[getUniqueNonExcludedLessonPhrases] removed", removedPhrases.length, "phrases", {
        after: uniquePhrases,
        removedPhrases,
      });
    }
    */

    return uniquePhrases;
  },
);

/** Filters "user_rateable_test_component_ratings" on the current rateable test against our test components */
function useComponentRatingFilter(
  rateableTestId: number,
  allComponents: { phrases: Phrase[]; videoComponents: VideoComponent[] },
  isTranslateIt: boolean,
) {
  const dispatch = useSharedDispatch();
  const ratings = useSharedSelector(
    (state) => state.lesson.entities.user_rateable_test_component_ratings[rateableTestId],
  );

  useMemo(() => {
    const arr = isTranslateIt ? allComponents.videoComponents : allComponents.phrases;

    const componentTypeId = ComponentTypeIds[isTranslateIt ? "video" : "phrase"];

    // If ratings are set
    if (Array.isArray(ratings)) {
      const filteredRatings = ratings.filter(
        (rating) =>
          rating.component_type_id === componentTypeId &&
          arr.some((component: Phrase | VideoComponent) => component.id === rating.component_id),
      );

      // Filtered ratings would be less than the user component ratings
      if (filteredRatings.length !== ratings.length) {
        dispatch(
          updateComponentRatings({
            rateableTestId: rateableTestId,
            componentRatings: filteredRatings,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allComponents.phrases, allComponents.videoComponents, rateableTestId]);
}
