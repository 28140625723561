import { useSharedDispatch, useSharedSelector } from "../../../../../store";
import { useGlobalPlayItStore } from "./usePlayIt";
import type { Phrase, TimeoutType, TranscriptLine } from "@rocketlanguages/types";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import useInterval from "../../../../../hooks/useInterval";
import clsx from "clsx";
import { Phrasebox, useWebRocketRecord } from "../../../../PhraseboxFacelift/PhraseboxFacelift";
import { PlayItContext } from "./context";
import AudioRecorder from "../../../../Phrasebox/includes/RocketRecord/AudioRecorder";
import Voice from "../../../../Phrasebox/includes/RocketRecord/Voice";
import { WritingSystemIds } from "../../../../../utils/constants";
import { phraseStringTransformer } from "./utils";
import { requestAddPoints } from "../../../../../store/lesson/actions";

export function PlayItVideoPlayer(props: { lessonId: number; transcriptId: number; characterId?: number }) {
  const transcriptComponent = useTranscriptComponent(props.lessonId, props.transcriptId);
  const phrases = useSharedSelector((store) => store.lesson.entities.phrases);
  const playIt = useGlobalPlayItStore();
  const currentVideoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useSharedDispatch();

  useEffect(() => {
    const video = currentVideoRef.current;
    if (!video) {
      return;
    }

    const abortController = new AbortController();

    const startPlayIt = () => {
      setIsLoading(false);
      if (props.characterId) {
        useGlobalPlayItStore.dispatch({
          type: "START",
          payload: {
            transcriptId: props.transcriptId,
            characterId: props.characterId,
            numLines: transcriptComponent?.lines.length || 0,
            format: "video",
          },
        });
      } else {
        useGlobalPlayItStore.dispatch({
          type: "LISTEN_CONVERSATION",
          payload: {
            transcriptId: props.transcriptId,
            numLines: transcriptComponent?.lines.length || 0,
            format: "video",
          },
        });
      }
    };

    const timeoutId = setTimeout(() => startPlayIt(), 3000);
    video.addEventListener(
      "canplay",
      () => {
        startPlayIt();
        clearTimeout(timeoutId);
      },
      { signal: abortController.signal, once: true },
    );

    return () => {
      clearTimeout(timeoutId);
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const video = currentVideoRef.current;
    if (video && playIt.status === "active") {
      video.play();
    }
  }, [playIt.status, playIt.index]);

  if (!transcriptComponent) {
    console.warn("No transcript component found for", props.transcriptId);
    return null;
  }

  const currentLine = transcriptComponent.lines[playIt.index];
  if (!currentLine) {
    console.warn("No current line found for", props.transcriptId);
    return null;
  }

  const currentPhrase = phrases[currentLine.phrase_id];
  if (!currentPhrase) {
    console.warn("No current phrase found for", props.transcriptId);
    return null;
  }

  const nextLine = transcriptComponent.lines[playIt.index + 1];
  const isRecordLine = props.characterId ? currentLine.character?.id === props.characterId : false;

  return (
    <div className="relative h-full">
      <>
        <Video
          key={currentLine.phrase_id}
          ref={currentVideoRef}
          line={currentLine}
          isRecordLine={props.characterId ? currentLine.character?.id === props.characterId : false}
          transcriptId={props.transcriptId}
          duration={currentPhrase.duration || undefined}
          onPlayFinish={() => {
            if (!isRecordLine) {
              useGlobalPlayItStore.dispatch({ type: "NEXT" });
              dispatch(
                requestAddPoints({
                  rewardType: "phrasePlay",
                  data: {},
                }),
              );
            }
          }}
        />
        {nextLine ? (
          <Video
            key={nextLine.phrase_id}
            line={nextLine}
            isRecordLine={props.characterId ? nextLine.character?.id === props.characterId : false}
            hidden
            transcriptId={props.transcriptId}
            duration={currentPhrase.duration || undefined}
          />
        ) : null}
      </>
      <div className="absolute bottom-0 left-0 right-0 z-20 flex flex-col justify-end">
        <PlayItStepper currentIndex={playIt.index} numLines={transcriptComponent.lines.length} />
        <div className="min-h-40 bg-surface1">
          <PlayItPhrasebox
            phrase={currentPhrase}
            line={currentLine}
            transcriptId={props.transcriptId}
            index={playIt.index}
            isRecordLine={isRecordLine}
          />
        </div>
      </div>
      <PlayItOverlay loading={isLoading} />
    </div>
  );
}

function PlayItStepper(props: { numLines: number; currentIndex: number; children?: React.ReactNode }) {
  const { numLines, currentIndex } = props;
  return (
    <div className="flex w-full flex-row items-center justify-center gap-2 px-4 py-2">
      {Array.from({ length: numLines }, (_, i) => (
        <div
          key={i}
          className={clsx("h-1 grow rounded-2xl bg-white", i <= currentIndex ? "opacity-100" : "opacity-40")}
        />
      ))}
      {props.children}
    </div>
  );
}

const Video = forwardRef<
  HTMLVideoElement,
  {
    isRecordLine: boolean;
    transcriptId: number;
    line: TranscriptLine;
    hidden?: boolean;
    duration?: number;
    onPlayFinish?: () => void;
  }
>((props, ref) => {
  const videoUrl = props.isRecordLine ? props.line.waiting_video_url : props.line.video_url;
  const active = useGlobalPlayItStore(
    (state) => state.transcriptId === props.transcriptId && state.status === "active",
  );

  const isActiveNonRecordLine = active && !props.isRecordLine;
  // Effect to handle playback timeouts
  // This may occur if the audio fails to load or play
  // We need to move to the next line in this case
  // to prevent the app from getting "stuck"
  useEffect(() => {
    if (!isActiveNonRecordLine) {
      return;
    }

    const timeoutDuration = (props.duration || 6000) + 10000;
    const timeout = setTimeout(() => {
      if (useGlobalPlayItStore.getState().status === "active") {
        // useGlobalPlayItStore.dispatch({ type: "NEXT" });
      }
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, [props.duration, isActiveNonRecordLine]);

  if (!videoUrl) {
    throw new Error("No video url found");
  }

  return (
    <video
      className={clsx("absolute bottom-32 aspect-[9/16] w-full bg-black", !props.hidden && "z-10")}
      src={videoUrl.replace("16x9", "9x16")}
      ref={ref}
      loop={props.isRecordLine}
      preload="auto"
      onEnded={props.onPlayFinish}
    />
  );
});

function PlayItOverlay(props: { loading: boolean }) {
  const secondsCounter = useGlobalPlayItStore((state) => state.secondsCounter);

  useInterval(
    () => useGlobalPlayItStore.dispatch({ type: "TICK" }),
    secondsCounter >= 1 && !props.loading ? 1000 : null,
  );

  if (secondsCounter === 0 && !props.loading) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-black/60">
      <span className={clsx("text-center text-white", props.loading ? "text-xl" : "text-6xl font-bold")}>
        {props.loading ? "Loading conversation..." : secondsCounter}
      </span>
    </div>
  );
}

function PlayItPhrasebox(props: {
  phrase: Phrase;
  line: TranscriptLine;
  transcriptId: number;
  index: number;
  isRecordLine: boolean;
}) {
  const isActive = useGlobalPlayItStore(
    (state) => state.format === "video" && state.transcriptId === props.transcriptId && state.status === "active",
  );
  const recordFinishTimeoutRef = useRef<TimeoutType>();
  const playItContext = useContext(PlayItContext);
  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug) || "";

  const rocketRecord = useWebRocketRecord({
    phrase: props.phrase,
    onRecordFinish: ({ phraseId, result, blobUrl }) => {
      if (!result || result.ratingLevel === 0) {
        return;
      }

      // Clear any previous timeouts to avoid double-entry
      clearTimeout(recordFinishTimeoutRef.current);

      // Timeout awaits layout render change (because rendering text from tts)
      recordFinishTimeoutRef.current = setTimeout(() => {
        const state = useGlobalPlayItStore.getState();
        const isRecordLine = Boolean(props.line.character && props.line.character.id === state.characterId);
        const active =
          state.format === "video" && state.transcriptId === props.transcriptId && state.status === "active";
        // Avoid dispatching next on a line that's not active
        const isThisLineActive = Boolean(state.index === props.index);
        if (isRecordLine && active && isThisLineActive) {
          useGlobalPlayItStore.dispatch({ type: "RECORD_FINISH", payload: { phraseId, result, blobUrl } });
          useGlobalPlayItStore.dispatch({ type: "NEXT" });
        }
      }, 750);
    },
  });

  useEffect(() => {
    return () => {
      const { actions, flag } = rocketRecord.useRocketRecordState.getState();
      if (["ACTIVE", "STARTING", "FINISHING"].includes(flag.status)) {
        // Clear speech timeouts
        actions.clearTimeouts();
        AudioRecorder?.finishRecording(false);
        Voice.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.index]);

  const canRecord = props.phrase.disable_voice_recognition === 0 && props.isRecordLine;
  const shouldRecord = isActive && canRecord;

  const hasError = rocketRecord.useRocketRecordState((state) => state.flag.status === "ERROR");

  useEffect(() => {
    if (shouldRecord) {
      void rocketRecord.methods.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.index, shouldRecord]);

  if (!playItContext) {
    console.warn("No playItContext found");
    return null;
  }
  const hasMoreThanOneWritingSystem = props.phrase.strings.length > 2;

  return (
    <Phrasebox rocketRecord={rocketRecord}>
      <div className="p-4">
        <div className={clsx("flex gap-2", canRecord ? "mb-4 border-b border-b-missilestroke pb-4" : "")}>
          <Phrasebox.StringsContainer>
            <Phrasebox.Strings filteredWritingSystemIds={[...playItContext.filteredWritingSystems.values()]}>
              {(phraseString, index, items) => {
                const isLast = index === items.length - 1;
                const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;

                let text = phraseString.text;

                const isTranslation =
                  phraseString.writing_system_id === WritingSystemIds.english &&
                  activeCourseSlug !== "english" &&
                  activeCourseSlug !== "ingles";

                const isNativeTranslation =
                  activeCourseSlug === "ingles" && phraseString.writing_system_id === WritingSystemIds.spanish;

                const shouldHideSecondWord = playItContext.hideWords && !isTranslation && !isNativeTranslation;

                if (shouldHideSecondWord) {
                  const transformer =
                    phraseStringTransformer[phraseString.writing_system_id] || phraseStringTransformer.default;
                  text = transformer(phraseString.text, phraseString.writing_system_id === WritingSystemIds.arabic);
                }

                return (
                  <div
                    className={shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined}
                  >
                    <Phrasebox.String {...phraseString} text={text} disableVocabUnderline />
                  </div>
                );
              }}
            </Phrasebox.Strings>
          </Phrasebox.StringsContainer>
        </div>

        <div className="flex min-h-12 items-center">
          <Phrasebox.RowLayout className="items-center">
            {hasError ? (
              <Phrasebox.RecordButton className="hover:bg-missileaccent/90 bg-missileaccent text-white" />
            ) : null}

            <Phrasebox.SpeechLayout className="flex-1">
              <Phrasebox.Speech />
              <Phrasebox.ErrorText />
              <Phrasebox.DebugPanel />
            </Phrasebox.SpeechLayout>
          </Phrasebox.RowLayout>
        </div>
      </div>
    </Phrasebox>
  );
}

function useTranscriptComponent(lessonId: number, transcriptId: number) {
  const lessonComponents = useSharedSelector((store) => store.lesson.entities.lesson_components[lessonId]);
  const transcript = lessonComponents?.find(
    (c) => c.type === "transcript" && c.component.id === transcriptId && c.component.lines.length >= 4,
  );

  if (!transcript || transcript.type !== "transcript") {
    return;
  }

  return transcript.component;
}
