import { RoundedButton } from "@rocketlanguages/ui";
import { useState } from "react";

import { Client } from "@rocketlanguages/shared/res/Api";
import { Link } from "react-router-dom";
import { getErrorMessage } from "@rocketlanguages/shared/utils";
import { toast } from "react-toastify";
import usePromise from "@rocketlanguages/shared/hooks/usePromise";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import FaceliftPage from "~/components/FaceliftPage";

export default function ForgotPasswordPage() {
  const t = useTranslation();
  const [email, setEmail] = useState("");
  const { state, execute } = usePromise<{ error: boolean; message: string } | void>();
  const isSuccessful = state?.status === "loaded";

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    execute(() =>
      Client.axios
        .post<{ error: boolean; message: string }>("v2/password/forgot", { email })
        .then((res) => res.data)
        .catch((e) => {
          toast.error(getErrorMessage(e));
          throw Error;
        }),
    );
  }

  function updateEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
  }

  return (
    <FaceliftPage title="Forgot Password" className="mx-auto max-w-2xl space-y-5 p-4">
      <div className="space-y-8 overflow-hidden rounded-3xl border border-missilestroke bg-surface2 p-12">
        <div className="space-y-4">
          <h1 className="text-3xl font-bold text-missilebrand dark:text-white">{t("forgot-your-password")}</h1>
          {!isSuccessful ? (
            <h2 className="text-sm font-normal text-missilegray dark:text-gray-300">
              {t("forgot-your-password-description")}
            </h2>
          ) : null}
        </div>

        {state?.status === "error" && (
          <div className="text-sm font-semibold text-missileaccent">
            {state?.errorText || "It looks like something went wrong. Please try again."}
          </div>
        )}

        {isSuccessful && (
          <div className="flex flex-col gap-2 rounded-3xl bg-missilesurfacelight p-4">
            <p>We have e-mailed your password reset link.</p>
            <p>Please check your inbox and spam folders.</p>
            <p>Note that the link is only valid for 60 minutes, so check your inbox now.</p>
          </div>
        )}

        {!isSuccessful && (
          <form method="post" action="/user/login" onSubmit={submit} className="space-y-4">
            <div>
              <label className="space-y-1">
                <div className="flex gap-1 text-xs font-bold text-missilegray dark:text-gray-300">
                  <span>{t("email-address")}</span>
                  <span className="text-missileaccent">*</span>
                </div>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={updateEmail}
                  required
                  minLength={3}
                  maxLength={255}
                  className="w-full rounded-full border border-missilestroke bg-transparent px-5 py-3 leading-5 text-missilegray dark:text-gray-300"
                  placeholder="Your email"
                />
              </label>
            </div>

            <RoundedButton
              className="mx-auto w-full max-w-96 bg-missileaccent font-semibold text-white hover:bg-missileaccent/90"
              type="submit"
              loading={state?.status === "loading"}
            >
              {t("email-me-the-password-reset-link")}
            </RoundedButton>
          </form>
        )}
      </div>
      <div className="flex justify-center">
        <Link className="inline-block text-sm font-semibold text-missilegray underline dark:text-gray-300" to="/login">
          {t("back-to-login")}
        </Link>
      </div>
    </FaceliftPage>
  );
}
