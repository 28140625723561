import CongratulationsMessage from "../../RateableTestUI/includes/CongratulationsMessage";
import ResetTestButton from "../../RateableTestUI/buttons/ResetTestButton";
import { TrophyConfettiSvg, TrophySvg } from "../../RateableTestUI/CompleteFacelift";
import { Link } from "react-router-dom";
import { RoundedButton } from "@rocketlanguages/ui";
import useTranslation from "../../../../hooks/useTranslation";

export default function DrawItComplete(props: {
  completedWithinSession: boolean;
  easyCount: number;
  goodCount: number;
  hardCount: number;
  numQuestions: number;
  redoNonEasyCharacters: () => void;
  onReset: () => void;
  nextActivityTitle?: string;
  nextActivityHref?: string;
}) {
  const t = useTranslation();

  const numNonEasy = (props.goodCount || 0) + props.hardCount;
  return (
    <div className="mx-auto flex w-96 max-w-96 flex-1 flex-col justify-evenly gap-y-8">
      <div className="relative h-20">
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <TrophySvg />
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <TrophyConfettiSvg />
        </div>
      </div>
      {props.completedWithinSession ? <CongratulationsMessage /> : null}
      <div className="rounded-2xl border border-missilestroke p-3">
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Easy</div>
          <div className="h-5 w-9 rounded-lg bg-missilegreen px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">{props.easyCount}</div>
          </div>
        </div>
        <div className="my-4 border border-dashed border-missilestroke" />
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Good</div>
          <div className="h-5 w-9 rounded-lg bg-orange-500 px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">{props.goodCount}</div>
          </div>
        </div>
        <div className="my-4 border border-dashed border-missilestroke" />
        <div className="flex items-center justify-between">
          <div className="text-sm font-bold leading-5">Hard</div>
          <div className="h-5 w-9 rounded-lg bg-missileaccent px-2 py-1">
            <div className="text-center text-xs font-semibold leading-3 text-white">{props.hardCount}</div>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {props.nextActivityHref && props.nextActivityTitle ? (
          <Link to={props.nextActivityHref}>
            <RoundedButton className="hover:bg-missileaccent/90 w-full bg-missileaccent font-semibold text-white">
              {props.nextActivityTitle}
            </RoundedButton>
          </Link>
        ) : null}
        {numNonEasy > 0 ? (
          <RoundedButton
            onClick={props.redoNonEasyCharacters}
            className="w-full bg-missileaccent font-semibold text-white"
          >
            Redo {numNonEasy} hard characters
          </RoundedButton>
        ) : null}
        <ResetTestButton className="w-full" testName={t("draw-it")} onConfirmReset={props.onReset} />
      </div>
    </div>
  );
}
