import { detect } from "detect-browser";

/** Cross-platform web & native Device API */
export default {
  async getDeviceInfo(userId?: number) {
    const browser = detect();
    const device = browser
      ? {
          device_uuid: `${userId}-${browser.name}-${browser.version}`,
          platform: browser?.name || "Unknown",
          version: browser?.version || "Unknown",
          app_version: "web",
        }
      : {
          device_uuid: `${userId}-unknown`,
          platform: "Unknown",
          version: "Unknown",
          app_version: "web",
        };

    return device;
  },
  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
};
