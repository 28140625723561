import { createContext, memo, useCallback, useMemo } from "react";
import API from "../../../res/Api";
import type { VocabMap } from "@rocketlanguages/types";
import usePromise from "../../../hooks/usePromise";
import useActiveCourse from "../../../hooks/useActiveCourse";
import { noop, omit } from "../../../utils";

export const VocabContext = createContext<{
  map: VocabMap;
  set(pair: VocabMap): void;
  remove(key: string): void;
}>({
  map: {},
  set: noop,
  remove: noop,
});

type Props = {
  children: React.ReactNode;
};

/**
 * Fetches the vocab map from the server, provides map of `{[word]:Vocab}` to all children (using `MyVocabContext`)
 *
 * Also provides an API to add/remove words (`AddToVocabModal` uses this)
 *
 * @example

 ```jsx
 import { MyVocabContext, MyVocabProvider } from "components/Lesson/MyVocab";

 function Lesson() {
    return (
      <MyVocabProvider>
        <RandomLessonComponent />
      </MyVocabProvider>
    )
 }


 function RandomLessonComponent() {
    const { words, add, remove } = useContext(MyVocabContext)!;

    return (
      <div>
        <h3>Words</h3>
        <code>{JSON.stringify(words)}</code>
        <Button onClick={() => add({ hello: "hola" })}>Add new word</Button>
        <Button onClick={() => remove("hello")}>Remove word</Button>
      </div>
    );
 }
 ```
 */
function VocabProvider(props: Props) {
  const courseId = useActiveCourse()?.id || 0;

  const promise = useCallback(() => API.getJson(["v2/vocab/course/{course}/map", { course: courseId }]), [courseId]);

  const { state, mutate } = usePromise(promise, { cacheKey: `vocab-map-${courseId}` });

  const contextValue = useMemo(
    () => ({
      set(pair: VocabMap) {
        if (state?.status !== "loaded" || !state.data) {
          return;
        }
        mutate({ ...state.data, ...pair });
      },
      remove(key: string) {
        if (state?.status !== "loaded" || !state.data) {
          return;
        }
        mutate(omit(state.data, key));
      },
      // https://libros-media.sentry.io/issues/4571994831/
      map: state?.status === "loaded" && state.data ? state.data : {},
    }),
    [mutate, state],
  );

  return <VocabContext.Provider value={contextValue}>{props.children}</VocabContext.Provider>;
}
const MemoizedVocabProvider = memo(VocabProvider);

export default MemoizedVocabProvider;
