import type { AdminUser, PermissionTypes } from "@rocketlanguages/types";

import { PermissionsIdsBySlug } from "../utils/constants";
import { useMemo } from "react";
import { useSharedSelector } from "../store";
import { shallowEqual } from "react-redux";
import type { UserState } from "../store/user/types";

type PartialUser = Partial<UserState> & { permission_ids: number[] | null };

/** Returns `true` or `false` whether a user has a given permission */
export function can(user: PartialUser, ...permissions: PermissionTypes[]) {
  const permissionIds = user.permission_ids || [];
  return permissions.some((permission) => permissionIds.includes(PermissionsIdsBySlug[permission]));
}

/** Returns `true` only if user has all the listed permissions */
export default function useHasPermission(...permissions: PermissionTypes[]) {
  const userPermissions = useSharedSelector((store) => store.user.permission_ids || [], shallowEqual);
  return useMemo(
    () => !permissions.some((slug) => !userPermissions.includes(PermissionsIdsBySlug[slug])),
    [permissions, userPermissions],
  );
}

/** Returns `true` only if user has all the listed permissions */
export function useUserHasPermission(user: AdminUser | null, ...permissions: PermissionTypes[]) {
  return useMemo(() => {
    if (!user) {
      return false;
    }
    for (const slug of permissions) {
      if (!user.roles.some((r) => r.permission_ids.includes(PermissionsIdsBySlug[slug]))) {
        return false;
      }
    }
    return true;
  }, [permissions, user]);
}

/** Returns `true` if user has any of the given permissions  */
export function useUserHasAnyPermission(user: AdminUser | null, ...permissions: PermissionTypes[]) {
  return useMemo(() => {
    if (!user) {
      return false;
    }
    for (const slug of permissions) {
      if (user.roles.some((r) => r.permission_ids.includes(PermissionsIdsBySlug[slug]))) {
        return true;
      }
    }
    return false;
  }, [permissions, user]);
}

/** Returns `true` if user has any of the given permissions  */
export function useHasAnyPermission(...permissions: PermissionTypes[]) {
  const userPermissions = useSharedSelector((store) => store.user.permission_ids || [], shallowEqual);
  return useMemo(
    () => permissions.some((slug) => userPermissions.includes(PermissionsIdsBySlug[slug])),
    [permissions, userPermissions],
  );
}
