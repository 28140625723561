import { type ReactNode, useState } from "react";
import BuyNowModal from "~/components/BuyNowModal";
import type { RateableTestEntity } from "@rocketlanguages/types";
import { Link } from "react-router-dom";
import { TooltipContent, TooltipFacelift, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui";
import { memo } from "react";
import useLessonRateableTests, { useRateableTestRating } from "@rocketlanguages/shared/hooks/useLessonRateableTests";
import useLessonStatus from "@rocketlanguages/shared/hooks/useLessonStatus";
import { withoutExtraTestsAndEnabled } from "@rocketlanguages/shared/utils";
import { LessonTypeId } from "@rocketlanguages/shared/utils/constants";
import { Check as CheckIcon, Lock as LockIcon, NavArrowRight as NavArrowRightIcon } from "iconoir-react";
import RateableTestTooltip from "~/components/ui/RateableTestTooltip";
import { clsx } from "clsx";
import type { LessonEntity } from "@rocketlanguages/types";
import type { SectionData } from "../utils/selector";
import { filters } from "@rocketlanguages/shared/utils/constants-web";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import RateableTestUI from "@rocketlanguages/shared/components/RateableTests/RateableTestUI";

export function ModuleItem({
  subsections,
  showSectionTitles,
  suggestedLessonId,
}: {
  subsections: SectionData[];
  suggestedLessonId: number;
  showSectionTitles: boolean;
}) {
  const t = useTranslation();
  const lessonStatus = useStoreSelector((store) => store.lesson.entities.user_lesson_status);

  return (
    <div className={clsx("flex flex-col gap-6")}>
      {subsections.map((subsection) => {
        const filter = filters[subsection.code];
        const Icon = filter?.icon;
        return (
          <div key={`s.${subsection.id}`} className="space-y-2">
            {showSectionTitles && (
              <div className="flex items-center gap-2">
                {Icon && <Icon strokeWidth={2} className="size-5" />}
                <div>{filter ? t(filter.code) : "Lessons"}</div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              {subsection.lessons.map((lesson) => (
                <MemoizedLessonItem
                  key={lesson.id}
                  lesson={lesson}
                  suggested={suggestedLessonId === lesson.id}
                  isAvailable={Boolean(lessonStatus[lesson.id]?.is_available)}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

type LessonItemProps = {
  lesson: LessonEntity;
  isAvailable: boolean;
  suggested: boolean;
  /** Progress 1-100 */
};

const MemoizedLessonItem = memo(LessonItem);

export function LessonItem({ lesson, isAvailable, suggested }: LessonItemProps) {
  const t = useTranslation();
  return (
    <LessonLink
      className={clsx(
        suggested && "shadow-sm",
        "flex gap-4 overflow-hidden rounded-2xl border border-missilestroke bg-missilesurfacelight px-3 py-4 hover:bg-missilestroke/60 focus:outline-none focus:ring focus-visible:ring dark:hover:bg-missilesurfacelight/70",
      )}
      lessonId={lesson.id}
      isAvailable={isAvailable}
    >
      <div className="relative size-12 min-h-12 min-w-12 overflow-hidden rounded-2xl bg-missilegray md:size-20 md:min-h-20 md:min-w-20">
        {lesson.image_url ? (
          <img src={lesson.image_url} alt={lesson.name} className="h-full w-full object-cover" />
        ) : null}
        {!isAvailable ? (
          <div className="absolute top-0 flex size-full items-center justify-center bg-black/50 p-4">
            <div className="flex size-8 min-h-8 min-w-8 items-center justify-center rounded-xl bg-missileaccent">
              <LockIcon className="text-lg text-white" />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex-1">
        <div className="flex items-center gap-2 text-left font-medium">
          <span className="flex flex-row items-center gap-1">
            {lesson.status === "hidden" || lesson.status === "draft" ? <span>{`[${lesson.status}]`}</span> : null}
            {lesson.number?.length > 0 ? <span>{lesson.number}</span> : null}
            <span>{lesson.name}</span>
          </span>
          <Tags lessonId={lesson.id} />
          {suggested && (
            <div className="flex-1 text-right font-bold text-missileaccent dark:text-[#f86a62]">
              <span className="flex items-center justify-end gap-1">
                <span className="hidden md:inline-block">{t("continue")}</span> <NavArrowRightIcon strokeWidth={2} />
              </span>
            </div>
          )}
        </div>
        <div className="block md:hidden">
          <LessonProgress
            lessonDurationMinutes={lesson.duration_minutes}
            isLessonAvailable={isAvailable}
            isLessonIAC={lesson.lesson_type_id === LessonTypeId.IAC}
          />
        </div>
        <div className="flex h-1 items-center py-3">
          <div className="h-[2px] w-full bg-missilestroke" />
        </div>
        <div className="flex items-center justify-between gap-2">
          <LessonItemTestIcons lesson={lesson} />
          <div className="hidden md:block">
            <LessonProgress
              lessonDurationMinutes={lesson.duration_minutes}
              isLessonAvailable={isAvailable}
              isLessonIAC={lesson.lesson_type_id === LessonTypeId.IAC}
            />
          </div>
        </div>
      </div>
    </LessonLink>
  );
}

function LessonProgress({
  lessonDurationMinutes,
  isLessonAvailable,
  isLessonIAC,
}: {
  lessonDurationMinutes?: number | null;
  isLessonAvailable: boolean;
  isLessonIAC: boolean;
}) {
  return (
    <div className={clsx("text-sm", !isLessonAvailable && "text-missilegray2")}>
      {lessonDurationMinutes ? (
        <>
          <TooltipProvider delayDuration={200}>
            <TooltipFacelift>
              <TooltipContent className="text-nowrap">
                {isLessonIAC
                  ? "The audio track play time (does not include activity time)"
                  : "The estimated lesson read time (does not include activity time)"}
              </TooltipContent>
              <TooltipTrigger>
                <div>{lessonDurationMinutes} min</div>
              </TooltipTrigger>
            </TooltipFacelift>
          </TooltipProvider>
        </>
      ) : null}
    </div>
  );
}

function LessonLink(props: { isAvailable: boolean; lessonId: number; children: ReactNode; className?: string }) {
  const productId = useStoreSelector((store) => store.preferences.activeProduct?.id || 0);

  if (!props.isAvailable) {
    return <UnavailableLessonLink className={props.className}>{props.children}</UnavailableLessonLink>;
  }

  return (
    <Link
      id={`lesson.${props.lessonId}`}
      className={props.className}
      to={`/members/products/${productId}/lesson/${props.lessonId}`}
    >
      {props.children}
    </Link>
  );
}

function UnavailableLessonLink(props: { className?: string; children: ReactNode }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {/* Render an <a /> tag to satisfy <button /> (tooltips) nesting rules */}
      <a
        href=""
        className={props.className}
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(!modalOpen);
        }}
      >
        {props.children}
      </a>
      <BuyNowModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}

function Tags({ lessonId }: { lessonId: number }) {
  const tags = useStoreSelector((store) => store.lesson.entities.user_lesson_tags[lessonId]) || [];
  return (
    <>
      {tags.map((tag) => {
        return !tag.seen ? (
          <span
            key={`tag.${tag.id}`}
            className={clsx(
              "ml-2 rounded-sm px-2 py-0.5 text-[0.65rem] font-bold leading-none text-white",
              tag.variant === "danger" && "bg-rocketred",
              tag.variant === "warning" && "bg-rocketorange [text-shadow:1px_1px_rgba(0_0_0_0.2)]",
            )}
          >
            {tag.label}
          </span>
        ) : null;
      })}
    </>
  );
}

const LessonItemTestIcons = memo(({ lesson }: { lesson: LessonEntity }) => {
  const t = useTranslation();
  const rateableTests = useLessonRateableTests({ lessonId: lesson.id });
  const filteredRateableTests = withoutExtraTestsAndEnabled(rateableTests);
  const isDone = useLessonStatus(lesson.id)?.is_done;

  if (filteredRateableTests.length === 0) {
    return (
      <TooltipProvider delayDuration={200}>
        <TooltipFacelift>
          <TooltipContent>{isDone ? t("complete") : t("incomplete")}</TooltipContent>
          <TooltipTrigger>
            <div
              className={clsx(
                "flex size-8 items-center justify-center rounded-full text-lg",
                isDone ? "bg-missilegreen text-white" : "bg-missilesurfacedark text-missilegray2 dark:text-white",
              )}
            >
              <CheckIcon />
            </div>
          </TooltipTrigger>
        </TooltipFacelift>
      </TooltipProvider>
    );
  }

  return (
    <div className="flex items-center gap-2">
      {filteredRateableTests.map((rateableTest) => (
        <RateableTestIconWithTooltip key={`rt.${rateableTest.id}`} rateableTest={rateableTest} />
      ))}
    </div>
  );
});

function RateableTestIconWithTooltip({ rateableTest }: { rateableTest: RateableTestEntity }) {
  const rating = useRateableTestRating(rateableTest.id);
  const markedAsComplete = useStoreSelector(
    (store) => store.lesson.entities.user_rateable_test_ratings[rateableTest.id]?.marked_complete,
  );
  const isInProgress = Boolean(
    useStoreSelector(
      (store) => store.lesson.entities.user_rateable_test_progress[rateableTest.id]?.num_components_rated,
    ),
  );

  const iconStatus = ((): "marked-complete" | "complete" | "in-progress" | "needs-review" | "todo" => {
    if (markedAsComplete) {
      return "marked-complete";
    }

    if (rating >= 100) {
      return "complete";
    }

    if (rating > 0) {
      return "needs-review";
    }

    if (isInProgress) {
      return "in-progress";
    }

    return "todo";
  })();

  return (
    <RateableTestTooltip testTypeId={rateableTest.rateable_test_type_id} status={iconStatus}>
      <RateableTestUI.Icon rateableTest={rateableTest} className="rounded-full" />
    </RateableTestTooltip>
  );
}
