export function Calendar() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_233_9516)">
        <path
          d="M27.6429 2.88623H4.35703C2.3024 2.88623 0.636719 4.55191 0.636719 6.60655V28.2799C0.636719 30.3345 2.3024 32.0002 4.35703 32.0002H27.6429C29.6975 32.0002 31.3632 30.3345 31.3632 28.2799V6.60655C31.3632 4.55181 29.6975 2.88623 27.6429 2.88623Z"
          fill="#FFE28D"
        />
        <path
          d="M31.3632 9.876V5.36826C31.3632 3.31363 29.6975 1.64795 27.6429 1.64795H4.35703C2.3024 1.64795 0.636719 3.31363 0.636719 5.36826V9.876H31.3632Z"
          fill="#CE412D"
        />
        <path
          d="M31.3632 10.7173V6.60654C31.3632 4.55191 29.6975 2.88623 27.6429 2.88623H4.35703C2.3024 2.88623 0.636719 4.55191 0.636719 6.60654V10.7173H31.3632Z"
          fill="#E56353"
        />
        <path
          d="M6.7301 4.88379C6.01298 4.88379 5.43164 5.46512 5.43164 6.18224C5.43164 6.89936 6.01298 7.48059 6.7301 7.48059C7.44722 7.48059 8.02845 6.89926 8.02845 6.18224C8.02855 5.46512 7.44722 4.88379 6.7301 4.88379Z"
          fill="#D15241"
        />
        <path
          d="M6.72981 0C6.34621 0 6.03516 0.311052 6.03516 0.69465V6.10686C6.03516 6.49057 6.34621 6.80151 6.72981 6.80151C7.1134 6.80151 7.42446 6.49057 7.42446 6.10686V0.69465C7.42446 0.311052 7.11351 0 6.72981 0V0Z"
          fill="#4D5C7D"
        />
        <path
          d="M12.9098 4.88379C12.1927 4.88379 11.6113 5.46512 11.6113 6.18224C11.6113 6.89936 12.1927 7.48059 12.9098 7.48059C13.6269 7.48059 14.2081 6.89926 14.2081 6.18224C14.2082 5.46512 13.6269 4.88379 12.9098 4.88379Z"
          fill="#D15241"
        />
        <path
          d="M12.9095 0C12.5259 0 12.2148 0.311052 12.2148 0.69465V6.10686C12.2148 6.49057 12.5259 6.80151 12.9095 6.80151C13.2931 6.80151 13.6041 6.49057 13.6041 6.10686V0.69465C13.6041 0.311052 13.2931 0 12.9095 0V0Z"
          fill="#4D5C7D"
        />
        <path
          d="M19.0895 4.88379C18.3724 4.88379 17.791 5.46512 17.791 6.18224C17.791 6.89936 18.3724 7.48059 19.0895 7.48059C19.8066 7.48059 20.3878 6.89926 20.3878 6.18224C20.3878 5.46512 19.8066 4.88379 19.0895 4.88379Z"
          fill="#D15241"
        />
        <path
          d="M19.0902 0C18.7066 0 18.3955 0.311052 18.3955 0.69465V6.10686C18.3955 6.49057 18.7066 6.80151 19.0902 6.80151C19.4738 6.80151 19.7848 6.49057 19.7848 6.10686V0.69465C19.7848 0.311052 19.4738 0 19.0902 0V0Z"
          fill="#4D5C7D"
        />
        <path
          d="M25.2692 4.88379C24.552 4.88379 23.9707 5.46512 23.9707 6.18224C23.9707 6.89936 24.552 7.48059 25.2692 7.48059C25.9863 7.48059 26.5675 6.89926 26.5675 6.18224C26.5675 5.46512 25.9862 4.88379 25.2692 4.88379Z"
          fill="#D15241"
        />
        <path
          d="M25.2689 0C24.8853 0 24.5742 0.311052 24.5742 0.69465V6.10686C24.5742 6.49057 24.8853 6.80151 25.2689 6.80151C25.6525 6.80151 25.9635 6.49057 25.9635 6.10686V0.69465C25.9635 0.311052 25.6526 0 25.2689 0V0Z"
          fill="#4D5C7D"
        />
        <path d="M11.9736 15.8574H14.7819V18.6657H11.9736V15.8574Z" fill="#E56353" />
        <path d="M17.2168 15.8574H20.025V18.6657H17.2168V15.8574Z" fill="#E56353" />
        <path d="M22.4609 15.8574H25.2692V18.6657H22.4609V15.8574Z" fill="#E56353" />
        <path d="M6.73047 20.2598H9.5387V23.068H6.73047V20.2598Z" fill="#E56353" />
        <path d="M11.9736 20.2598H14.7819V23.068H11.9736V20.2598Z" fill="#E56353" />
        <path d="M17.2168 20.2598H20.025V23.068H17.2168V20.2598Z" fill="#E56353" />
        <path d="M22.4609 20.2598H25.2692V23.068H22.4609V20.2598Z" fill="#E56353" />
        <path d="M6.73047 24.6621H9.5387V27.4703H6.73047V24.6621Z" fill="#E56353" />
        <path d="M11.9736 24.6621H14.7819V27.4703H11.9736V24.6621Z" fill="#E56353" />
        <path d="M17.2168 24.6621H20.025V27.4703H17.2168V24.6621Z" fill="#E56353" />
      </g>
      <defs>
        <clipPath id="clip0_233_9516">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
