import { Navigate, useLocation, useParams } from "react-router-dom";
import useProductFromCourseParams from "~/hooks/useProductFromCourseParams";

/** Route: members/course/:courseId/level/:levelId/lesson/:lessonId */
export default function RedirectToProductLessonPage() {
  const lessonId = Number(useParams().lessonId);
  const product = useProductFromCourseParams();
  const location = useLocation();

  if (product) {
    // Pass through anchors, such as #know-it
    return <Navigate to={`/members/products/${product.id}/lesson/${lessonId}${location.hash}`} replace />;
  }

  return <Navigate to="/members/courses" replace />;
}
