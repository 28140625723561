import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import type { RootState, RootAction } from "./types";

import authSagas from "./auth/sagas";
import lessonSagas from "./lesson/sagas";
import dashboardSagas from "./dashboard/sagas";
import userSagas from "./user/sagas";
import moduleTestSagas from "./moduletest/sagas";
import customFlashcardsSagas from "./customFlashcards/sagas";
import uiSagas from "./ui/sagas";
import user from "./user/reducer"; // Handles user information
// import auth from "./auth/reducer"; // Authentication status & token
import auth from "@rocketlanguages/shared/store/auth/reducer";
import dashboard from "./dashboard/reducer"; // Dashboard lesson list for each product
import lesson from "./lesson/reducer"; // Stored Lessons & rateable tests
import preferences from "./preferences/reducer"; // User preferences
import moduletest from "./moduletest/reducer";
import customFlashcards from "./customFlashcards/reducer";
import { createDispatchHook, createSelectorHook, createStoreHook } from "react-redux";

/**
 * Root App reducer. This is the Redux store.
 */
export const rootReducer = combineReducers<RootState>({
  auth,
  dashboard,
  user,
  lesson,
  preferences,
  moduletest,
  customFlashcards,
});

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of
// as processes running in the background, watching actions dispatched to the store.
export function* rootSaga() {
  const allSagas = [
    ...authSagas,
    ...lessonSagas,
    ...dashboardSagas,
    ...userSagas,
    ...moduleTestSagas,
    ...customFlashcardsSagas,
    ...uiSagas,
  ];
  yield all(allSagas);
}

export const useStoreTyped = createStoreHook<RootState, RootAction>();
export const useStoreSelector = createSelectorHook<RootState, RootAction>();
export const useStoreDispatch = createDispatchHook<RootState, RootAction>();
