import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { type SidebarItem, sidebarItems } from "./sidebar";
import { Suspense, useState } from "react";

import { ActiveProductTopbar } from "~/layouts/components/TopBar/TopBar";
import BookIcon from "~/components/icons/BookIcon";
import BuyNowModal from "~/components/BuyNowModal";
import { FaLock } from "react-icons/fa";
import FaceliftPage from "~/components/FaceliftPage";
import MembersFaceliftLayout from "~/layouts/MembersLayout/MembersFaceliftLayout";
import PageLoader from "@rocketlanguages/shared/ui/PageLoader";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import useHasPermission, { can } from "@rocketlanguages/shared/hooks/useHasPermission";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import useIsMackinUser from "@rocketlanguages/shared/hooks/useIsMackinUser";
import { usePreferenceValue } from "@rocketlanguages/shared/hooks/usePreference";
import {
  SidebarAnchor,
  SidebarBackButton,
  SidebarDivider,
  SidebarSection,
  StickySidebarContainer,
} from "~/components/ui/SidebarFacelift";
import { Notes as NotesIcon, Tools as ToolsIcon } from "iconoir-react";

export default function ToolsLayout() {
  const activeProduct = useActiveProduct();
  if (!activeProduct) {
    return <Navigate to="/members/courses" replace />;
  }
  return (
    <>
      <ActiveProductTopbar />
      <MembersFaceliftLayout>
        <LayoutContent />
      </MembersFaceliftLayout>
    </>
  );
}

function LayoutContent() {
  const t = useTranslation();
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const canPostInForums = useHasPermission("post_in_forums");
  const isRSL = activeCourse?.slug === "sign-language";
  const isMackinUser = useIsMackinUser();
  const showNotes = usePreferenceValue("show_notes", "0") === "1";

  return (
    <FaceliftPage.WithoutTitle>
      <FaceliftPage.TwoColumns>
        <StickySidebarContainer>
          <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
            {activeProduct && (
              <SidebarBackButton label={t("dashboard")} to={`/members/products/${activeProduct.id}/dashboard`} />
            )}
            <SidebarDivider />
            <SidebarSection className="flex flex-1 flex-col">
              <div className="hidden h-14 items-center gap-2 px-2 font-bold md:flex md:px-5">
                <ToolsIcon />
                <span>{t("tools")}</span>
              </div>
              {sidebarItems.map((tool) => {
                // Forums: hide if user doesn't have permission to post in forums
                if (tool.path.includes("forum") && !canPostInForums) {
                  return null;
                }
                // disable certain tools for selected courses, eg: ebook
                if (tool.hiddenForCourse?.includes(activeCourse?.slug || "")) {
                  return null;
                }
                // disable certain tools for selected products, eg: travelogues
                if (tool.hiddenForProductLevel?.includes(activeProduct?.level_id || -1)) {
                  return null;
                }
                if (tool.hiddenForType?.includes(activeProduct?.type_id || 1)) {
                  return null;
                }
                if (tool.hiddenForMackinUsers && isMackinUser) {
                  return null;
                }
                if (!showNotes && tool.path === "notes") {
                  return null;
                }
                return <ToolAnchor key={tool.localeTitle} item={tool} />;
              })}
              {!isRSL && <CustomFlashcardsAnchor />}
              {isRSL && <DictionaryAnchor />}
            </SidebarSection>
          </div>
        </StickySidebarContainer>
        <div>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </FaceliftPage.TwoColumns>
    </FaceliftPage.WithoutTitle>
  );
}

function CustomFlashcardsAnchor() {
  const canSeeCustomFlashcards = useStoreSelector(
    (s) => Boolean(s.user.preferences?.show_custom_flashcards) || can(s.user, "edit_content"),
  );

  if (!canSeeCustomFlashcards) {
    return null;
  }

  return (
    <ToolAnchor
      item={{
        localeTitle: "custom-flashcards",
        path: "flashcards",
        icon: NotesIcon,
      }}
    />
  );
}

function ToolAnchor({ item }: { item: SidebarItem }) {
  const t = useTranslation();
  const [buyNowModalOpen, setBuyNowModalOpen] = useState(false);
  const activeProduct = useActiveProduct();
  const activeCourse = useActiveCourse();
  const locked = item.lockedForType?.includes(activeProduct?.type_id || -1);
  const currentPath = useLocation().pathname;

  // Forum is a special case, where the path starts with "/members/forum" instead of "/members/products/[id]/tools"
  const path =
    item.path === "/members/forum"
      ? `/members/forum/${activeCourse?.slug || ""}`
      : `/members/products/${activeProduct?.id || 0}/tools/${item.path}`;

  const active = item.path === "/members/forum" ? currentPath.startsWith(item.path) : currentPath === path;

  // const label = (() => {
  //   // // Hack for sign language, change the search Label to "Dictionary"
  //   // if (activeCourse?.slug === "sign-language" && item.localeTitle === "search") {
  //   //   return "Dictionary";
  //   // }
  //   // @ts-ignore
  //   return t(item.localeTitle);
  // })();

  const Icon = item.icon;
  return (
    <>
      <SidebarAnchor
        use={locked ? "div" : Link}
        onClick={locked ? () => setBuyNowModalOpen(true) : undefined}
        to={path}
        active={active}
        label={t(item.localeTitle)}
        icon={
          Icon ? (
            <span className="size-6 items-center justify-center">
              <Icon />
            </span>
          ) : null
        }
      >
        {locked && <FaLock />}
      </SidebarAnchor>
      <BuyNowModal isOpen={buyNowModalOpen} onClose={() => setBuyNowModalOpen(false)} />
    </>
  );
}

function DictionaryAnchor() {
  return <ToolAnchor item={{ localeTitle: "dictionary", path: "dictionary", icon: BookIcon }} />;
}
