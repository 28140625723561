import { useRef, useEffect, useCallback } from "react";

/**
 * Returns a function that allows you to retrieve the current value
 *
 * Useful for: retrieving the current state after async side effects (such as network requests)
 *
 * Read more: https://overreacted.io/how-are-function-components-different-from-classes/
 *
 * Usage:
 *
 * ```js
 *
 * function Component() {
 *
 *  const [status, setStatus] = useState("init");
 *
 *  // Use this when inside a function with async behavior
 *  const getState = useGetter(state);
 *
 *  function afterTimeout() {
 *    console.log(status) // "init", stale
 *    const currentStatus = getState(); // "loading", not stale
 *  }
 *
 *  function doTimeout() {
 *    setStatus("loading");
 *    setTimeout(afterTimeout, 3000);
 *  }
 * }
 *
 * ```
 */
export default function useGetter<T>(value: T): () => T {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return useCallback(() => valueRef.current, [valueRef]);
}
