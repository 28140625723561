import { ActiveProductTopbar, EbookTopBar } from "~/layouts/components/TopBar/TopBar";
import { Banner } from "../components/Banner/Banner";
import type { Course, Product } from "@rocketlanguages/types";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Suspense, memo, useEffect, useMemo } from "react";
import { useStoreDispatch, useStoreSelector } from "../../store";
import AppPromptModal from "~/components/AppPromptModal";
import BadgeNotification from "../../components/ui/BadgeNotification/BadgeNotification";
import CompleteAccountModal from "../../components/CompleteAccountModal";
import Courses from "@rocketlanguages/shared/res/courses";
import PageLoader from "@rocketlanguages/shared/ui/PageLoader";
import PrintWatermark from "~/layouts/components/PrintWatermark";
import type { ReactNode } from "react";
import { SentryErrorBoundary } from "../../components/ErrorBoundary";
import SignupModal from "../components/SignupModal";
import classes from "./MembersFaceliftLayout.module.scss";
import { clsx } from "clsx";
import { isMobile } from "react-device-detect";
import { setActiveCourseAndProduct } from "@rocketlanguages/shared/store/preferences/actions";
import { shallowEqual } from "react-redux";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import { useParams } from "react-router-dom";

const getCourseAndProductFromId = (productId: number) => {
  for (const course of Courses) {
    for (const product of course.products) {
      if (product.id === productId) {
        return {
          course,
          product,
        };
      }
    }
  }
  return null;
};

const MembersFaceliftLayout = memo((props: { children?: ReactNode }) => {
  const { hasIncompleteDetails, isGuest, activeProductId } = useStoreSelector((store) => {
    return {
      isGuest: store.user.isGuest,
      hasIncompleteDetails: store.user.hasMissingName || store.user.hasMissingPassword,
      activeProductId: store.preferences.activeProduct?.id,
    };
  }, shallowEqual);

  const rankUp = useStoreSelector((store) => store.user.stats.newRank);
  const { pathname } = useLocation();
  const pageName = pathname.split("/").pop();
  const showCompleteAccountModal = !isGuest && hasIncompleteDetails;

  return (
    <LoginGuard>
      {showCompleteAccountModal && <CompleteAccountModal />}
      {!showCompleteAccountModal && isMobile && <AppPromptModal />}
      {rankUp && <BadgeNotification />}
      {isGuest && <SignupModal />}
      {pageName !== "courses" && activeProductId && !showCompleteAccountModal && <Banner productId={activeProductId} />}

      <PrintWatermark />
      <div className={clsx("relative flex w-full flex-col items-center", classes.container)}>
        <SentryErrorBoundary key={1}>
          <Suspense fallback={<PageLoader />}>{props.children || <Outlet />}</Suspense>
        </SentryErrorBoundary>
      </div>
    </LoginGuard>
  );
});

/** Applies for layouts with /:productId */
export function MembersFaceliftProductIdLayout(props: { children?: ReactNode }) {
  const productId = Number(useParams()?.productId);

  return (
    <LoginGuard>
      <ProductChangeGuard productId={productId}>
        {productId === 261 ? <EbookTopBar /> : <ActiveProductTopbar />}
        <MembersFaceliftLayout {...props} />
      </ProductChangeGuard>
    </LoginGuard>
  );
}

/** Redirects to /login when unauthenticated */
function LoginGuard(props: { children: ReactNode }) {
  const isLoggedIn = useStoreSelector((s) => s.auth.status === "loggedin");

  if (!isLoggedIn) {
    // Replace the current page with the login page,
    // but preserve the current URL so that the user can be redirected back to the page they were on
    return <Navigate to="/login" replace state={{ redirectLocation: window.location.pathname }} />;
  }

  return <>{props.children}</>;
}

function jsonEqual<T extends object>(a: T, b: T) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function ProductChangeGuard({ productId, children }: { productId: number; children: ReactNode }) {
  const dispatch = useStoreDispatch();
  const products = useStoreSelector((store) => store.user.products, jsonEqual);
  const activeProduct = useActiveProduct();

  // Syncs the active product/course with the productId in the URL
  const changeProductAndCourse = useMemo((): [Course, Product] | undefined | null => {
    if (productId !== activeProduct?.id) {
      const courseAndProduct = getCourseAndProductFromId(productId);
      if (courseAndProduct) {
        // Check if user has the product
        const hasProduct = [...products.paid, ...products.trial].some((p) => p.id === courseAndProduct.product.id);
        if (hasProduct) {
          return [courseAndProduct.course, courseAndProduct.product];
        }
        return null; // User doesn't have the product, so redirect to courses
      }
    }
  }, [activeProduct?.id, productId, products.paid, products.trial]);

  useEffect(() => {
    if (changeProductAndCourse) {
      const [course, product] = changeProductAndCourse;
      dispatch(setActiveCourseAndProduct(course, product));
    }
  }, [changeProductAndCourse, dispatch]);

  // The URL params don't match the user's active product, so render nothing until it changes
  if (changeProductAndCourse) {
    return null;
  }

  // User doesn't have the product
  if (changeProductAndCourse === null) {
    return <Navigate to="/members/courses" replace />;
  }

  return <>{children}</>;
}

/** Applies for layouts with /:courseSlug/level/:levelId */
export function MembersFaceliftCourseSlugLevelIdLayout() {
  const courseSlug = useParams()?.courseSlug;
  const productLevelId = Number(useParams()?.levelId);
  const dispatch = useStoreDispatch();
  const [course, product] = useUserProductFromCourseSlugAndLevel(String(courseSlug), Number(productLevelId));
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);

  // No user-owned product/course found
  if (!course || !product) {
    return <Navigate to="/members/courses" replace />;
  }

  const activeProductHasChanged = product.id !== activeProduct?.id;

  // User has the product, but it doesnt match url, switch active course and product
  if (activeProductHasChanged) {
    dispatch(setActiveCourseAndProduct(course, product));
    return null;
  }

  // User still doesn't have an active product
  if (!activeProduct) {
    return <Navigate to="/members/courses" replace />;
  }

  return (
    <>
      {activeProduct.id === 261 ? <EbookTopBar /> : <ActiveProductTopbar />}
      <MembersFaceliftLayout />
    </>
  );
}

/** Derives user-owned products & courses from URL params */
const useUserProductFromCourseSlugAndLevel = (courseSlug: string, productLevelId: number) => {
  const course = useMemo(() => Courses.find((c) => c.slug === courseSlug), [courseSlug]);
  const products = useStoreSelector((store) => store.user.products);

  return useMemo((): [null, null] | [Course, Product | null] => {
    if (!course) {
      return [null, null];
    }
    const userProducts = [...products.paid, ...products.trial];

    return [
      course,
      course.products.find(
        (p) =>
          p.level_id === productLevelId && userProducts.some((up) => up.id === p.id && up.course_id === p.course_id),
      ) || null,
    ];
  }, [course, productLevelId, products.paid, products.trial]);
};

export default MembersFaceliftLayout;
