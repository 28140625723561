import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function usePageQuery() {
  const location = useLocation();
  return useMemo(() => {
    if (location.search) {
      const query = location.search.slice(1);
      return queryString.parse(query);
    }
    return {};
  }, [location.search]);
}

export const getStaticPageQuery = () => {
  if (typeof window === "undefined") {
    return {};
  }
  const query = window.location?.search?.slice(1) || "";
  return queryString.parse(query);
};
