import { clsx } from "clsx";

type NotationButtonProps = {
  title: string;
  variant?: "faded";
};

export default function Notation(props: NotationButtonProps) {
  return (
    <div
      className={clsx(
        "inline-flex h-6 select-none whitespace-nowrap rounded-t-lg px-2 py-1 text-center text-xs font-semibold focus:ring-0 print:hidden",
        props.variant === "faded"
          ? "bg-rocketred-extra-light text-white active:bg-rocketred-extra-light"
          : "bg-missilesurfacedark text-missilebrand dark:text-white",
      )}
    >
      {props.title}
    </div>
  );
}
