/**
 * Takes a webvtt track in string format and converts it to a blob url.
 * Returns a url string.
 * @param track
 */

export function getSubtitleUrlFromTranscript(track: string) {
  const blob = new Blob([track], { type: "text/vtt;charset=utf-8" });
  try {
    return URL.createObjectURL(blob);
  } catch (err) {
    return "";
  }
}
