import type { ReactNode } from "react";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { TooltipContent, TooltipFacelift, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui";
import type { RateableTestLocaleKey } from "@rocketlanguages/types";
import { RateableTestMetadata } from "@rocketlanguages/shared/utils/constants-web";
import { RateableTestTypeIds } from "@rocketlanguages/shared/utils/constants";

type RateableTestTooltipProps = {
  testTypeId: number;
  children: ReactNode;
  status: RateableTestTooltipStatus;
};

export type RateableTestTooltipStatus = "marked-complete" | "complete" | "in-progress" | "needs-review" | "todo";

export default function RateableTestTooltip({ testTypeId, children, status }: RateableTestTooltipProps) {
  const localeKey = RateableTestMetadata[testTypeId]?.localeKey;

  if (!localeKey) {
    console.warn(`No locale key found for rateable test type ${testTypeId}`);
    return <>{children}</>;
  }

  return (
    <TooltipProvider delayDuration={200}>
      <TooltipFacelift>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent>
          <TooltipLabelRenderer localeKey={localeKey} status={status} testTypeId={testTypeId} />
        </TooltipContent>
      </TooltipFacelift>
    </TooltipProvider>
  );
}

const nonPhraseTests: number[] = [
  RateableTestTypeIds.QUIZ,
  RateableTestTypeIds.TRANSLATE_IT,
  RateableTestTypeIds.DRAW_IT,
];

function TooltipLabelRenderer(props: {
  localeKey: RateableTestLocaleKey;
  status: RateableTestTooltipStatus;
  testTypeId: number;
}) {
  const t = useTranslation();

  const { status, localeKey } = props;

  let ratingText = t("todo");
  switch (status) {
    case "marked-complete":
    case "complete":
      ratingText = t("complete");
      break;
    case "needs-review":
      ratingText = nonPhraseTests.includes(props.testTypeId) ? "Needs review" : "Phrases need review";
      break;
    case "in-progress":
      ratingText = "In progress";
      break;
    case "todo":
      ratingText = t("todo");
      break;
  }

  return (
    <>
      <span className="font-bold">{t(localeKey)}</span>
      <br />
      {ratingText}
    </>
  );
}
