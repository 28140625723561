import { clsx } from "clsx";
import classes from "./InputLabel.module.scss";

interface InputLabelProps {
  name?: string;
  children?: React.ReactNode | string;
  className?: string;
}

export default function InputLabel(props: InputLabelProps) {
  return (
    <label className={clsx(classes.label, props.className)} htmlFor={props.name}>
      {props.children}
    </label>
  );
}
