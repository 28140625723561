import type { LessonComponent, FileComponent as FileComponentType } from "@rocketlanguages/types";

export type Props = {
  lessonComponent: LessonComponent<"file", FileComponentType>;
};

export default function FileComponent(props: Props) {
  const { lessonComponent } = props;
  return <a href={lessonComponent.component.path}>Click to download {lessonComponent.component.filename}</a>;
}
