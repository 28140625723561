import { ActiveProductTopbar, AvatarCircle, Topbar } from "~/layouts/components/TopBar/TopBar";
import { FiHelpCircle, FiLogOut, FiMail, FiStar, FiUser } from "react-icons/fi";
import { Link, Outlet, useLocation } from "react-router-dom";

import FaceliftPage from "~/components/FaceliftPage";
import MembersFaceliftLayout from "~/layouts/MembersLayout/MembersFaceliftLayout";
import PageLoader from "@rocketlanguages/shared/ui/PageLoader";
import { Suspense } from "react";
import { logout } from "@rocketlanguages/shared/store/auth/actions";
import { toast } from "react-toastify";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import { useDispatch } from "react-redux";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import useCanContactSupport from "@rocketlanguages/shared/hooks/useCanContactSupport";
import useIsMackinUser from "@rocketlanguages/shared/hooks/useIsMackinUser";
import {
  SidebarAnchor,
  SidebarBackButton,
  SidebarDivider,
  SidebarSection,
  StickySidebarContainer,
} from "~/components/ui/SidebarFacelift";

export default function UtilsLayout() {
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);

  return (
    <>
      {activeProduct ? (
        <ActiveProductTopbar />
      ) : (
        <Topbar>
          <Topbar.LogoLink hasSidebar use={Link} path={"/members/courses"} title="Rocket Languages" />
          <AvatarCircle />
        </Topbar>
      )}
      <MembersFaceliftLayout>
        <UtilsPage />
      </MembersFaceliftLayout>
    </>
  );
}

export function UtilsPage() {
  const t = useTranslation();
  const activeProductId = useActiveProduct()?.id;
  return (
    <FaceliftPage.WithoutTitle>
      <FaceliftPage.TwoColumns>
        <StickySidebarContainer>
          <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
            {activeProductId ? (
              <SidebarBackButton to={`/members/products/${activeProductId}/dashboard`} label={t("dashboard")} />
            ) : (
              <SidebarBackButton to="/members/courses" label="Back to Courses" />
            )}
            <SidebarDivider />
            <SidebarSection>
              <UtilsSidebar />
            </SidebarSection>
          </div>
        </StickySidebarContainer>
        <div>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </FaceliftPage.TwoColumns>
    </FaceliftPage.WithoutTitle>
  );
}

export function UtilsSidebar() {
  const t = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const product = useActiveProduct();
  const course = useActiveCourse();
  const canContactSupport = useCanContactSupport();
  const isMackinUser = useIsMackinUser();

  return (
    <>
      {!isMackinUser && (
        <SidebarAnchor
          use={Link}
          to={"/members/profile"}
          label={t("profile")}
          active={location.pathname === "/members/profile"}
          icon={<FiUser className="size-6" />}
        />
      )}
      {canContactSupport && product && course?.slug !== "ebook" && (
        <SidebarAnchor
          use={Link}
          to={`/members/products/${product.id}/reviews`}
          label={t("reviews")}
          active={location.pathname === `/members/products/${product.id}/reviews`}
          icon={<FiStar className="size-6" />}
        />
      )}
      <SidebarAnchor
        use={Link}
        to={"/members/help"}
        label={t("help-center")}
        active={location.pathname === "/members/help"}
        icon={<FiHelpCircle className="size-6" />}
      />
      {canContactSupport && (
        <SidebarAnchor
          use={Link}
          to={"/members/contact-us"}
          label={t("contact-us")}
          active={location.pathname === "/members/contact-us"}
          icon={<FiMail className="size-6" />}
        />
      )}
      <SidebarAnchor
        label={t("log-out")}
        icon={<FiLogOut className="size-6" />}
        onClick={() => {
          dispatch(logout(true));
          toast.success(t("logged-out"));
        }}
      />
    </>
  );
}
