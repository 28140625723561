import type { QuizAnswer, VideoComponent } from "@rocketlanguages/types";
import { useMemo } from "react";
import { shuffleArray } from "../utils";

function smartShuffle(arr: Array<{ component: VideoComponent; bias: number }>) {
  return [...arr].sort((a, b) => {
    const foo = a.bias * Math.random();
    const bar = b.bias * Math.random();
    return foo > bar ? -1 : 1;
  });
}

export default function useTranslateItQuestions(
  videoComponents: VideoComponent[],
  testComponents: VideoComponent[],
  deps?: string,
) {
  const depsToUse = deps || JSON.stringify(videoComponents) + JSON.stringify(testComponents);
  return useMemo(() => {
    const questions: QuizAnswer[][] = [];
    const biasedComponents = videoComponents.map((component) => ({ bias: videoComponents.length, component }));
    let shuffledVideoComponents: typeof biasedComponents = [];

    const offsetBias = (componentId: number, offset: number) => {
      const b = biasedComponents.find((item) => item.component.id === componentId);
      if (b) {
        b.bias += offset;
      }
    };

    for (let i = 0; i < testComponents.length; i++) {
      // Re-shuffle video components
      shuffledVideoComponents = smartShuffle(biasedComponents);

      const question: QuizAnswer[] = [];
      const testComponent = testComponents[i];

      if (!testComponent) {
        continue;
      }

      // Push answer
      question.push({
        id: testComponent.id,
        text: testComponent.caption,
        is_correct: 1,
      });

      const limit = Math.min(videoComponents.length, 5);

      // Make it less likely that the current question will be an option on the next question
      offsetBias(testComponent.id, -(limit * 3));

      for (let j = 0; question.length < limit; j++) {
        const component = shuffledVideoComponents[j]?.component;

        if (!component) {
          break;
        }

        const { id, caption } = component;

        // Disallow duplicates
        if (question.some((q) => q.text === caption)) {
          continue;
        }
        question.push({
          id,
          text: caption,
          is_correct: 0,
        });
        offsetBias(id, -limit);
      }

      // Shuffle answers
      questions[i] = shuffleArray(question);
    }

    return questions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depsToUse]);
}
