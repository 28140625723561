import { useState } from "react";
import type { ReactNode } from "react";
import AddToVocabPopover from "./AddToVocabPopover/AddToVocabPopover";
import AddToVocabModal from "./VocabModals/AddToVocabModal";

/**
 * Hooks in mouseup/mousedown/resize listeners for popover, provides button popover, and modal UI
 */
export function AddToVocab(props: { children: ReactNode }) {
  const [selection, setSelection] = useState<string | null>(null);
  return (
    <>
      <div>
        {/** The container ref will be used for listening for selection events */}
        {props.children}
      </div>

      {/** Attaches selection listeners to containerRef, pops up with a "save" button */}
      <AddToVocabPopover onConfirmSelect={setSelection} />
      {selection && (
        <AddToVocabModal
          mode={{
            val: "selection",
            text: selection,
          }}
          onClose={() => setSelection(null)}
        />
      )}
    </>
  );
}
