import classes from "./Centered.module.scss";
import { clsx } from "clsx";

type CenteredProps = React.HTMLAttributes<HTMLDivElement> & {
  absolute?: boolean;
  column?: boolean;
  horizontal?: boolean;
};

export default function Centered({ className, absolute, column, horizontal, ...rest }: CenteredProps) {
  return (
    <div
      className={clsx(
        classes.container,
        absolute && classes.absolute,
        column && classes.column,
        horizontal && classes.horizontal,
        className,
      )}
      {...rest}
    />
  );
}
