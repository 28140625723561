import { Link } from "react-router-dom";
import type { ReactNode } from "react";
import { NavArrowLeft as NavArrowLeftIcon, Tools as ToolsIcon } from "iconoir-react";
import { clsx } from "clsx";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import classes from "./SidebarFacelift.module.scss";
import { twMerge } from "tailwind-merge";

export function StickySidebarContainer(props: { children: ReactNode; className?: string }) {
  return (
    <aside className={clsx("fixed w-52 md:sticky md:w-auto", classes.StickySidebar)}>
      <div
        className={twMerge(
          clsx("flex flex-col overflow-y-auto pb-8 pt-2", classes.StickySidebar__content, props.className),
        )}
        style={{ scrollbarWidth: "thin", scrollbarColor: "rgb(227, 232, 235) transparent" }}
      >
        {props.children}
      </div>
    </aside>
  );
}

export function SidebarBackButton(props: { label: string; to: string }) {
  return (
    <Link
      to={props.to}
      className="flex h-12 w-full items-center justify-start gap-2 rounded-3xl px-5 font-bold leading-5 hover:bg-missilestroke focus:outline-none focus:ring focus-visible:ring dark:hover:bg-neutral-700"
    >
      <NavArrowLeftIcon aria-hidden className="size-4 md:size-6" />
      <span className="inline-block">{props.label}</span>
    </Link>
  );
}

type SidebarAnchorProps<TComponentType extends React.ElementType> = {
  active?: boolean;
  onClick?: React.MouseEventHandler;
  icon?: ReactNode;
  label: string;
  className?: string;
  use?: TComponentType;
  children?: ReactNode;
} & React.ComponentPropsWithoutRef<TComponentType>;

export function SidebarAnchor<TComponentType extends React.ElementType>({
  active,
  onClick,
  icon,
  label,
  className,
  use: Component = "a" as TComponentType,
  children,
  ...rest
}: SidebarAnchorProps<TComponentType>) {
  const componentProps = {
    className: twMerge(
      clsx(
        "flex h-12 w-full cursor-pointer items-center gap-2 rounded-3xl px-5 hover:bg-missilestroke hover:dark:bg-neutral-700",
        active && "bg-missilestroke dark:bg-neutral-700",
        className,
      ),
    ),
    onClick,
    ...rest,
  };

  const ComponentWithProps = Component as React.ElementType;

  return (
    <ComponentWithProps {...componentProps}>
      {icon ? icon : null}
      <div className="line-clamp-2">{label}</div>
      {children}
    </ComponentWithProps>
  );
}

export function SidebarDivider(props: { className?: string }) {
  return (
    <div className={twMerge(clsx("flex h-1 items-center px-5 py-2", props.className))}>
      <div className="h-[1px] w-full bg-missilestroke" />
    </div>
  );
}

export function SidebarToolsLink(props: { className?: string }) {
  const activeCourse = useActiveCourse();
  const activeProductId = useActiveProduct()?.id;
  const t = useTranslation();
  // Mackin users & ebook products can't access tools
  if (activeCourse?.slug === "ebook") {
    return null;
  }

  return (
    <Link
      to={`/members/products/${activeProductId}/tools/leaderboard`}
      className={clsx(
        "flex h-12 w-full cursor-pointer items-center gap-2 rounded-3xl px-5 hover:bg-missilestroke hover:dark:bg-neutral-700",
        props.className,
      )}
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-500/10 bg-missilestroke text-missilegray2 dark:text-white">
        <ToolsIcon className="size-4" strokeWidth={2} />
      </div>
      <span>{t("tools")}</span>
    </Link>
  );
}

export function SidebarSection({
  title,
  left,
  children,
  className,
}: {
  title?: string;
  left?: JSX.Element;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={className}>
      {title && left ? (
        <div className="hidden h-12 items-center md:flex">
          {left}
          <h4 className="font-bold tracking-wide">{title}</h4>
        </div>
      ) : null}

      <div>{children}</div>
    </div>
  );
}
