import { type ReactNode, createContext, memo, useState } from "react";
import type { SetStateFunction } from "@rocketlanguages/types";

type QuizState = "idle" | "started" | "revealed";

export const DrawItContext = createContext<
  | {
      testContainerRef: React.MutableRefObject<HTMLDivElement | null>;
      showGrid: boolean;
      setShowGrid: SetStateFunction<boolean>;
      showShadow: boolean;
      setShowShadow: SetStateFunction<boolean>;
      enableHints: boolean;
      setEnableHints: SetStateFunction<boolean>;
      quizState: QuizState;
      setQuizState: SetStateFunction<QuizState>;
    }
  | undefined
>(undefined);

const DrawItContextProvider = memo(function DrawItContextProvider({
  children,
  testContainerRef,
}: {
  children: ReactNode;
  testContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const [showGrid, setShowGrid] = useState(false);
  const [showShadow, setShowShadow] = useState(true);
  const [enableHints, setEnableHints] = useState(true);
  const [quizState, setQuizState] = useState<QuizState>("idle");

  return (
    <DrawItContext.Provider
      value={{
        testContainerRef,
        showGrid,
        setShowGrid,
        showShadow,
        setShowShadow,
        enableHints,
        setEnableHints,
        quizState,
        setQuizState,
      }}
    >
      {children}
    </DrawItContext.Provider>
  );
});

export default DrawItContextProvider;
