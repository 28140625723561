import { cloneElement, useRef, useEffect } from "react";

interface FocusOnMountProps {
  /** Whether to enable focus. Default: true */
  value?: boolean;
  tabIndex?: number;
  children: JSX.Element;
}

export default function FocusOnMount({ value, tabIndex = 0, children }: FocusOnMountProps) {
  const elementRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (value) {
      elementRef.current?.focus();
    }
  }, [value]);

  return cloneElement(children, {
    ref: elementRef,
    /*
    onKeyPress:
      children.props.onKeyPress ||
      ((e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === "Space") {
          children.props.onClick?.();
        }
      }),
    */
    tabIndex,
  });
}
