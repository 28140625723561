// Actions that handle the request to /api/v1/product/1/module-page-data
export const SAGA_REQUEST_PAGE_DATA = "ModuleTest/SAGA_REQUEST_PAGE_DATA";
export const PAGE_DATA_REQUEST_SUCCESS = "ModuleTest/PAGE_DATA_REQUEST_SUCCESS";
export const PAGE_DATA_REQUEST_ERROR = "ModuleTest/PAGE_DATA_REQUEST_ERROR";

// Actions that handle the request to /api/v1/grouped-test/module/97/tests
export const SAGA_REQUEST_MODULE_TESTS = "ModuleTest/SAGA_REQUEST_MODULE_TESTS";
export const MODULE_TESTS_REQUEST_SUCCESS = "ModuleTest/TESTS_REQUEST_SUCCESS";
export const MODULE_TESTS_REQUEST_ERROR = "ModuleTest/TESTS_REQUEST_ERROR";

// Dynamic UI updates
// export const UPDATE_TEST_RATINGS = "ModuleTest/UPDATE_TEST_RATINGS";
export const RESET_TEST_SUCCESS = "ModuleTest/RESET_TEST_SUCCESS";
export const SAGA_RATE_SUBTEST = "ModuleTest/SAGA_RATE_SUBTEST";
export const SAGA_RATE_TEST = "ModuleTest/SAGA_RATE_TEST";
export const SET_TEST_RATING = "ModuleTest/SET_TEST_RATING";
