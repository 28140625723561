import { type RefObject, useEffect, useRef } from "react";
import type { AudioComponent } from "@rocketlanguages/types";
import TranscriptLine from "./TranscriptLine";
import VirtualizedList from "./VirtualizedList";
import classes from "./TranscriptList.module.scss";
import { clsx } from "clsx";
import useActiveCueIndex from "./useActiveCueIndex";
import type useMedia from "../../../../hooks/useMedia";
import { useSliderProgress } from "./state";
import { detected } from "../../../../utils/browser";

type TranscriptListProps = {
  subtitles: TextTrackCue[];
  height: number;
  component: AudioComponent;
  player: ReturnType<typeof useMedia>;
};

export default function TranscriptList({ height, component, player, subtitles }: TranscriptListProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const activeIndex = useActiveCueIndex(player?.id, subtitles);

  useScrollToActiveLine({ activeIndex, listRef, playerId: player?.id }, [subtitles]);

  if (!component.audio_transcript || subtitles.length === 0) {
    return null;
  }

  return (
    <VirtualizedList
      ref={listRef}
      data={subtitles}
      index={activeIndex}
      height={height}
      windowSize={7}
      className={clsx(classes.shadow_bottom, "overflow-hidden dark:before:!shadow-none lg:after:!shadow-none")}
      renderItem={(item, index) => (
        <TranscriptLine
          key={`t.${component.id}.${index}`}
          index={index}
          activeIndex={activeIndex}
          subtitle={item as TextTrackCue}
          player={player}
          phrase={component.phrases?.[(item as TextTrackCue).startTime]}
        />
      )}
    />
  );
}

type UseScrollToActiveLineParams = {
  activeIndex: number;
  playerId: number | undefined;
  listRef: RefObject<HTMLDivElement>;
};

function useScrollToActiveLine({ activeIndex, listRef, playerId }: UseScrollToActiveLineParams, deps: unknown[]) {
  const seeking = useSliderProgress((s) => s[`seeking_${playerId || 0}`]);
  useEffect(() => {
    if (listRef.current) {
      const index = Math.max(activeIndex, 0);
      scrollToElement({
        selector: `div[data-index="${index}"]`,
        parent: listRef.current,
        // Note: Safari 15.4 has a bug where scroll offsets seem to not work for smooth scroll
        instant: seeking || detected?.name === "safari",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, listRef, seeking]);

  // When settings change (subtitles, phrase mode), instantly scroll to the active cue
  useEffect(() => {
    if (listRef.current) {
      const index = Math.max(activeIndex, 0);
      scrollToElement({
        selector: `div[data-index="${index}"]`,
        parent: listRef.current,
        instant: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

function scrollToElement({ selector, parent, instant }: { selector: string; parent: HTMLElement; instant: boolean }) {
  const element = parent.querySelector<HTMLDivElement>(selector);
  if (!element) {
    return;
  }

  // Setting inline style of scroll-behavior to 'auto' temporarily
  parent.scrollTo({ top: element.offsetTop, behavior: !instant ? "smooth" : undefined });
}
