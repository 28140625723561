import type { Character, Phrase, PhraseString, TranscriptLine } from "@rocketlanguages/types";
import {
  RomanizedWritingSystemIds,
  WritingSystemIds as WS,
  WritingSystemIds,
  WritingSystemLanguages,
} from "../../../../../utils/constants";

// Make sure the underline is not processed as bold/italics by the phrase string renderer
const markdownEscapedUnderline = "\\_\\_";
const markdownEscapedLargeUnderline = "\\_\\_\\_";
const markdownEscapedUnderlineForRtl = "\\_";

// eslint-disable-next-line
const punctuationRegex = new RegExp(/([ ‘’"'“”.~¿,！、　。？，¡?!])/);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function hideEverySecondCharacter(originalString: string, _isRtl = false) {
  const characters = originalString.trim();
  let modifiedString = "";
  // Every second character
  for (let i = 0; i < characters.length; i += 1) {
    if (i % 2 === 0 && characters.length !== 1) {
      modifiedString += characters[i];
    } else if (characters[i]?.match(punctuationRegex)) {
      modifiedString += characters[i];
    } else {
      modifiedString += markdownEscapedLargeUnderline;
    }
  }
  return modifiedString;
}

type RegexSegment = {
  characters: string;
  index: number;
};

/**
 * Hides every second word
 * @param {string} originalString
 * @param {boolean} isRtl Applies a custom arabic character instead of underscores because of LTR-RTL issues
 */
function hideEverySecondWord(originalString: string, isRtl: boolean = false) {
  const allCharacters = originalString.split(punctuationRegex).filter((_word) => !!_word);

  const { words, punctuation } = (() => {
    const words: RegexSegment[] = [];
    const punctuation: RegexSegment[] = [];
    allCharacters.forEach((_characters, i) => {
      const segment = {
        characters: _characters,
        index: i,
      };
      punctuationRegex.test(_characters) ? punctuation.push(segment) : words.push(segment);
    });
    return { words, punctuation };
  })();

  const replacedWords = [...words].map((_segment, i) => {
    if (i % 2 !== 0) {
      if (isRtl) {
        return {
          ..._segment,
          characters: markdownEscapedUnderlineForRtl,
        };
      }
      if (_segment.characters.length === 1) {
        return {
          ..._segment,
          characters: markdownEscapedUnderline,
        };
      }
      return {
        ..._segment,
        characters: markdownEscapedUnderline.repeat(_segment.characters.length - 1),
      };
    }
    return _segment;
  });

  const rejoinedWords = [...replacedWords, ...punctuation].sort((a, b) => a.index - b.index);
  const finalString = rejoinedWords.reduce((acc, val) => (acc += val.characters), "");

  return finalString;
}

export const phraseStringTransformer = {
  default: hideEverySecondWord,
  [WS.kanji]: hideEverySecondCharacter,
  [WS.hanzi]: hideEverySecondCharacter,
} satisfies Record<string, (orig: string, isRtl?: boolean) => string>;

type PhraseStringOptions = {
  /** Hides the notation button associated with the phrase string */
  displayText?: string;
  /** Blur the phrase string */
  blur?: boolean;
};

export function createPhraseStringDisplaySettings(options: {
  /** Whether user has opted to hide every second word/character */
  applyStringTransformer: boolean;
  /** Affects how the phrase string will be modified */
  courseSlug: string;
  /** A set of writing system IDs to filter from the phrase */
  filterWs: Set<number>;
}) {
  return function phraseStringOptions(phraseString: PhraseString): PhraseStringOptions | undefined {
    const baseOptions: PhraseStringOptions = {
      blur: options.filterWs.has(phraseString.writing_system_id),
    };

    // If user hasn't opted to hide every second word/character
    if (!options.applyStringTransformer) {
      return baseOptions;
    }

    // Don't modify any of the English translations
    if (
      phraseString.writing_system_id === WS.english &&
      options.courseSlug !== "english" &&
      options.courseSlug !== "ingles"
    ) {
      return baseOptions;
    }

    // For Ingles, Spanish is the native translation
    if (options.courseSlug === "ingles" && phraseString.writing_system_id === WS.spanish) {
      return baseOptions;
    }

    const transformer = phraseStringTransformer[phraseString.writing_system_id] || phraseStringTransformer.default;
    const isRtl = phraseString.writing_system_id === WS.arabic;
    // @ts-ignore
    const transformedText = transformer(phraseString.text, isRtl);

    return {
      ...baseOptions,
      displayText: transformedText,
    };
  };
}

/**
 * Returns a list of unique characters from a transcript
 */
export function getCharacters(lines: TranscriptLine[]): Character[] {
  const chars: Character[] = [];
  // Every transcript line...
  for (const { character } of lines) {
    if (character) {
      const characterId = character.id;
      if (chars.findIndex((c) => c?.id === characterId) === -1) {
        chars.push(character);
      }
    }
  }

  // UI - First character that speaks should be the second "Play as.." button
  return chars.reverse();
}

export function getPlayableCharacters(lines: TranscriptLine[]) {
  const allCharacters = getCharacters(lines);
  if (allCharacters.length < 2) {
    return undefined;
  }

  // Reverse, because the leftmost character to talk to should begin with that character talking, as opposed to the user talking
  return allCharacters.slice(-2).reverse() as [Character, Character];
}

/**
 * Returns a map of unique languages from a transcript
 */
export function getLanguages(
  lines: TranscriptLine[],
  phraseMap: { [phraseId: number]: Phrase | undefined },
  romanizationHidden = false,
  kanaHidden = false,
): Map<number, string> {
  const lang: Map<number, string> = new Map();

  // For each transcript line...
  for (const line of lines) {
    const phrase = phraseMap[line.phrase_id];
    if (!phrase) {
      continue;
    }
    // Get every phrase string..
    for (const phraseString of phrase.strings) {
      const { writing_system_id: writingSystemId } = phraseString;

      const writingSystemLanguage = WritingSystemLanguages[writingSystemId];

      // Already have writing system, or WS doesn't exist in enum
      if (lang.has(writingSystemId) || !writingSystemLanguage) {
        continue;
      }

      // Filter out romanization
      if (romanizationHidden && RomanizedWritingSystemIds.includes(writingSystemId)) {
        continue;
      }

      // Filter out kana if hidden
      if (kanaHidden && writingSystemId === WritingSystemIds.hiragana_katakana) {
        continue;
      }

      // Add to list of languages
      lang.set(writingSystemId, writingSystemLanguage);
    }
  }

  return lang;
}
